
var colors = {
    background: '#ffffff',
    primary: '#000000',
    secondary: '#00000055',
    separator: '#00000022',
    placeholder: '#ECECEC'
}

var darkColors = {
    background: '#222222',
    primary: '#ffffff',
    secondary: '#ffffff55',
    separator: '#ffffff22',
    placeholder: '#262626'
}

var styles = {
    'default': { name: 'Light', colors: colors },
    //'dark': { name: 'Dark', colors: darkColors },
}


var fonts = { 
    largeTitleHeader : { name: 'Large Title Header', size: 34, weight: 'bold', color: "primary", fontFamily: 'Roboto Slab', fontStyle: 'regular' },
    titleHeader : { name: 'Title Header', size: 34, weight: 'bold', color: "primary", fontStyle: 'regular' },
    productTitleHeader : { name: 'Heading 2', size: 22,  weight: 'bold',color: "primary"},
    cardTitleHeader : { name: 'Heading 2', size: 24,  weight: '600',color: "primary"},
    summaryText : { name: 'Summary Text', size: 15,  weight: '600',color: "primary"},
    h1 : { name: 'Heading 1', size: 28, weight: 'bold', color: "primary" },
    h2 : { name: 'Heading 2', size: 18,  weight: 'bold',color: "primary"},
    h3 : { name: 'Heading 3', size: 16, weight: 'bold', color: "primary" },
    body : { name: 'Body', size: 16, lineHeight: 1.5, color: "primary" },
    subtitle : { name: 'Subtitle', size: 14, color: "secondary" },
    headline : { name: 'Headline', size: 16, color: "secondary",weight: 'bold' },
    headline2 : { name: 'Headline 2', size: 16, color: "secondary",weight: 'bold' },
    quote : { name: 'Quote', size: 28, weight: 'bold', color: "primary", fontFamily: '-apple-system-ui-serif, ui-serif', fontStyle: 'italic' },
    subhead: { name: 'Subhead', size: 15, weight: 'regular', color: "primary"},
    caption: { name: 'Caption', size: 12, weight: 'regular', color: "secondary"},
    captionUppercased: { name: 'Caption (Uppercase)', size: 12, weight: 'bold', color: "secondary", textTransform: "uppercase"},
    footnote: { name: 'Footnote', size: 13, weight: 'regular', color: "secondary" }
}

export { fonts, styles }