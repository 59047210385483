
import React from 'react';
import { Section, VStack, Text, Image, YapUI } from '@yapstudios/yap-content-builder';

export default class ImageComponent {
    constructor(url, defaults) {
        this.type = 'image';
        this.url = url;
        this.defaults = defaults
    }

    defaultProps() {
        return {
            url: this.url,
            description: this.description,
            ...this.defaults
        };
    }

    yapUI(props) {
        let image = YapUI.Directive('Image', { src: props.url, description: props.description, dimensions: props.dimensions, crop: props.crop, title: props.title }, [  ])
        return YapUI.Directive('ContentImage', { footnote: props.footnote, aspect: props.aspect, fullbleed: props.fullbleed }, [ image ])
    }

    decode(props) {
        return (<Section paddingVertical={20} paddingHorizontal={props.fullbleed ? 0 : 20} title="Image">
                    <VStack spacing={5} >
                    <Image  id="image"
                            editOptions={['aspect', 'fullbleed']} 
                            containerWidth={350}
                            dimensions={props.dimensions} 
                            placeholder={true} 
                            aspect={props.aspect} 
                            fullbleed={props.fullbleed} 
                            src={props.url} 
                            crop={props.crop}
                            description={props.description} 
                            accessibilityLabel="Icon"></Image>
                        <Text align={"left"} id="footnote" style={"footnote"} content={props.footnote} placeholder={"Footnote"}/>
                    </VStack>
                </Section>);
    }

    encode(model, props) {
        switch (model.id) {
            case "footnote":
                props.footnote = model.content;
                break
            case "image":
                props.url = model.src;
                props.description = model.description;
                props.aspect = model.aspect;
                props.fullbleed = model.fullbleed;
                props.dimensions = model.dimensions;    
                props.crop = model.crop;
                props.title = model.title;
                break;
        }

        return props;
    }

    icon() {
        return (
            <svg width="101" height="71" viewBox="0 0 101 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="70" transform="translate(0.769379 0.245972)" fill="white"/>
                <g clipPath="url(#clip0_156_2883)">
                <rect x="18.6031" y="16.2543" width="64.7159" height="44.6738" fill="#384CC0" fillOpacity="0.12"/>
                <path d="M25.3848 38.9877L21.1658 42.4457C19.5436 43.7753 18.6031 45.7873 18.6031 47.8847C18.6031 51.7507 21.7371 54.9377 25.6031 54.9377H76.5796C80.3017 54.9377 83.3191 51.9204 83.3191 48.1983C83.3191 45.92 82.168 43.796 80.2593 42.5521L64.2842 32.141C61.8958 30.5845 58.8021 30.6319 56.4625 32.2608L44.5538 40.5521C42.1538 42.2231 38.9678 42.226 36.5647 40.5593L33.8115 38.6497C31.2423 36.8678 27.803 37.0058 25.3848 38.9877Z" fill="#384CC0"/>
                <ellipse cx="34.3629" cy="29.6276" rx="4.02356" ry="4.03194" fill="#384CC0"/>
                </g>
                <rect x="19.9611" y="17" width="62" height="37" rx="9" stroke="#384CC0" strokeOpacity="0.4" strokeWidth="2"/>
                <defs>
                <clipPath id="clip0_156_2883">
                <rect x="18.9611" y="16" width="64" height="39" rx="10" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        )
    }
}
