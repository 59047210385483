import React from 'react';
import styled from 'styled-components'
import { useStyle } from '../Style.tsx'
import { useActions } from '../Actions.tsx';

export function ConfigurationLabel({ title, children }) {
    var style = {...useStyle()}
    var actions = {...useActions()}

    style['textAlign'] = style['textAlign'] || 'center'
    return <_Button style={style} {...actions}>{children}</_Button>
}

const _Button = styled('button').withConfig({ shouldForwardProp: () => { return true } })`
    border: 0px;
    font-size: initial;
`