import * as React from 'react'
import { Page } from '../page-components/Page'
import { ContentBuilderStateProvider } from '../content-builder-components/ContentBuilderStateProvider'
import { CanvasContext } from '../content-builder-components/ContentBuilderCanvas'
import * as htmlToImage from 'html-to-image';

/** 
 * Renders a dynamic / DOM based preview 
 */
function ContentPreview({ page, document, dark, components, width, interactionEnabled, styles, fonts, pageRenderer, renderer }) {
    var contentPage = {
        ...page,
        style: dark ? "dark" : page.style
    }
    let pageContent = <Page {...contentPage} selected={false} />

    const interactionClass = (interactionEnabled == true ? "interaction-enabled" : "interaction-disabled")

    const innerContent = (
        <ContentBuilderStateProvider components={components} 
                                    componentTemplates={null} 
                                    pages={[contentPage]} 
                                    onPagesUpdated={null}
                                    onAssetImport={null}
                                    onAssetDelete={null}
                                    onAssetChange={null}
                                    assets={[]} 
                                    templates={[]}
                                    isReadonly={true}
                                    renderMarkdown={true}
                                    styles={styles}
                                    fonts={fonts}
                                    pageRenderer={pageRenderer}
                                    >
                        <CanvasContext.Provider value={{}}>
                                        {pageContent}
                        </CanvasContext.Provider>
                </ContentBuilderStateProvider>
    )

    if (renderer && renderer.render) {
        return <div className={`polymer-preview-item ${interactionClass}`}>
                    {renderer.render({ width: width, page: page, document: document }, innerContent)}
                </div>
        
    } else {

        var width = width ?? 100

        const canvasWidth = 375
        const canvasHeight = 816
        const scale = width / canvasWidth

        const borderWidth = 20 // From CSS
        const displayWidth = (canvasWidth + (borderWidth * 2)) * scale
        const displayHeight = (canvasHeight + (borderWidth * 2)) * scale

        const containerStyle = {
            'width' : `${displayWidth}px`,
            'height' : `${displayHeight}px`,
        }

        const style = {
            'transform' : 'scale(' + scale + ')'
        }


        return (
            <div className={`polymer-preview-item ${interactionClass}`} style={containerStyle}>
                <div className="polymer-preview-item-content" style={style}>
                    {innerContent}
                </div>
            </div>
        )
    }
}

/** 
 * Renders a static png preview 
 */
function ContentPreviewStatic({ page, components,  width, interactionEnabled, imageData, onImageData }) {
    var [loadedImageData, setLoadedImageData] = React.useState(null)
    const [generating, setIsGenerating] = React.useState(false)

    if (imageData != null) {
        loadedImageData = imageData
    }

    const ref = React.useRef(null)
    
    var width = width ?? 100

    const canvasWidth = 375
    const canvasHeight = 816
    const scale = width / canvasWidth

    const borderWidth = 20 // From CSS
    const displayWidth = (canvasWidth + (borderWidth * 2)) * scale
    const displayHeight = (canvasHeight + (borderWidth * 2)) * scale

    React.useEffect( () => {
        if (loadedImageData != null)   { return }
        if (ref.current == null) { return }
        if (generating) { return }
        
        const node = ref.current

        setIsGenerating(true)
        
        htmlToImage.toPng(node, { skipFonts: true, imagePlaceholder: "/background-grid-light.png" })
            .then(function (dataUrl) {
                setLoadedImageData(dataUrl)
                if (onImageData) {
                    onImageData(dataUrl)
                }
                setIsGenerating(false)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
                setIsGenerating(false)
            });
    }, [ref.current])

    const pageContent = <Page {...page} selected={false} />

    const containerStyle = {
        position: 'absolute',
        top: '-10000px',
        left: '-10000px',
    }

    const style = {
        width: `${displayWidth}px`,
        height: `${displayHeight}px`,
        overflow: 'hidden'
    }

    const imgStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'top left'
    }

    const imageContent = (
        <div className="polymer-preview-item-static" style={style}>
            <img src={loadedImageData} style={imgStyle}/>
        </div>
    )

    const interactionClass = (interactionEnabled == true ? "interaction-enabled" : "interaction-disabled")

    const previewItem = (
        <div className={`polymer-preview-item ${interactionClass} `} style={containerStyle}>
            <div className="polymer-preview-item-content" id={"preview-node"} ref={ref}>
                <ContentBuilderStateProvider components={components} 
                                    componentTemplates={null} 
                                    pages={[page]} 
                                    onPagesUpdated={null}
                                    onAssetImport={null}
                                    onAssetDelete={null}
                                    onAssetChange={null}
                                    assets={[]} 
                                    templates={[]}
                                    isReadonly={true}>
                        <CanvasContext.Provider value={{}}>
                                        {pageContent}
                        </CanvasContext.Provider>
                </ContentBuilderStateProvider>
            </div>
        </div>
    )

    return (
        <div>
            {imageContent}
            {loadedImageData == null && page && previewItem}
        </div>
    )
}

export { ContentPreview, ContentPreviewStatic }