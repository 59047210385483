import React from 'react'

const chevronDown = <path d="M9.25684 12.1387C9.5498 12.1387 9.7959 12.0273 10.0244 11.793L14.3604 7.35742C14.5361 7.18164 14.624 6.9707 14.624 6.71289C14.624 6.19141 14.208 5.76953 13.6924 5.76953C13.4404 5.76953 13.2002 5.875 13.0068 6.06836L9.2627 9.93555L5.5127 6.06836C5.31934 5.875 5.0791 5.76953 4.82129 5.76953C4.30566 5.76953 3.88965 6.19141 3.88965 6.71289C3.88965 6.96484 3.97754 7.17578 4.15332 7.35742L8.48926 11.793C8.72949 12.0332 8.96973 12.1387 9.25684 12.1387Z"/>

const chevronRight = <path d="M11.9961 8.76953C11.9961 8.47656 11.8906 8.23633 11.6504 8.00781L7.2207 3.66602C7.04492 3.49023 6.82812 3.40234 6.57031 3.40234C6.05469 3.40234 5.63281 3.82422 5.63281 4.33398C5.63281 4.5918 5.73828 4.83203 5.93164 5.01953L9.79297 8.76367L5.93164 12.5137C5.73828 12.707 5.63281 12.9414 5.63281 13.2051C5.63281 13.7207 6.05469 14.1367 6.57031 14.1367C6.82812 14.1367 7.04492 14.0488 7.2207 13.873L11.6504 9.53125C11.8965 9.29688 11.9961 9.05664 11.9961 8.76953Z"/>

const doc = <path d="M6.74805 16.644H14.5483C16.1157 16.644 16.9287 15.8164 16.9287 14.2417V7.59863C16.9287 6.58057 16.7969 6.11182 16.1597 5.46729L12.3145 1.55615C11.6992 0.933594 11.1938 0.787109 10.2783 0.787109H6.74805C5.18066 0.787109 4.36768 1.61475 4.36768 3.18945V14.2417C4.36768 15.8237 5.18066 16.644 6.74805 16.644ZM6.86523 15.2085C6.15479 15.2085 5.80322 14.835 5.80322 14.1538V3.27734C5.80322 2.60352 6.15479 2.22266 6.86523 2.22266H9.98535V6.23633C9.98535 7.28369 10.5054 7.78906 11.5454 7.78906H15.4932V14.1538C15.4932 14.835 15.1343 15.2085 14.4238 15.2085H6.86523ZM11.6772 6.5293C11.377 6.5293 11.2524 6.39746 11.2524 6.09717V2.42041L15.2954 6.5293H11.6772Z"/>

const iconPhoto =  <path d="M4.27002 15.5527H16.8164C18.3984 15.5527 19.2188 14.7397 19.2188 13.1797V4.25879C19.2188 2.69873 18.3984 1.88574 16.8164 1.88574H4.27002C2.68799 1.88574 1.86768 2.69141 1.86768 4.25879V13.1797C1.86768 14.7397 2.68799 15.5527 4.27002 15.5527ZM3.30322 4.3833C3.30322 3.67285 3.67676 3.31396 4.35791 3.31396H16.7285C17.4023 3.31396 17.7832 3.67285 17.7832 4.3833V12.125L14.1138 8.68994C13.7842 8.39697 13.4033 8.24316 13.0151 8.24316C12.6123 8.24316 12.2534 8.38232 11.9165 8.67529L8.61328 11.6343L7.2583 10.4111C6.95068 10.1401 6.61377 9.99365 6.26953 9.99365C5.93262 9.99365 5.63232 10.1328 5.32471 10.4038L3.30322 12.2202V4.3833ZM7.54395 8.85107C8.50342 8.85107 9.29443 8.06006 9.29443 7.09326C9.29443 6.13379 8.50342 5.33545 7.54395 5.33545C6.56982 5.33545 5.78613 6.13379 5.78613 7.09326C5.78613 8.06006 6.56982 8.85107 7.54395 8.85107Z" />

const iconText =  <path d="M2.9375 14.2051C3.42822 14.2051 3.67725 14 3.83838 13.4873L4.65869 11.2021H8.84082L9.65381 13.4873C9.81494 14 10.0713 14.2051 10.5547 14.2051C11.0674 14.2051 11.397 13.8975 11.397 13.4141C11.397 13.2383 11.3677 13.0918 11.3018 12.894L8.05713 4.13428C7.83008 3.51904 7.41992 3.21875 6.75342 3.21875C6.11621 3.21875 5.69873 3.51904 5.48633 4.12695L2.21973 12.9307C2.15381 13.1211 2.12451 13.2749 2.12451 13.4287C2.12451 13.9121 2.43213 14.2051 2.9375 14.2051ZM5.07617 9.84717L6.73145 5.08643H6.77539L8.43066 9.84717H5.07617ZM15.6963 14.249C16.9487 14.249 17.9082 13.6631 18.355 12.6157H18.4136V13.5093C18.4209 13.9854 18.7212 14.2563 19.1387 14.2563C19.5781 14.2563 19.8784 13.9707 19.8784 13.48V6.63184C19.8784 6.14844 19.5781 5.85547 19.1387 5.85547C18.7139 5.85547 18.4136 6.14844 18.4136 6.63184V7.51807H18.355C17.9082 6.49268 16.9121 5.87012 15.6963 5.87012C13.6675 5.87012 12.3345 7.54004 12.3345 10.0596C12.3345 12.5938 13.6675 14.249 15.6963 14.249ZM16.1211 12.9673C14.7515 12.9673 13.8945 11.854 13.8945 10.0596C13.8945 8.27246 14.7588 7.14453 16.1211 7.14453C17.4834 7.14453 18.377 8.30908 18.377 10.0742C18.377 11.8467 17.4907 12.9673 16.1211 12.9673Z"/>

const iconContainer =  <path d="M4.89795 15.5527H17.4443C19.0264 15.5527 19.8467 14.7397 19.8467 13.1797V4.25879C19.8467 2.69873 19.0264 1.88574 17.4443 1.88574H4.89795C3.31592 1.88574 2.49561 2.69141 2.49561 4.25879V13.1797C2.49561 14.7397 3.31592 15.5527 4.89795 15.5527ZM4.98584 14.1172C4.30469 14.1172 3.93115 13.7656 3.93115 13.0552V4.3833C3.93115 3.67285 4.30469 3.31396 4.98584 3.31396H17.3564C18.0303 3.31396 18.4111 3.67285 18.4111 4.3833V13.0552C18.4111 13.7656 18.0303 14.1172 17.3564 14.1172H4.98584Z"/>

const iconStack =  <path d="M2.68408 7.14795H15.3696C16.5781 7.14795 17.186 6.55469 17.186 5.34619V2.49707C17.186 1.2959 16.5781 0.702637 15.3696 0.702637H2.68408C1.47559 0.702637 0.867676 1.2959 0.867676 2.49707V5.34619C0.867676 6.55469 1.47559 7.14795 2.68408 7.14795ZM2.85986 5.77832C2.45703 5.77832 2.24463 5.56592 2.24463 5.14844V2.70215C2.24463 2.27734 2.45703 2.07227 2.85986 2.07227H15.1865C15.5967 2.07227 15.8091 2.27734 15.8091 2.70215V5.14844C15.8091 5.56592 15.5967 5.77832 15.1865 5.77832H2.85986ZM2.68408 14.7432H15.3696C16.5781 14.7432 17.186 14.1499 17.186 12.9414V10.0923C17.186 8.89111 16.5781 8.29785 15.3696 8.29785H2.68408C1.47559 8.29785 0.867676 8.89111 0.867676 10.0923V12.9414C0.867676 14.1499 1.47559 14.7432 2.68408 14.7432ZM2.85986 13.3662C2.45703 13.3662 2.24463 13.1611 2.24463 12.7437V10.2974C2.24463 9.87256 2.45703 9.66748 2.85986 9.66748H15.1865C15.5967 9.66748 15.8091 9.87256 15.8091 10.2974V12.7437C15.8091 13.1611 15.5967 13.3662 15.1865 13.3662H2.85986Z"/>

const iconSpeaker =  <path d="M1.86768 8.98291C1.86768 11.2021 2.51221 13.6558 3.59619 15.5527C3.80127 15.9116 4.18945 16.0215 4.55566 15.8384C4.91455 16.3657 5.52979 16.5781 6.22559 16.373C7.10449 16.1021 7.5 15.3403 7.23633 14.4688L6.18164 10.9897C5.91797 10.1255 5.1709 9.72998 4.28467 9.98633C3.91113 10.0962 3.62549 10.3013 3.44238 10.5723C3.36914 10.0376 3.3252 9.50293 3.3252 8.98291C3.3252 4.97656 5.77148 2.31055 9.44092 2.31055C13.1104 2.31055 15.564 4.97656 15.564 8.98291C15.564 9.50293 15.5273 10.0376 15.4468 10.5723C15.2637 10.3086 14.978 10.1035 14.5972 9.98633C13.7183 9.72998 12.9712 10.1255 12.7075 10.9897L11.6528 14.4688C11.3818 15.3477 11.7847 16.1021 12.6562 16.373C13.3594 16.5781 13.9746 16.3657 14.3262 15.8457C14.6997 16.0215 15.0879 15.9116 15.293 15.5527C16.377 13.6558 17.0288 11.2021 17.0288 8.98291C17.0288 4.09766 14.0039 0.853027 9.44092 0.853027C4.88525 0.853027 1.86768 4.09766 1.86768 8.98291Z"/>

export var contentIcons = {
  "doc" : doc,
  "photo" : iconPhoto,
  "text"  : iconText,
  "stack" : iconStack,
  "container" : iconContainer,
  "audio" : iconSpeaker
}

export function contentIcon(name, fill) {
    return <svg width="22" height="18" viewBox="0 0 22 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
        {contentIcons[name]}
    </svg>    
}

export function chevronIcon(name, fill) {
    var icons = {
        "down" : chevronDown,
        "right" : chevronRight,
      }

    return <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
        {icons[name]}
    </svg>    
}