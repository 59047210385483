import { Sidebar, SidebarTile, SidebarSectionHeader, SidebarItem, SidebarSection } from "../components/Sidebar.js"
import * as React from 'react'
import Masonry from 'react-masonry-css'
import { useAppState } from '../content-builder-components/ContentBuilderStateProvider.js'
import AssetFileUploader from './AssetFileUploader.js'
import ProgressBar from "../uikit-components/ProgressBar.js"
import Header from "../uikit-components/Header.js"
import Toolbar from "../uikit-components/Toolbar.js"
import { Page, PageContent } from "../uikit-components/Page.js"
import Scrollable from "../uikit-components/Scrollable.js"
import HeaderButton from "../uikit-components/HeaderButton.js"
import AssetLibraryAlbums from "./AssetLibraryAlbums.js"
import AssetLibraryAssets from "./AssetLibraryAssets.js"
import { AssetDetail, AssetEditModeType } from "./AssetDetail.js"
import { assetDetailRows } from "./AssetDetail.js"
import { TableSection } from '../shared-components/TableSection.js';
var lastSelectedAssetLibrary = null
import { v4 as uuid } from 'uuid';

// CSS
import './AssetComponents.css';
import { TableRow } from "../shared-components/TableRow.js"
import AssetImage from "./AssetImage.js"
import { PolymerMenu, PolymerMenuActionMore, PolymerMenuOption, menuEditIcon, menuTrashIcon } from "../uikit-components/Menu.js"
import { useIcons } from "../content-builder-components/ContentBuilderIconProvider.js"

function AssetUploadProgress({ progress, total, index }) {
    return (
        <div className="asset-upload-progress">
            <h1>Uploading {index + 1} of {total}</h1>
            <ProgressBar progress={progress} />
        </div>
    )
}

export default function AssetLibraryBrowser(props) {
    const { canCrop, canMultiUpload, onAssetSelected, setShowAssetLibrary, canAddAssetType, assetLibraryTypes, assets, onAssetImport, onAssetChange, onAssetDelete, onCancel } = props// useAppState()

    const [selectedAlbumId, setSelectedAlbumId] = React.useState(lastSelectedAssetLibrary)
    const [selectedAsset, setSelectedAsset] = React.useState(null)
    const [editingAsset, setEditingAsset] = React.useState(null)
    const [uploadingAsset, setUploadingAsset] = React.useState(false)
    const [uploadingError, setUploadingError] = React.useState(null)
    const [assetUploadProgress, setAssetUploadProgress] = React.useState(0)
    const [assetUploadProgressInfo, setAssetUploadProgressInfo] = React.useState({ total: 1, index: 0})    

    const uploadCancelCallback = React.useRef(null)
    const uploadCancelled = React.useRef(false)
    const uploadCompleteCallback = React.useRef(null)

    const uploadingAssetRef = React.useRef(null)
    uploadingAssetRef.current = uploadingAsset

    const assetsHaveSections = assets.filter( a => {  return (a.assets) }).length != 0
    
    // Remember album selection if user brings back up
    if (selectedAlbumId) {
        lastSelectedAssetLibrary = selectedAlbumId
    }

    // Get current selected album base don id
    const selectedAlbum = assetsHaveSections ? 
                                (assets.find(section => section.id === selectedAlbumId || section.name === selectedAlbumId)) : 
                                { assets: assets, name: 'Asset Library' }

    const onAssetEdit = (asset) => {
        setEditingAsset(asset)
    }

    const progressCallback = (progress, progressInfo, uploading, error) => {
        if (uploadingAssetRef.current == false) {
            console.warn(`progressCallback called while no asset uploading. progress [${progress}]`);
            return;
        }

        let total = progressInfo.total
        let index = progressInfo.index

        let fileProgress = index / total
        let totalProgress = fileProgress + (progress / total)

        if (error) {
            setUploadingError(error)
            setUploadingAsset(false)
            setAssetUploadProgress(0)
        } else {
            if (uploading != null && uploading == false && index == total - 1) {
                setAssetUploadProgress(1.0)
                setUploadingAsset(false)       
                console.log('- upload complete')             
            } else {
                setAssetUploadProgress(totalProgress)
                setUploadingAsset(true)        
                console.log(`- upload progress [${totalProgress}]`);
            }
        }
    }

    const importableAssets = (assetLibraryTypes ?? []).filter(type => {
        if (canAddAssetType) {
            return canAddAssetType(type)
        } else {
            return true
        }
    })

    const importButton = importableAssets.length > 0 ? (
        <AssetFileUploader 
            canMultiUpload={canMultiUpload}
            types={importableAssets}
            withDimensions={true}
            onWillBeginUploading={() => {
                setUploadingAsset(true)
            }}
            onError={() => {
                uploadCancelCallback.current = null
                setUploadingAsset(false)
            }}
            onUploadFile={(file, dimensions, isVideo, progressInfo, onComplete) => {
                var asset = { id: uuid(), title: removeFileExtension(file.name), created: new Date(), type: file.type, isVideo: isVideo, size: file.size, dimensions: dimensions }

                setAssetUploadProgress(0)  
                setAssetUploadProgressInfo(progressInfo)
                setUploadingAsset(true)
                setSelectedAsset(asset) 
                
                uploadCompleteCallback.current = onComplete

                const currentUploadCancelCallback = onAssetImport(file, asset, { id: selectedAlbumId, ...selectedAlbum }, 
                (progress, uploading, error) => {
                    progressCallback(progress, progressInfo, uploading, error)
                }, (asset) => {

                    if (progressInfo.index == progressInfo.total - 1) {
                        setUploadingAsset(false)
                        setSelectedAsset(asset)
                    }

                    setAssetUploadProgress(0)                
                    onComplete(true)

                }, (error) => {
                    setUploadingAsset(false)
                    setAssetUploadProgress(0)     

                    if (error) {
                        setUploadingError(error)
                    }
                    onComplete(false)
                })
                
                uploadCancelCallback.current = currentUploadCancelCallback
                  
            }}>{"Add Asset"}</AssetFileUploader>
    ) : null

    const actions = [
        <HeaderButton key={"cancel"} onClick={onCancel}>{"Cancel"}</HeaderButton>,
        <HeaderButton key={"choose"} primary={true} onClick={() => {
            if (selectedAsset && onAssetSelected.current) {
                onAssetSelected.current(selectedAsset)
                setShowAssetLibrary(false)
            }
        }} disabled={selectedAsset ? false : true}>{"Choose"}
        </HeaderButton>
    ]

    const albumBack = () => {
        setSelectedAlbumId(null)
        setSelectedAsset(null)
    }

    const onAssetDeleteAction = (asset) => {
        if (onAssetDelete) { onAssetDelete(asset) }
        setEditingAsset(null)
        setSelectedAsset(null)
    }

    var content = null

    // Editing
    if (editingAsset) {
        const editingAssetBack = () => {
            setEditingAsset(null)
        }

        content = (
            <Page fullscreen={true} onBack={editingAssetBack}>
                <Header largeTitle={false} title={editingAsset.title} />
                <AssetDetail canEditName={true} 
                             canEditDescription={true} 
                             canCrop={canCrop}
                             modalEdit={true} 
                             editMode={true} 
                             asset={editingAsset} 
                             assets={assets} 
                             assetTemplates={[]} 
                             onAssetChange={(asset) => {
                    if (onAssetChange) { onAssetChange(asset) }
                    setSelectedAsset(asset)
                }} onDelete={onAssetDeleteAction} />
            </Page>
        )

    // Uploading Error
    } else if (uploadingError) {
        const okButton = <HeaderButton primary={true} onClick={() => {
            setUploadingError(null)
        }}>{"Ok"}</HeaderButton>

        content = (
            <Page fullscreen={true}>
                <Header largeTitle={false} title={selectedAlbum.name} headerActions={[]} />
                <div className="asset-upload-progress">
                    <h1>Upload Error</h1>
                    <p>{uploadingError}</p>
                </div>
                <Toolbar actionsRight={[okButton]}></Toolbar>
            </Page>
        )

    // Uploading
    } else if (uploadingAsset) {
        const cancelButton = <HeaderButton primary={false} onClick={() => {
            
            setAssetUploadProgress(0)  
            setAssetUploadProgressInfo({index: 0, total: 0})
            setSelectedAsset(null)
            setUploadingAsset(false)           
    
            if (uploadCancelCallback.current) {
                uploadCancelCallback.current()
            }

            if (uploadCompleteCallback.current) {
                uploadCompleteCallback.current(false)
            }

        }} >Cancel</HeaderButton>

        content = (
            <Page fullscreen={true}>
                <Header largeTitle={false} title={selectedAlbum.name} headerActions={[]} backAction={cancelButton} onBack={albumBack} />
                <AssetUploadProgress progress={assetUploadProgress} total={assetUploadProgressInfo.total} index={assetUploadProgressInfo.index}/>
            </Page>
        )

    // Album Selected
    } else if (selectedAlbum) {
        const importAction = <HeaderButton key={"import"}>{importButton}</HeaderButton>

        content = (
            <Page fullscreen={true}>
                <Header largeTitle={false} title={selectedAlbum.name} onBack={assetsHaveSections ? albumBack : null} headerActions={[importAction]}></Header>

                <div className="layout">
                    <section className="layout-center">
                        <Scrollable>
                            <PageContent>
                                <AssetLibraryAssets assetLibraryTypes={assetLibraryTypes} selectedAsset={selectedAsset} assets={selectedAlbum.assets} onAssetSelected={(asset) => {
                                    asset != selectedAsset ? setSelectedAsset(asset) : setSelectedAsset(null)
                                }} />
                            </PageContent>
                        </Scrollable>
                    </section>
                    <section className="layout-right">
                        <Scrollable>
                            <PageContent>
                                {selectedAsset && <AssetLibraryAssetDetail key={selectedAsset.assetVersion} onDelete={onAssetDeleteAction} onEdit={onAssetEdit} asset={selectedAsset} />}
                            </PageContent>
                        </Scrollable>
                    </section>
                </div>

                <Toolbar actionsRight={actions}></Toolbar>
            </Page>
        )

        // Asset Selected
    } else {
        content = (
            <Page fullscreen={true}>
                <Header largeTitle={false} title={"Asset Library"}></Header>
                <Scrollable>
                    <PageContent>
                        <AssetLibraryAlbums albums={assets} assetLibraryTypes={assetLibraryTypes} onAlbumSelected={(albumId) => {
                            setSelectedAlbumId(albumId)
                        }} />
                    </PageContent>
                </Scrollable>
                <Toolbar actionsRight={actions}></Toolbar>
            </Page>
        )
    }

    return content
}


function AssetLibraryAssetDetail({ asset, onDelete, onEdit }) {
    let icons = useIcons()

    var centerImage = {
        'minHeight': '256px'
    }

    const onDeleteMenuOption = () => {
        if (onDelete) {
            const result = window.confirm(`Are you sure you want to delete ${asset.title}`);
            if (result) {
                onDelete(asset)
            }
        }
    }

    const onEditMenuOption = () => {
        if (onEdit) { onEdit(asset) }
    }

    let canDelete = asset.canDelete != false
    let canEdit = asset.canEdit != false

    var menu = (
        <PolymerMenu action={<PolymerMenuActionMore />}>
            {canDelete && <PolymerMenuOption title="Delete" onClick={onDeleteMenuOption} icon={icons.get('menu.trash', 'color-destructive')} destructive={true} />}
            {canEdit && <PolymerMenuOption title="Edit" onClick={onEditMenuOption} icon={icons.get('menu.edit')} />}
        </PolymerMenu>
    )

    if (canDelete == false && canEdit == false) {
        menu = null
    }

    return (
        <div className="asset-detail-content">
            <div className={"center-content"} style={centerImage}>
                <AssetImage key={asset.src} background={true} asset={asset} variableWidth={true} maxWidth={284} allowPlayback={true} />
            </div>

            <TableSection tableHeader={true} title={asset.title} actions={menu}>
                {assetDetailRows(asset)}
            </TableSection>

            <TableSection tableHeader={true} title={"Alt Text"}>
                <span className={asset.description == null ? "empty-text" : null}>{asset.description ? asset.description : "No Alt Text"}</span>
            </TableSection>
        </div>
    )
}


function removeFileExtension(filename) {
    var lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex !== -1) {
        return filename.substring(0, lastDotIndex);
    }
    return filename;
}
