import React , { useMemo, useEffect } from 'react';
import { Section, ZStack, View , useViewState, InspectorControlButton } from '@yapstudios/yap-content-builder';
import { InspectorContent, InspectorControlText, InspectorControlSelect, InspectorProperty, InspectorHeader } from '@yapstudios/yap-content-builder';
import { Map, Marker, CoordinateRegion } from 'mapkit-react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Viewer, RequestScheduler, Cesium3DTileset, Ion, Ellipsoid, Cartesian3, HeadingPitchRange, Math as CesiumMath } from 'cesium';
import Cesium from 'cesium'
import "cesium/Build/Cesium/Widgets/widgets.css";

// Team
// David Fumberger
// David Fumberger - J4SCZ3H32F

let mapkitToken = "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjVIRlZRSjJDM00ifQ.eyJpc3MiOiJKNFNDWjNIMzJGIiwiaWF0IjoxNzA3NzkzNzQzLCJleHAiOjE3Mzk0MDQ4MDB9.y6zdU3ro0N2f87iXno0qvvYmd9YRZQbGu5Ji6Fkigpu4Q3s_lqHZZAoDolKicRO_8xAIwzvSehbC56nsFfgkhw"

export default class MapComponent {
    constructor(title, url) {
        this.type = 'map-component';
        this.defaultTitle = title ?? "Location Title";
        this.defaultDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
        this.defaultURL = url;
    }
    

    defaultProps() {
        return {
            title: this.defaultTitle,
            description: this.defaultDescription,
            pageType : "location",
            latitude: 42.374856,
            longitude: -71.118228
        };
    }

    // Titles used for validation
    titlesForProp() {
        return {
            title: 'Location Title',
            latitude: 'Latitude'
        };
    }

    // Element ids used for validation
    elementsForProp() {
        return {
            title: 'map'
        };
    }

    decode(props) {
        return (
            <Section>
                <ComponentContent {...props} model={props}/>
            </Section>
        )
    }

    encode(model, props) {
        var newProps = {...model}
        return newProps;
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="70" fill="white"/>
                <rect x="8.11734" y="7.02233" width="83.8066" height="55.5572" rx="6" fill="#384CC0" fillOpacity="0.1"/>
                <path d="M38.7393 44.1816V29.7227C38.7393 29.3646 38.8145 29.0566 38.9648 28.7988C39.1224 28.5339 39.3623 28.3118 39.6846 28.1328L44.7764 25.2109C44.8695 25.1536 44.9697 25.0999 45.0771 25.0498C45.1846 24.9925 45.2848 24.9424 45.3779 24.8994V42.6992L40.8662 45.1377C40.5296 45.3239 40.2288 45.417 39.9639 45.417C39.5771 45.417 39.2764 45.3096 39.0615 45.0947C38.8467 44.8799 38.7393 44.5755 38.7393 44.1816ZM46.8496 42.4951V24.7168C46.9499 24.7383 47.0501 24.7705 47.1504 24.8135C47.2578 24.8564 47.3581 24.9066 47.4512 24.9639L52.7578 28.208V45.7393C52.679 45.7178 52.5967 45.6891 52.5107 45.6533C52.432 45.6175 52.3496 45.5781 52.2637 45.5352L46.8496 42.4951ZM54.2188 45.707V28.0039L58.9561 25.3721C59.2926 25.1859 59.5934 25.0928 59.8584 25.0928C60.2451 25.0928 60.5459 25.2002 60.7607 25.415C60.9756 25.6299 61.083 25.9342 61.083 26.3281V40.7979C61.083 41.1559 61.0042 41.4674 60.8467 41.7324C60.6963 41.9902 60.46 42.2087 60.1377 42.3877L54.541 45.5674C54.4909 45.596 54.4372 45.6211 54.3799 45.6426C54.3298 45.6712 54.276 45.6927 54.2188 45.707Z" fill="#384CC0"/>
            </svg>
        )
    }
}

function ComponentContent(props) {
    let height = 800

    const [editing, setEditing] = React.useState(false)

    let imageMapContent = <CMapContent editing={editing} latitude={props.latitude} longitude={props.longitude} 
                        pitch={props.pitch}
                        heading={props.heading}
                        height={props.height} />

    let standardMapContent = <MapContent editing={editing} latitude={props.latitude} longitude={props.longitude} 
    pitch={props.pitch}
    heading={props.heading}
    height={props.height} />

    let mapContent = props.mapStyle == "standard" ? standardMapContent : imageMapContent

    let infoContent = (
        <div className="map-info">
            <h2>{props.title}</h2>
            <span>{props.description}</span>
        </div>
    )

    let headerContent = (
        <div className="map-header">
            <h2>{props.title}</h2>
            <span>{props.description}</span>
        </div>
    )

    let overlayContnet = props.pageType == "header" ? headerContent : infoContent

    let viewContent = (
        <ZStack  width={"infinity"} height={height} align={props.pageType == "header" ? "leading" : "trailing"}>
            <div className={editing ? "allow-interaction" : null} style={{height: height + "px"}}>
                {mapContent}
            </div>
            {editing ? null : overlayContnet}        
        </ZStack>
    )

    return (
        <View width={"infinity"} model={props.model}  height={height} inspector={ (model, updateModel) => {
            return <ComponentInspector onEditingChanged={setEditing} model={model} updateModel={updateModel}/>
        }}>
            {viewContent}
            {/* {editing ? mapContent : viewContent } */}
        </View>
    )
}


function ComponentInspector({ onEditingChanged }) {
    const [render, setRender] = React.useState(0)

    const { model, updateModel } = useViewState()

    const editingRef = React.useRef(null)
    const editing = editingRef.current

    const pageTypes = { 'location' : 'Location ', 'header' : 'Header' }

    let pageInfo = [
        <InspectorHeader title={"Page Info"} />,

                
        <InspectorProperty title={"Layout"} >
            <InspectorControlSelect value={model.pageType} options={Object.values(pageTypes)} keys={Object.keys(pageTypes)} onChange={(v) => {
                model.pageType = v
                updateModel(model)
            }} />                    
        </InspectorProperty> ,  

        <InspectorProperty  title={"Title"}>
            <InspectorControlText value={model.title} onChange={v => {
                //var model = {...model}
                model.title = v
                updateModel(model)
            }} /> 
        </InspectorProperty>,

        <InspectorProperty title={"Description"} singleColumn={true}>
            <InspectorControlText multiline={true} value={model.description} onChange={v => {
                    model.description = v
                    updateModel(model)
                }} /> 
        </InspectorProperty>     ,


        <InspectorProperty divider={true} title={"Detail Page"} singleColumn={false}>
        <InspectorControlText multiline={false} value={model.detailPageId} onChange={v => {
                model.detailPageId = v
                updateModel(model)
            }} /> 
        </InspectorProperty>  


    ]

    let mapCoords = [
        <InspectorProperty property={'latitude'} title={`Latitude`}>     
            <InspectorControlText value={model.latitude} onChange={v => {
                model.latitude = v
                updateModel(model)
            }} />                                              
        </InspectorProperty>   ,

        <InspectorProperty property={'longitude'}  title={`Longitude`}>     
            <InspectorControlText value={model.longitude} onChange={v => {
                model.longitude = v
                updateModel(model)
            }} />                                              
        </InspectorProperty>,    

        <InspectorProperty property={'height'} title={`Height`}>     
            <InspectorControlText value={model.height} onChange={v => {
                model.height = v
                updateModel(model)
            }} />                                              
        </InspectorProperty>,   


        <InspectorProperty property={'heading'} title={`Heading`}>     
            <InspectorControlText value={model.heading} onChange={v => {
                model.heading = v
                updateModel(model)
            }} />                                              
        </InspectorProperty> ,                   

        <InspectorProperty property={'pitch'}  title={`Pitch`}>     
            <InspectorControlText value={model.pitch} onChange={v => {
                model.pitch = v
                updateModel(model)
            }} />                                              
        </InspectorProperty>                    
    ]

    const mapStyles = { 'imagery' : '3D Imagery', 'standard' : 'Standard' }

    return (
        <InspectorContent>
           
            {editing ? null : pageInfo}

            <InspectorHeader divider={!editing} title={editing ? "Edit Map" : "Map"} />

            {editing ? mapCoords : null}


            <InspectorProperty title={"Style"} >
                <InspectorControlSelect value={model.mapStyle} options={Object.values(mapStyles)} keys={Object.keys(mapStyles)} onChange={(v) => {
                    model.mapStyle = v
                    updateModel(model)
                }} />                    
            </InspectorProperty>   

            <div style={{"paddingTop" : "10px"}}>
                <InspectorControlButton title={editing ? "Done" : "Edit Location"} onClick={ () => {
                    setRender(render + 1)
                    editingRef.current = !editingRef.current
                    onEditingChanged(editingRef.current)
                }}/>
            </div>

        </InspectorContent>
    )
}

function MapContent(props) {
    const { latitude, longitude } = props
    const { model, updateModel, viewId } = useViewState()
    const ref = React.useRef(null)

    let height = (props.height ?? 100) / 100000
    const initialRegion = useMemo(() => ({
        centerLatitude: latitude,
        centerLongitude: longitude,
        latitudeDelta:  height,
        longitudeDelta: height,
      }), []);

      useEffect( () => {
        if (ref.current) {
            ref.current.setCameraDistanceAnimated(height * 100000, false)
        }
      }, [ref, ref.current, height])

    return (
        <Map initialRegion={initialRegion} ref={ref} token={mapkitToken}  onRegionChangeEnd={(region) => {
            console.log(region.centerLatitude)
            console.log('map update')
            model.height = ref.current.cameraDistance
            model.latitude = region.centerLatitude
            model.longitude = region.centerLongitude
            updateModel(model)
            // setCenterLatitude(region.centerLatitude);
            // setCenterLongitude(region.centerLongitude);
            // setLatitudeDelta(region.latitudeDelta);
            // setLongitudeDelta(region.longitudeDelta);
        }}>
        </Map>
    )
}



function GMapContent(props) {
    const containerStyle = {
        width: '100%',
        height: '100%'
    };
      
    const center = {
        lat: props.latitude,
        lng: props.longitude
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAjizWgPe7UOgIfBHtLlbBdytFmwMJD_dw"
      })
    
      const [map, setMap] = React.useState(null)

      let mapId = "eb9f6b48b47ff948"

      const onLoad = React.useCallback(function callback(map) {
        
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        map.setMapTypeId('terrain');
        
        setMap(map)
        
        console.log('on load')
      }, [])
    
      const onUnmount = React.useCallback(function callback(map) {
        console.log('on unload')
        setMap(null)
      }, [])
    
      console.log('google map - render')

      return isLoaded ? (
          <GoogleMap
            options={{mapId: mapId}}
            mapContainerStyle={containerStyle}
            mapId={mapId}
            id={mapId}
            //center={center}
            zoom={16}
            heading={320}
            tilt={47.5}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            { /* Child components, such as markers, info windows, etc. */ }
            <></>
          </GoogleMap>
      ) : <></>
}



function CMapContent(props) {
    const ref = React.useRef()
    const [viewer, setViewer] = React.useState(null)
    const { model, updateModel, refreshInspector } = useViewState()
    
    const editingRef = React.useRef(false)
    editingRef.current = props.editing

    const modelRef = React.useRef(null)
    modelRef.current = model

    // Enable simultaneous requests.
    RequestScheduler.requestsByServer["tile.googleapis.com:443"] = 10;

    let mapKey = "AIzaSyClSZXfe_1gIiAAi7qokP87UrrLjypyc68"
    let url = "https://tile.googleapis.com/v1/3dtiles/root.json?key=" + mapKey

    window.CESIUM_BASE_URL = '/cesium';

    Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzYjFlN2E5NS1lN2Y3LTQ2NWEtYTQwZC0wZGQ5ZThhYTZmOTAiLCJpZCI6MTk1MzE2LCJpYXQiOjE3MDc4MTM5MjB9.Z1tzINwmH1E3j20BWAj6c2hrCxiFSiHDmEw7NxPmOH0"

    const loadGoogleMaps = async (viewer) => {
        try {
            const set = await Cesium3DTileset.fromUrl( "https://tile.googleapis.com/v1/3dtiles/root.json?key=" + mapKey, {
                cullWithChildrenBounds: true,
                showCreditsOnScreen: false
            })

            viewer.scene.primitives.add(set);

            // 1. Using a cartesian offset
            const center = Cartesian3.fromDegrees(props.longitude, props.latitude, props.height);
            const heading = CesiumMath.toRadians(props.heading);
            const pitch = CesiumMath.toRadians(props.pitch);
            const range = props.height;
            viewer.camera.setView({ 
                destination: center,
                orientation: {
                    heading : heading, // east, default value is 0.0 (north)
                    pitch : pitch,    // default value (looking down)
                    roll : 0.0                             // default value
                }
            })
        } catch(error) {
            console.log(error)
        }

    }


    React.useEffect( () => {
        if (viewer != null) {
            return
        }

        if (ref.current) {
            let viewer = new Viewer(ref.current, {
                imageryProvider: false,
                baseLayerPicker: false,
                geocoder: true,
                globe: false,
                timeline: false,
                homeButton: false,
                selectionIndicator: false,
                infoBox: false,
                fullscreen: false,
                fullscreenElement: null,
                fullscreenButton: false,
                navigationHelpButton: false,
                navigationInstructionsInitiallyVisible: false,
                sceneModePicker: false,
                animation: false,
                // //https://cesium.com/blog/2018/01/24/cesium-scene-rendering-performance/#enabling-request-render-mode
                requestRenderMode: true,
            })
            viewer.camera.percentageChanged = 0.01
            viewer.camera.changed.addEventListener( (event) => {
                console.log(`Map Camera Changed ${event}`);

                var carto = viewer.camera.positionCartographic;
                var lat = CesiumMath.toDegrees(carto.latitude);
                var lon = CesiumMath.toDegrees(carto.longitude);                
                var height = carto.height

                if (editingRef.current) {
                    var newModel = modelRef.current
                    newModel.latitude = lat
                    newModel.longitude = lon
                    newModel.height = height
                    newModel.heading = CesiumMath.toDegrees(viewer.camera.heading)
                    newModel.pitch = CesiumMath.toDegrees(viewer.camera.pitch)
                    updateModel(newModel)
                }
            })
            loadGoogleMaps(viewer);
            setViewer(viewer)
        }
    }, [ref ,viewer])

    const containerStyle = {
        width: '100%',
        height: '100%'
    };
      

    return (
        <div ref={ref} style={containerStyle}>
        </div>
    )
}

const customComparator = (prevProps, nextProps) => {
    return nextProps.latitude === prevProps.latitude;
  };

let CMapContentCached = React.memo(CMapContent, customComparator);
