import React from 'react';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import { InspectorProperty } from '../inspector-ui/InspectorProperty.js';
import { InspectorHeader } from '../inspector-ui/InspectorHeader.js';
import { InspectorControlSelect } from '../inspector-ui/InspectorControlSelect.js';
import { InspectorControlText } from '../inspector-ui/InspectorControlText.js';
import { InspectorControlSegmented } from '../inspector-ui/InspectorControlSegmented.js';

function InspectorText(props) {
    let { fonts, updateModel } = useAppState();

    var model = props.model;

    if (!model) {
        return <div></div>;
    }

    let style = model['style'];

    var selectableFontKeys = Object.keys(fonts).filter(key => {
        let font = fonts[key];
        return font && font.userSelectable == true;
    });

    var styleOptions = selectableFontKeys.map(key => {
        return fonts[key].name;
    });

    var styleIndex = selectableFontKeys.indexOf(style);

    var alignOptions = [
        <div key={"align-left"} className="option-icon align-left" />,
        <div key={"align-center"} className="option-icon align-center" />,
        <div key={"align-right"} className="option-icon align-right" />
    ];

    let valueContent = (
        <InspectorProperty property={model.validationProperty ?? 'title'} singleColumn={true}>
            <InspectorControlText multiline={style == 'body'} value={model.content} onChange={v => {
                model.content = v;
                updateModel(model);
            }} />
        </InspectorProperty>
    );

    let editableStyleContent = [
        <InspectorProperty key="style" title="Style">
            <InspectorControlSelect value={style} options={styleOptions} keys={selectableFontKeys} onChange={(v) => {
                model.style = v;
                updateModel(model);
            }} />
        </InspectorProperty>,
        <InspectorProperty key="align" title="Align">
            <InspectorControlSegmented value={model.align ?? 'left'} options={alignOptions} keys={['left', 'center', 'right']} onChange={(v) => {
                model.align = v;
                updateModel(model);
            }} />
        </InspectorProperty>
    ];

    return <div>
        <section>
            <InspectorHeader title={"Text"} />
            {model.canEditStyle && editableStyleContent}
            {valueContent}
        </section>

    </div>;
}

export { InspectorText }