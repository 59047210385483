import React from 'react'
import { useIcons } from "../content-builder-components/ContentBuilderIconProvider.js"

function TableSection(props) {
    var { id } = props

    var headerclass = props.tableHeader == true ? " " : " with-divider" 

    var headerId = id ? `table-section-header-id-${id}` : null
    var accessoryId = id ? `table-section-accessory-id-${id}` : null
    var ariaLabeledBy = props.ariaHeaderRole ? [headerId, accessoryId].join(' ') : null
    var description = (
        <div className="asset-detail-section-description">
            {props.description}
        </div>
    )

    return <section className="asset-detail-section">
        
        <div role={props.ariaHeaderRole} aria-labelledby={ariaLabeledBy} className="asset-detail-section-header-container">
            {props.title && <div className={`asset-detail-section-header ${headerclass}`}>{props.title && <h5 id={headerId}>{props.title}</h5>} <span id={accessoryId}>{props.actions}</span></div>}
            {props.description && description}
        </div>

        <div className="asset-detail-section-content">
            {props.children}
        </div>
    </section>
}

function TableSectionActionInfo({ onClick, title }) {
    let icons = useIcons()
    let infoIcon = icons.get('label.info')

    return <span className="info"><a onClick={onClick}>{title ?? "Info"} {infoIcon}</a></span>
}

export { TableSection, TableSectionActionInfo }