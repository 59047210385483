import React, { useState, useEffect } from 'react';
import { useStyle } from '../Style.tsx';
import { useActions } from '../Actions.tsx';

export function Circle({ fill }: { fill?: string }) {
    var actions = useActions()

    const style = {
        ...useStyle(),
        borderRadius: '50%',
        // width: '100%', // Fill available space by default
        // height: 'auto', // Maintain aspect ratio with width
        aspectRatio: '1',
        flexGrow: 1,
        backgroundColor: fill ?? 'black',
    };

    // Ensure width and height fill available space if not defined
    if (!style.width) {
        style.width = '-webkit-fill-available';
    }
    if (!style.height) {
        style.height = '-webkit-fill-available';
    }

    return <div style={style} {...actions}></div>;
}