import React from 'react';
import { Section, Box, Stack, Spacer, VStack, ZStack, HStack, Text, ActionButton, Image } from '@yapstudios/yap-content-builder';
import { InspectorHeader , InspectorModelWell, InspectorContent, InspectorProperty, InspectorControlSlider, InspectorControlText, InspectorControlSelect, InspectorControlButton, InspectorControlDate, useInspectorState }  from '@yapstudios/yap-content-builder'
import { View } from '@yapstudios/yap-content-builder';
//import modelViewer from '@google/model-viewer'

export default class ModelHeaderComponent {
    static type = 'model-header'

    constructor(title, subtitle) {
        this.type = ModelHeaderComponent.type;
        this.url = null
        this.defaultTitle = title ?? "Title"
        this.defaultSubtitle = subtitle ?? "Subtitle"
    }

    defaultProps() {
        return {
            url: this.url,
            rotX: 0,
            rotY: 90,
            zoom: 100,
            translateX: 0,
            translateY: 0,
            translateZ: 0,
            title: this.defaultTitle,
            subtitle: this.defaultSubtitle,
            brand: "BRAND",
            style: 'light',
        };
    }

    titlesForProp() {
        return {
            price: 'Price',
        };
    }

    decode(props) {
        const height = 800
        const model = <ModelObject themeStyle={props.style} 
                                 height={height} 
                                 url={props.asset ? props.asset.src : null} 
                                 rotX={props.rotX} 
                                 rotY={props.rotY} 
                                 translateX={props.translateX} 
                                 translateY={props.translateY} 
                                 translateZ={props.translateZ} 
                                 zoom={props.zoom}/>
        return (
            <Section padding="0" paddingBottom="0" id="section-header" className="model-header"  title="Header">
                <div className='model-header'>
                <View width={"infinity"} model={props} inspector={(model, updateModel) => {
                    return this.inspector(model, updateModel)
                }}>                    
                    <ZStack width={"infinity"} height={height} align={"leading"}>                        
                        <Box backgroundColor="headerBackground" height="500" padding="30" paddingTop="80" className="model-header-info">
                            <Text canEditStyle={false} id="brand" className="subtitle" style={"productHeaderCaption"} content={props.brand}></Text>
                            <Text canEditStyle={false} id="subtitle" style={"productHeaderSubtitle"} content={props.subtitle}></Text>
                            <Text canEditStyle={false} id="title" style={"productHeader"} content={props.title}></Text>
                        </Box>
                        {model}                        
                    </ZStack>
                </View>        
                </div>
            </Section>
        );
    }

    inspector(model, updateModel) {
        
        let groupStyle = {"display" : 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '5px'}
        
        const editingControls = [
            <InspectorProperty divider={true}  title={"Rotate X"}>
                <div style={groupStyle}>
                    <InspectorControlSlider width={90} value={model.rotX} min={-180} max={180} onChange={v => {
                        model.rotX = v
                        updateModel(model)
                    }}/>
                    <InspectorControlText width={24} value={model.rotX} onChange={v => {
                            model.rotX = v
                            updateModel(model)
                        }} /> 
                </div>
            </InspectorProperty>,              

            <InspectorProperty title={"Rotate Y"}>
                <div style={groupStyle}>
                    <InspectorControlSlider width={90} value={model.rotY} min={0} max={360} onChange={v => {
                        model.rotY = v
                        updateModel(model)
                    }}/>
                    <InspectorControlText width={24} value={model.rotY} onChange={v => {
                            model.rotY = v
                            updateModel(model)
                        }} /> 
                </div>                    
            </InspectorProperty>,      

            <InspectorProperty title={"Zoom"}>
                <div style={groupStyle}>
                    <InspectorControlSlider width={90} value={100 - model.zoom} min={0} max={100} onChange={v => {
                        model.zoom = 100 - v
                        updateModel(model)
                    }}/>
                    <InspectorControlText width={24} value={100 - model.zoom} onChange={v => {
                        model.zoom = 100 - v
                        updateModel(model)
                    }} /> 
                </div>      
            </InspectorProperty>,      

            <InspectorProperty divider={true} title={"Position X"}>
                <div style={groupStyle}>
                    <InspectorControlSlider width={90} value={model.translateX} min={-10} max={10} onChange={v => {
                        model.translateX = v
                        updateModel(model)
                    }}/>
                    <InspectorControlText width={24} value={model.translateX} onChange={v => {
                            model.translateX = v
                            updateModel(model)
                        }} /> 
                </div> 
            </InspectorProperty>,              

            <InspectorProperty title={"Position Y"}>
                <div style={groupStyle}>
                    <InspectorControlSlider width={90} value={model.translateY} min={-10} max={10} onChange={v => {
                        model.translateY = v
                        updateModel(model)
                    }}/>
                    <InspectorControlText width={24} value={model.translateY} onChange={v => {
                            model.translateY = v
                            updateModel(model)
                        }} /> 
                </div> 
            </InspectorProperty>     , 

            <InspectorProperty title={"Position Z"}>
                <div style={groupStyle}>
                    <InspectorControlSlider width={90} value={model.translateZ} min={-10} max={10} onChange={v => {
                        model.translateZ = v
                        updateModel(model)
                    }}/>
                    <InspectorControlText width={24} value={model.translateZ} onChange={v => {
                            model.translateZ = v
                            updateModel(model)
                        }} /> 
                </div> 
            </InspectorProperty>      

        ]

        return (
            <InspectorContent key={"header-inspector"}>
                <InspectorHeader title={"Page Info"} />      

                <InspectorProperty  title={"Title"}>
                    <InspectorControlText value={model.title} onChange={v => {
                            model.title = v
                            updateModel(model)
                        }} /> 
                </InspectorProperty>   

                <InspectorProperty title={"Subtitle"}>
                    <InspectorControlText  value={model.subtitle} onChange={v => {
                            model.subtitle = v
                            updateModel(model)
                        }} /> 
                </InspectorProperty>                    

                <InspectorProperty title={"Brand"}>
                    <InspectorControlText  value={model.brand} onChange={v => {
                            model.brand = v
                            updateModel(model)
                        }} /> 
                </InspectorProperty>    

                <InspectorHeader  divider={true} title={"Model"} />      

                <InspectorProperty singleColumn={true} >
                    <InspectorModelWell  src={model.asset ? model.asset.src : null} title={model.asset ? model.asset.title : null} onFileSelected={asset => {
                            model.asset = asset
                            updateModel(model)
                        }} /> 
                </InspectorProperty>   

                {model.asset && editingControls}

            </InspectorContent>
        )
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="70" fill="white"/>
                <rect opacity="0.3" x="8.6817" y="20.317" width="74.0211" height="6" rx="3" fill="#384CC0"/>
                <rect opacity="0.3" x="8.6817" y="9.06078" width="18.8654" height="6" rx="3" fill="#384CC0"/>
                <rect opacity="0.3" x="8.6817" y="32.317" width="59.3879" height="6" rx="3" fill="#384CC0"/>
                <path d="M10.483 59.3231C10.0318 59.0712 9.69781 58.7841 9.48102 58.4618C9.26422 58.1337 9.15582 57.6649 9.15582 57.0556V49.7079C9.15582 49.2157 9.2525 48.8026 9.44586 48.4687C9.64508 48.1347 9.96149 47.8534 10.3951 47.6249L16.4947 44.2763C17.0572 43.9657 17.6402 43.8104 18.2437 43.8104C18.8531 43.8104 19.439 43.9657 20.0015 44.2763L26.1011 47.6249C26.5347 47.8534 26.8482 48.1347 27.0416 48.4687C27.2349 48.8026 27.3316 49.2157 27.3316 49.7079V57.0556C27.3316 57.6649 27.2232 58.1337 27.0064 58.4618C26.7955 58.7841 26.4615 59.0712 26.0045 59.3231L19.3951 62.9354C18.6275 63.3456 17.8629 63.3456 17.1011 62.9354L10.483 59.3231ZM11.7134 57.6356L17.2154 60.6942V54.0673L11.1246 50.7099V56.7128C11.1246 56.9237 11.1685 57.0995 11.2564 57.2401C11.3443 57.3808 11.4966 57.5126 11.7134 57.6356ZM24.774 57.6356C24.9908 57.5126 25.1431 57.3808 25.231 57.2401C25.3189 57.0995 25.3629 56.9237 25.3629 56.7128V50.7099L19.272 54.0673V60.6942L24.774 57.6356ZM18.2437 52.2655L24.2466 48.9696L19.105 46.1395C18.5367 45.829 17.9654 45.829 17.3912 46.1395L12.2496 48.9696L18.2437 52.2655Z" fill="#B7BEE8"/>
            </svg>
        )
    }

    encode(model, props) {
        
        switch (model.id) {

            // Textfield props
            case "title":
                props.title = model.content
                break
            case "subtitle":
                props.subtitle = model.content
                break    
            case "brand":
                props.brand = model.content
                break 
                
            // Inspector props
            default:
                props = {...props, ...model}
                break;
        }

        return props;
    }

    validations() {
        return [ ];
    }
}


function ModelObject({ url, rotX, rotY, translateX, translateY, translateZ, zoom, height, themeStyle }) {

    const ref = React.useRef(null)

    let w = 375
    let h = height

    let orbit = `${rotX ?? 0}deg ${rotY ?? 0}deg ${zoom}m`
    console.log(orbit)

    let unit = (v) => {
        if (v == null) {
            return "auto"
        } else if (v == 0) {
            return "auto"
        } else if (v != 0) {
            return `${v}m`
        } else {
            return v
        }
    }

    let target = `${unit(translateX)} ${unit(translateY)} ${unit(translateZ)}`
    
    return (
        <VStack align={"leading"} spacing={3} ustify={"leading"} width={w} spaceItems={"between"} height={h} padding={0} >
            <model-viewer src={url}
                  iosSrc={url}
                  ref={ref}
                  alt="A drill"
                  camera-orbit={orbit}
                  camera-target={target}
                  min-field-of-view={10}
                  shadow-intensity="1"
                  style={{height: `${h}px`, width: `${w}px`}}>
            </model-viewer>
        </VStack>
    )
}
