import React from 'react';
import { Section, Stack, Text, Video } from '@yapstudios/yap-content-builder';

export default class VideoComponent {
    constructor(src) {
        this.type = 'video';
        this.src = src;
    }

    defaultProps() {
        return {
            title: "Video Title",
            src: this.src,
            thumbnailTime: 5
        };
    }

    decode(props) {
        const playbackStyleFullscreen = props.playbackStyle == null || props.playbackStyle == 'fullscreen'
        const fullbleed = props.fullbleed == true
        const cornerRadius = playbackStyleFullscreen && !fullbleed ? 12 : 0;

        /** Overlay shown when playback style is fullscreen */
        const titleOverlay = ({ video, height, durationFormatted, title }) => {
            return (
                <Stack spacing={5} height={height - 40} padding={20} justify="trailing">
                    {video && <Text id="duration" readonly={true} content={durationFormatted} style="headline" align="left" color="#ffffffbb" />}
                    <Text id="title" content={title ?? "Video Title"} style="headline" align="left" color="white" />
                </Stack>
            )
        }

        return (
            <Section paddingHorizontal={fullbleed ? 0 : 20} height={null} paddingVertical={20} id="video-section" title="Video">
                <Video id="video"
                    cornerRadius={cornerRadius}
                    title={props.title}
                    dimensions={props.dimensions}
                    playbackStyle={props.playbackStyle}
                    fullbleed={fullbleed}
                    thumbnailTime={props.thumbnailTime}
                    src={props.src}
                    overlay={titleOverlay}
                    editOptions={['playbackStyle', 'fullbleed', 'thumbnailTime']}
                ></Video>
            </Section>
        );
    }

    encode(model, props) {
        console.log('model:')
        console.log(model)
        switch (model.id) {
            case "title":
                props['title'] = model.content
                break
            case "video":
                props['playbackStyle'] = model.playbackStyle
                props['fullbleed'] = model.fullbleed
                props['src'] = model.src
                props['dimensions'] = model.dimensions
                props['assetTitle'] = model.assetTitle
                props['title'] = model.title;
                props['thumbnailTime'] = model.thumbnailTime;
                props['defaultSubtitle'] = model.subtitle;
                break
        }
        return props;
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="70" fill="white" />
                <rect x="8.11734" y="7.02233" width="83.8066" height="55.5572" rx="6" fill="#384CC0" fillOpacity="0.1" />
                <path d="M49.8799 46.2012C48.3831 46.2012 46.9723 45.9147 45.6475 45.3418C44.3298 44.7689 43.166 43.9775 42.1562 42.9678C41.1465 41.958 40.3551 40.7943 39.7822 39.4766C39.2093 38.1517 38.9229 36.7409 38.9229 35.2441C38.9229 33.7474 39.2093 32.3402 39.7822 31.0225C40.3551 29.6976 41.1429 28.5303 42.1455 27.5205C43.1553 26.5107 44.319 25.7194 45.6367 25.1465C46.9616 24.5736 48.3724 24.2871 49.8691 24.2871C51.3659 24.2871 52.7767 24.5736 54.1016 25.1465C55.4264 25.7194 56.5938 26.5107 57.6035 27.5205C58.6133 28.5303 59.4046 29.6976 59.9775 31.0225C60.5505 32.3402 60.8369 33.7474 60.8369 35.2441C60.8369 36.7409 60.5505 38.1517 59.9775 39.4766C59.4046 40.7943 58.6133 41.958 57.6035 42.9678C56.5938 43.9775 55.4264 44.7689 54.1016 45.3418C52.7839 45.9147 51.3766 46.2012 49.8799 46.2012ZM47.8389 39.5732L54.1553 35.8564C54.3844 35.7132 54.4954 35.5127 54.4883 35.2549C54.4883 34.9971 54.3773 34.8001 54.1553 34.6641L47.8389 30.9258C47.6025 30.7897 47.359 30.7682 47.1084 30.8613C46.8577 30.9544 46.7324 31.1335 46.7324 31.3984V39.1006C46.7324 39.3656 46.8506 39.5518 47.0869 39.6592C47.3304 39.7594 47.5811 39.7308 47.8389 39.5732Z" fill="#384CC0" />
            </svg>
        )
    }
}
