import React from 'react';
import { InspectorHeader } from './InspectorHeader';
import { useDimensions } from 'react-hook-dimensions';

function InspectorDialog(props) {

    // Element used to get positiion on page
    const [elementRef, elementDimensions] = useDimensions({
        dependencies: [props.visible],
        layoutEffect: true,
    });

    // Element used to get size on page
    const [mainElementRef, mainElementDimensions, updateElementDimensions] = useDimensions({
        dependencies: [props.visible],
        layoutEffect: true,
    });

    // Force an update of dimensions after layout to ensure content has time to fill in.
    React.useEffect(() => {
        setTimeout( () => {
            if (props.visible) { updateElementDimensions() }
        }, 10)
    }, [props.visible])

    // Early exit if not visible.
    if (props.visible != true) {
        return null
    }

    const closeIcon = (
        <svg style={{cursor: "pointer"}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.95264 17.4736C4.3291 17.4736 0.48584 13.6304 0.48584 9.00684C0.48584 4.375 4.3208 0.540039 8.94434 0.540039C13.5762 0.540039 17.4194 4.375 17.4194 9.00684C17.4194 13.6304 13.5845 17.4736 8.95264 17.4736ZM6.16357 12.4932C6.35449 12.4932 6.52881 12.4268 6.66162 12.2939L8.95264 9.98633L11.252 12.2939C11.3765 12.4268 11.5508 12.4932 11.7417 12.4932C12.1318 12.4932 12.439 12.186 12.439 11.8042C12.439 11.6133 12.3726 11.439 12.2314 11.3145L9.93213 9.01514L12.2397 6.70752C12.3892 6.55811 12.4473 6.40869 12.4473 6.21777C12.4473 5.83594 12.1401 5.53711 11.7583 5.53711C11.5757 5.53711 11.4263 5.59521 11.2852 5.73633L8.95264 8.05225L6.63672 5.74463C6.50391 5.62012 6.35449 5.55371 6.16357 5.55371C5.78174 5.55371 5.48291 5.84424 5.48291 6.23438C5.48291 6.41699 5.54932 6.58301 5.68213 6.71582L7.98145 9.01514L5.68213 11.3228C5.54932 11.4473 5.48291 11.6216 5.48291 11.8042C5.48291 12.186 5.78174 12.4932 6.16357 12.4932Z" fill="#aaaaaa"/>
        </svg>
    )

    const containerStyle = {
        position: 'absolute',

        /** Left and width defaults / values */
        left: (props.left ?? 0) + "px",
        width: (props.width ?? 320) + "px",

        /** Force the dialog to not go off the page */
        top: elementDimensions.top > 0 ? `min(100vh - ${mainElementDimensions.height}px - 80px, ${elementDimensions.top}px - 60px)` : null,
        textAlign: 'left',
    }
    
    const headerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '-40px',
    }

    const header = (
        <div style={headerStyle} onClick={props.onClose}>
            <InspectorHeader title={props.title ?? "Details"}/>
            {closeIcon}
        </div>
    )

    return [
        <div ref={elementRef}></div>,
        <div ref={mainElementRef} style={containerStyle}>
            <div className={"inspector-content"}>
                <div>
                    {header}
                    {props.children}
                </div>
            </div>
        </div>
    ]
}

export { InspectorDialog }