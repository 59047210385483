import ProductTemplate from "./Templates/ProductTemplate.js"
import GuideTemplate from "./Templates/GuideTemplate.js"
import BasicTemplate from "./Templates/BasicTemplate.js"

const templateLibrary = [
    {
        name: 'Retail',
        templates: [
            { template: new BasicTemplate(), title: "Basic Page" },    
            { template: new GuideTemplate(), title: "How To / Guide" },                    
            { template: new ProductTemplate(), title: "Product Page" }
        ]
    }
]

export { templateLibrary }