import * as React from 'react'
import AssetImage from './AssetImage'
import PolymerAssetType from './AssetType'

function AssetLibraryAssets({ assets, cols = 3, assetLibraryTypes, selectedAsset, onAssetSelected, contentMode }) {

    var contentMode = contentMode ?? "fit"

    // Get flat list of valid file types to show (if assetLibraryTypes passed)
    // assetLibraryTypes can be [PolymerAssetType.Image, PolymerAssetType.Video]
    const fileTypes = (assetLibraryTypes ?? []).flatMap( (type) => {
        return type.fileTypes
    })

    // Filter assets based on extension
    const validAssets = assets.filter( asset => {
        if (fileTypes.length == 0) { return asset }
        return fileTypes.includes(asset.src.fileExtension().toLowerCase())
    })

    return (
        <div className={`polymer-grid polymer-grid-assets-columns  polymer-grid-assets-content-mode-${contentMode}`} role="grid">
            {validAssets.map((item) => {                
                return (
                    <AssetLibraryItem 
                    contentMode={contentMode}
                    asset={item} 
                    key={item.src} 
                    selected={selectedAsset && selectedAsset.id == item.id} 
                    isIcon={false} 
                    title={item.name ?? item.title ?? item.src} 
                    id={item.src} 
                    onClick={() => {
                        if (onAssetSelected) {
                            onAssetSelected(item)
                        }
                    }}
                 ></AssetLibraryItem>
                )
            })}
        </div>
    )

}

function AssetLibraryItem(props) {
    const contentMode = props.contentMode
    const className = `asset-item content-mode-${contentMode} ${props.selected ? " selected" : ""}`

    const handlePress = (e) => {
        let keyCode = e.keyCode ? e.keyCode : e.which;

        // Enter key
        if (keyCode === 13 || keyCode == 32) {
            props.onClick()
            e.preventDefault()
        }
    }

    var imageContent =  <AssetImage 
                            contentMode={contentMode} 
                            background={false} 
                            asset={props.asset} 
                            width={"100%"} 
                            height={"100%"}/>
    var asset = props.asset

    var fileContent = asset.coverImage ? <AssetImage asset={asset.coverImage} width={"100%"} height={"100%"}/> : props.title

    const fileExtension = asset.src.fileExtension()
    const isVideo =  PolymerAssetType.Video.fileTypes.includes(fileExtension)
    const isImage =  PolymerAssetType.Image.fileTypes.includes(fileExtension)

    return (
        <div role="button" tabIndex="0" className={className} onClick={props.onClick} onFocus={props.onFocus} onBlur={props.onBlur} onKeyUp={handlePress}>
            {isVideo || isImage ? imageContent : fileContent }            
        </div>
    )
}


export default AssetLibraryAssets