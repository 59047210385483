import * as React from 'react'
import { useYapPageState } from "./Page.js"

// CSS
import './uikit.css';


function PolymerToolbar({ position, actionsLeft, actionsRight, actionsCenter }) {
    const state = useYapPageState()

    var positionClass = position == "top" ? "polymer-toolbar-top" : "polymer-toolbar-bottom"

    const left = <span key={"left"} className="polymer-toolbar-actions polymer-toolbar-actions-left">{actionsLeft}</span>
    const right = <span key={"right"} className="polymer-toolbar-actions polymer-toolbar-actions-right">{actionsRight}</span>
    const center = <span key={"center"} className="polymer-toolbar-actions polymer-toolbar-actions-center">{actionsCenter}</span>
    return <div key={"toolbar"} className={`polymer-toolbar ${positionClass}`}>
                <div className={`polymer-toolbar-content ${state.containerClassName}`} key={"toolbar-content"}>{left}{center}{right}</div>
            </div>
}

export default PolymerToolbar