import React from 'react'
import { useAppState } from '../content-builder-components/ContentBuilderStateProvider.js'
import LargeButton from './LargeButton.js'
import { InspectorHeader, InspectorProperty } from './Inspector.js'
import { useInspectorState, InspectorStateProvider } from './InspectorState.js'
import { ContentBuilderModal } from '../content-builder-components/ContentBuilderModals.js'
import { JSONFormatter } from '../shared-components/JSONFormatter.js'
import Header from '../uikit-components/Header.js'
import HeaderButton from '../uikit-components/HeaderButton.js'
import { Page } from '../uikit-components/Page.js'
import { DocumentYapUI } from '../yap-ui/DocumentYapUI.js'

export function DebugInspector(props) {
    const { showDebug } = useAppState()

    if (!showDebug) { return null }

    return (
        <div className="inspector">
            <div className="inspector-content">                
                <InspectorStateProvider model={{}} updateModel={() => { }}>
                    <DebugInspectorContent/>
                </InspectorStateProvider>
            </div>
        </div>
    )
}

function DebugInspectorContent() {
        
    const [showJSON, setShowJSON] = React.useState(false)
    const [showYapUI, setShowYapUI] = React.useState(false)
    const {  selectedSectionId, selectedPageId, currentPage } = useAppState()
    
    const truncatedStyle = {'width' : '100px', 
                            'textOverflow' : 'ellipsis',
                            'textWrap' : 'nowrap', 
                            'display': 'inline-block', 
                            'overflow' : 'hidden',
                            'whiteSpace': 'nowrap' }

    const pageIdProperty = (
        <InspectorProperty title="Page ID">
            <span style={truncatedStyle}>{currentPage.id}</span>           
        </InspectorProperty>
    )

    const sectionIdProperty = (
        <InspectorProperty title="Section ID">
            <span style={truncatedStyle}>{selectedSectionId}</span>
        </InspectorProperty>
    )

    const showJsonProperty = (
        <InspectorProperty singleColumn={true}>
            <LargeButton onClick={() => { setShowJSON(true)} }>{"Page JSON"}</LargeButton>
        </InspectorProperty>
    )

    const showYapUIProperty = (
        <InspectorProperty singleColumn={true}>
            <LargeButton onClick={() => { setShowYapUI(true)} }>{"Document YapUI"}</LargeButton>
        </InspectorProperty>
    )

    return (
        <section>                    
            <InspectorHeader title="Debug" />
            {selectedSectionId ? sectionIdProperty : pageIdProperty}
            {showJsonProperty}
            {showYapUIProperty}
            <DebugJSONModal show={showJSON} setShow={setShowJSON}/>
            <DebugYapUIModal show={showYapUI} setShow={setShowYapUI}/>
        </section>
    )
}

function DebugJSONModal({ show, setShow }) {

    let {  currentPage, currentSelectedSection } = useAppState()

    const content = currentSelectedSection() ?? currentPage

    const closeButton = (
        <HeaderButton key={"close"} primary={false} onClick={() => {
            if (setShow) {
                setShow(false)
            }
        }} >Close</HeaderButton>
    )

    return (
        <ContentBuilderModal show={show} onClose={() => { setShow(false) }} >
            <Page fullscreen={true} >
                <Header largeTitle={false} title={"JSON"} headerActions={[]} backAction={closeButton}></Header>
                <div style={{ 'paddingTop' : '65px'}}>
                    {show && <JSONFormatter>{content}</JSONFormatter>}
                </div>
            </Page>
        </ContentBuilderModal>
    )
}


function DebugYapUIModal({ show, setShow }) {

    let {  currentPage, currentSelectedSection,  pages, components, pageRenderer , yapuiJSON} = useAppState()

    const content = currentSelectedSection() ?? currentPage

    const closeButton = (
        <HeaderButton key={"close"} primary={false} onClick={() => {
            if (setShow) {
                setShow(false)
            }
        }} >Close</HeaderButton>
    )

    //let yapui = show ? new DocumentYapUI().update(pages, components, pageRenderer) : ""

    return (
        <ContentBuilderModal show={show} onClose={() => { setShow(false) }} >
            <Page fullscreen={true} >
                <Header largeTitle={false} title={"YapUI"} headerActions={[]} backAction={closeButton}></Header>
                <div style={{ 'paddingTop' : '65px'}}>
                    {show && <JSONFormatter>{yapuiJSON}</JSONFormatter>}
                </div>
            </Page>
        </ContentBuilderModal>
    )
}


