import * as React from 'react'
import { icons } from "./ContentBuilderIcons.js"

const ContentBuilderIconProviderContext = React.createContext()

const defaultIconProvider = {
    get: (name, color) => {
        let icon = icons[name]
        if (icon && typeof icon === 'function') {
            icon = icon(color ?? "color-tint")
        }
        return icon
    }
}

function useIcons() {
    const context = React.useContext(ContentBuilderIconProviderContext)
    if (!context) {
        return defaultIconProvider
    }
    var newContext = {...context}
    newContext.get = (name, color) => {
        let icon = context.get(name, color)
        if (icon) {
            return icon
        } else {
            return defaultIconProvider.get(name, color)
        }
    }
    return newContext
}

// Example of defining an icon provider
function ContentBuilderIconProvider(props) {
    return <ContentBuilderIconProviderContext.Provider value={defaultIconProvider}>{props.children}</ContentBuilderIconProviderContext.Provider>
}

export { useIcons, ContentBuilderIconProvider, ContentBuilderIconProviderContext }