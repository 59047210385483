import React from 'react';
import {  Section, Stack, Text, Video, View,  HStack, VStack, ZStack, Spacer, useViewState } from '@yapstudios/yap-content-builder';
import { InspectorHeader , InspectorContent, InspectorControlSelect, InspectorVideoWell, InspectorProperty, InspectorControlText, InspectorControlButton, InspectorControlDate, useInspectorState }  from '@yapstudios/yap-content-builder'
export default class VideoComponent {
    constructor(src) {
        this.type = 'video';
        this.src = src;
        this.videoRef = React.createRef({})
    }

    defaultProps() {
        return {
            title: "Video Title",
            src: this.src,
            thumbnailTime: 5
        };
    }

    decode(props) {
        
        const canvasWidth = 340

        var width = "100%"
        var height = canvasWidth

        const playbackStyleFullscreen = props.playbackStyle == null || props.playbackStyle == 'fullscreen'
        const fullbleed = props.fullbleed == true
        const cornerRadius = playbackStyleFullscreen && !fullbleed ? 12 : 0;

        if (props.dimensions && props.dimensions.width > 0 && props.dimensions.height > 0) {
            let aspect = props.dimensions.height / props.dimensions.width
            width = canvasWidth
            height = canvasWidth * aspect
        }

        let containerStyle = {
            width: '100%'
        };

        containerStyle['backgroundColor'] = '#696969';
        containerStyle['borderRadius'] = cornerRadius + 'px'
        containerStyle['height'] = height + 'px';
        
        const videoStyle = {
            borderRadius:  cornerRadius + 'px'
        }

        const metadata = <VideoMetadata title={props.title} height={height} videoRef={this.videoRef}/>
        
        return (<Section paddingHorizontal={fullbleed ? 0 : 20} paddingVertical={20} id="video" height={height} title="Video">
                    <ZStack style={{height: height}}>
                        <View width={width} height={height} model={props} inspector={(model, updateModel) => {
                            return this.inspector(model, updateModel)
                        }}>
                            <ZStack style={containerStyle}>
                                <Video cornerRadius={cornerRadius} height={height} videoRef={this.videoRef} style={videoStyle} canPlay={false} title={props.title} controls={false} src={props.src}></Video>
                                {playbackStyleFullscreen && metadata}
                            </ZStack>
                        </View>
                        
                    </ZStack>
                </Section>);
    }

    inspector(model, updateModel) {
        
        const titleProperty = (                        
            <InspectorProperty key={"title"} property={'title'} singleColumn={true} title={"Title"}>
                <InspectorControlText value={model.title} onChange={v => {
                    model.title = v
                    updateModel(model)
                }} />
            </InspectorProperty>
        )

        const videoWellProperty = (
            <InspectorProperty key={"video"} property={"video"} singleColumn={true} title={"Video"}>
                <InspectorVideoWell title={model.assetTitle} videoRef={this.videoRef} src={model.src} onImageSelected={ (asset) => {
                    model.src = asset.src
                    model.assetTitle = asset.title
                    model.dimensions = asset.dimensions
                    updateModel(model)
                }} ></InspectorVideoWell> 
            </InspectorProperty>
        )

        const playbackStyleProperty = (
            <InspectorProperty title="Playback Style" singleColumn={false} divider={true}>
                <InspectorControlSelect value={model.playbackStyle == null ? 'fullscreen' : model.playbackStyle} options={['Fullscreen', 'Inline Autoplay']} keys={['fullscreen', 'inline']} onChange={v => {
                    updateModel({...model, 
                                playbackStyle: v})
                }} />
            </InspectorProperty>
        )    


        const fullbleedProperty = (
            <InspectorProperty title="Inset" singleColumn={false} divider={false}>
                <InspectorControlSelect value={model.fullbleed == true ? 'fullbleed' : 'default'} options={['Inset', 'Full Bleed']} keys={['default', 'fullbleed']} onChange={v => {
                    updateModel({...model, 
                                fullbleed: (v == 'fullbleed')})
                }} />
            </InspectorProperty>
        )    

        const posterProperty = (
            <InspectorProperty divider={true} key={"thumbnailTime"} singleColumn={false} title={"Thumbnail Time"}>
                <InspectorControlText value={model.thumbnailTime} onChange={v => {
                    model.thumbnailTime = v
                    updateModel(model)
                }} />
            </InspectorProperty>
        )

        const previewControls = (
            <InspectorProperty divider={true} key={"preview"} singleColumn={true} title={"Preview"}>
                <VideoInspectorControls videoRef={this.videoRef}/>
            </InspectorProperty>
        )

        return (
            <InspectorContent key={"video-inspector"}>
                    <InspectorHeader title={"Video"} />
                    <Stack spacing={20} align={"leading"} width={"infinity"}>
                        {titleProperty}
                        {videoWellProperty}
                        {playbackStyleProperty}
                        {fullbleedProperty}
                        {posterProperty}
                        {model.src && previewControls}
                    </Stack>
                </InspectorContent>
        )
    }
    encode(model, props) {
        console.log('encode')
        console.log(model)
        if (model.id == 'title') {
            props['title'] = model.content
        } else {
            props['playbackStyle'] = model.playbackStyle
            props['fullbleed'] = model.fullbleed
            props['src'] = model.src
            props['dimensions'] = model.dimensions
            props['assetTitle'] = model.assetTitle
            props['title'] = model.title;
            props['thumbnailTime'] = model.thumbnailTime;
            props['defaultSubtitle'] = model.subtitle;
        }
        return props;
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="70" fill="white"/>
                <rect x="8.11734" y="7.02233" width="83.8066" height="55.5572" rx="6" fill="#384CC0" fillOpacity="0.1"/>
                <path d="M49.8799 46.2012C48.3831 46.2012 46.9723 45.9147 45.6475 45.3418C44.3298 44.7689 43.166 43.9775 42.1562 42.9678C41.1465 41.958 40.3551 40.7943 39.7822 39.4766C39.2093 38.1517 38.9229 36.7409 38.9229 35.2441C38.9229 33.7474 39.2093 32.3402 39.7822 31.0225C40.3551 29.6976 41.1429 28.5303 42.1455 27.5205C43.1553 26.5107 44.319 25.7194 45.6367 25.1465C46.9616 24.5736 48.3724 24.2871 49.8691 24.2871C51.3659 24.2871 52.7767 24.5736 54.1016 25.1465C55.4264 25.7194 56.5938 26.5107 57.6035 27.5205C58.6133 28.5303 59.4046 29.6976 59.9775 31.0225C60.5505 32.3402 60.8369 33.7474 60.8369 35.2441C60.8369 36.7409 60.5505 38.1517 59.9775 39.4766C59.4046 40.7943 58.6133 41.958 57.6035 42.9678C56.5938 43.9775 55.4264 44.7689 54.1016 45.3418C52.7839 45.9147 51.3766 46.2012 49.8799 46.2012ZM47.8389 39.5732L54.1553 35.8564C54.3844 35.7132 54.4954 35.5127 54.4883 35.2549C54.4883 34.9971 54.3773 34.8001 54.1553 34.6641L47.8389 30.9258C47.6025 30.7897 47.359 30.7682 47.1084 30.8613C46.8577 30.9544 46.7324 31.1335 46.7324 31.3984V39.1006C46.7324 39.3656 46.8506 39.5518 47.0869 39.6592C47.3304 39.7594 47.5811 39.7308 47.8389 39.5732Z" fill="#384CC0"/>
            </svg>
        )
    }
}

function VideoMetadata(props) {
    const { isSelected, isPreview } = useViewState()

    const [duration, setDuration] = React.useState(0)
    const [playing, setPlaying] = React.useState(false)
    const videoRef = props.videoRef
    const video = props.videoRef.current
    const height = props.height ?? 340

    var isPlaying = video && video.paused == false

    const onPlaybackChange = (event) => {
        if (video == null) { return }
        console.log('onPlaybackChange')  
        setPlaying(video.paused == false)
    }

    const onDurationChanged = (event) => {
        if (video == null) { return }
        //console.log('onDurationChanged')
        setDuration(video.duration ?? 0) 
    }


    React.useEffect( () => {
        const video = props.videoRef.current
        if (video == null) { return }

        video.addEventListener("pause", onPlaybackChange)
        video.addEventListener("play", onPlaybackChange)
        video.addEventListener("durationchanged", onDurationChanged)
        video.addEventListener("loadedmetadata", onDurationChanged)
        
        onDurationChanged()

        return () => {
            video.removeEventListener("pause", onPlaybackChange)
            video.removeEventListener("play", onPlaybackChange)
            video.removeEventListener("durationchanged", onDurationChanged)
            video.removeEventListener("loadedmetadata", onDurationChanged)
        }

    }, [props.videoRef.current, props.videoRef])


    const startPlayback = (e) => {
        if (video.paused == false) {
            return
        }

        // Dont toggle if currently not selected
        // If in preview mode can toggle anytime.
        if (isPreview == false &&  isSelected == false) {
            return
        }

        if (video) {
            video.play()
        }

        if (e.preventDefault) {
            e.preventDefault()
        }

        if (e.stopPropagation) {
            e.stopPropagation()
        }
        return false
    }

    const stopPlayback = (e) => {
        // Dont toggle if currently not selected
        // If in preview mode can toggle anytime.
        if (isPreview == false &&  isSelected == false) {
            return
        }
        
        if (video.paused) {
            return
        }
        video.pause()
    }

    const playButton = (
        <div  onClick={startPlayback}  style={{ pointerEvents: isPlaying ? 'none' : 'all'}} >
            <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="42.5" cy="42.5" r="42.5" fill="white" fillOpacity="0.25"/>
            <path d="M33.8594 55.4688C33.2865 55.4688 32.8281 55.2604 32.4844 54.8438C32.151 54.4375 31.9844 53.8958 31.9844 53.2188V31.25C31.9844 30.5729 32.151 30.0312 32.4844 29.625C32.8281 29.2188 33.2865 29.0156 33.8594 29.0156C34.1719 29.0156 34.4688 29.0729 34.75 29.1875C35.0312 29.2917 35.3281 29.4323 35.6406 29.6094L53.8594 40.1562C54.5156 40.5312 54.9688 40.8698 55.2188 41.1719C55.4792 41.4635 55.6094 41.8177 55.6094 42.2344C55.6094 42.651 55.4792 43.0104 55.2188 43.3125C54.9688 43.6042 54.5156 43.9427 53.8594 44.3281L35.6406 54.8594C35.3281 55.0469 35.0312 55.1927 34.75 55.2969C34.4688 55.4115 34.1719 55.4688 33.8594 55.4688Z" fill="white"/>
            </svg>
        </div>
    )
    
    const containerStyle = {
        opacity: playing ? 0.0 : 1.0,
        transition: '0.25s',
        pointerEvents: 'all',
    }

    
    let titleInfo = (
        <Stack spacing={5} height={height - 40} padding={20} justify="trailing">
            { video && <Text id="duration" readonly={true} content={mmss(duration ? duration : 0)} style="headline" align="left" color="#ffffff90" />}
            <Text id="title" content={props.title ?? "Video Title"} style="headline" align="left" color="white" />
        </Stack>
    )

    return ( 
            <ZStack style={containerStyle} align={"center"} justify={"center"}>
                <div onClick={stopPlayback}  className="gradient-overlay" style={{ pointerEvents: isPlaying ? 'all' : 'none', "height": (height) + "px", 'borderRadius': '12px' }}></div>
                {props.title && props.title.lenght > 0 && titleInfo}           
                {video && playButton}
           </ZStack>
        )
}


export function VideoInspectorControls(props) {
    const [playing, setPlaying] = React.useState(false)
    const [duration, setDuration] = React.useState(0)
    const [playbackTime, setPlaybackTime] = React.useState(0)
    const [progress, setProgress] = React.useState(0)
    const [isDragging, setIsDragging] = React.useState(false)

    const video = props.videoRef.current

    const onPlaybackChange = (event) => {
        if (video == null) { return }
        console.log('onPlaybackChange')  
        setPlaying(video.paused == false)
    }

    const onDurationChanged = (event) => {
        if (video == null) { return }
        console.log('onDurationChanged')
        setDuration(video.duration ?? 0) 
    }

    const onProgressChanged = (event) => {
        if (video == null) { return }
        setPlaybackTime(video.currentTime)
        if (isDragging == false) {
            if (video.duration > 0) {
                setProgress(video.currentTime / video.duration)
            } else {
                setProgress(0)
            }            
        }
    }

    React.useEffect( () => {
        const video = props.videoRef.current
        if (video == null) { return }

        video.addEventListener("ratechange", onPlaybackChange)
        video.addEventListener("pause", onPlaybackChange)
        video.addEventListener("play", onPlaybackChange)
        video.addEventListener("durationchanged", onDurationChanged)
        video.addEventListener("loadedmetadata", onDurationChanged)
        video.addEventListener("timeupdate", onProgressChanged)
        
        onPlaybackChange()
        onDurationChanged()
        onProgressChanged()

        return () => {
            video.removeEventListener("ratechange", onPlaybackChange)
            video.removeEventListener("pause", onPlaybackChange)
            video.removeEventListener("play", onPlaybackChange)
            video.removeEventListener("durationchanged", onDurationChanged)
            video.removeEventListener("timeupdate", onProgressChanged)
        }

    }, [props.videoRef.current, props.videoRef, isDragging])

    if (video == null) {
        return <div>{props.videoRef.current}</div>
    }

    const playButton = (
        <InspectorControlButton title={playing ? "Pause" : "Play"} onClick={ () => {
            if (playing) {
                video.pause()
            } else {
                video.play()
            }
        }}>
            
        </InspectorControlButton>
    )

    const onProgressDragStart = () => {
        console.log('drag start')
        setIsDragging(true)
    }

    const onProgressDragEnd = () => {
        console.log('drag end')
        setIsDragging(false)
    }

    const onProgressDragChange = (event) => {
        const progress = event.target.value / 100
        setProgress(progress)
        video.currentTime = progress * duration
    }
    const slider = (
        <HStack spaceItems={"between"}>
            <span>{mmss(duration > 0 ? progress * duration : 0)}</span>
            <Spacer/>
                <input onMouseDown={onProgressDragStart} onChange={onProgressDragChange} onMouseUp={onProgressDragEnd} style={{"width": "200px"}} type="range" min="0" max="100" value={progress * 100}></input>
            <Spacer/>
            <span>{mmss(duration > 0 ? duration : 0)}</span>
        </HStack>
    )

    return (
        <VStack paddingTop={10} spacing={20}>
            {slider}
            {playButton}
        </VStack>
    )
}

function mmss(seconds) {
    if (seconds == null) {
        return `00:00`
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    
    const minutesString = String(minutes).padStart(2, '0');
    const secondsString = String(remainingSeconds).padStart(2, '0');
    
    return `${minutesString}:${secondsString}`;
 }