import * as React from 'react'
import { useAppState } from './ContentBuilderStateProvider.js'
import { Page } from "../page-components/Page.js"
import { Video } from '../page-components/ui/Video.js';
import { Text } from '../page-components/ui/Text.js';
import { Stack } from '../page-components/ui/Stack.js';
import { Image } from '../page-components/ui/Image.js';
import { useState, cloneElement } from "react";
import { ContentBuilderValidation } from './ContentBuilderValidation.js';
import { useCanvas , CanvasContext } from './ContentBuilderCanvasState.js';
import { debugPort } from 'process';


const down = (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none">
        <path d="M4.7207 0.386719C4.28711 0.386719 3.99414 0.685547 3.99414 1.13086V8.07422L4.04102 9.3457L2.65234 7.77539L1.33398 6.46289C1.19922 6.33398 1.02344 6.24609 0.8125 6.24609C0.414062 6.24609 0.115234 6.53906 0.115234 6.94922C0.115234 7.14258 0.191406 7.32422 0.34375 7.47656L4.18164 11.3203C4.32227 11.4668 4.52148 11.5488 4.7207 11.5488C4.91992 11.5488 5.125 11.4668 5.26562 11.3203L9.10352 7.47656C9.25586 7.32422 9.33203 7.14258 9.33203 6.94922C9.33203 6.53906 9.0332 6.24609 8.63477 6.24609C8.42383 6.24609 8.24805 6.33398 8.11328 6.46289L6.78906 7.77539L5.40039 9.3457L5.45312 8.07422V1.13086C5.45312 0.685547 5.1543 0.386719 4.7207 0.386719Z" fill="black" />
    </svg>
)

const up = (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.7207 11.5488C5.1543 11.5488 5.45312 11.25 5.45312 10.8047V3.86133L5.40039 2.58984L6.78906 4.16016L8.11328 5.47266C8.24805 5.60156 8.42383 5.68945 8.63477 5.68945C9.0332 5.68945 9.33203 5.39648 9.33203 4.98633C9.33203 4.79297 9.25586 4.61133 9.10352 4.45898L5.26562 0.615234C5.125 0.46875 4.91992 0.386719 4.7207 0.386719C4.52148 0.386719 4.32227 0.46875 4.18164 0.615234L0.34375 4.45898C0.191406 4.61133 0.115234 4.79297 0.115234 4.98633C0.115234 5.39648 0.414062 5.68945 0.8125 5.68945C1.02344 5.68945 1.19922 5.60156 1.33398 5.47266L2.65234 4.16016L4.04102 2.58984L3.99414 3.86133V10.8047C3.99414 11.25 4.28711 11.5488 4.7207 11.5488Z" fill="black" />
    </svg>
)

const trash = (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.67578 14.0879H9.07227C10.0156 14.0879 10.6133 13.5371 10.6543 12.5879L11.0469 4.20898H11.6504C11.9785 4.20898 12.2305 3.96289 12.2305 3.63477C12.2305 3.3125 11.9785 3.06641 11.6504 3.06641H9.0957V2.20508C9.0957 1.27344 8.50391 0.728516 7.48438 0.728516H5.25195C4.23242 0.728516 3.64648 1.27344 3.64648 2.20508V3.06641H1.09766C0.775391 3.06641 0.517578 3.3125 0.517578 3.63477C0.517578 3.96289 0.775391 4.20898 1.09766 4.20898H1.70117L2.09375 12.5938C2.13477 13.5371 2.72656 14.0879 3.67578 14.0879ZM4.8418 2.25195C4.8418 1.9707 5.03516 1.79492 5.33984 1.79492H7.39648C7.70703 1.79492 7.90039 1.9707 7.90039 2.25195V3.06641H4.8418V2.25195ZM3.82227 12.9336C3.5 12.9336 3.2832 12.7051 3.26562 12.3594L2.87891 4.20898H9.85156L9.47656 12.3594C9.46484 12.7109 9.24805 12.9336 8.91992 12.9336H3.82227ZM4.6543 12.0312C4.91797 12.0312 5.08789 11.8555 5.08203 11.6094L4.90625 5.56836C4.90039 5.31641 4.72461 5.15234 4.47266 5.15234C4.20898 5.15234 4.03906 5.32227 4.04492 5.56836L4.2207 11.6152C4.22656 11.8672 4.40234 12.0312 4.6543 12.0312ZM6.37695 12.0312C6.63477 12.0312 6.81055 11.8613 6.81055 11.6152V5.56836C6.81055 5.31641 6.63477 5.15234 6.37695 5.15234C6.11328 5.15234 5.9375 5.31641 5.9375 5.56836V11.6152C5.9375 11.8613 6.11328 12.0312 6.37695 12.0312ZM8.09375 12.0312C8.3457 12.0312 8.52148 11.8672 8.52734 11.6152L8.70312 5.56836C8.70898 5.32227 8.53906 5.15234 8.27539 5.15234C8.02344 5.15234 7.84766 5.31641 7.8418 5.56836L7.66602 11.6094C7.66016 11.8613 7.83008 12.0312 8.09375 12.0312Z" fill="black" />
    </svg>
)

const more = (
    <svg width="13" height="4" viewBox="0 0 13 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.25977 1.9707C3.25977 1.25 2.69141 0.681641 1.95898 0.681641C1.25 0.681641 0.669922 1.25586 0.669922 1.9707C0.669922 2.67383 1.25 3.25977 1.95898 3.25977C2.66797 3.25977 3.25977 2.67383 3.25977 1.9707ZM7.64258 1.9707C7.64258 1.25 7.08008 0.681641 6.35938 0.681641C5.65039 0.681641 5.07031 1.25586 5.07031 1.9707C5.07031 2.67383 5.65039 3.25977 6.35938 3.25977C7.05664 3.25977 7.64258 2.67383 7.64258 1.9707ZM12.043 1.9707C12.043 1.25 11.4805 0.681641 10.7539 0.681641C10.0391 0.681641 9.45898 1.25586 9.45898 1.9707C9.45898 2.67383 10.0391 3.25977 10.7539 3.25977C11.457 3.25977 12.043 2.67383 12.043 1.9707Z" fill="black" />
    </svg>
)

const add = (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
        <path d="M0.494141 5.76953C0.494141 6.18555 0.839844 6.52539 1.25 6.52539H4.69531V9.9707C4.69531 10.3867 5.03516 10.7266 5.45117 10.7266C5.86719 10.7266 6.20703 10.3867 6.20703 9.9707V6.52539H9.65234C10.0684 6.52539 10.4082 6.18555 10.4082 5.76953C10.4082 5.35352 10.0684 5.01367 9.65234 5.01367H6.20703V1.56836C6.20703 1.1582 5.86719 0.8125 5.45117 0.8125C5.03516 0.8125 4.69531 1.1582 4.69531 1.56836V5.01367H1.25C0.839844 5.01367 0.494141 5.35352 0.494141 5.76953Z" fill="black" />
    </svg>
)

const insertIcon = (
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.464073 6.33105C0.464073 6.11686 0.541547 5.93229 0.696495 5.77734C0.851443 5.6224 1.03601 5.54492 1.25021 5.54492H5.12618V1.67578C5.12618 1.46159 5.20138 1.27702 5.35177 1.12207C5.50672 0.962565 5.69357 0.882812 5.91232 0.882812C6.13107 0.882812 6.31564 0.962565 6.46603 1.12207C6.62097 1.27702 6.69845 1.46159 6.69845 1.67578V5.54492H10.5744C10.7886 5.54492 10.9709 5.6224 11.1213 5.77734C11.2762 5.93229 11.3537 6.11686 11.3537 6.33105C11.3537 6.5498 11.2762 6.73665 11.1213 6.8916C10.9709 7.04655 10.7886 7.12402 10.5744 7.12402H6.69845V10.9932C6.69845 11.2074 6.62097 11.3919 6.46603 11.5469C6.31564 11.7018 6.13107 11.7793 5.91232 11.7793C5.69357 11.7793 5.50672 11.7018 5.35177 11.5469C5.20138 11.3919 5.12618 11.2074 5.12618 10.9932V7.12402H1.25021C1.03601 7.12402 0.851443 7.04655 0.696495 6.8916C0.541547 6.73665 0.464073 6.5498 0.464073 6.33105Z" fill="black"/>
</svg>

)

const addIcon = (
    <svg  width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1_13)">
        <circle cx="13" cy="11" r="9" fill="white"/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.7996 7.59134C13.7996 7.10809 13.4079 6.71634 12.9246 6.71634C12.4414 6.71634 12.0496 7.10809 12.0496 7.59134V10.1517H9.59133C9.10808 10.1517 8.71633 10.5435 8.71633 11.0267C8.71633 11.51 9.10808 11.9017 9.59133 11.9017H12.0496V14.4087C12.0496 14.8919 12.4414 15.2837 12.9246 15.2837C13.4079 15.2837 13.7996 14.8919 13.7996 14.4087V11.9017H16.4087C16.8919 11.9017 17.2837 11.51 17.2837 11.0267C17.2837 10.5435 16.8919 10.1517 16.4087 10.1517H13.7996V7.59134Z" fill="black"/>
        <defs>
        <filter id="filter0_d_1_13" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_13"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_13" result="shape"/>
        </filter>
        </defs>
    </svg>
)

function ContentBuilderCanvas(props, pageIndex) {
    let { selectedId, setSelectedId, selectedPageId, pages, setShowAddSection, deleteSection, moveSection, setShowComponentLibrary, setInsertSectionIndex, setInsertSectionGroup, insertSectionIndex, canInsertComponent } = useAppState()

    const [hoveredSectionId, setHoveredSectionId] = useState(null)
    const [hoveredSectionRef, setHoveredSectionRef] = useState(null)
    const [scrollOffset, setPageScrollOffset] = useState(null)

    const timer = React.useRef(null)

    React.useEffect(() => {
        if (props.onSectionHover != null) {
            props.onSectionHover(hoveredSectionRef)
        }
    }, [hoveredSectionRef])

    const cancelHoverClear = () => {
        if (timer.current) {
            clearTimeout(timer.current)
            timer.current = null
        }
    }

    const setHoveredRef = (ref, delay) => {
        cancelHoverClear()

        if (ref == null && delay == true) {
            timer.current = setTimeout( () => {
                setHoveredSectionRef(null)
            }, 100)
        } else {
            setHoveredSectionRef(ref)
        }
    }

    /**
     * Section Hovering
     */
    var canvasContextValue = {
        setHoveredSectionRef: setHoveredRef,
        setPageScrollOffset: setPageScrollOffset,
        setHoveredSectionId: setHoveredSectionId
    }
    var sectionHover = null

    var onMouseOverControls = () => {
        cancelHoverClear()
        //console.log('mouse over')
    }

    var onMouseOutControls = () => {
        //console.log('mouse out')
    }

    var onMouseOverSectionOut = () => {
        setHoveredSectionRef(null)
    }

    var deleteHighlightedSection = () => {
        if (hoveredSectionId) {
            deleteSection(hoveredSectionId)
            setHoveredSectionId(null)
            setHoveredSectionRef(null)
        }
    }

    var moveSectionUp = () => {
        moveSection(hoveredSectionId, -1)
    }

    var moveSectionDown = () => {
        moveSection(hoveredSectionId, 1)
    }

    var canDelete = true
    var canMoveUp = true
    var canMoveDown = true
    var canInsert = true
    var canInsertBottom = true
    var canInsertTop = true
    var sectionIndex = 0
    var moveDownClass = ""
    var moveUpClass = ""
    var hasMoreActions = false

    var currentSection = null

    if (hoveredSectionRef) {
        var currentPage = pages.filter(page => {
            return page.id == selectedPageId
        })[0]


        // Find current section
        currentPage.sections.forEach( (section, idx) => {
            if (section.id == hoveredSectionId) {
                currentSection = section
                sectionIndex = idx
            }
        })

        // Determine if the sectoin is in a 'group' of siblings. 
        // 'group' is optional.
        let siblingSections = currentPage.sections.filter( (section, idx) => {
            return (currentSection.group != null) ? (section.group == currentSection.group) : true
        })

        // index of currentPage in siblingSections
        const currentSiblingIndex = siblingSections.findIndex(section => section.id === currentSection.id);
        const previousSection = (currentSiblingIndex > 0) ? siblingSections[currentSiblingIndex - 1] : null
        const nextSection = siblingSections[currentSiblingIndex + 1]


        if (currentSection) {
            if (currentSection.canDelete != null) {
                canDelete = currentSection.canDelete
            }
            if (currentSection.canMove != null) {
                canMoveUp = currentSection.canMove
                canMoveDown = currentSection.canMove
            }
            if (currentSection.canMove == false) {
                if (previousSection != null) {
                    canInsertTop = previousSection.canMove != false
                } else {
                    canInsertTop = false
                }

                if (nextSection != null) {
                    canInsertBottom = nextSection.canMove != false
                } else {
                    canInsertBottom = false
                }
            }
        }

        if (previousSection == null || previousSection.canMove == false) {
            moveUpClass = "disabled"
        }
        if (nextSection == null || nextSection.canMove == false) {
            moveDownClass = "disabled"
        }

        if (canInsertBottom && canInsertComponent != null) {
            let canInsert = canInsertComponent(null, { index: currentSiblingIndex, group: currentSection.group })
            if (canInsert == false) {
                canInsertBottom = false
            }
        }
    }

    if (sectionIndex == insertSectionIndex - 1) {
        //canInsertBottom = false
    }

    if (hoveredSectionRef && (canDelete || canMoveUp || canMoveDown || canInsertBottom || canInsertTop)) {
        var canvas = document.getElementById('canvas')
        var containerRect = canvas.getBoundingClientRect();
        var rect = hoveredSectionRef.getBoundingClientRect();

        var style = {
            top: (rect.top - containerRect.top + canvas.scrollTop) + "px",
            left: (rect.left - containerRect.left) + "px",
            width: rect.width + "px",
            height: rect.height + "px"
        }

        var insertSectionTop = () => {
            setInsertSectionIndex(sectionIndex)
            setShowComponentLibrary(true)
        }

        var insertSectionBottom = () => {
            setInsertSectionIndex(sectionIndex + 1)
            if (currentSection != null) {
                setInsertSectionGroup(currentSection.group)
            }
            setShowComponentLibrary(true)
        }

        let showControls =  (canDelete || canMoveUp || canMoveDown)
        sectionHover = <div className="section-hover" style={style}>
            <div className="section-hover-outline"></div>
            <div className="section-hover-content">

                {/* {canInsertTop && <div onClick={insertSectionTop} className="section-insert section-insert-top">
                    {addIcon}
                </div>} */}
            
                {canInsertBottom && <div className="section-insert section-insert-bottom" onMouseOver={onMouseOverControls} >
                    <div onClick={insertSectionBottom} >
                    {addIcon}
                    </div>
                </div>}

                {showControls && <div className="section-controls" onMouseOver={onMouseOverControls} onMouseOut={onMouseOutControls}>
                    <div className="section-controls-content">
                        {hasMoreActions && <div className="control">{more}</div>}
                        {canMoveUp && <div className={`control ${moveUpClass}`} onClick={moveSectionUp}>{up}</div>}
                        {canMoveDown && <div className={`control ${moveDownClass}`} onClick={moveSectionDown}>{down}</div>}
                        {canDelete && <div className="control" onClick={deleteHighlightedSection}>{trash}</div>}
                        {/* {canInsert && <div className="control" onClick={insertSectionBottom}>{insertIcon}</div>} */}
                    </div>
                </div>}

                <div className="section-hover-outside-left" onMouseOver={onMouseOverSectionOut}></div>
                <div className="section-hover-outside-right" onMouseOver={onMouseOverSectionOut}></div>
                <div className="section-hover-outside-right-bottom" onMouseOver={onMouseOverSectionOut}></div>
                <div className="section-hover-outside-right-top" onMouseOver={onMouseOverSectionOut}></div>

            </div>
        </div>
    }

    return <div className="canvas" id="canvas" >
        <CanvasContext.Provider value={canvasContextValue}>
            <div className="canvas-container">
                {props.children}
            </div>
            {sectionHover}
        </CanvasContext.Provider>
    </div>
}



function ContentBuilderCanvasContent(props) {
    let { selectedId, setSelected, pages, currentPage, components } = useAppState()
    const [canvasBottomPadding, setCanvasBottomPadding] = React.useState(0)

    if (!currentPage) {
        return <div className="canvas-content">{"Missing"}</div>
    }

    let pageIndex = pages.map(page => { return page.id }).indexOf(currentPage.id)

    let page = <Page {...currentPage} index={pageIndex} selected={selectedId == currentPage.id} onHover={props.onPageHover}>
                    <ContentBuilderValidation components={components} page={currentPage} onBottomPadding={setCanvasBottomPadding} />
                </Page>

    var onClick = function () {
        setSelected(null)
    }

    var contentStyle = {
        paddingBottom: canvasBottomPadding + 'px'
    }

    return <div style={contentStyle} className="canvas-content" onClick={onClick}>
        {page}
    </div>
}

export { ContentBuilderCanvas, ContentBuilderCanvasContent, CanvasContext, useCanvas }