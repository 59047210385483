import * as React from 'react'
import Modal from 'react-modal';
import AssetLibraryBrowser from "../asset-components/AssetLibraryBrowser.js"
import { TemplateLibrary } from "../data/TemplateLibrary.js"
import { useAppState } from './ContentBuilderStateProvider.js'
import { ComponentSidebar } from '../components/ComponentSidebar.js';

function ContentBuilderModals() {
    let {  showAddSection, setShowAddSection, setShowAssetEditModal, showAssetEditModal, showComponentLibrary,  setShowComponentLibrary, setShowAssetLibrary, showAssetLibrary, showTemplateLibrary, setShowTemplateLibrary, canCrop, canMultiUpload } = useAppState()
    let { onAssetSelected, assetLibraryTypes, canAddAssetType, assets, onAssetImport, onAssetChange, onAssetDelete } = useAppState()

    return <div>
         {/* <Modal className="modal" overlayClassName="modal-overlay" isOpen={showAddSection} onRequestClose={  () => {
              setShowAddSection(false)
            }}>
              <SectionList/>
          </Modal> */}

          <Modal contentLabel={"Asset Editing"} ariaHideApp={false} className="modal modal-fullscreen" overlayClassName="modal-overlay" isOpen={showAssetEditModal != null} onRequestClose={  () => {
              setShowAssetEditModal(null)
          }}>
              {showAssetEditModal}
          </Modal>

          <Modal contentLabel={"Asset Library"} ariaHideApp={false} className="modal modal-fullscreen" overlayClassName="modal-overlay" isOpen={showAssetLibrary} onRequestClose={  () => {
              setShowAssetLibrary(false)
            }}>
              <AssetLibraryBrowser
                  canCrop={canCrop}
                  canMultiUpload={canMultiUpload}
                  onAssetSelected={onAssetSelected}
                  setShowAssetLibrary={setShowAssetLibrary}
                  assetLibraryTypes={assetLibraryTypes}
                  canAddAssetType={canAddAssetType}
                  assets={assets}
                  onAssetImport={onAssetImport}
                  onAssetChange={onAssetChange}
                  onAssetDelete={onAssetDelete}
                  onCancel={ ()  => {
                    setShowAssetLibrary(false)
                }}/>
          </Modal>


          <Modal contentLabel={"Template Library"} ariaHideApp={false} className="modal" overlayClassName="modal-overlay" isOpen={showTemplateLibrary} onRequestClose={  () => {
              setShowTemplateLibrary(false)
            }}>
              <TemplateLibrary/>
          </Modal>

          <Modal contentLabel="Component Library" className="modal" overlayClassName="modal-overlay" isOpen={showComponentLibrary} onRequestClose={  () => {
              setShowComponentLibrary(false)
            }}>
              <ComponentSidebar/>
          </Modal>
    </div>
}

function ContentBuilderModal(props) {
  return (
    <Modal contentLabel={"Modal"} 
           ariaHideApp={false} 
           className="modal modal-fullscreen"
            overlayClassName="modal-overlay" 
            isOpen={props.show} onRequestClose={  () => {
              if (props.setShow) {
                  props.setShow(false)
              }
              if (props.onClose) {
                  props.onClose(false)
              }
            }}>
        {props.children}
    </Modal>
  )
}

export { ContentBuilderModals, ContentBuilderModal }