import * as React from 'react'
import { useIcons } from "../content-builder-components/ContentBuilderIconProvider.js"

function Notification(props) {
    const icons = useIcons()

    const { show , setShow, title, duration, icon } = props

    const visibleClassName = show ? " polymer-notification-visible" : ""
    const iconImage        = icons.get(`notification.${icon}`)

    React.useEffect( () => {
        if (show) {
            setTimeout( () => {
                setShow(false)
            }, duration ?? 1500)
        }
    }, [show])
        
    return (
        <div className={`polymer-notification ${visibleClassName}`}>
            <div className={`polymer-notification-content`}>
                {iconImage}{title}
            </div>
        </div>
    )
}

export { Notification }