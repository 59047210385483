import * as React from 'react';
import Props from "../PropsHelper.js";

function ZStack(props) {
    const style = {...props.style};

    if (props.height) {
        style['height'] = props.height + 'px';
    }

    if (props.width) {
        style['width'] = Props.width(props);
    }

    if (props.spacing) {
        style['gap'] = props.spacing + 'px';
    }

    if (props.clip) {
        style['overflow'] = 'hidden';
    }

    if (props.clipX) {
        style['overflowX'] = 'hidden';
    }

    if (props.align == 'leading') {
        style['alignItems'] = 'flex-start';
    } else if (props.align == 'trailing') {
        style['alignItems'] = 'flex-end';
    } else if (props.align == 'center') {
        style['alignItems'] = 'center';
        style['textAlign'] = 'center';
    }

    if (props.justify == 'leading') {
        style['justifyContent'] = 'flex-start';
    } else if (props.justify == 'trailing') {
        style['justifyContent'] = 'flex-end';
    } else if (props.justify == 'center') {
        style['justifyContent'] = 'center';
    }


    if (props.padding) {
        style['padding'] = props.padding + 'px';
    }

    if (props.paddingVertical != null) {
        style['paddingTop'] = props.paddingVertical + 'px';
        style['paddingBottom'] = props.paddingVertical + 'px';
    }

    if (props.paddingTop != null) {
        style['paddingTop'] = props.paddingTop + 'px';
    }

    if (props.paddingBottom != null) {
        style['paddingBottom'] = props.paddingBottom + 'px';
    }

    if (props.cornerRadius != null) {
        style['borderRadius'] = props.cornerRadius + 'px';
    }

    const children = React.Children.map(props.children ?? [], child => {
        if (React.isValidElement(child)) {
            return <div className="zstack-element">{React.cloneElement(child)}</div>;
        }
        return child;
    });

    return <div onClick={props.onClick} style={style} className="zstack">{children}</div>;
}

export { ZStack }