import * as React from 'react';
import useUniqueId from '../useUniqueId.js';
import { Stack } from './Stack.js';
import { Text } from './Text.js';
import { ZStack } from './ZStack.js';
import { View, useViewState } from './View.js';
import Props from "../PropsHelper.js"

function Video(props) {
    const id = useUniqueId(props.id);
    const videoRef = React.useRef(null)

    var model = {
        type: 'video',
        id: id,
        videoRef: videoRef,
        ...props,
    };

    const canvasWidth = 340
    const playbackStyleFullscreen = props.playbackStyle == null || props.playbackStyle == 'fullscreen'

    var width = "100%"
    var height = canvasWidth

    if (props.dimensions && props.dimensions.width > 0 && props.dimensions.height > 0) {
        let aspect = props.dimensions.height / props.dimensions.width
        width = canvasWidth
        height = canvasWidth * aspect
    }

    if (props.height) {
        height = props.height
    }

    const containerStyle = {
        width: '100%',
        backgroundColor: '#696969',
        borderRadius:  Props.cornerRadius(props),
        height:  Props.height(props)
    };
    
    const videoStyle = {
        borderRadius: Props.cornerRadius(props),
    }

    const metadata = <VideoMetadata overlay={props.overlay} title={props.title} height={height} videoRef={videoRef}/>

    return (<ZStack style={{ height: height }}>
                <View width={width} height={height} model={model} videoRef={videoRef} id={id}>
                    <ZStack style={containerStyle}>
                        <VideoPlayer
                            cornerRadius={props.cornerRadius} 
                            height={height} 
                            videoRef={videoRef} 
                            style={videoStyle} 
                            canPlay={false} 
                            title={props.title} 
                            controls={false} 
                            src={props.src}></VideoPlayer>
                        {playbackStyleFullscreen && metadata}
                    </ZStack>
                </View>

            </ZStack>);
}

function VideoPlayer(props) {
    const [playing, setIsPlaying] = React.useState(false);
    const canPlay = props.canPlay != null ? props.canPlay : true 

    const onPlay = function (event) {
        setIsPlaying(true);
    };

    const videoStyle = {
        'objectFit': 'cover',
        ...props.style
    };

    var videoElement = <div>&nbsp;</div>;

    if (props.src) {
        const videoAttrs = {
            width: '100%',
            height: '100%'
        }

        if (props.height) {
            videoAttrs['height'] = props.height + 'px'
        }

        if (props.width) {
            videoAttrs['width'] = props.width + 'px'
        }

        if (props.controls != false) {
            videoAttrs['controls'] = true
        }

        if (props.autoPlay != null) {
            videoAttrs['autoPlay'] = props.autoPlay
        }

        videoElement = <video ref={props.videoRef} style={videoStyle} src={props.src} onPlay={onPlay} muted playsInline {...videoAttrs} />;
    }

    return videoElement
}

function VideoMetadata(props) {
    const { isSelected, isPreview } = useViewState()

    const [duration, setDuration] = React.useState(0)
    const [playing, setPlaying] = React.useState(false)
    const videoRef = props.videoRef
    const video = props.videoRef.current
    const height = props.height ?? 340

    var isPlaying = video && video.paused == false

    const onPlaybackChange = (event) => {
        if (video == null) { return }
        console.log('onPlaybackChange')  
        setPlaying(video.paused == false)
    }

    const onDurationChanged = (event) => {
        if (video == null) { return }
        //console.log('onDurationChanged')
        setDuration(video.duration ?? 0) 
    }


    React.useEffect( () => {
        const video = props.videoRef.current
        if (video == null) { return }

        video.addEventListener("pause", onPlaybackChange)
        video.addEventListener("play", onPlaybackChange)
        video.addEventListener("durationchanged", onDurationChanged)
        video.addEventListener("loadedmetadata", onDurationChanged)
        
        onDurationChanged()

        return () => {
            video.removeEventListener("pause", onPlaybackChange)
            video.removeEventListener("play", onPlaybackChange)
            video.removeEventListener("durationchanged", onDurationChanged)
            video.removeEventListener("loadedmetadata", onDurationChanged)
        }

    }, [props.videoRef.current, props.videoRef])


    const startPlayback = (e) => {
        if (video.paused == false) {
            return
        }

        // Dont toggle if currently not selected
        // If in preview mode can toggle anytime.
        if (isPreview == false &&  isSelected == false) {
            return
        }

        if (video) {
            video.play()
        }

        if (e.preventDefault) {
            e.preventDefault()
        }

        if (e.stopPropagation) {
            e.stopPropagation()
        }
        return false
    }

    const stopPlayback = (e) => {
        // Dont toggle if currently not selected
        // If in preview mode can toggle anytime.
        if (isPreview == false &&  isSelected == false) {
            return
        }
        
        if (video.paused) {
            return
        }
        video.pause()
    }

    const playButton = (
        <div  onClick={null}  style={{ pointerEvents: isPlaying ? 'none' : 'all'}} >
            <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="42.5" cy="42.5" r="42.5" fill="white" fillOpacity="0.25"/>
            <path d="M33.8594 55.4688C33.2865 55.4688 32.8281 55.2604 32.4844 54.8438C32.151 54.4375 31.9844 53.8958 31.9844 53.2188V31.25C31.9844 30.5729 32.151 30.0312 32.4844 29.625C32.8281 29.2188 33.2865 29.0156 33.8594 29.0156C34.1719 29.0156 34.4688 29.0729 34.75 29.1875C35.0312 29.2917 35.3281 29.4323 35.6406 29.6094L53.8594 40.1562C54.5156 40.5312 54.9688 40.8698 55.2188 41.1719C55.4792 41.4635 55.6094 41.8177 55.6094 42.2344C55.6094 42.651 55.4792 43.0104 55.2188 43.3125C54.9688 43.6042 54.5156 43.9427 53.8594 44.3281L35.6406 54.8594C35.3281 55.0469 35.0312 55.1927 34.75 55.2969C34.4688 55.4115 34.1719 55.4688 33.8594 55.4688Z" fill="white"/>
            </svg>
        </div>
    )
    
    const containerStyle = {
        opacity: playing ? 0.0 : 1.0,
        transition: '0.25s',
        pointerEvents: 'all',
    }

    var overlay = null
    if (props.overlay) {
        overlay = props.overlay({
            height: height,
            video: video,
            duration: duration,
            durationFormatted: mmss(duration ? duration : 0),
            title: props.title
        })
    }

    return ( 
            <ZStack style={containerStyle} align={"center"} justify={"center"}>
                <div onClick={stopPlayback}  className="gradient-overlay" style={{ pointerEvents: isPlaying ? 'all' : 'none', "height": (height) + "px", 'borderRadius': '12px' }}></div>
                {overlay}
                {video && playButton}
           </ZStack>
        )
}

function mmss(seconds) {
    if (seconds == null) {
        return `00:00`
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    
    const minutesString = String(minutes).padStart(2, '0');
    const secondsString = String(remainingSeconds).padStart(2, '0');
    
    return `${minutesString}:${secondsString}`;
 }

export { Video, VideoPlayer, mmss }