import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import * as React from 'react';
import { useState } from "react";
import { useSection } from '../SectionContext.js';
import Props from "../PropsHelper.js";
import useUniqueId from '../useUniqueId.js';

const ViewStateContext = React.createContext();

function useViewState() {
    const context = React.useContext(ViewStateContext);
    if (!context) {
        throw new Error(`useViewState must be used within a ViewStateContext`);
    }
    return context;
}

function ViewStateProvider(props) {
    var value = {
        ...props
    };
    return <ViewStateContext.Provider value={value}>{props.children}</ViewStateContext.Provider>;
}

function View(props) {
    const [isHighlighted, setIsHighlighted] = useState(false);
    const id = useUniqueId(props.id);
    let { selectedId, setSelected, isReadonly, updateModel, currentPage, refreshInspector } = useAppState();

    let { sectionId, sectionEncode } = useSection();

    // Check selected
    const isSelected = selectedId && selectedId == id;

    // Calc style
    var style = { ...props.style };

    if (props.height) {
        style['height'] = Props.height(props);
    }

    // Calc classes
    const selectedClass = (isSelected ? "selected" : "");
    const highlightClass = (isHighlighted ? "highlighted" : "");

    // Events
    var onMouseOver = function () {
        if (isReadonly || props.readonly) { return false; }
        setIsHighlighted(true);
    };

    var onMouseOut = function () {
        if (isReadonly || props.readonly) { return false; }
        setIsHighlighted(false);
    };

    var onClick = function (e) {
        if (isReadonly || props.readonly) { return false; }

        var model = props.model;
        model.sectionId = sectionId;
        model.sectionEncode = sectionEncode;
        setSelected(id, model, sectionId, sectionEncode, props.inspector);
        e.stopPropagation();
        if (props.onClick) {
            props.onClick(e);
        }
    };

    const useViewUpdateModel = (newModel) => {
        var model = { ...newModel, sectionId: sectionId, sectionEncode: sectionEncode };
        updateModel(model);
    };

    return (
        <ViewStateProvider model={props.model}
            updateModel={useViewUpdateModel}
            refreshInspector={refreshInspector}
            page={currentPage}
            viewId={id}
            sectionId={sectionId}
            isHighlighted={isHighlighted}
            isSelected={isSelected}
            isPreview={isReadonly == true}
            >
            <div className={`view ${selectedClass} ${highlightClass}`}
                style={style}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onClick={onClick}
                key={id}
                id={id}>
                {props.children}
            </div>
        </ViewStateProvider>
    );
}


export { useViewState, View, ViewStateProvider }