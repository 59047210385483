import BasicMessageTemplate from "./Templates/BasicMessageTemplate.js"

const templateLibrary = [
    {
        name: 'Messages',
        templates: [
            { template: new BasicMessageTemplate(), title: "Basic Message"  },

            // { template: new StoryTemplate(), title: "Map Story" },            
        ]
    },
]

export { templateLibrary }