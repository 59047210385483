
var Props = {
    screenSize : { width: 375, height: 818 },
    height : (props) => {
        var h = props.height
        if (h == null) { return null }
        var heightVal = props.height
        if (h == "infinity") {
            return '-webkit-fill-content'
        }
        if (h.indexOf && h.indexOf('%') > -1) {
            var p = h.replace('%', '')
            heightVal = p * (Props.screenSize.height / 100)
        }
        return heightVal + 'px'
    },
    width : (props) => {
        var h = props.width
        if (h == null) { return null }
        var heightVal = props.width
        if (h == "infinity") {
            return '-webkit-fill-available'
        }
        if (h.indexOf && h.indexOf('%') > -1) {
            var p = h.replace('%', '')
            heightVal = p * (Props.screenSize.width / 100)
        }
        return heightVal + 'px'
    },
    transform : (props) => {
        var transform = [];
        if (props.scale) {
            transform.push('scale(' + props.scale + ')');
        }
        if (props.rotate) {
            transform.push('rotate(' + props.rotate + 'deg)');
        }
        if (props.offsetX != null) {
            transform.push('translateX(' + String(props.offsetX) + 'px)');
        }
        if (props.offsetY != null) {
            transform.push('translateY(' + String(props.offsetY) + 'px)');
        }
        return transform.join(' ');
    },
    cornerRadius : (props) => {
        if (props.cornerRadius) {
            return props.cornerRadius + 'px';
        } else {
            return null
        }
    }
}

export default Props