import * as React from 'react';
import { useSection } from '../SectionContext.js';
import Props from "../PropsHelper.js";

function Stack(props) {

    let { sectionStyle } = useSection();

    var style = [];
    var className = 'vstack';
    if (props.direction == 'horizontal') {
        style['flexDirection'] = 'row';
        className = 'hstack';
    }

    if (props.height) {
        style['height'] = Props.height(props);
    }

    if (props.width) {
        style['width'] = Props.width(props);
    }

    if (props.spacing) {
        style['gap'] = props.spacing + 'px';
    }

    if (props.border) {
        style['border'] = props.border;
    }

    if (props.borderBottom) {
        style['borderBottom'] = props.borderBottom;
    }

    if (props.borderTop) {
        style['borderTop'] = props.borderTop;
    }

    if (props.borderLeft) {
        style['borderLeft'] = props.borderLeft;
    }

    if (props.borderRight) {
        style['borderRight'] = props.borderRight;
    }

    if (props.borderColor) {
        if (sectionStyle && sectionStyle.colors) {
            style['borderColor'] = sectionStyle.colors[props.borderColor] ?? props.borderColor;
        } else {
            style['borderColor'] = props.borderColor;
        }
    }


    if (props.backgroundColor) {
        if (sectionStyle && sectionStyle.colors) {
            style['backgroundColor'] = sectionStyle.colors[props.backgroundColor] ?? props.backgroundColor;
        } else {
            style['backgroundColor'] = props.backgroundColor;
        }
    }

    if (props.cornerRadius) {
        style['borderRadius'] = props.cornerRadius + 'px';
    }

    if (props.align == 'leading') {
        style['alignItems'] = 'flex-start';
        style['textAlign'] = 'left';
    } else if (props.align == 'trailing') {
        style['alignItems'] = 'flex-end';
    } else if (props.align == 'center') {
        style['alignItems'] = 'center';
        style['textAlign'] = 'center';
    }

    if (props.justify == 'leading') {
        style['justifyContent'] = 'flex-start';
    } else if (props.justify == 'trailing') {
        style['justifyContent'] = 'flex-end';
    } else if (props.justify == 'center') {
        style['justifyContent'] = 'center';
    }

    if (props.spaceItems == "between") {
        style['justifyContent'] = 'space-between';
    }

    if (props.padding) {
        style['padding'] = props.padding + 'px';
    }

    if (props.paddingHorizontal) {
        style['paddingLeft'] = props.paddingHorizontal + 'px';
        style['paddingRight'] = props.paddingHorizontal + 'px';
    }

    if (props.paddingVertical != null) {
        style['paddingTop'] = props.paddingVertical + 'px';
        style['paddingBottom'] = props.paddingVertical + 'px';
    }

    if (props.paddingLeft != null) {
        style['paddingLeft'] = props.paddingLeft + 'px';
    }

    if (props.paddingRight != null) {
        style['paddingRight'] = props.paddingRight + 'px';
    }

    if (props.paddingTop != null) {
        style['paddingTop'] = props.paddingTop + 'px';
    }

    if (props.paddingBottom != null) {
        style['paddingBottom'] = props.paddingBottom + 'px';
    }


    const children = React.Children.map(props.children ?? [], (child, idx) => {
        if (React.isValidElement(child)) {
            var className = "stack-element";
            if ( (child.props.width && child.props.width == 'infinity') || (props.width == 'infinity') ) {
                className += ' fill-available-width';
            }
            return <div onClick={props.onClick} key={idx} className={className}>{React.cloneElement(child)}</div>;
        }
        return child;
    });

    var content = children
    if (props.contentCallback != null) {
        content = props.contentCallback(children)
    }
    return <div style={style} onClick={props.onClick} className={`stack ${className}`}>{content}</div>;
}

export { Stack }