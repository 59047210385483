import React, { useEffect } from 'react';
import styled from 'styled-components'

import { rewriteReactModifiers, resolveUnresolvedNodes, rewriteVariables,  jsxStringToReact } from '../Generator.js'
import { Function, FunctionContext } from './ui/Function.tsx';    
import { RendererContext } from './RendererContext.tsx'; 
export function Renderer({ jsx , viewCallback }) {

    const [result, setResult] = React.useState(null);

    useEffect(() => {
        var content = <></>

        content = jsxStringToReact(jsx, viewCallback)           // Converts the JSX string to a React tree
        content = resolveUnresolvedNodes(content, viewCallback) // Resolves all nodes that aren't built-in components  
        content = rewriteReactModifiers(content)                // Rewrites properties that are modifiers to be parent elements
        content = rewriteVariables(content)                     // Rewrites variables that are expressions to be wrapped with a provider that executes the expression at runtime

        setResult(content)
    }, [jsx])

    /**
     * Function for when a view wants to lookup and render another view by tag name.
     * Currently for button styles.
     */
    const viewProvider = (view) => {
        if (viewCallback == null) { return null }
        let v = viewCallback(view)
        v = rewriteReactModifiers(v)
        v = rewriteVariables(v)
        return v
    }

    return (
        <RendererContext.Provider value={{ viewCallback: viewProvider }}>
            <FunctionContext.Provider value={{ functions: {}, functionArgs: {}, scripts: {} }}>
                <RendererContainer className="rendererContainer">
                    {result}
                </RendererContainer>
            </FunctionContext.Provider>
        </RendererContext.Provider>
    )
}

const RendererContainer = styled.div`   
    display: flex;
    flex-direction: column;;
    width: -webkit-fill-available;
    align-items: center;
    height: calc(100vh - 200px);
    justify-content: center;
    padding: 20px;
`