
import * as React from 'react'
import { useState} from "react";

const SectionContext = React.createContext({ sectionStyle: {} })

function useSection() {
    const context = React.useContext(SectionContext)
    if (!context) {
          //throw new Error(`useSection must be used within a SectionContext`)
    }
    
    return context
}

export { SectionContext, useSection }