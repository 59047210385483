import React from 'react';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import { InspectorProperty } from '../inspector-ui/InspectorProperty.js';
import { InspectorHeader } from '../inspector-ui/InspectorHeader.js';
import { InspectorControlSelect } from '../inspector-ui/InspectorControlSelect.js';

export function InspectorPage(props) {
    let { styles, updateModel } = useAppState();
    var model = props.model;

    var styleOptions = Object.keys(styles).map(key => {
        return styles[key].name;
    });

    var publishOptions = ['Draft', 'Review', 'Published'];

    return <div>
        <section>
            <InspectorHeader title="Style" />
            <InspectorProperty title="Appearance">
                <InspectorControlSelect value={model.style} options={styleOptions} keys={Object.keys(styles)} onChange={(v) => {
                    model.style = v;
                    updateModel(model);
                }} />
            </InspectorProperty>
        </section>
        {/* <section>
            <InspectorHeader title="Document" />
            <InspectorProperty title="Publish Status">
                <InspectorControlSelect value={model.style} options={publishOptions} keys={publishOptions} onChange={(v) => {
                    //model.style = v
                    //updateModel(model)
                }} />
            </InspectorProperty>
        </section> */}
    </div>;
}
