import React from 'react';
import { Section, Stack, Spacer, VStack, HStack, Text, ActionButton, Image } from '@yapstudios/yap-content-builder';
import { InspectorHeader , InspectorContent, InspectorProperty, InspectorControlText, InspectorControlButton, InspectorControlDate, useInspectorState }  from '@yapstudios/yap-content-builder'
import { View } from '@yapstudios/yap-content-builder';
//import modelViewer from '@google/model-viewer'

function LearnItem({ content }) {
    let style = {
        backgroundColor: 'white',
        width: '-webkit-fill-available'
    }

    let w = 375 - 40

    return (
        <VStack align={"leading"} spacing={0} cornerRadius={10} justify={"leading"} width={375 - 40 } spaceItems={"between"} height={320} backgroundColor={"white"}>
            <div style={{ backgroundImage: 'url(' + content.image + ')',
                            width: (w - 10) + "px",
                            height: '200px',
                            margin: '0 auto 0 auto',
                            borderRadius: '12px',
                            border: '5px solid white',
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'}}></div> 

            <VStack align={"leading"}  spacing={5} paddingHorizontal={20} paddingBottom={20}>
                <Text readonly={true} style={"captionUppercased"} color={"accent"} content={content.category}/>
                <Text readonly={true} style={"cardTitleHeader"} color={"primary"} content={content.title}/>
            </VStack>
        </VStack>
    )
}

export default class LearnContentComponent {
    static type = 'learn-products'

    constructor(items) {
        this.type = LearnContentComponent.type;
        this.items = items ?? [{
            title: "How to Drill Holes for Hanging Pictures",
            category: 'DIY',
            image: 'drill-man.png'
        }]
    }

    defaultProps() {
        return {
            url: this.url,
        };
    }

    titlesForProp() {
        return {
            price: 'Price',
        };
    }

    decode(props) {

        const containerStyle = { "overflow": "scroll", width: '-webkit-fill-available', paddingTop: '0px' };
        
        let items = this.items.map( content => {
            return <LearnItem content={content}/>
        })

        return (
            <Section padding="0" paddingBottom="20" id="section-header"  title="Header">
                <View width={"infinity"} model={props} inspector={(model, updateModel) => {
                        return this.inspector(model, updateModel)
                }}>
                    <VStack spacing={10} width={"infinity"} align={"leading"}>
                        <HStack paddingLeft={20} align={"leading"} justify={"leading"}>
                            <Text content="Learn" style={"h3"} readonly={true}></Text>
                        </HStack>

                        <div className={"review-content"}  style={containerStyle}>
                            <HStack align={"leading"} paddingHorizontal={"20"} justify={"leading"}  spacing={10} width={"infinity"} >
                                {items}
                            </HStack>
                        </div>
                    </VStack>
                </View>        
            </Section>
            
        );
    }

    inspector(model, updateModel) {
        
        return (
            <InspectorContent key={"header-inspector"}>
                <InspectorHeader title={"AR Model"} />                    
            </InspectorContent>
        )
    }

    encode(model, props) {

        // From inspector
        if (model.inspector) {
            model.inspector = null
            props = {...model}
            return props
        }

        // From field
        switch (model['id']) {
            case 'modelNumber':
                props['modelNumber'] = model.content;
                //props['titleStyle'] = model.style;
                break;
            // case 'brandName':
            //     props['brandName'] = model.content;
            //     break;
            default:
                break;
        }

        return props;
    }

    validations() {
        return [
            //PolymerValidations.fieldRequired('title')
        ];
    }
}
