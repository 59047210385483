import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import * as React from 'react';
import { useState } from "react";
import { useSection } from '../SectionContext.js';
import useUniqueId from '../useUniqueId.js';
import { processStyle, convertMarkdownToHTML } from '../Page.js';
import { View } from './View.js';

function Text(props) {
    let { fonts, styles, setSelected, selectedId, updateModel, renderMarkdown, setInspectorModel } = useAppState();
    let { sectionId, sectionStyle, sectionEncode } = useSection();
    const [editable, setEditable] = useState(false);
    const [forceRender, setForceRender] = useState(0);
    const fieldRef = React.useRef();

    const id = useUniqueId(props.id);

    var isSelected = id == selectedId;

    React.useEffect(() => {
        if (editable && !isSelected) {
            setEditable(false);
        }
    }, [isSelected]);

    var style = processStyle({ props: props ?? {}, fonts: fonts, sectionStyle: sectionStyle });

    // Make the text entry only emit <br>'s , not divs etc.

    // Disabled for now as wasn't working in safari properly for new lines in paragraphs..
    //style['display'] = 'inline-block';

    style['width'] = '100%';

    const viewProps = {
        fonts: fonts,
        style: style,
        styles: styles,
    };

    const model = {
        type: 'text',
        componentTitle: props.componentTitle,
        validationProperty: props.validationProperty,
        title: props.title,
        align: props.align,
        style: props.style,
        fonts: fonts,
        id: id,
        content: props.content,
        sectionId: sectionId,
        sectionEncode: sectionEncode,
        canEditStyle: props.canEditStyle != null ? props.canEditStyle : true
    };

    var finalModel = props.inspectorModel ? props.inspectorModel : model;
    finalModel.validationProperty = props.validationProperty;

    const parseValue = (value) => {
        var text = value;

        text = text.replace(/&nbsp;/g, ' ');
        text = text.replace(/<br>/g, '\n');
        text = text.replace(/<div>/g, '');
        text = text.replace(/<\/div>/g, '');

        text = removeHTML(text);
        return text;
    };

    const removeHTML = (input) => {
        return input.replace(/<\/?[^>]+(>|$)/g, "");
    };

    const onFocus = () => {
    };

    const onClick = () => {
        if (isSelected) {
            setEditable(isSelected);
        }
    };

    const onChange = (text) => {
        // Force update of inspector model
        let im = { ...finalModel };
        im[props.modelProperty ?? 'content'] = text;
        setInspectorModel(im);
    };

    const onBlur = (e) => {
        let text = parseValue(e.target.innerText);

        onChange(text);

        if (props.onChange) {
            props.onChange(text, props.inspectorModel, updateModel);
        } else {
            var newModel = model;
            newModel[props.modelProperty ?? 'content'] = text;
            updateModel(newModel);
        }

        e.preventDefault();

        // Ensure the span is re-rendered every time the field is updated / blurred
        // to make sure no html is left rendered in the field.
        setForceRender(forceRender + 1);
    };

    var content = props.content ?? "";
    content = content.replace(/\n/g, '<br>');

    if (props.renderMarkdown || renderMarkdown) {
        content = convertMarkdownToHTML(content);
    }
    
    if (content.length == 0 && props.placeholder && !isSelected)  {
        content = props.placeholder
    }
    if (props.readonly) {
        return <div style={style}>
            <span contentEditable={isSelected} onBlur={onBlur} className="view-text" style={style} onFocus={onFocus} dangerouslySetInnerHTML={{ __html: content }}></span>
        </div>;
    } else {
        return (
            <View {...viewProps} inspector={props.inspector} id={id} model={finalModel} onClick={onClick}>
                <span autoComplete="off" autoCorrect="off" key={forceRender} ref={fieldRef} suppressContentEditableWarning={true} contentEditable={isSelected} onBlur={onBlur} className="view-text" style={style} onFocus={onFocus} dangerouslySetInnerHTML={{ __html: content }}></span>
            </View>
        );
    }
}

export { Text }