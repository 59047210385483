import React from 'react';
import { Section, Stack, Text, Image , YapUI } from '@yapstudios/yap-content-builder';

export default class HeaderComponent {
    static type = 'message-header';

    constructor(title) {
        this.type = 'message-header';
        this.title = title ?? "Message Title";
    }

    defaultProps() {
        return {
            title: this.title,
            caption: "MON 13 JAN, 10:15 AM",
            coverImage: { src: null, contentMode: "cover", saturation: 0, contrast: 0 }
        };
    }

    titlesForProp() {
        return {
            title: 'Header Title',
            caption: 'Caption',
            coverImage: 'Header Image'
        };
    }

    yapUI(props) {
        let image = YapUI.Directive('Image', { src: props.coverImage.src, crop: props.coverImage.crop, dimensions: props.coverImage.dimensions, contentMode: props.coverImage.contentMode, saturation: props.coverImage.saturation, contrast: props.coverImage.contrast }, [  ])
        return YapUI.Directive('MessageHeader', { title: props.title, titleStyle: props.titleStyle, caption: props.caption }, [ image ])
    }

    decode(props) {

        var title = props.title;
        var titleStyle = props.titleStyle ?? "titleHeader";
        var caption = props.caption;
        var image = props.coverImage ?? { src: null, contentMode: "cover" };

        return (
            <Section padding="0" paddingVertical="0" id="section-header" title="Header">
                <Stack paddingTop={100} paddingHorizontal={20} paddingBottom={25} spacing={5}>
                    <Text id="title" canEditStyle={false} componentTitle={this.titlesForProp()['title']} content={title} style={titleStyle} align="left" color="primary" />
                    <Text id="caption" canEditStyle={false} componentTitle={this.titlesForProp()['caption']} readonly={true} content={caption} style="captionUppercased" align="left" color="secondary" />
                </Stack>
                <Image placeholder={true} src={image.src} crop={image.crop} description={image.description} height="350" contentMode={image.contentMode} id="coverImage" accessibilityLabel="Icon" {...image}></Image>
                {/* <Text validationProperty={'footnote'} id="footnote" canEditStyle={false} componentTitle={"Footnote"} content={props.footnote} align="left" color="footnote" /> */}
            </Section>
        );
    }

    encode(model, props) {
        switch (model['id']) {
            case 'title':
                props['title'] = model.content;
                props['titleStyle'] = model.style;
                break;
            case 'caption':
                props['caption'] = model.content;
                break;
            case 'coverImage':
                props['coverImage'] = {
                    src: model.src,
                    description: model.description,
                    contentMode: model.contentMode,
                    saturation: model.saturation,
                    contrast: model.contrast,
                    dimensions: model.dimensions,
                    title: model.title,
                    crop: model.crop
                };
            case 'footnote':
                props['footnote'] = model.content;
            default:
                break;
        }

        return props;
    }

    validations() {
        return [
            //PolymerValidations.fieldRequired('title')
        ];
    }
}
