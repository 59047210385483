import * as React from 'react';

import { processStyle } from '../Page.js';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import { useSection } from '../SectionContext.js';


function Box(props) {
    const { fonts, styles } = useAppState();
    const { sectionId, sectionStyle, sectionEncode } = useSection();

    var style = processStyle({ props: props, fonts: fonts, sectionStyle: sectionStyle });

    if (props.cornerRadius) {
        style['borderRadius'] = props.cornerRadius + 'px';
    }

    if (props.blurredBackground == true) {
        style['backdrop-filter'] = 'blur(30px)';
        style['-webkit-backdrop-filter'] = 'blur(30px)';
    }

    style['zIndex'] = 1000;

    if (props.padding) {
        style['padding'] = props.padding + 'px';
    }

    if (props.paddingVertical != null) {
        style['paddingTop'] = props.paddingVertical + 'px';
        style['paddingBottom'] = props.paddingVertical + 'px';
    }

    if (props.paddingTop != null) {
        style['paddingTop'] = props.paddingTop + 'px';
    }

    if (props.paddingBottom != null) {
        style['paddingBottom'] = props.paddingBottom + 'px';
    }

    if (props.paddingHorizontal) {
        style['paddingLeft'] = props.paddingHorizontal + 'px';
        style['paddingRight'] = props.paddingHorizontal + 'px';
    }

    if (props.paddingRight) {
        style['paddingRight'] = props.paddingRight + 'px';
    }

    if (props.paddingLeft) {
        style['paddingLeft'] = props.paddingLeft + 'px';
    }

    if (props.margin) {
        style['margin'] = props.margin + 'px';
    }

    return <div style={style} className={`box ${props.className}`}>{props.children}</div>;
}

export { Box }