import React from 'react';
import { InspectorHeader } from '../inspector-ui/InspectorHeader.js';

export function InspectorInfo(props) {
    const { title, message } = props.model;

    return (
        <div>
            <section>
                <InspectorHeader title={title ?? "Info"} />
                <div className="inspector-property">
                    <span>{message}</span>
                </div>
            </section>
        </div>
    );
}
