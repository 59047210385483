import React from 'react'
import { Sidebar, SidebarTile, SidebarAction, SidebarItem, SidebarSectionHeader, SidebarTitleHeader, SidebarSeparator, SidebarContent, SidebarSection, SidebarSectionContext, SidebarCategoryItem } from "./Sidebar.js"
import { useAppState } from '../content-builder-components/ContentBuilderStateProvider.js'

function PagesSidebar() {
    const { selectedPageId, pages, setSelectedPageId, onAddPage, onDeletePage } = useAppState()

    var sidebarItems = pages.map( (page, index) => {
        return <SidebarItem title={page.title} id={page.id} canDelete={pages.length > 1 && page.id == selectedPageId}/>
    })

    let addItem = <SidebarAction title="Add Page" onClick={onAddPage}/> 

    return <Sidebar selectedId={selectedPageId} setSelectedId={setSelectedPageId} onDelete={(id) => {
        onDeletePage(id)
    }}>
                <SidebarSection >
                        {sidebarItems}
                        {addItem}
                    </SidebarSection>
            </Sidebar>
}

export { PagesSidebar }