import React from 'react';
import { Section, ActionButton } from '@yapstudios/yap-content-builder';

export default class ActionComponent {
    constructor(title, url) {
        this.type = 'action';
        this.defaultTitle = title ?? "Action";
        this.defaultURL = url;
    }
    

    defaultProps() {
        return {
            title: this.defaultTitle,
            url: this.defaultURL
        };
    }

    // Titles used for validation
    titlesForProp() {
        return {
            title: 'Action Title',
            url: 'URL'
        };
    }

    // Element ids used for validation
    elementsForProp() {
        return {
            title: 'action-button',
            url: 'action-button'
        };
    }

    decode(props) {
        return (
            <Section padding={20} paddingVertical={20} paddingHorizontal={20} id="action-button" title="Action Button">
                <ActionButton componentTitle={"Action Button"} id="action-button" title={props.title} url={props.url} />
            </Section>
        );
    }

    encode(model, props) {
        props['title'] = model.title;
        props['url'] = model.url;
        return props;
    }

}
