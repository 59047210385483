import React, { useState, useEffect } from 'react';
import { useStyle } from '../Style.tsx';

export function Rectangle({ fill }) {
    const style = { ...useStyle() }
    style['width'] = style.width ?? '-webkit-fill-available';
    if (style.height == null) {
        //style['flexGrow'] = 1;
    }
    style['height'] = style.height ?? '-webkit-fill-available';
    
    style['backgroundColor'] = fill ?? 'black';
    return <div style={style}></div>;
}