import { DoublePageRenderer } from "./DoublePageRenderer"
import styled from 'styled-components'
import QRCode from "react-qr-code";
import { YapUI,  Component, Modifier  } from '@yapstudios/yap-content-builder'
class AthenaPageRenderer {

    yapUI(props, children, page) {
        let content = new DoublePageRenderer().yapUI(props, children, page)
        return YapUI.Directive('AthenaPage', { index: props.index ?? -1 }, [content])
    }

    yapUIReact(props, children, page) {
        let content = new DoublePageRenderer().yapUI(props, children, page)

        return (
            <Component name="AthenaPage" index={props.index ?? -1}>
                {content}
            </Component>
        )        
    }

    rootYapUI(props, children) {
        return YapUI.Directive('AthenaExamDocument', props, children)
    }

    render(props) {
        let doublePage = new DoublePageRenderer()
        return doublePage.render(props)
    }
}

class AthenaPreviewRenderer {
    constructor(props) {
        this.width = props.width ?? 1024
        this.preview = props.preview
    }

    render(props, children) {
        let canvasSize = { width: 1024, height: 768 }
        let width = this.width
        let scale = width / canvasSize.width

        if (this.preview == 'main') {
            return (
                <AthenaQRPagePreview page={props.page} document={props.document}/>
            )
        } else {
            return <AthenaPreviewContainer scale={scale}>
                <AthenaPage scale={scale}>
                    {children}
                </AthenaPage>
            </AthenaPreviewContainer>
        }
    }
}


const AthenaPage = styled.div`
    width: 1024px;
    height: 768px;
    top: 0px;
    left: 0px;
    background: clear;
    transform: scale(${props => props.scale ?? 1});    
    transform-origin: top left;
`

const AthenaPreviewContainer = styled.div`
    width: calc(1024px * ${props => props.scale ?? 1});
    height: calc(768px * ${props => props.scale ?? 1});
`

function AthenaQRPagePreview({ page, document }) {
    let pageIndex = document ? document.pages.map( p => p.id ).indexOf(page.id) : 0
    let code = `cbpreview://document/${document.id}/page/${pageIndex}`
    console.log(code)
    return (
        <QRContainer>
            <QRCode
                size={200}
                value={code}
            />
        </QRContainer>
    )
}

const QRContainer = styled.div`
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1);
    border-radius: 20px;
`


export { AthenaPageRenderer, AthenaPreviewRenderer }