import React from 'react';
import { Section, Stack, Text, Info, YapUI } from '@yapstudios/yap-content-builder';

export default class UniversityComponent {
    constructor(title, subtitle, iconURL) {
        this.type = 'institution';
        this.defaultTitle = title;
        this.defaultSubtitle = subtitle;
        this.defaultIconURL = iconURL;
    }

    defaultProps() {
        return {
            title: this.defaultTitle,
            subtitle: this.defaultSubtitle,
            iconURL: this.defaultIconURL
        };
    }

    yapUI(props) {
        return YapUI.Directive('UniversityProfile', { title: props.title, subtitle: props.subtitle }, [ ])
    }

    decode(props) {
        const infoMessage = (
            <div>
                Use the <a href="">profile screen</a> to change any profile information
            </div>
        );

        return (
            <Section padding={0} id="action-button" title="Action Button">
                <Info title="University Profile" message={infoMessage}>
                    <Stack spacing={10} direction={"horizontal"} align={"center"} justify={"leading"} borderBottom="1px solid" borderColor="separator" padding={20} paddingVertical={20} paddingHorizontal={20}>
                        <img width="40" height="40" src={props.iconURL} style={{ border: '1px solid #e3e3e3', borderRadius: 8 }} />
                        <Stack spacing={4} paddingBottom={2} align={"leading"}>
                            <Text readonly={true} style={"headline"} color={"primary"} content={props.title}></Text>
                            <Text readonly={true} style={"subhead"} color={"secondary"} content={props.subtitle}></Text>
                        </Stack>
                    </Stack>
                </Info>
            </Section>
        );
    }

    encode(model, props) {
        props['title'] = model.title;
        props['subtitle'] = model.subtitle;
        return props;
    }
}
