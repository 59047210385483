import * as React from 'react';
import { View } from './View.js';

function Spacer(props) {
    const style = {
        height: (props.height ?? 10) + 'px'
    }

    const model = {
        type: 'spacer',
        height: props.height
    }

    return (
        <View {...props} model={model}>
            <div className="spacer" style={style}></div>
        </View>
    )
}

export { Spacer }