import React from 'react'

export default function LargeButton(props) {
    var className = "button large"
    if (props.selected) {
        className += " selected"
    }
    return <div className={`button large ${className}`} onClick={props.onClick}>{props.children}</div>
}

