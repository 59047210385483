import { useAppState } from '../content-builder-components/ContentBuilderStateProvider.js'
import { useCanvas } from '../content-builder-components/ContentBuilderCanvasState.js'
import * as React from 'react'
import { Section } from "./ui/Section.js"
import { SectionAdd } from "./SectionAdd.js"
import Props from "./PropsHelper.js";

var updateIds = function (rootId, element, isChild) {
    return React.Children.map(element, (child) => {
        if (!React.isValidElement(child)) {
            return child;
        }

        if (child.props.children) {
            child = React.cloneElement(child, {
                children: updateIds(rootId, child.props.children, true)
            });
        }

        if (child.props.id) {
            var id = isChild && rootId ? child.props.id + (".rootId-" + rootId) : child.props.id
            let c = React.cloneElement(child, {
                id: id //uuid()
            })
            return c
        } else {
            return child;
        }
    })
}

function Page(props) {
    let { selectedId, setSelectedId, styles, components, canAddComponents, isReadonly, setInsertSectionIndex, showInsertSection, setShowInsertSection, pageRenderer } = useAppState()
    const { setPageScrollOffset } = useCanvas()

    const { 
            // Template components that should be sections
            sections, 

            showAddSection,

            // Add index - optional, specifies where the drop area should be 
            addIndex
    } = props

    // Update the preferred 'addIndex' which is an index from the bottom.
    React.useEffect( () => {
        setInsertSectionIndex(sections.length - addIndex)

    }, [addIndex, sections.length, showAddSection])

    if (showAddSection != null) {
        //setShowInsertSection(showAddSection(sections))
    } else {
        //setShowInsertSection(false)
    }

    const isSelected = selectedId == props.id

    var pageStyle = styles[props.style] ?? styles['default'] ?? styles['light']
    var style = {}

    if (props.align) {
        style['justify-content'] = props.align;
    }

    style['backgroundColor'] = pageStyle.colors.background

    const onClick = function () {
        setSelectedId(null)
    }

    const onScroll = function (e) {
        if (setPageScrollOffset != null) {
            setPageScrollOffset(e.target.scrollTop)
        }
    }

    var componentSections = []

    // Decode sections from react elements
    if (sections && sections.props) {
        componentSections = React.Children.map(sections.props.children, (s) => {
            return s
        })

    // Decode sections from json
    } else if (sections) {

        componentSections = sections.map((sectionData, idx) => {

            let component =  components.filter( component => { return component != null }).filter( component => {
                return component.type == sectionData.type
            })[0]

            if (component == null) {
                return <div>Component {sectionData.type} not found</div>
            }

            let id = sectionData.id

            if (component && component.decode) {
                let decodedElement = component.decode(sectionData.props, { style: pageStyle, styleId: style, isPreview: isReadonly, sections: sections, sectionId: id, index: idx })
                var element = React.cloneElement(decodedElement, { id: id, key: id, encode: component.encode })
                element = updateIds(sectionData.id, element)
                return <div key={'polymer-page-section-' + id + idx} id={'polymer-page-section-' + id} section={sectionData}>{element}</div>
            } else {
                return <div>Invalid componnet</div>
            }

        })
    } else {
        componentSections = sections
    }

    var componentSectionItems = componentSections

    // Insert <SectionAdd/> at index specified by addIndex (from the bottom)
    if (canAddComponents && showInsertSection) {
        componentSectionItems = React.Children.toArray(componentSections)
        var addSectionIndex = componentSectionItems.length - (addIndex ?? 0)
        componentSectionItems.splice(addSectionIndex, 0, <SectionAdd index={addSectionIndex}/>)
    }
    
    var pageContent = (
        <div className={"page"} onClick={onClick}>
            <div className="page-content" style={style} onScroll={onScroll} >
             {componentSectionItems}
            </div>
        </div>
    )

    // If a custom page renderer has been passed, use this to generate
    // TODO: In the future implement this by default as a page renderer.
    if (pageRenderer != null && pageRenderer.render != null) {
        let render = pageRenderer.render(props)
        pageContent = React.cloneElement(render, { onClick, onScroll, pageStyle, sections, componentSections })
    }

    return (
        <div className={"page-container"} id={"page-container"}>
            {props.children}
            {pageContent}
        </div>
    )

}

export function processStyle({props, fonts, sectionStyle}) {
    var style = {}

    var sectionStyle = sectionStyle ?? { colors: {} }

    if (props.height) {
        style['height'] = Props.height(props);
    }

    if (props.width) {
        style['width'] = Props.width(props);
    }

    if (props.style) {
        var fonts = sectionStyle.fonts ?? fonts
        let fontStyle = fonts[props.style]
        if (props.style.constructor == Object) {
            fontStyle = props.style
        }
        if (fontStyle) {
            style['fontSize'] = fontStyle.size + 'px'
            style['fontWeight'] = fontStyle.weight

            if (fontStyle.lineHeight) {
                style['lineHeight'] = String(fontStyle.lineHeight)
            }

            if (fontStyle.color) {
                style['color'] = sectionStyle.colors[fontStyle.color] ?? fontStyle.color
            }

            if (fontStyle.fontFamily) {
                style['fontFamily'] = fontStyle.fontFamily
            }

            if (fontStyle.fontVariation) {
                style['fontVariationSettings'] = fontStyle.fontVariation
            }

            if (fontStyle.fontStyle) {
                style['fontStyle'] = fontStyle.fontStyle
            }

            if (fontStyle.textTransform) {
                style['textTransform'] = fontStyle.textTransform
            }

            if (fontStyle.outline) {
                style['-webkit-text-stroke-width'] = "1px";
                style['-webkit-text-stroke-color'] = fontStyle.outline;
            }

            if (fontStyle.align) {
                style['textAlign'] = fontStyle.align
            }
        }

    }

    if (props.shadow) {
        style['boxShadow'] = props.shadow
    }

    if (props.padding) {
        style['padding'] = props.padding + 'px'
    }

    if (props.paddingHorizontal) {
        style['paddingLeft'] = props.paddingHorizontal + 'px'
        style['paddingRight'] = props.paddingHorizontal + 'px'
    }

    if (props.paddingVertical != null) {
        style['paddingTop'] = props.paddingVertical + 'px'
        style['paddingBottom'] = props.paddingVertical + 'px'
    }

    if (props.paddingTop != null) {
        style['paddingTop'] = props.paddingTop + 'px'
    }

    if (props.paddingBottom != null) {
        style['paddingBottom'] = props.paddingBottom + 'px'
    }

    if (props.lineHeight) {
        style['lineHeight'] = String(props.lineHeight)
    }

    if (props.align) {
        style['textAlign'] = props.align
    }

    if (props.color) {
        style['color'] = sectionStyle.colors[props.color] ?? props.color
    }

    if (props.borderColor) {
        style['borderColor'] = sectionStyle.colors[props.borderColor] ?? props.borderColor
    }

    if (props.backgroundColor) {
        style['backgroundColor'] = sectionStyle.colors[props.backgroundColor] ?? props.backgroundColor
    }

    if (props.stroke) {
        //style['text-shadow'] = '0px 0px rgba(255,255,255,1.0)';
        style['-webkit-text-stroke-width'] = '-0.5px';
        style['-webkit-text-stroke-color'] = 'rgba(255,255,255,0.5)';
    }

    return style
}

export function convertMarkdownToHTML(inputString) {
    // Regular expression to match Markdown links
    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    
    // Regular expression to match Markdown bold text
    const markdownBoldRegex = /\*\*([^*]+)\*\*/g;
  
    // Regular expression to match Markdown italic text
    const markdownItalicRegex = /\*([^*]+)\*/g;
  
    // Replace Markdown links with HTML anchor tags
    const convertedLinks = inputString.replace(markdownLinkRegex, '<a href="$2">$1</a>');
  
    // Replace Markdown bold text with HTML bold tags
    const convertedBold = convertedLinks.replace(markdownBoldRegex, '<strong>$1</strong>');
  
    // Replace Markdown italic text with HTML italic tags
    const convertedItalic = convertedBold.replace(markdownItalicRegex, '<span style="font-style: italic">$1</span>');
  
    return convertedItalic;
}

export { Page, Section }