import React from 'react';
import { Section, ImageGallery } from '@yapstudios/yap-content-builder';

export default class GalleryComponent {
    constructor(images) {
        this.images = [];
        this.type = 'gallery';
    }

    defaultProps() {
        return {
            images: [ 
                //{ src: null, description: null}
            ]
        };
    }

    decode(props) {
        return (
            <Section padding="0" paddingTop="0" title="Gallery Image">
                <ImageGallery itemSize={{ width: 260, height: 260 }} images={props.images} id="gallery"/>
            </Section>
        )
    }

    encode(model, props) {
        switch (model.id) {
            case "gallery":
                props.images = model.images.map( image => {
                    return {
                        ...image,
                        url: image.src ?? image.url,
                        title: image.title,
                        crop: image.crop,
                        description: image.description,
                        footnote: image.footnote,
                        dimensions: image.dimensions,
                        crop: image.crop
                    }
                });
                break;
            default:
                break;
        }
        return props;
    }

    icon() {
        return (
            <svg width="100" height="71" viewBox="0 0 100 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_156_2890)">
                <rect width="100" height="70" transform="translate(0 0.245972)" fill="white"/>
                <g clipPath="url(#clip1_156_2890)">
                <rect x="17.8337" y="16.2543" width="64.7159" height="44.6738" fill="#384CC0" fillOpacity="0.12"/>
                <path d="M24.6155 38.9877L20.3964 42.4457C18.7743 43.7753 17.8337 45.7873 17.8337 47.8847C17.8337 51.7507 20.9677 54.9377 24.8337 54.9377H75.8102C79.5323 54.9377 82.5497 51.9204 82.5497 48.1983C82.5497 45.92 81.3986 43.796 79.4899 42.5521L63.5148 32.141C61.1264 30.5845 58.0327 30.6319 55.6931 32.2608L43.7845 40.5521C41.3844 42.2231 38.1984 42.226 35.7954 40.5593L33.0421 38.6497C30.473 36.8678 27.0336 37.0058 24.6155 38.9877Z" fill="#384CC0"/>
                <ellipse cx="33.5935" cy="29.6276" rx="4.02356" ry="4.03194" fill="#384CC0"/>
                </g>
                <rect x="19.1917" y="17" width="62" height="37" rx="9" stroke="#384CC0" strokeOpacity="0.4" strokeWidth="2"/>
                <g opacity="0.4">
                <g clipPath="url(#clip2_156_2890)">
                <rect x="-50.358" y="16.2543" width="64.7159" height="44.6738" fill="#384CC0" fillOpacity="0.12"/>
                <path d="M-43.5762 38.9877L-47.7953 42.4457C-49.4175 43.7753 -50.358 45.7873 -50.358 47.8847C-50.358 51.7507 -47.224 54.9377 -43.358 54.9377H7.61854C11.3406 54.9377 14.358 51.9204 14.358 48.1983C14.358 45.92 13.2069 43.796 11.2982 42.5521L-4.67689 32.141C-7.06527 30.5845 -10.159 30.6319 -12.4986 32.2608L-24.4072 40.5521C-26.8073 42.2231 -29.9933 42.226 -32.3963 40.5593L-35.1496 38.6497C-37.7187 36.8678 -41.1581 37.0058 -43.5762 38.9877Z" fill="#384CC0"/>
                </g>
                <rect x="-49" y="17" width="62" height="37" rx="9" stroke="#384CC0" strokeOpacity="0.4" strokeWidth="2"/>
                </g>
                <g opacity="0.4">
                <g clipPath="url(#clip3_156_2890)">
                <rect x="85.642" y="16.2543" width="64.7159" height="44.6738" fill="#384CC0" fillOpacity="0.12"/>
                <path d="M92.4238 38.9877L88.2047 42.4457C86.5825 43.7753 85.642 45.7873 85.642 47.8847C85.642 51.7507 88.776 54.9377 92.642 54.9377H143.619C147.341 54.9377 150.358 51.9204 150.358 48.1983C150.358 45.92 149.207 43.796 147.298 42.5521L131.323 32.141C128.935 30.5845 125.841 30.6319 123.501 32.2608L111.593 40.5521C109.193 42.2231 106.007 42.226 103.604 40.5593L100.85 38.6497C98.2813 36.8678 94.8419 37.0058 92.4238 38.9877Z" fill="#384CC0"/>
                <ellipse cx="101.402" cy="29.6276" rx="4.02356" ry="4.03194" fill="#384CC0"/>
                </g>
                <rect x="87" y="17" width="62" height="37" rx="9" stroke="#384CC0" strokeOpacity="0.4" strokeWidth="2"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_156_2890">
                <rect width="100" height="70" fill="white" transform="translate(0 0.245972)"/>
                </clipPath>
                <clipPath id="clip1_156_2890">
                <rect x="18.1917" y="16" width="64" height="39" rx="10" fill="white"/>
                </clipPath>
                <clipPath id="clip2_156_2890">
                <rect x="-50" y="16" width="64" height="39" rx="10" fill="white"/>
                </clipPath>
                <clipPath id="clip3_156_2890">
                <rect x="86" y="16" width="64" height="39" rx="10" fill="white"/>
                </clipPath>
                </defs>
            </svg>)
    }

}
