import React from 'react';
import { Section, Box, Stack, VStack, HStack, Text, ActionButton, Image } from '@yapstudios/yap-content-builder';
import { InspectorHeader , InspectorContent, InspectorProperty, InspectorControlText, InspectorControlButton, InspectorControlDate, useInspectorState }  from '@yapstudios/yap-content-builder'
import { View } from '@yapstudios/yap-content-builder';

export default class LearnActivityComponent {
    static type = 'learn-activity';

    constructor(title, brandName) {
        this.type = 'learn-activity';
        this.title = title
    }

    defaultProps() {
        return {
            title: this.title ?? "Personalize your desktop.",
            description: this.description ?? "The Mac desktop is where you can easily access the apps, documents, and information you use most often. Customize your desktop to optimize it for your working style and give it a look that fits your personal taste."
        };
    }

    titlesForProp() {
        return {
            title: 'Title',
            description: 'Description'
        };
    }

    decode(props) {

        let image = props.image ?? { }
        let number = (
                    <Box width={32} height={32} cornerRadius={16} backgroundColor="black">
                        <VStack justify="center" align="center" height={31}>
                            <NumberContent props={props} titles={this.titlesForProp()}/>
                        </VStack>
                    </Box>
                    )

        return (
            <Section padding="0" paddingVertical="0" id="section-header"  title="Header">
                <VStack paddingTop={150} paddingBottom={150} paddingLeft={20} paddingRight={20} spacing={10} width="infinity" backgroundColor="secondaryBackground" padding="20">
                    {number}
                    <Text id="title" align={"left"} canEditStyle={false} componentTitle={this.titlesForProp()['title']} readonly={false} content={props.title} style="headline" color="primary" />
                    <Image id="image" cornerRadius={4} placeholder={true} width={"100%"} height={220} fullbleed={image.fullbleed} src={image.src} description={props.description}  accessibilityLabel="Image"></Image>
                    <Text id="description" align={"left"} canEditStyle={false} componentTitle={this.titlesForProp()['description']} readonly={false} content={props.description} style="body" color="primary" />
                </VStack>
            </Section>            
        );
    }


    encode(model, props) {
        
        // From field
        switch (model['id']) {
            case 'title':
                props['title'] = model.content;
                break;
            case 'description':
                props['description'] = model.content;
                break;    
            case 'image':
                props['image'] = model;
            default:
                break;
        }

        return props;
    }

    validations() {
        return [
            //PolymerValidations.fieldRequired('title')
        ];
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="70" fill="white"/>
                <rect x="5.44611" y="6.97676" width="89.23" height="57.154" rx="5" fill="#384CC0" fillOpacity="0.09"/>
                <rect opacity="0.3" x="11.8257" y="15.3874" width="74.1245" height="16.77" rx="4" fill="#384CC0"/>
                <rect opacity="0.3" x="11.8257" y="39.9683" width="42.2867" height="16.77" rx="4" fill="#384CC0"/>
            </svg>
        )
    }
}

function HeaderIcon() {
    return (
        <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.7314 34.4299H10.3314C10.3143 34.4299 10.2974 34.4333 10.2817 34.4398C10.2659 34.4464 10.2516 34.4559 10.2395 34.468C10.2274 34.4801 10.2178 34.4944 10.2113 34.5102C10.2048 34.526 10.2014 34.5429 10.2014 34.5599V40.1199H34.8614V34.5599C34.8614 34.5429 34.8581 34.526 34.8515 34.5102C34.845 34.4944 34.8354 34.4801 34.8233 34.468C34.8113 34.4559 34.7969 34.4464 34.7812 34.4398C34.7654 34.4333 34.7485 34.4299 34.7314 34.4299ZM15.1414 38.8599H11.5614V35.6899H15.1414V38.8599ZM18.0314 38.9999C17.6869 38.9999 17.35 38.8976 17.0637 38.706C16.7773 38.5143 16.5543 38.242 16.4229 37.9235C16.2915 37.6049 16.2577 37.2546 16.3256 36.9168C16.3936 36.579 16.5603 36.269 16.8046 36.026C17.049 35.7831 17.3599 35.6182 17.6981 35.5522C18.0363 35.4861 18.3865 35.522 18.7042 35.6553C19.022 35.7885 19.2931 36.0131 19.483 36.3005C19.673 36.588 19.7734 36.9254 19.7714 37.2699C19.7688 37.7297 19.5843 38.1697 19.2583 38.4938C18.9322 38.818 18.4912 38.9999 18.0314 38.9999ZM21.4114 39.1599L21.0314 35.6399L23.8814 35.2899L24.3314 38.8199L21.4114 39.1599ZM29.0814 37.9299H28.7114L28.8114 38.7099L25.6214 39.1099L25.3014 36.5899L25.8714 36.5199V35.3999H29.0814V37.9299ZM30.4314 38.8699L30.0514 35.8699L33.2414 35.4699L33.6314 38.4699L30.4314 38.8699Z" fill="#5EBC57"/>
            <path d="M3.96143 16.11V36.53C3.96143 37.0015 4.05428 37.4683 4.2347 37.9039C4.41511 38.3394 4.67955 38.7352 5.01291 39.0685C5.34627 39.4019 5.74203 39.6663 6.17759 39.8468C6.61315 40.0272 7.07998 40.12 7.55143 40.12H9.20143V34.56C9.20143 34.2603 9.32048 33.9729 9.53239 33.761C9.74431 33.5491 10.0317 33.43 10.3314 33.43H34.7314C35.0303 33.4326 35.3162 33.5525 35.5276 33.7639C35.7389 33.9752 35.8588 34.2611 35.8614 34.56V40.12H37.5114C37.9829 40.12 38.4497 40.0272 38.8853 39.8468C39.3208 39.6663 39.7166 39.4019 40.0499 39.0685C40.3833 38.7352 40.6477 38.3394 40.8282 37.9039C41.0086 37.4683 41.1014 37.0015 41.1014 36.53V15.78H3.96143V16.11Z" fill="#5EBC57"/>
            <path d="M37.5114 10.05H7.5114C7.03911 10.05 6.57147 10.1432 6.13525 10.3243C5.69904 10.5053 5.30284 10.7706 4.96934 11.1051C4.63585 11.4395 4.37162 11.8364 4.1918 12.2731C4.01197 12.7099 3.92009 13.1778 3.9214 13.65V14.78H41.1014V13.65C41.1027 13.1778 41.0108 12.7099 40.831 12.2731C40.6512 11.8364 40.3869 11.4395 40.0535 11.1051C39.72 10.7706 39.3238 10.5053 38.8875 10.3243C38.4513 10.1432 37.9837 10.05 37.5114 10.05Z" fill="#5EBC57"/>
            <path d="M37.5114 9.05005H7.51139C6.29312 9.0527 5.12566 9.53851 4.26516 10.4009C3.40465 11.2633 2.92138 12.4318 2.92139 13.65V36.53C2.92403 37.7466 3.40846 38.9125 4.26868 39.7728C5.1289 40.633 6.29486 41.1174 7.51139 41.12H37.5114C38.7279 41.1174 39.8939 40.633 40.7541 39.7728C41.6143 38.9125 42.0987 37.7466 42.1014 36.53V13.65C42.1014 12.4318 41.6181 11.2633 40.7576 10.4009C39.8971 9.53851 38.7296 9.0527 37.5114 9.05005ZM34.8614 40.12H10.2014V34.5601C10.2014 34.543 10.2047 34.5261 10.2113 34.5103C10.2178 34.4945 10.2274 34.4802 10.2395 34.4681C10.2515 34.4561 10.2659 34.4465 10.2816 34.4399C10.2974 34.4334 10.3143 34.43 10.3314 34.43H34.7314C34.7485 34.43 34.7654 34.4334 34.7811 34.4399C34.7969 34.4465 34.8112 34.4561 34.8233 34.4681C34.8354 34.4802 34.845 34.4945 34.8515 34.5103C34.858 34.5261 34.8614 34.543 34.8614 34.5601V40.12ZM41.1014 33.43V36.53C41.1014 37.0015 41.0085 37.4683 40.8281 37.9039C40.6477 38.3394 40.3833 38.7352 40.0499 39.0686C39.7165 39.4019 39.3208 39.6664 38.8852 39.8468C38.4497 40.0272 37.9828 40.12 37.5114 40.12H35.8614V34.5601C35.8588 34.2612 35.7389 33.9753 35.5275 33.7639C35.3162 33.5526 35.0303 33.4327 34.7314 33.43H10.3314C10.0317 33.43 9.74427 33.5491 9.53236 33.761C9.32044 33.9729 9.20139 34.2604 9.20139 34.5601V40.12H7.55139C7.07994 40.12 6.61311 40.0272 6.17755 39.8468C5.74199 39.6664 5.34624 39.4019 5.01287 39.0686C4.67951 38.7352 4.41507 38.3394 4.23466 37.9039C4.05424 37.4683 3.96139 37.0015 3.96139 36.53V15.78H41.1014V33.43ZM41.1014 13.78V14.78H3.96139V13.65C3.96007 13.1778 4.05196 12.7099 4.23178 12.2731C4.41161 11.8364 4.67584 11.4395 5.00933 11.1051C5.34282 10.7706 5.73903 10.5053 6.17524 10.3243C6.61145 10.1432 7.0791 10.05 7.55139 10.05H37.5514C38.0237 10.05 38.4913 10.1432 38.9275 10.3243C39.3637 10.5053 39.7599 10.7706 40.0934 11.1051C40.4269 11.4395 40.6912 11.8364 40.871 12.2731C41.0508 12.7099 41.1427 13.1778 41.1414 13.65L41.1014 13.78Z" fill="white"/>
            <path d="M18.0314 35.54C17.6872 35.54 17.3508 35.6421 17.0647 35.8333C16.7785 36.0245 16.5555 36.2962 16.4238 36.6142C16.2921 36.9321 16.2577 37.282 16.3248 37.6195C16.392 37.957 16.5577 38.2671 16.801 38.5104C17.0444 38.7537 17.3544 38.9195 17.6919 38.9866C18.0295 39.0537 18.3793 39.0193 18.6973 38.8876C19.0152 38.7559 19.2869 38.5329 19.4781 38.2467C19.6693 37.9606 19.7714 37.6242 19.7714 37.28C19.7714 36.8186 19.5881 36.376 19.2617 36.0497C18.9354 35.7234 18.4929 35.54 18.0314 35.54Z" fill="white"/>
            <path d="M15.1514 35.6899H11.5614V38.8599H15.1514V35.6899Z" fill="white"/>
            <path d="M23.8623 35.2846L21.0146 35.6423L21.4584 39.1746L24.306 38.8169L23.8623 35.2846Z" fill="white"/>
            <path d="M33.2927 35.4517L30.0978 35.853L30.4779 38.8792L33.6728 38.4779L33.2927 35.4517Z" fill="white"/>
            <path d="M25.9114 36.5299L25.3414 36.5999L25.6614 39.1199L28.8514 38.7199L28.7514 37.9499H29.1214V35.3999H25.9114V36.5299Z" fill="white"/>
        </svg>
    )
}


function NumberContent({ props, titles }) {
    return (
        <Text id="number" componentTitle={titles['number']} content={"1"} readonly={true} canEditStyle={false} style={"headline"} align={"center"} justify={"center"} color="white" />
    )
}