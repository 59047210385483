
// Import YapContentBuilder components
import { 
    YapContentValidations
} from '@yapstudios/yap-content-builder'

// Our example components for the content builder
import { UniversityComponent,
    GalleryComponent,
    TextComponent,
    ActionComponent,
    HeaderComponent,
    QuizComponent,
    CalendarComponent,
    ImageComponent,
    VideoComponent } from './Components.js'

// Validations to apply to the current page
let validations = [
    // Header Validations

    // Field Required Validator
    YapContentValidations.fieldRequired('title', { component: new HeaderComponent() }),

    YapContentValidations.maximumLengthWarning('title', { length: 30, component: new HeaderComponent() }),

    YapContentValidations.fieldRequired('coverImage', { propertyKey: 'src', title: 'Image Required', description: 'An image is required to be selected', component: new HeaderComponent() }),

    // Custom Image Validator
  //   YapContentValidations.image('coverImage', { 
  //     // Optional. Defaults to field name
  //     title: 'Image Validation',

  //     // Required. Message to present when validation fails
  //     description: 'This is a validation for the image', 

  //     // Required. The validation
  //     check: (asset) => {
  //         return true
  //     },

  //     // Optional. If provided will show when the image is provided and passes validation
  //     validDescription: 'This is valid',

  //     // Optional. If provided will show when the image is null
  //     infoDescription: 'This is a thing thats needed',

  //     // Optional (defaults to warning) - 'error' or 'warning'
  //     type: 'error',

  //     // Component to validate
  //     component: new HeaderComponent(), 
  //   }),

    //YapContentValidations.imageDimensionsWarning('coverImage', { minimumWidth: 2048, minimumHeight: 2048, component: new HeaderComponent() }),

    // Body Text
    //YapContentValidations.fieldRequired('title', { fieldTitle: 'Message Text', component: new TextComponent() }),
    //YapContentValidations.minimumLengthWarning('title', { length: 50, fieldTitle: 'Message Text', component: new TextComponent() }),
    YapContentValidations.preventLinks('title', { component: new TextComponent() }),
    //YapContentValidations.requireHTTPSLinks('title', { component: new TextComponent(), fieldTitle: 'Body Text' }),
    YapContentValidations.urlProtocolsRequired('title', { component: new TextComponent(), protocols: ['http', 'https', 'cb'] }),

    // Action
    //YapContentValidations.fieldRequired('title', { component: new ActionComponent() }),
    //YapContentValidations.fieldRequired('url', { component: new ActionComponent() }),
    //YapContentValidations.urlProtocolsRequired('url', { component: new ActionComponent(), protocols: ['http', 'https', 'cb'] }),

    //YapContentValidations.fieldRequired('url', { fieldTitle: 'URL', component: new CalendarComponent() }),
    //YapContentValidations.fieldRequired('description', { fieldTitle: 'Description', component: new CalendarComponent() }),
]

export { validations }

