import React from 'react';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import { validationContent } from '../InspectorValidation.js';
import { InspectorControlText } from '../inspector-ui/InspectorControlText.js';
import { InspectorImageWell } from '../inspector-ui/InspectorImageWell.js';
import { InspectorImageActions } from '../inspector-ui/InspectorImageActions.js';
import { InspectorHeader } from '../inspector-ui/InspectorHeader.js';
import { InspectorProperty } from '../inspector-ui/InspectorProperty.js';
import { InspectorControlSelect } from '../inspector-ui/InspectorControlSelect.js'

/**
 * Built in inspector for Image
 * @param {} props 
 * @returns 
 */
export function InspectorImage(props) {
    let { updateModel, setShowAssetEditModal, canCrop } = useAppState();
    var model = props.model;

    const assetDidChange = (asset) => {
        var updatedModel = {
            ...model,
            src: asset.src,
            contrast: asset.contrast,
            saturation: asset.saturation,
            description: asset.description,
            dimensions: asset.dimensions,
            title: asset.title,
            crop: asset.crop
        }

        if (model.onChange) {
            model.onChange(asset);
        } else {
            updateModel(updatedModel);
        }
    }
    
    const asset = { 
                  src : model.src, 
                  title: model.title, 
                  dimensions: model.dimensions, 
                  description: model.description, 
                  contrast: model.contrast, 
                  saturation: model.saturation,
                  crop: model.crop 
                }

    const altTextProperty = (

        <InspectorProperty title="Alt Text" singleColumn={true} divider={true}>
            <InspectorControlText placeholder={"Image Alt Text"} multiline={true} value={model.description} onChange={v => {
                model.description = v;
                updateModel(model);
            }} />
        </InspectorProperty>
    );

    const aspectProperty = (

        <InspectorProperty title="Aspect" singleColumn={false} divider={false}>
            <InspectorControlSelect value={model.aspect} options={['Original','Square']} keys={['original', '1']} onChange={v => {
                model.aspect = v
                updateModel(model)
            }} />
        </InspectorProperty>
    )

    const fullbleedProperty = (
        <InspectorProperty title="Inset" singleColumn={false} divider={false}>
            <InspectorControlSelect value={model.fullbleed == true ? 'fullbleed' : 'default'} options={['Inset', 'Full Bleed']} keys={['default', 'fullbleed']} onChange={v => {
                updateModel({...model, 
                            fullbleed: (v == 'fullbleed')})
            }} />
        </InspectorProperty>
    )    

    const editOptions = model.editOptions ?? []

    return <div>
        <section>
            <InspectorHeader title="Image" />
            <InspectorImageWell model={model} canCrop={canCrop} src={props.model.src} onImageSelected={(asset) => {
                assetDidChange(asset)
            }}></InspectorImageWell>

            <InspectorImageActions cropAspectRatios={model.cropAspectRatios}
                                   asset={asset}
                                   assetDidChange={assetDidChange}/>

            {validationContent()}

            {editOptions.includes('aspect') && aspectProperty}
            {editOptions.includes('fullbleed') && fullbleedProperty}

            {model.canEditAltText != false && model.src && altTextProperty}
        </section>
    </div>;
}
