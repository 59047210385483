import React, { createContext, useContext, ReactNode } from 'react';
import { useFunctions } from './Function.tsx';

import styled from 'styled-components'

// Define a type for the style context
export interface VariablesContextType {
    variables: React.CSSProperties;
    setVariables: (any) => void;
}

// Create the StyleContext with an empty default value
export const VariablesContext = createContext<VariablesContextType>({ variables: {}, setVariables: () => {} });

// Custom hook to use the StyleContext
export function useVariables() {
    return useContext(VariablesContext);
}

export function Variables(props) {
    const { variables, setVariables } = useVariables();
    
    var passProps = {...props}
    delete passProps.children;
    delete passProps.key;

    let allVariables = {...variables, ...passProps};

    return <VariablesContext.Provider value={{ variables: allVariables, setVariables: setVariables }}>{props.children}</VariablesContext.Provider>;
}

export function Variable(props : { name: string, defaultValue?: string, placeholder: string, children: ReactNode }) {

    /** Get variables and functions */
    const { variables } = useVariables();
    const functions = {...useFunctions()}

    /** Default VAlue */
    const def = props.placeholder;

    /** Extract string content only */
    let stringContent = React.Children.toArray(props.children).filter( (item) => { return typeof item === 'string' }).join("\n");

    let name = props.name
    
    // Is variable ? ($thing)
    if (name != null) {
        let value = variables[name] ?? variables[name.toLowerCase()];
        if (value ) {
            return <>{value}</>;
        } 
    } else if (isVariable(stringContent)) {
        let name = extractVariable(stringContent);
        let lcname = name.toLowerCase();
        if (variables && variables[name] && variables[name].length > 0 ) {
            return <>{variables[name]}</>;
        } else if (variables && variables[lcname] && variables[lcname].length > 0 ) {
            return <>{variables[lcname]}</>;
        } 

    // Is expression ? { thing }
    } else if (isExpression(stringContent)) {   
        let expression = extractExpression(stringContent);
        try {
            let returnValue = executeExpression(expression ?? "", variables, functions);       
            if (returnValue != null) {
                return <>{returnValue}</>
            }
        } catch {

        }
    } else {
        let value = variables[stringContent];
        if (value) {
            return <>{value}</>
        } else {
            return <>{stringContent}</>;
        }

    }

    return def;
}


const variableRegex = /^[\s\r\n]*\$/;
export function isVariable(value) {
    return typeof value === 'string' && variableRegex.test(value);
}

const variableExtractRegex = /^[\s\r\n]*\$(.*?)[\s\r\n]*$/;
export function extractVariable(value) {
    return value ? value.replace(variableExtractRegex, '$1') : null;
}
// export function isVariable(value) {
//     return typeof value === 'string' && (value.startsWith('$') || value.startsWith('props.')  || value.startsWith('$props.'));
// }

// export function isExpression(value) {
//     return typeof value === 'string' && value.startsWith('{') && value.endsWith('}')
// }

export function isExpression(value) {
    return typeof value === 'string' && /^\s*\{.*\}\s*$/.test(value);
}

export function isVariableOrExpression(value) {
    return isVariable(value) || isExpression(value);
}

// export function extractVariable(value) {
//     return value ? value.replace('$', '').replace('props.', '').replace('$.', '') : null; 
// }

export function extractExpression(value) {
    return value ? value.replace(/^\s*{\s*|\s*}\s*$/g, '') : null;
}
// export function extractExpression(value) {
//     return value ? value.replace('{', '').replace('}', '') : null;
// }

export function executeExpression(expression, targetVariables, functionData, hasReturnValue = true) {
        
    var headerValue = ""

    /**
     * Include referenced functions
     */
    if (functionData) {
        var c = 0
        for (var functionName in functionData.functions) {
            if (expression.indexOf(functionName) > -1) {
                var argData = functionData.functionArgs[functionName];
                var args = argData != null ? functionData.functionArgs[functionName].join(", ") : "";
                headerValue += `function ${functionName}(${args}) {\n` + functionData.functions[functionName] + ";\n};\n\n";
                c += 1;
            }
        }

        if (functionData.scripts) {
            for (var id in  functionData.scripts) {
                let script = functionData.scripts[id];
                headerValue += script + "\n";
            }
        }

        //console.log(`including ${c} of ${Object.keys(functionData.functions).length} functions`)
    }

    var result = null
    try {
        let varNames = Object.keys(targetVariables);
        let varValues = Object.values(targetVariables);
        
        var props = targetVariables;
        
        // Build expression
        var expression = (expression ?? "").replace(/\$([a-zA-Z])/g, 'props.$1');
        expression = hasReturnValue && expression.indexOf('return') == -1 ? `return ${expression};` : expression;

        const finalExpression = headerValue + expression;
        const func = new Function(...varNames, 'props', '$', finalExpression);

        //console.log(finalExpression);
        result = func(...varValues, props, props);
        
    } catch (e) {
        console.log('function error', expression)
        console.error(e)
    }

    return hasReturnValue ? result : null
}

export function SetVariables({ variables, children  }) {
    const targetVariables = useVariables().variables;
    const functions = {...useFunctions()}

    var rewrittenVariables = {...variables};
    for (var key in variables) {
        var value = variables[key];
         
        // Variable - $thing
        if (isVariable(value)) {

            rewrittenVariables[key] = targetVariables[extractVariable(value)];     

        // Expression - { something ... }
        } else if (isExpression(value)) {   

            rewrittenVariables[key] = executeExpression(extractExpression(value) ?? "", targetVariables, functions, true);        
        } else {
            rewrittenVariables[key] = targetVariables[value];
        }
    }

    let newChildren = React.Children.map(children, (child) => {
        if (child.type === SetVariables) {
            return <></>
        }
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...rewrittenVariables });
        } else {
            return child;
        }
    })
    return <>{newChildren}</>
}
