import * as React from 'react';
import useUniqueId from '../useUniqueId.js';
import { useSection } from '../SectionContext.js';
import { View } from './View.js';

function Info(props) {
    const id = useUniqueId(props.id);
    let { sectionId , sectionEncode } = useSection();
    const model = {
        type: 'info',
        id: id,
        sectionId: sectionId,
        sectionEncode: sectionEncode, 
        ...props,
    };

    
    return <View {...props} id={id} model={model}>
        {props.children}
    </View>;
}

export { Info }