import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import * as React from 'react';
import { useSection } from '../SectionContext.js';
import useUniqueId from '../useUniqueId.js';
import { Text } from './Text.js';
import { View } from './View.js';

function ActionButton(props) {
    let { updateModel, currentPage } = useAppState();
    let { sectionId, sectionStyle, sectionEncode } = useSection();


    const id = useUniqueId(props.id);
    const canEditText = props.canEditText == null ? true : props.canEditText;
    const canEditURL  = props.canEditURL == null ? true : props.canEditURL;
    var model = {
        type: 'action',
        id: id,
        componentTitle: props.componentTitle,
        canEditURL: canEditURL,
        canEditText: canEditText,
        sectionEncode: sectionEncode,
        sectionId: sectionId,
        ...props,
    };

    var containerStyle = { width: '100%' };

    var isReadonly = (canEditURL == false && canEditText == false) || props.readonly

    const content = (

        <div className="action-button">
            <Text id={"action-button-title"}
                readonly={!canEditText}
                inspectorModel={model}
                canEditStyle={false}
                content={model.title}
                modelProperty={"title"}
                style="headline"
                align="center"
                color="white"
                onChange={(text) => {
                    var newModel = model;
                    newModel.title = text;
                    updateModel(newModel);
                }} />
        </div>
    )
            
    if (isReadonly) {
        return content
    } else {
        return (
            <View {...props} model={model} style={containerStyle}>
                {content}
            </View>
        )
    }
}

export { ActionButton }