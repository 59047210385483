import React from 'react';
import { Section, Stack, HStack, Seperator, Text, Gallery, Image, View, useViewState, InspectorImageWell , InspectorImageWellCondensed } from '@yapstudios/yap-content-builder';
import { InspectorHeader ,InspectorControlButton,  InspectorContent, InspectorProperty, InspectorControlText, InspectorControlDate, InspectorListItems, useInspectorState }  from '@yapstudios/yap-content-builder'

export default class InfoTableComponent {
    constructor(rows) {
        this.rows = [];
        this.type = 'info-table';
    }

    defaultProps() {
        return {
            rows: [ 
                { title: "Title 1", value: "Value 1"},
                { title: "Title 2 ", value: "Value 2"},
            ]
        };
    }

    decode(props) {
        const spacing     = 8
        const paddingLeft = 20

        const tableContent = props.rows.map( (row, index) => { 
            return [
                <HStack paddingVertical={10} spacing={"between"} width={"infinity"}>
                    <Text align={"left"} readonly={true} content={row.title} width={"infinity"}/>
                    <Text align={"right"} readonly={true} content={row.value} width={"infinity"} color={"secondary"}/>
                </HStack>,
                (index != props.rows.length - 1 ? <Seperator/> : "")
            ];
        });

        const content = (
            <Stack cornerRadius={12} paddingHorizontal={20} paddingVertical={10} backgroundColor={"#fafafa"} direction={"vertical"} align={"leading"} justify={"leading"} spacing={spacing} width="infinity" >
                {tableContent}
            </Stack>
        );

        const emptyContent = (
            <div>{"Info Table"}</div>
        );

        const hasContent = props.rows.length > 0
        const containerStyle = { 'margin' : '20px', width: '100%' };

        return (
            <Section id="info-table-section">
                <View id={"info-table"} model={props} inspector={this.inspector}>
                    <div className={"info-table-content"}  style={containerStyle}>
                        {hasContent ? content : emptyContent}
                    </div>
                </View>
            </Section>
        )
    }

    inspector(model, updateModel) {

        // Add or replace an image
        const addRow = (title, value, index) => {
            let newRow = {
                title: title,
                value: value
            }

            if (index != null) {
                model.rows[index] = newRow
            } else {
                model.rows.push(newRow)
            }

            updateModel(model)
        }

        // Remove an image
        const deleteImage = (index) => {
            model.rows.splice(index, 1);
            updateModel(model)
        }

        // Swap image places
        const moveImage = (oldIndex, newIndex) => {
            const moveItem = model.rows[oldIndex];
            model.rows[oldIndex] = model.rows[newIndex];
            model.rows[newIndex] = moveItem;
            updateModel(model)
        }

        const rowItems = model.rows.map ((row, idx) => {
            return (
                <div className={"polymer-grid polymer-grid-2-columns"} style={{"paddingRight" : "10px"}}>                    
                    <InspectorControlText value={row.title} onChange={v => {
                        model.rows[idx]["title"] = v
                        updateModel(model) 
                    }}></InspectorControlText>

                    <InspectorControlText value={row.value} onChange={v => {
                        model.rows[idx]["value"] = v
                        updateModel(model) 
                    }}></InspectorControlText>
                </div>                
            )
        })

        const addButton = (
            <InspectorControlButton title="Add Row" onClick={ () => { addRow("","") }}/>            
        )

        return (
            <InspectorContent>
                <InspectorHeader title={"Info Table"} />
                <Stack spacing={10} paddingTop={20}>
                    <InspectorListItems onDelete={deleteImage} onMove={moveImage}>
                        {rowItems}
                    </InspectorListItems>
                    {addButton}
                </Stack>                         
            </InspectorContent>
        )
    }

    encode(model, props) {
        switch (model['id']) {
            case "table":
                props['rows'] = model.rows;
                break;
            default:
                break;
        }
        return props;
    }

    icon() {
        return (
<svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="100" height="70" fill="white"/>
<rect x="8.0766" y="9" width="82.161" height="16" rx="5" fill="#C3C9EC"/>
<rect x="8.0766" y="27" width="82.161" height="16" rx="5" fill="#C3C9EC"/>
<rect x="8.0766" y="45" width="82.161" height="16" rx="5" fill="#C3C9EC"/>
</svg>

        )
    }

}

function InfoTableRow({ title, value}) {
    return (
        <div className="info-table-row"><span>{title}</span><span>{value}</span></div>
    )
}