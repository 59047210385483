import React from 'react'
import ReactSlider from 'react-slider'

function TableRow(props) {
    var className = "asset-detail-row"
    if (props.first == true)  { className += " first" }
    if (props.single == true) { className += " single" }
    if (props.last == true)   { className += " last" }

    const isValue = typeof props.children === 'string' || Number.isInteger(props.children);

    return <div className={className}>
        {props.title && <h6>{props.title}</h6>}
        {isValue ? <span>{props.children}</span> : props.children}
    </div>
}

function TableRowDescription(props) {
    var className = ["asset-detail-row asset-detail-row-description ", props.className ?? ""].join(' ')
    if (props.first == true)  { className += " first" }
    if (props.single == true) { className += " single" }
    if (props.last == true)   { className += " last" }

    return (
        <div className={className}>
            <div className="asset-detail-row-description-icon">
                {props.icon}
            </div>
            <div className="asset-detail-row-description-content">
                <div className="asset-detail-row-description-header">
                    {props.title && <h6>{props.title}</h6>}
                    {props.value && <span>{props.value}</span>}
                </div>
                <span>{props.children}</span>
            </div>
        </div>
    )

}

function TableRowAction(props) {
    var className = "asset-detail-row "

    if (props.first == true) {
        className += " first"
    }

    if (props.single == true) {
        className += " single"
    }
    
    var anchorClass = ""
    if (props.destructive == true) {
        anchorClass += " destructive"
        className += " destructive"
    }

    return <div className={className}>
                <a className={anchorClass} onClick={ (event) => { if (props.onClick) { props.onClick(); } event.preventDefault(); }}>{props.title}</a>
                <span>{props.children}</span>
           </div>
}

var tick = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.36938 14.3828C5.95271 14.3828 5.59854 14.2031 5.30688 13.8438L1.26 8.85938C1.14542 8.72396 1.06208 8.59115 1.01 8.46094C0.963125 8.33073 0.939688 8.19531 0.939688 8.05469C0.939688 7.74219 1.04385 7.48438 1.25219 7.28125C1.46052 7.07812 1.72354 6.97656 2.04125 6.97656C2.40063 6.97656 2.70271 7.13021 2.9475 7.4375L6.33813 11.7344L12.9553 1.25C13.0907 1.04167 13.2314 0.895833 13.3772 0.8125C13.523 0.723958 13.7053 0.679688 13.9241 0.679688C14.2418 0.679688 14.5022 0.778646 14.7053 0.976562C14.9084 1.17448 15.01 1.42708 15.01 1.73438C15.01 1.85938 14.9892 1.98438 14.9475 2.10938C14.9058 2.23438 14.8407 2.36458 14.7522 2.5L7.43969 13.8125C7.18969 14.1927 6.83292 14.3828 6.36938 14.3828Z" fill="#55C838" />
            </svg>

var warning = <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.7207 14.9189C2.27148 14.9189 1.88086 14.8164 1.54883 14.6113C1.2168 14.4111 0.958008 14.1426 0.772461 13.8057C0.586914 13.4688 0.494141 13.1001 0.494141 12.6997C0.494141 12.3091 0.594238 11.938 0.794434 11.5864L6.44141 1.72803C6.64648 1.36182 6.92236 1.08594 7.26904 0.900391C7.61572 0.709961 7.97705 0.614746 8.35303 0.614746C8.729 0.614746 9.08789 0.70752 9.42969 0.893066C9.77148 1.07861 10.0498 1.35693 10.2646 1.72803L15.9043 11.5791C16.0068 11.7598 16.0825 11.9453 16.1313 12.1357C16.1802 12.3213 16.2046 12.5093 16.2046 12.6997C16.2046 13.1001 16.1118 13.4688 15.9263 13.8057C15.7456 14.1426 15.4893 14.4111 15.1572 14.6113C14.8252 14.8164 14.4346 14.9189 13.9854 14.9189H2.7207ZM8.36035 9.6748C8.88281 9.6748 9.15869 9.40625 9.18799 8.86914L9.3125 5.7124C9.32227 5.43896 9.23682 5.2168 9.05615 5.0459C8.87549 4.87012 8.64111 4.78223 8.35303 4.78223C8.06006 4.78223 7.82324 4.86768 7.64258 5.03857C7.4668 5.20947 7.38623 5.43164 7.40088 5.70508L7.51807 8.87646C7.54736 9.40869 7.82812 9.6748 8.36035 9.6748ZM8.36035 12.3262C8.65332 12.3262 8.89746 12.2407 9.09277 12.0698C9.29297 11.8989 9.39307 11.6743 9.39307 11.396C9.39307 11.1177 9.29297 10.8931 9.09277 10.7222C8.89746 10.5513 8.65332 10.4658 8.36035 10.4658C8.06738 10.4658 7.8208 10.5513 7.62061 10.7222C7.42041 10.8931 7.32031 11.1177 7.32031 11.396C7.32031 11.6743 7.42041 11.8989 7.62061 12.0698C7.82568 12.2407 8.07227 12.3262 8.36035 12.3262Z" fill="#FF8A00"/>
</svg>

var cross = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.36938 14.3828C5.95271 14.3828 5.59854 14.2031 5.30688 13.8438L1.26 8.85938C1.14542 8.72396 1.06208 8.59115 1.01 8.46094C0.963125 8.33073 0.939688 8.19531 0.939688 8.05469C0.939688 7.74219 1.04385 7.48438 1.25219 7.28125C1.46052 7.07812 1.72354 6.97656 2.04125 6.97656C2.40063 6.97656 2.70271 7.13021 2.9475 7.4375L6.33813 11.7344L12.9553 1.25C13.0907 1.04167 13.2314 0.895833 13.3772 0.8125C13.523 0.723958 13.7053 0.679688 13.9241 0.679688C14.2418 0.679688 14.5022 0.778646 14.7053 0.976562C14.9084 1.17448 15.01 1.42708 15.01 1.73438C15.01 1.85938 14.9892 1.98438 14.9475 2.10938C14.9058 2.23438 14.8407 2.36458 14.7522 2.5L7.43969 13.8125C7.18969 14.1927 6.83292 14.3828 6.36938 14.3828Z" fill="#55C838" />
            </svg>

function TableValueValid(props) {
    if (props.valid == "warning") {
        return <div className="asset-detail-row-validation warning">{props.children}{warning}</div>
    } else if (props.valid == "error") {
        return <div className="asset-detail-row-validation error">{props.children}{tick}</div>
    } else {
        return <div className="asset-detail-row-validation valid">{props.children}{cross}</div>
    }
}

function TableValueSlider(props) {
    return <ReactSlider className="polymer-slider" thumbClassName="polymer-slider-thumb" trackClassName="polymer-slider-track" {...props} />
}

export { TableRow, TableRowDescription, TableRowAction, TableValueValid, TableValueSlider }