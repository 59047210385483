/* eslint-disable */
import React, { useState, useEffect, Suspense } from 'react'

// Import YapContentBuilder components
import { YapContentBuilder, 
         YapContentValidations, 
         YapContentPreviewsModal,
         YapContentPreview,
         YapPage, 
         YapHeader,
         YapHeaderButton,
         YapNotification,
         YapAssetType,
         useYapPageState,
         YapUIGenerator,
         DocumentYapUI,
         YapUI,
         SegmentedControl, SegmentedControlOption,
         JSONFormatter,
         Sidebar,SidebarItem, SidebarAction, SidebarSection, SidebarContent, SidebarSectionHeader
       } from '@yapstudios/yap-content-builder'

import { CodeEditor } from './CodeEditor'
import { Renderer } from './Renderer/Renderer'

import { loadTypes, initBucketData, updateTypes } from '../AppData.js'
import { Group } from './Renderer/ui/Views/Group.tsx'
import { AuthProvider } from "../useAuth.js"

import { ProtectedRoute } from "../ProtectedRoute.js"

import { Loading } from '../Loading.js'
// React router
import { useNavigate, useLoaderData, Link, useParams, Await } from "react-router-dom";

import styled from 'styled-components'

import { v4 as uuidv4 } from 'uuid';
import { rewriteReactModifiers , resolveUnresolvedNodes, jsxStringToReact, swiftuiToReact } from './Generator.js'

/**
* Loader
*/
export const ContentEditorAuthLoader = (props) => {
    const { userPromise } = useLoaderData();
    const params = useParams();
    console.log('ContentBuilderAuthLoader')
    
    return (
        <AuthProvider>
            <ProtectedRoute>
                <Suspense fallback={<Loading/>}>
                    <Await
                        resolve={userPromise({params: params})}
                        // errorElement={<div>Something went wrong!</div>}
                        children={(data) => (
                            <ContentEditor types={data}/>
                        )}
                    />
                </Suspense>
            </ProtectedRoute>
        </AuthProvider>
    );
};

export async function contentEditorLoader({ params }) {
    // Initialise info from user 
    initBucketData()
    const types = await loadTypes();
    return types
}

function organizeItems(items) {
    const root = { items: [], children: {} };

    items.forEach(item => {
        const parts = item.title.split('/');
        let currentLevel = root;

        if (parts.length === 1) {
            // Item is a top-level view
            currentLevel.items.push({
                id: item.id,
                title: parts[0],
                fullTitle: item.title
            });
        } else {
            // Organize item into the appropriate section
            const itemTitle = parts.pop(); // Get the actual item title

            parts.forEach((part) => {
                if (!currentLevel.children[part]) {
                    currentLevel.children[part] = {
                        items: [],
                        children: {}
                    };
                }
                currentLevel = currentLevel.children[part];
            });

            // Add the item to the final section
            currentLevel.items.push({
                id: item.id,
                title: itemTitle,
                fullTitle: item.title
            });
        }
    });

    return root;
}

function generateSections(structure, level = 1) {
    const topLevelItems = structure.items.map(item => (
        <SidebarItem
            key={item.id}
            id={item.id}
            title={item.title}
            fullTitle={item.fullTitle}
            canDelete={true}
            canRename={true}
        />
    ))

    return (
        <>
            {level == 1 && topLevelItems}
            {Object.keys(structure.children).map(sectionTitle => {
                const section = structure.children[sectionTitle];
                const hasChildren = Object.keys(section.children).length > 0;

                return (
                    <SidebarSection key={sectionTitle} level={level}>
                        <SidebarSectionHeader key={sectionTitle} title={sectionTitle} canCollapse={true}/>
                        <SidebarContent>
                        {section.items.map(item => (
                            <SidebarItem
                                key={item.id}
                                id={item.id}
                                title={item.title}
                                fullTitle={item.fullTitle}
                                canDelete={true}
                                canRename={true}
                            />
                        ))}
                        {hasChildren && generateSections(section, level + 1)}
                        </SidebarContent>
                    </SidebarSection>
                );
            })}
        </>
    );
}
export function ContentEditor({ types }) {
    let defaultTypeItems = [
        { title: "NewType", id: "NewType", content: "", json: "" },
    ]

    const [selectedId, setSelectedId] = useState()
    const [displayType, setDisplayType] = useState("preview")

    const [items, setItems] = useState(types ?? defaultTypeItems)

    const selectedItem = selectedId != null ? items.find((item) => item.id === selectedId) : null
    const [ showPublishNotification, setShowPublishNotification ] = React.useState(false);

    let sectionItems = organizeItems(items)
    let sectionContent = generateSections(sectionItems)

    const updateContent = (content) => {

        /**
         * Generate json from content string    
         */
        const jsx = jsxStringToReact(content ? content : null, null, nameCallback)
        const jsxWithModifiers = rewriteReactModifiers(jsx) 
        const jsxyapui = new YapUIGenerator({ nameCallback: nameCallback }).encode(jsxWithModifiers)
        const jsxString = JSON.stringify(jsxyapui, null, 2)
        
        let newItems = items.map((item) => {
            if (item.id === selectedId) {
                return { ...item, content: content, json: jsxString }
            }
            return item
        })
        setItems(newItems)
    }

    const saveContent = () => {
        updateTypes(items)
        setShowPublishNotification(true)
    }

    const actions = [
        <YapHeaderButton key="save" onClick={() => {
            saveContent()
        }}>{"Save"}</YapHeaderButton>
    ]

    const addNew = () => {
        let userInput = prompt("Type name");
        if (userInput && userInput.length > 0) {
            let newItem = { title: userInput, id: uuidv4(), content: "", json: "" }
            setItems([...items, newItem])
            setSelectedId(newItem.id)
        }
    }

    const onDelete = (id) => {
        let newItems = items.filter((item) => item.id !== id)
        setItems(newItems)
        setSelectedId(null)
    }

    const onRename = (id, title) => {
        let newItems = items.map((item) => {
            item.title = item.id === id ? title : item.title
            return item
        })
        setItems(newItems)
    }

    const displayOption = (
        <SegmentedControl options={["Preview", "JSON"]} keys={["preview", "json"]} value={displayType} onChange={(value) => {
            setDisplayType(value)
        }}/>
    )

    /**
     * Callback that preserves the case of the name
     */
    const nameCallback = (tagName) => {
        for (const item of items) {
            const parts = item.title.split('/')
            const itemName = String(parts[parts.length - 1])
            if (itemName.toLowerCase() === tagName.toLowerCase()) {
                return itemName
            }
        }
        return null
    }

    /**
     * Callback that resolves jsx for a specific view name
     */
    const viewCallback = (name) => {
        for (const item of items) {
            const parts = item.title.split('/')
            const itemName = String(parts[parts.length - 1]).toLowerCase();
            if (itemName.toLowerCase() === name.toLowerCase()) {
                return jsxStringToReact(item.content, viewCallback);
            }
        }
        console.log(`not found ${name}`)
        return null
    }

    const previewContent = displayType == "preview" ? <Renderer jsx={selectedItem ? selectedItem.content : null} viewCallback={viewCallback} /> : <JSONPreview json={selectedItem ? selectedItem.json : null}/>

    return (
        <YapPage fullscreen={true} >
            <YapNotification title="Saved" icon={"success"} show={showPublishNotification} setShow={setShowPublishNotification}/>
            <YapHeader fixed={true} titleLeft={true} largeTitle={false} title={"Editor"} headerActions={actions}></YapHeader>
            <div className={`layout layout-app with-top-toolbar`}>
                <LayoutLeftSidebar>
                    <Sidebar selectedId={selectedId} setSelectedId={setSelectedId} onDelete={onDelete} onRename={onRename}>
                        {sectionContent}
                            <SidebarAction title="Add" onClick={() => {
                                addNew()
                            }}></SidebarAction>
                    </Sidebar>
                </LayoutLeftSidebar>
                <section className="layout-center">
                    <YapHeader titleLeft={true} largeTitle={false} title={selectedItem ? selectedItem.title : ""}></YapHeader>   
                    {selectedItem && <CodeEditor content={selectedItem.content} setContent={updateContent}/>}
                </section>
                <section className="layout-right">
                    <section className={"inspector-right"}>
                        <PreviewContainer>
                            {displayOption}
                            <PreviewInnerContent>
                                {selectedItem && previewContent}
                            </PreviewInnerContent>
                        </PreviewContainer>
                    </section>
                </section>
            </div>
        </YapPage>
    )
}

function JSONPreview({ json }) {    
    let content = null
    try {
        content = JSON.parse(json)
    } catch (error) {
        console.log('error')
        console.log(error)
    }
    return (
        <JSONFormatter>{content}</JSONFormatter>
    )
}

function Background({ content }) {
    return (
        content
    )
}

function LayoutLeftSidebar({ children }) {
    return (
        <section className='layout-left'>
            <section className={`sidebar sidebar-left`}>
                <div className={`sidebar-content`}>
                    {children}
                </div>
            </section>
        </section>
    )               
}

const PreviewInnerContent = styled.div`
    width: -webkit-fill-available;
    height: -webkit-fill-available;
`

const PreviewContainer = styled.div`
    background-color: #fcfcfc;
    padding: 0px;
    min-width: 440px;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: top;

    & .control-segmented {
        border-bottom: 1px solid #E3e3e3;
        width: -webkit-fill-available;
        align-items: center;
        justify-content: center;
        inline-display: block;
        padding: 18px;
    }

    & .control-segmented-option {
        padding: 4px;
        cursor: pointer;
        display: block;
        width: 100px;
        justify-content: center;
        height: 20px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
    }
`