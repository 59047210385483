import React from 'react';
import { Section, Image, Box, HStack, VStack, Spacer, Stack, Text } from '@yapstudios/yap-content-builder';

export default class InfoCardComponent {
    constructor(text, style) {
        this.type = 'info-card';
    }

    titlesForProp() {
        return {
            title: null 
        };
    }

    defaultProps() {
        return {
            title: null,
            description: null,
            image: {
                src: null
            }
        };
    }

    decode(props) {

        let image = props.image ?? { }
        return (
            <Section padding="16" id="card" title="Card" >
                <Box backgroundColor="#F3F3F3" cornerRadius={20}>
                <VStack width={"infinity"} padding={12} spacing={10} justify={"leading"} align={"leading"}>
                    <Image placeholder={true} cornerRadius={14} src={image.src} description={image.description} height="200" contentMode={image.contentMode} id="coverImage" accessibilityLabel="Icon" {...image}></Image>
                    <VStack width={"infinity"} padding={10} spacing={5} justify={"leading"} align={"leading"}>
                        <Text id="title" paddingTop={2} componentTitle={"Card Title"} placeholder={"Card Title"} content={props.title} canEditStyle={false} style={"h3"} align={"left"}/>
                        <Text id="description" paddingTop={2} componentTitle={"Card Body"} placeholder={"Body Text"} content={props.description} canEditStyle={false} style={"body"} align={"left"}/>
                    </VStack>
                </VStack>
                </Box>
            </Section>
        );
    }

    encode(model, props) {
        if (model.id == "coverImage") {
            props['image'] = {...model}
        }
        if (model.id == "title") {
            props['title'] = model.content;
        }
        if (model.id == "description") {
            props['description'] = model.content;
        }

        return props;
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11_71)">
            <path d="M100 0H0V70H100V0Z" fill="white"/>
            <rect x="3.57178" y="4.84998" width="92.6636" height="59.5969" rx="5" fill="#EDEFF9"/>
            <rect x="8.18579" y="10.3105" width="83.4355" height="35.3" rx="5" fill="#32427D" fillOpacity="0.15"/>
            <rect x="8.18579" y="50.8361" width="38.4495" height="9.22778" rx="4.61389" fill="#32427D" fillOpacity="0.15"/>
            </g>
            <defs>
            <clipPath id="clip0_11_71">
            <rect width="100" height="70" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            
        )
    }

}
