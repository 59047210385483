import React from 'react';
import { Section, Stack, VStack, HStack, Text, ActionButton, Image } from '@yapstudios/yap-content-builder';
import { InspectorHeader , InspectorContent, InspectorProperty, InspectorControlText, InspectorControlButton, InspectorControlDate, useInspectorState }  from '@yapstudios/yap-content-builder'
import { View } from '@yapstudios/yap-content-builder';

export default class SpacerComponent {
    static type = 'spacer';

    constructor() {
        this.type = 'spacer';
    }

    defaultProps() {
        return {
            height: '20'
        };
    }

    titlesForProp() {
        return {

        };
    }

    decode(props) {
        return (
            <Section padding="5" height={props.height}>
                <View width={"100%"} height={props.height} model={props} inspector={(model, updateModel) => {
                    return this.inspector(model, updateModel)
                }}>
                    <span>&nbsp;</span>
                </View>        
            </Section>
        );
    }

    inspector(model, updateModel) {
        
        return (
            <InspectorContent key={"appearance-detent"}>
                <InspectorHeader title={"Spacer"} />
                <Stack spacing={20} align={"leading"} width={"infinity"}>
                    <InspectorProperty key={"height"} property={'height'} singleColumn={true} title={"Height"}>
                        <InspectorControlText value={model.height} onChange={v => {
                            model.height = v
                            updateModel(model)
                        }} />
                    </InspectorProperty>
                </Stack>
            </InspectorContent>
        )
    }

    encode(model, props) {
        // From inspector
        props = {...model}
        return props
    }

    validations() {
        return [
            
        ];
    }

    icon() {
        return (
<svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_8_34)">
<path d="M100 0H0V70H100V0Z" fill="white"/>
<path d="M90.2883 28.3129H9.6062C7.39706 28.3129 5.6062 30.1037 5.6062 32.3129V37.6872C5.6062 39.8963 7.39706 41.6872 9.6062 41.6872H90.2883C92.4974 41.6872 94.2883 39.8963 94.2883 37.6872V32.3129C94.2883 30.1037 92.4974 28.3129 90.2883 28.3129Z" fill="#EDEFF9"/>
</g>
<defs>
<clipPath id="clip0_8_34">
<rect width="100" height="70" fill="white"/>
</clipPath>
</defs>
</svg>
        )
    }
}
