/* eslint-disable */
import React, { useEffect } from 'react'

// Use styled components
import styled from 'styled-components'

// Import YapContentBuilder components
import { YapSectionAdd, useCanvas, useViewState, useAppState } from '@yapstudios/yap-content-builder'
import { YapUI, Component, Modifier } from '@yapstudios/yap-content-builder'
// Import YapContentBuilder components
import { 
    useYapPageState
  } from '@yapstudios/yap-content-builder'


export class DoublePageRenderer {
    
    hasCenterAlignment(sections) {
        const imageQuestions = sections.filter((child, i) => {
            return (child.type === 'athena-image-question')
        })
        return imageQuestions.length > 0 
    }

    yapUI(props, children, page) {

        console.log(children)

        const leftChildren = children.filter((child) => { return (child.group == "left" || child.group == null) })
        const rightChildren = children.filter((child) => { return child.group == "right" })

        const centerAlignment = this.hasCenterAlignment(page.sections)
    
        let frameModifier = YapUI.Directive('flexFrame', { maxHeight: "infinity", maxheight: 'infinity', alignment: centerAlignment ? "center" : "top" });

       let leftContainer  = YapUI.ModifiedContent(frameModifier, YapUI.Directive("VStack", { spacing: 16 }, leftChildren))
       let rightContainer = YapUI.ModifiedContent(frameModifier, YapUI.Directive("VStack", { spacing: 16 }, rightChildren))

       let right = YapUI.Directive('AthenaContentRight', {}, [rightContainer])
       let left = YapUI.Directive('AthenaContentLeft', {}, [leftContainer])

        return YapUI.Directive('AthenaContentDouble', { title: page.title ?? "Page Title" }, [left, right])
    }

    yapUIReact(props, children, page) {

        console.log(children)

        const leftChildren = children.filter((child) => { return (child.group == "left" || child.group == null) })
        const rightChildren = children.filter((child) => { return child.group == "right" })

        const centerAlignment = this.hasCenterAlignment(page.sections)

       return (
            <Component name="AthenaContentDouble" title={page.title ?? "Page Title"}>

                <Component name="AthenaContentLeft">
                    <Modifier name="flexFrame" maxHeight="infinity" alignment={  centerAlignment ? "center" : "top" }>
                        <Component name="VStack" spacing="16">{leftChildren}</Component>
                    </Modifier>
                </Component>

                <Component name="AthenaContentRight">
                    <Modifier name="flexFrame" maxHeight="infinity" alignment={  centerAlignment ? "center" : "top" }>
                        <Component name="VStack" spacing="16">{rightChildren}</Component>
                    </Modifier>
                </Component>

            </Component>
        )
    }

    render(props) {
        return <DoublePageRendererContent {...props} centerAlignment={this.hasCenterAlignment(props.sections)} />
    }
}

function DoublePageRendererContent({ sections, componentSections, centerAlignment }) {

    const canvas = useCanvas()
    const appState = useAppState()

    const componentArray = React.Children.toArray(componentSections)
    const leftSections = componentArray.filter((child, i) => {
        let align = child.props.section.group
        return align == 'left' || align == null;
    });

    const addLeft = <AddContainer><YapSectionAdd group="left"/></AddContainer>

    const rightSections = componentArray.filter((child, i) => {
        let align = child.props.section.group
        return align == 'right';
    });
    
    const addRight = <AddContainer><YapSectionAdd group="right"/></AddContainer>

    const onHoverEmpty = () => {
        if (canvas && canvas.setHoveredSectionRef) {
            canvas.setHoveredSectionRef(null, false)
        }
    }

    const onLeave = (event) => {
        if (canvas && canvas.setHoveredSectionRef) {
            canvas.setHoveredSectionRef(null, true)
        }
    }

    const isPreview = appState.isReadonly
    
    return (
        <PageContainer isPreview={isPreview}>
            <SplitPage className="page-renderer-inner-content" onMouseLeave={onLeave} isPreview={isPreview}>
                
                <PageSection  centerAlignment={centerAlignment && leftSections.length}>
                    {leftSections}
                    {leftSections.length == 0 && !isPreview && addLeft}
                    <EmptyArea onMouseOver={onHoverEmpty}/>
                </PageSection>

                <PageDivider/>

                <PageSection centerAlignment={centerAlignment && rightSections.length}>
                    {rightSections}
                    {rightSections.length == 0 && !isPreview && addRight}
                    <EmptyArea onMouseOver={onHoverEmpty}/>
                </PageSection>

            </SplitPage>
        </PageContainer>
    )
}

const EmptyArea = styled.div`
    flex: 1;
`
const PageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: top;
    padding-top: ${props => props.isPreview ? '0px' : '50px'};
    padding-bottom: ${props => props.isPreview ? '0px' : '50px'};
    min-height: 100vh;
`
const SplitPage = styled.div`
    width: 1024px;
    border-radius: ${props => props.isPreview ? '20px' : '20px'};
    box-shadow: ${props => props.isPreview ? '' : '0px 10px 20px 0px rgba(0,0,0,0.1)'};
    background-color: ${props => props.isPreview ? 'white' : 'white'};
    display: grid;
    grid-template-columns: 1fr 0.0001fr 1fr;
    grid-gap: 10px;
    padding: 10px;
    height: -webkit-fit-content;
    min-height: 768px;
`
const PageSection = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: ${props => props.centerAlignment ? "column" : "column"};
    justify-content: center;
    align-self: ${props => props.centerAlignment ? "center" : ""};
` 

const PageDivider = styled.div`
    min-height: 768px;
    width: 1px;
    background-color: #f0f0f0;
`

const AddContainer = styled.div`  
    height: 100%;
    .section-add {
        height: 100%;
        background: #fafafa;
        border-radius: 10px;    
    }
`