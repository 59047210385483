import * as React from 'react'

function ContentValidations() {

}

function titleForValidation(property, title, component) {
    var validationTitle = property.capitalized()

    // Get title from args if exists
    if (title) {
        validationTitle = title

    // Get title from title function if exists
    } else if (component && component.titlesForProp) {
        let propTitle = component.titlesForProp()[property]
        if (propTitle) {
            validationTitle = propTitle
        }
    }

    return validationTitle
}

function elementForValidation(property, component) {
    var validationElement = property
    if (component && component.elementsForProp) {
        let element = component.elementsForProp()[property]
        if (element) {
            validationElement = element
        }
    }
    return validationElement
}

/**
 * Determines component either from the one passed in or one in current context
 * @param {} component 
 * @returns 
 */
ContentValidations._currentComponent = null
function currentComponent(component) {
    if (component != null) { return component }
    if (ContentValidations._currentComponent) {
        return ContentValidations._currentComponent
    }
    return null
}

ContentValidations.fieldRequired = (property, props) => {
    var { fieldTitle, title, component, description, propertyKey } = props ?? {}
    component = currentComponent(component)
    if (component == null) { return {} }

    let defaultPropertyValue = component.defaultProps()
    const elementId            = elementForValidation(property, component)
    const validationTitle      = titleForValidation(property, fieldTitle, component)

    var value = defaultPropertyValue[property]
    if (propertyKey && value) {
        value = value[propertyKey]
    }

    var defaultDescription = null

    const allowDefault = props.allowDefault == true
    if (value && allowDefault == false) {
        defaultDescription = `Required to have a value other than the default of '${value}'`
    } else {
        defaultDescription = `Required to have a value`
    }

    let validationProps = props

    return {    
        'filter' : props.filter,
        'check' : (props) => { 
            const allowDefault = validationProps.allowDefault == true
            var value = null
            if (propertyKey) {
                if (props[property]) {
                    value = props[property][propertyKey]
                }
            } else {
                value = (String(props[property] ?? "")).trim()
            }

            if (value == null || value.length == 0 || (value == defaultPropertyValue[property] && allowDefault == false)) {
                return false
            } else {
                return true
            }
        }, 
        'filter' : props.filter,
        'showAsIndicator' : (props, valid) => {
            if (allowDefault && !validationProps.alwaysShowIsRequired) {
                return valid == false
            } else {
                return true
            }
        },
        'description' : description ?? defaultDescription,
        'title' : title ?? `${validationTitle} Is Required`,
        'elementTitle' : validationTitle,
        'type' : 'requirement',
        'component' : component.type,
        'elementId' : elementId,
        'property' : property
      }
}

ContentValidations.minimumLength = (property, props, type) => {
    var { fieldTitle, length, component, title, defaultDescription } = props ?? {}

    component = currentComponent(component)
    if (component == null) { return {} }

    const defaultPropertyValue = component.defaultProps()
    const validationTitle      = titleForValidation(property, fieldTitle, component)
    const description = `${validationTitle} should be more than ${length} characters`
    
    return {    
        'filter' : props.filter,
        'check' : (props) => { 
            let value = (String(props[property] ?? "")).trim()
            if (value == defaultPropertyValue[property]) { return true }
            return (value.length > length)
        }, 
        'description' : defaultDescription ?? description,
        'title' : title ?? `${validationTitle} Is Too Short`,
        'elementTitle' : validationTitle,
        'type' : type,
        'showAsIndicator' : (props, valid) => { 
            let value = (String(props[property] ?? "")).trim()
            if (value.length == 0) {
                return false
            } else {
                return valid != true 
            }
        },
        'showAsValidation' : (props) => { 
            let value = (String(props[property] ?? "")).trim()
            return value.length > 0
        },
        'component' : component.type,
        'elementId' : property,
        'property' : property
      }
}

ContentValidations.imageDimensionsRequired = (property, props) => {
    return ContentValidations.imageDimensions(property, { ...props, required: true })
}

ContentValidations.imageDimensionsWarning = (property, props) => {
    return ContentValidations.imageDimensions(property, { ...props, required: false })
}

ContentValidations.imageDimensions = (property, props) => {
    var { fieldTitle, length, component, title, defaultDescription, minimumWidth, minimumHeight, required } = props ?? {}

    component = currentComponent(component)
    if (component == null) { return {} }

    const defaultPropertyValue = component.defaultProps()
    const validationTitle      = titleForValidation(property, fieldTitle, component)
    
    return {    
        'filter' : props.filter,
        'check' : (props) => { 
            let v = props[property]

            var dimensions = v.dimensions
            if (v.crop) {
                dimensions = { width: v.crop.width, height: v.crop.height }
            }

            if (v && dimensions) {
                if (minimumWidth && dimensions.width < minimumWidth) { return false }
                if (minimumHeight && dimensions.height < minimumHeight) { return false }
                return true
            } 
            return false
        }, 

        // Build description
        'description' : (props, isValid) => {
            var dimensions = props[property] ? props[property].dimensions : null
            let crop = props[property].crop
            
            if (crop) {
                dimensions = { width: crop.width, height: crop.height }
            }

            var description = `${validationTitle} can be any size`

            if (minimumWidth && !minimumHeight) {
                description = required ? `Minimum width is required to be ${minimumWidth}` : `Minimum width is recommended to be ${minimumWidth}`
            } else if (minimumHeight && !minimumWidth) {
                description = required ? `Minimum height is required to be ${minimumHeight}` : `Minimum height is recommended to be ${minimumHeight}`
            } else {
                description = required ? `Minimum size is required to be ${minimumWidth}x${minimumHeight}` : `Minimum size is recommended to be ${minimumWidth}x${minimumHeight}`
            }

            if (!isValid && dimensions) {
                var actualDimension = ""
                if (minimumWidth && !minimumHeight) {
                    actualDimension = ` but is ${dimensions.width}`
                } else if (minimumHeight && !minimumWidth) {
                    actualDimension = ` but is ${dimensions.height}`
                } else{
                    actualDimension = ` but is ${dimensions.width}x${dimensions.height}`
                }
                description = description + actualDimension
            }

            return description        
        },
        'title' : title ?? `${validationTitle} has invalid dimensions`,
        'elementTitle' : validationTitle,
        'type' : (props, isValid) => {
            let src = props[property] ? props[property].src : null
            if (src && !isValid) {
                return required ? 'error' : 'warning'
            } else {
                return required ? 'requirement' : 'info'
            }
        },
        'showAsIndicator' : true,
        'showAsValidation' : (props) => {
            let src = props[property] ? props[property].src : null
            return (src != null) 
        },
        'component' : component.type,
        'elementId' : property,
        'property' : property
      }
}


ContentValidations.minimumLengthWarning = (property, props) => {
    return ContentValidations.minimumLength(property, props, 'warning');    
}

ContentValidations.minimumLengthError = (property, props) => {
    return ContentValidations.minimumLength(property, props, 'error');    
}

ContentValidations.maximumLength = (property, props, type) => {
    var { fieldTitle, length, component, description, title } = props ?? {}

    component = currentComponent(component)
    if (component == null) { return {} }

    let defaultPropertyValue = component.defaultProps()
    const validationTitle      = titleForValidation(property, fieldTitle, component)
    var defaultDescription = `${validationTitle} should be less than ${length} characters`

    return {    
        'filter' : props.filter,
        'check' : (props) => { 
            let value = (String(props[property] ?? "")).trim()
            if (value == defaultPropertyValue[property]) { return true }
            return (value.length < length)
        }, 
        'description' : description ?? defaultDescription,
        'title' : title ?? `${validationTitle} Is Too Long`,
        'elementTitle' : validationTitle,
        'showAsIndicator' : (props, valid) => { return valid != true },
        'type' : type,
        'component' : component.type,
        'elementId' : property,
        'property' : property
      }
}

ContentValidations.maximumLengthWarning = (property, props, type) => {
    return ContentValidations.maximumLength(property, props, 'warning')
}

ContentValidations.maximumLengthError = (property, props, type) => {
    return ContentValidations.maximumLength(property, props, 'error')
}

ContentValidations.preventLinks = (property, props) => {
    var { fieldTitle, length, component, description, title } = props ?? {}

    component = currentComponent(component)
    if (component == null) { return {} }

    const validationTitle      = titleForValidation(property, fieldTitle, component)
    
    var defaultDescription = `${validationTitle} cannot contain links in Markdown format.`

    return {    
        'filter' : props.filter,
        'check' : (props) => {             
            let v = props[property];
            const pattern = /\[.*?\]\(.*?\)/;            
            return pattern.test(v) == false
        }, 
        'description' : description ?? defaultDescription,
        'title' : title ?? `${validationTitle} Contains a Link`,
        'elementTitle' : validationTitle,
        'showAsIndicator' : (props, valid) => { return valid != true },
        'type' : 'error',
        'component' : component.type,
        'elementId' : property,
        'property' : property
      }
}


ContentValidations.requireHTTPSLinks = (property, props) => {
    return ContentValidations.urlProtocolsRequired(property, { ...props, protocols: ['https'] })
}

ContentValidations.urlProtocolsRequired = (property, props) => {
    var { fieldTitle, length, component, description, title, isMarkdown, protocols } = props ?? {}

    protocols = protocols ?? []

    component = currentComponent(component)
    if (component == null) { return {} }

    const validationTitle      = titleForValidation(property, fieldTitle, component)
    
    var defaultDescription = `${validationTitle} links are required to use protocol${protocols.length > 1 ? 's' : ''} ${protocols.join(',')}`

    const containsProtocol = (val) => {
        for (const protocol of protocols) {
            var p = protocol.substr(0, val.length)
            if (val.indexOf(p) == 0) {
                return true
            }
        }
        return false
    }

    return {    
        'filter' : props.filter,
        'check' : (props) => {             
            let v = props[property];
            if (v == null) { return true }

            if (isMarkdown || property != 'url') {
                const pattern = /\[.*?\]\((.*?)\)/g;            
                let matches = v.matchAll(pattern)
                for (const match of matches) {
                    let link = match[1]
                    if (containsProtocol(link) == false) {
                        return false
                    }
                }
            } else {
                return containsProtocol(v)
            }
            return true
        }, 
        'description' : description ?? defaultDescription,
        'title' : title ?? `${validationTitle} Requires https:// Links`,
        'elementTitle' : validationTitle,
        'showAsIndicator' : (props, valid) => { return valid != true },
        'type' : 'error',
        'component' : component.type,
        'elementId' : property,
        'property' : property
      }
}

ContentValidations.urlProtocolsRequired = (property, props) => {
    var { fieldTitle, length, component, description, title, isMarkdown, protocols } = props ?? {}

    protocols = protocols ?? []

    component = currentComponent(component)
    if (component == null) { return {} }

    const validationTitle      = titleForValidation(property, fieldTitle, component)
    
    var defaultDescription = `${validationTitle} links are required to use protocol${protocols.length > 1 ? 's' : ''} ${protocols.join(',')}`

    const containsProtocol = (val) => {
        for (const protocol of protocols) {
            var p = protocol.substr(0, val.length)
            if (val.indexOf(p) == 0) {
                return true
            }
        }
        return false
    }

    return {    
        'filter' : props.filter,
        'check' : (props) => {             
            let v = props[property];
            if (v == null) { return true }

            if (isMarkdown || property != 'url') {
                const pattern = /\[.*?\]\((.*?)\)/g;            
                let matches = v.matchAll(pattern)
                for (const match of matches) {
                    let link = match[1]
                    if (containsProtocol(link) == false) {
                        return false
                    }
                }
            } else {
                return containsProtocol(v)
            }
            return true
        }, 
        'description' : description ?? defaultDescription,
        'title' : title ?? `${validationTitle} Requires https:// Links`,
        'elementTitle' : validationTitle,
        'showAsIndicator' : (props, valid) => { return valid != true },
        'type' : 'error',
        'component' : component.type,
        'elementId' : property,
        'property' : property
      }
}



ContentValidations.image = (property, props) => {
    var { fieldTitle, length, check, type, component, title, description, validDescription, infoDescription } = props ?? {}

    component = currentComponent(component)
    if (component == null) { return {} }

    const defaultPropertyValue = component.defaultProps()
    const validationTitle      = titleForValidation(property, fieldTitle, component)
    
    return { 
        'filter' : props.filter,   
        'check' : (props) => { 
            let v = props[property]
            if (v.src == null) {
                return infoDescription ? false : true
            }

            if (v && check) {
                let result = check(v)
                return result
            } else {
                return true
            }
        }, 

        // Build description
        'description' : (props, isValid) => {
            
            
            let v = props[property]
            
            var descriptionValue = description
            if (v.src == null && infoDescription) {
                descriptionValue = infoDescription
            } else if (isValid && validDescription) {
                descriptionValue = validDescription
            } else {
                descriptionValue = description
            }

            if (descriptionValue instanceof Function) {
                return descriptionValue(v, isValid)
            } else{
                return descriptionValue ?? ""
            }    
        },
        'title' : title ?? validationTitle,
        'elementTitle' : title ?? validationTitle,
        'type' : (props, isValid) => {
            
            let v = props[property]
            if (v.src == null && infoDescription != null) {
                return 'info'
            }

            if (type instanceof Function) {
                return type(v, isValid)
            } else {
                return type ?? 'warning'
            }  
        },
        'showAsIndicator' : (props, valid) => {
            let v = props[property]

            // If invalid always show indicator
            if (!valid) {
                return true
            }
            if (v.src == null) {
                return infoDescription != null ? true : false
            } else {
                return validDescription != null
            }
        },
        'showAsValidation' : (props) => {
            return true
            //let src = props[property] ? props[property].src : null
            //return (src != null) 
        },
        'component' : component.type,
        'elementId' : property,
        'property' : property
      }
}


export default ContentValidations