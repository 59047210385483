import { Navigate } from "react-router-dom";
import { useAuth } from "./useAuth.js";

export const ProtectedRoute = ({ children }) => {
    const { bucketData } = useAuth()

    if (!bucketData) {
        // user is not authenticated
        return <Navigate to="/login" />;
    }
    return children;
};