import * as React from 'react'
import Modal from 'react-modal';
// CSS
import './ContentPreview.css';
import SegmentedControl from '../uikit-components/SegmentedControl.js'

const close = (
    <svg  aria-hidden="true" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="black" fillOpacity="0.8" />
        <path  fill="white" fillOpacity="0.75"  d="M10.3965 22.3779C10.2203 22.1966 10.102 21.9852 10.0415 21.7435C9.98615 21.5018 9.98615 21.2627 10.0415 21.026C10.102 20.7894 10.2152 20.588 10.3814 20.4219L14.4142 16.3815L10.3814 12.3562C10.2152 12.1901 10.1045 11.9887 10.0491 11.7521C9.99371 11.5104 9.99371 11.2687 10.0491 11.0271C10.1045 10.7854 10.2203 10.5765 10.3965 10.4003C10.5727 10.219 10.7816 10.1007 11.0233 10.0453C11.27 9.98993 11.5117 9.98993 11.7483 10.0453C11.99 10.1007 12.1939 10.2115 12.36 10.3776L16.3928 14.4029L20.4181 10.3852C20.5893 10.214 20.7932 10.1007 21.0298 10.0453C21.2665 9.9849 21.5031 9.9849 21.7397 10.0453C21.9814 10.1057 22.1903 10.2266 22.3665 10.4078C22.5478 10.584 22.6686 10.7905 22.729 11.0271C22.7895 11.2637 22.7895 11.5029 22.729 11.7445C22.6737 11.9812 22.5604 12.1851 22.3892 12.3562L18.3715 16.3815L22.3892 20.4143C22.5604 20.5855 22.6737 20.7919 22.729 21.0336C22.7895 21.2702 22.7895 21.5094 22.729 21.751C22.6686 21.9877 22.5478 22.1941 22.3665 22.3703C22.1903 22.5465 21.9814 22.6648 21.7397 22.7253C21.5031 22.7857 21.2665 22.7882 21.0298 22.7328C20.7932 22.6774 20.5893 22.5616 20.4181 22.3854L16.3928 18.3602L12.36 22.393C12.1939 22.5591 11.99 22.6699 11.7483 22.7253C11.5117 22.7857 11.2725 22.7857 11.0309 22.7253C10.7892 22.6699 10.5777 22.5541 10.3965 22.3779Z"/>
    </svg>
)

export default function ContentPreviewsModal({ show, onClose, children, width }) {
    const [previewIndex, setSelectedPreviewIndex] = React.useState(0)
    const contentRef = React.useRef()

    React.useEffect(() => {
        document.body.classList.toggle('noscroll', show);
    }, [show])

    // Get first react element from 'children' array variable
    let items = React.Children.toArray(children)
    let element = items[previewIndex]
    if (element == null) { 
        return null
    }
    let previewElement = React.cloneElement(element, {
        width: width ?? 300,
        interactionEnabled: true
    })

    let previewElementAria = <div>
        <p id={"item-description"} className="yap-sr-only">{element.props.altText ?? element.props.description ?? element.props.name}</p>
        {previewElement}
    </div>

    let titles = items.map((item) => {
        return item.props.name
    })

    let header = null
    let content = <div role="tabpanel" aria-labelledby={"item-description"}>{previewElementAria}</div>

    if (titles.length > 1) {
        header = <SegmentedControl titles={titles} selectedIndex={previewIndex} setSelectedIndex={setSelectedPreviewIndex} />
    } else if (titles.filter((a) => a).length == 1) {
        header = <h1 className="preview-modal-content-header">{titles[0]}</h1>
    }


    const contentOnClick = (e) => {
        // If clicking the background then let it close
        // but prevent close if clicking the content
        if (contentRef.current == e.target) {
            onClose()
        }
    }

    const onKeyUp = (e) => {
        let keyCode = e.keyCode ? e.keyCode : e.which;
        if (keyCode === 13 || keyCode == 32) { 
            onClose()
        }
    }

    return (
        <Modal contentLabel={"Preview"} ariaHideApp={true} className="preview-modal-content dark" overlayClassName="modal-overlay" isOpen={show} onRequestClose={() => { onClose() }}>
            <div ref={contentRef} className="preview-modal-inner-content" onClick={contentOnClick}>
                {header}                
                {content}
            </div>
            <div role="button" aria-label="close preview dialog" tabIndex="0" onKeyUp={onKeyUp} className="preview-modal-close" onClick={() => { 
                onClose() 
            }}>{close}</div>
        </Modal>
    )
}
