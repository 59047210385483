import * as React from 'react'

var backgroundImageLight = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMnSURBVHgB7dYBEYAwEMCwBzHzb2czM/DRRESvz977DllrraHrHSBLACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACDsub8h65wzdDkACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACPsAGA4MhUaQUtgAAAAASUVORK5CYII="
var backgroundImageDark = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMqSURBVHgB7dZBEYAwEMDAg3e1VED9K6ghEJJdEZk855xvyLr3Dl3vAFkCAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGHPWusbsvbeQ5cDgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgLAfUQsHzwUPBscAAAAASUVORK5CYII="
import AssetImageCropped  from './AssetImageCropped.js'

const AssetImageContentModeType = {
    Fill: "fill",
    Fit: "fit"
}

/**
 * Cached interface of AssetImage .
 * Wont re-render if set of props are the same
 */
function AssetImage(props) {  

    const asset = props.asset ?? {}
    const crop = asset.crop ?? {} 
    
    return React.useMemo(() => {
        return <AssetImageContent {...props}/>;
      }, [asset.src, 
          props.width, 
          props.height, 
          crop.x, 
          crop.y, 
          crop.width, 
          crop.height, 
          props.maxWidth, 
          props.variableWidth,
          props.background, 
          props.assetVersion, 
          props.maxHeight]);
}


/**
 * Component to show a Polymer Asset (Image or Video)
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.asset - The asset object containing the image source and other properties.
 * @param {number} [props.scale=1.0] - The scale factor to adjust the size of the image.
 * @param {number} [props.width] - (Optional) The absolute width of the image in pixels. 
 * @param {number} [props.height] - (Optional) The absoulute height of the image in pixels.
 * @param {number} [props.maxWidth] - (Optional) The maximum width of the image in pixels.
 * @param {number} [props.maxHeight] - (Optional )The maximum height of the image in pixels.
 * @param {boolean} [props.variableWidth=false] - Indicates if the image width should be variable based on the height and aspect ratio.
 * @param {string} [props.borderRadius] - The border radius of the image container.
 * @param {boolean} [props.background=false] - Indicates if the image should be displayed with a background to show transparent areas.
 * @param {boolean} [props.dark=false] - Indicates if the image is intended to be used in a dark context.
 * @param {string} [props.className] - Additional CSS class name for the image container.
 * @param {number} [props.assetVersion] - The version of the asset.
 * @param {Function} [props.onDimensions] - Callback function invoked with the image dimensions - { width: number, height: number }
 * * @param {Function} [props.onDisplayDimensions] - Callback function invoked with the image dimensions as rendered - { width: number, height: number }
 * @param {string} [props.key] - The unique key for the component.
 * @returns {JSX.Element} The rendered image asset component.
 */
function AssetImageContent(props) {
    var { asset, scale } = props

    const debug = false

    scale = scale ?? 1.0

    var [imageWidth,  setImageWidth]  = React.useState(asset && asset.dimensions ? asset.dimensions.width : null)
    var [imageHeight, setImageHeight] = React.useState(asset && asset.dimensions ? asset.dimensions.height : null)
    const [loaded, setLoaded] = React.useState(false)

    // Image object used to determine dimensions of the image
    const imageObject = React.useRef(new Image())

    // Dimensions of the container the asset is in. Used for rendering cropped
    const [containerDimensions, setContainerDimensions] = React.useState(null)
    const containerRef = React.useRef(null)

    // Determine inital aspec ratio
    var aspect = 1
    if (imageWidth && imageHeight) {
        aspect = imageWidth / imageHeight
    }

    if (asset && asset.dimensions) {
        if (debug) {
             console.log(`${asset.title} - dimensions - ${asset.dimensions.width} ${asset.dimensions.height}`); 
        }
    }
    
    // Use dimensions from the image or unless explicitly set in props
    if (props.width || props.height) {
        if (debug) {
            console.log(`props width | height specificied ${props.width} ${props.height}`)
        }
        imageWidth = props.width
        imageHeight = props.height
    }

    // If asset is null return with an empty container of the specified size.
    if (asset == null) {
        const style = {
            width: imageWidth + 'px',
            height: imageHeight + 'px'
        }
        return <div style={style}>{"Null Asset"}</div>
    }

    // Callback when the image has received its dimensions
    const updateImageDimensions = () => {

        var width = 0
        var height = 0

        // Use dimensions from the crop if there's a crop but not in cropping mode
        if (asset.crop && props.isCropping != true) {
            width = asset.crop.width
            height = asset.crop.height
        
        } else if (asset.dimensions) {
            
            width = asset.dimensions.width
            height = asset.dimensions.height

        // Otherwise use natural dimensions of image
        } else {
            width  = imageObject.current.width
            height = imageObject.current.height
    
            if (debug) {
                console.log(`${asset.title} - updateImageDimensions ${width} ${height}`)
            }
    
        }

        if (imageWidth == null && width > 0) {
            setImageWidth(width)
        }

        if (imageHeight == null && height > 0) {
            setImageHeight(height)
        }

        // Fire call back if provided
        if (props.onDimensions) {
            props.onDimensions({ width: width, height: height })
        }
    }

    const updateContainerDimensions = () => {
        let e = containerRef.current
        if (containerRef && e) {
            let w = e.offsetWidth
            let h = e.offsetHeight
            if (debug) {
                console.log(`updated container dimensions ${w} ${h}`)
            }
            setContainerDimensions({ width: w, height: h })
        }
    }

    // Get container dimensions (if needed by crop).
    React.useEffect(() => {        
        updateImageDimensions()
        updateContainerDimensions()
    }, [asset.src, asset.crop, props.id, containerRef])

    // Get images dimensions on load
    React.useEffect(() => {

        if (imageObject.current.src && (imageObject.current.src != asset.src)) {
            setLoaded(false)
            imageObject.current.loaded = false
            setImageWidth(null)
            setImageHeight(null)
        }

        if (props.width != null && props.height != null && props.onDimensions == null) {
            if (debug) {
                console.log(`${asset.title} has size - ` + asset.title + props.width + ' ' + props.height)
            }

            setLoaded(true)
            imageObject.current.loaded = true
            return
        }

        if (asset && asset.dimensions) {
            if (debug) {
                console.log(`${asset.title} - has dimensions - ${asset.dimensions.width} ${asset.dimensions.height}`)
            }
            updateImageDimensions()
            imageObject.current.loaded = true
            setLoaded(true)
            return
        }

        imageObject.current.loaded = false
        imageObject.current.onload = () => {

            setLoaded(true)

            if (debug) {
                console.log(`${asset.title} - loaded`)
            }

            if (!imageObject.current.loaded) {
                if (debug) {
                    console.log(`${asset.title} - updateImageDimensions `)
                }

                imageObject.current.loaded = true
                updateImageDimensions()
            }
        }

        if (asset.base64 != null) {
            var img = "url('" + asset.base64 + "')";
            if (imageObject.current.src != asset.base64) {
                imageObject.current.src = asset.base64
                updateImageDimensions()
            }
        } else {
            imageObject.current.src = asset.src
        }
    }, [asset.src, props.id])

    if (debug) {
        console.log(`${asset.title} scale ${scale} imageWith ${imageWidth} imageHeight ${imageHeight}`)
    }

    const widthPercent = imageWidth != null && String(imageWidth).indexOf('%') > 0
    const heightPercent = imageHeight != null && String(imageHeight).indexOf('%') > 0

    // Determine width and height 
    var displayWidth = imageWidth ? imageWidth * scale : null
    var displayHeight = imageHeight ? imageHeight * scale : null

    if (asset && asset.crop) {
        aspect = asset.crop.width / asset.crop.height
    } else if (imageWidth && imageHeight && !widthPercent && !heightPercent) {
        aspect = imageWidth / imageHeight
    }
    
    if ((props.maxHeight != null && displayHeight > (props.maxHeight * scale)) || (props.width == null && props.maxHeight != null)) {
        displayHeight = props.maxHeight * scale
        displayWidth = displayHeight * aspect
        if (debug) { console.log(`set - max height ${props.maxHeight} ${displayHeight}`) }
    }

    if (debug) { console.log(`props.maxMeight [${props.maxHeight}] displayHeight [${displayHeight}]`) }

    if (props.variableWidth == true) {
        displayWidth = displayHeight * aspect
        if (debug) {
            console.log('props.variableWidth == true')
        }
    }

    if (props.maxWidth && displayWidth > (props.maxWidth * scale)) {
        displayWidth = props.maxWidth * scale
        displayHeight = displayWidth * (1 / aspect)
    }

    React.useEffect( () => {
        if (props.onDisplayDimensions) {
            props.onDisplayDimensions({ width: displayWidth, height: displayHeight })
        }
    }, [displayWidth, displayHeight])

    var style = {
        width:  widthPercent  ? imageWidth : displayWidth + 'px',
        height: heightPercent ? imageHeight : displayHeight + 'px'
    }

    // If content fit size based on aspect ratio
    var cssAspectRatio = null
    var imageStyleDimensions = {}
    if (props.contentMode == AssetImageContentModeType.Fit && imageWidth == "100%" && imageHeight == "100%") {
        cssAspectRatio = aspect
        if (cssAspectRatio < 1) {
            style['width'] = null
            style['height'] = '100%'
            imageStyleDimensions['width'] = 'min-content'
            imageStyleDimensions['height'] = '100%'
        } else {
            style['width'] = '100%'
            style['height'] =  "-webkit-fill-available"            
            imageStyleDimensions['width'] = '100%'
            imageStyleDimensions['height'] =  "min-content"
        }
        style['display'] = 'flex'
        style['alignItems'] = 'center'
        style['justifyContent'] = 'center'
    }
    

    // Apply border
    if (props.borderRadius) {
        style['borderRadius'] = props.borderRadius
    }

    var isVideo = asset.isVideo == true

    // Return if empty
    if (!isVideo && (imageWidth == null && imageHeight == null)) {
        if (debug) { console.log('- returning empty, no imageWidth && imageHeight') }
        return <div style={style}></div>
    }

    // Apply Filters
    style['filter'] = applyFilters(asset)

    // Setup class name
    var classes = [props.className, "asset-image"]
    if (loaded) {
        classes.push("loaded")
    }
    const className = classes.join(' ')

    // Setup content
    var assetContent = null

    // Video
    if (isVideo) {
        const videoStyle = {
            width: '100%',
            height: '100%'
        }

        var attrs = {
            'autoPlay' : false
        }
        if (props.allowPlayback) {
            attrs['controls'] = true
        }

        assetContent = (
            <div className={className} key={"asset" + props.assetVersion} style={style}>
                <video src={asset.src} style={videoStyle} {...attrs} />
            </div>  
        )

    // Image
    } else {

        var imageContent = null
        var wantsContainerSize = false

        // <AssetImageCropped>
        // If there's a crop display the image using <canvas>
        if (asset.crop) {
            const crop = asset.crop
            if (debug) {
                console.log(`- asset has crop ${asset.title} (canvas)`);
                console.log(crop)
            }

            var imageContainerDimensions = containerDimensions ?? { width: null, height: null}

            if (debug) {
                console.log(`- container dimensions ${imageContainerDimensions.width} ${imageContainerDimensions.height}`)
            }

            var w = widthPercent  ? imageContainerDimensions.width : displayWidth
            var h = heightPercent ? imageContainerDimensions.height : displayHeight

            // If dimension is specified in percentage need to determine container size.
            if (widthPercent || heightPercent) {
                wantsContainerSize = true
            }

            if (h == null || h == 0 ||  props.contentMode == AssetImageContentModeType.Fit) {
                const aspect = (crop.height / crop.width);
                if (debug) { console.log(` -- deriving height from aspect ${aspect}`) }
                if (w == 0 && h > 0 || h > w) {
                    w = h * (1/aspect)
                } else {
                    h = w * aspect
                }
            }

            if (debug) {
                console.log(`- w ${w} h ${h}`)
            }
                
            if (w && h) {
                imageContent = <AssetImageCropped
                                    key={"asset" + props.assetVersion} 
                                    title={asset.title} 
                                    alt={asset.description ?? asset.title} 
                                    width={w} 
                                    height={h} 
                                    crop={crop} 
                                    src={asset.src}
                                    className={"asset-image-content"}
                                    contentMode={props.contentMode}
                                    style={{'borderRadius' : props.borderRadius}}/>

                style = {
                    ...style,
                    width: w + 'px',
                    height: h + 'px'
                }
            }

            if (debug) {
                console.log(`- image h ${imageHeight} w ${imageWidth}`)
            }

        // <img tag>
        // Otherwise use basic img tag
        } else {
            if (debug) {
                console.log('- asset has no crop (img)')
            }

            if (asset.base64 != null) {
                var img = "url('" + asset.base64 + "')";
                style['backgroundImage'] = img
                style['backgroundSize'] = 'cover'
                style['backgroundPosition'] = 'center'
                style['backgroundRepeat'] = 'no-repeat'                                
            } 
    
            const objectFit = props.contentMode == AssetImageContentModeType.Fit ? "contain" : "cover"

            const imgStyle = {
                'objectFit': objectFit,
                'objectPosition': 'center',
                'width': style.width,
                'height': style.height,
                'borderRadius' : props.borderRadius,
                'top': '0px',
                'left': '0px',
                'aspectRatio' : cssAspectRatio,
                ...imageStyleDimensions
            }

            imageContent = <img alt={asset.description ?? asset.title} 
                                title={asset.title} 
                                key={"asset" + props.assetVersion} 
                                src={asset.src} 
                                className={"asset-image-content"}
                                style={imgStyle} />
        }

    
        assetContent = (
            <div ref={wantsContainerSize ? containerRef : null} className={className} key={"asset" + props.assetVersion} style={style}>
                {imageContent}
            </div>
        )
    }

    if (props.background == true) {

        // Setup background
        const backgroundStyle = {
            borderRadius: props.borderRadius,
            backgroundImage: 'url(' + (props.dark == true ? backgroundImageDark : backgroundImageLight) + ')',
            backgroundRepeat: 'repeat',
            backgroundSize: (16 * scale) + 'px ' + (16 * scale) + 'px'
        }

        return <div className="asset-background" style={backgroundStyle}>{assetContent}</div>
    } else {
        return assetContent
    }
}

function applyFilters(asset) {
    // Filters
    var filters = []
    if (asset.saturation != null) {
        let v = (asset.saturation * 100) + 100
        filters.push('saturate(' + v + '%)')
    }

    if (asset.contrast != null) {
        let v = (asset.contrast * 100) + 100
        filters.push('contrast(' + v + '%)')
    }
    return filters.join(' ')
}

export default AssetImage