import React from 'react';
import { Section, Stack, VStack, HStack, Text, ActionButton, Image } from '@yapstudios/yap-content-builder';
import { InspectorHeader , InspectorContent, InspectorProperty, InspectorControlText, InspectorControlButton, InspectorControlDate, useInspectorState }  from '@yapstudios/yap-content-builder'
import { View } from '@yapstudios/yap-content-builder';

export default class AppearanceDetentComponent {
    static type = 'appearance-detent';

    constructor() {
        this.type = 'appearance-detent';
    }

    defaultProps() {
        return {
            description: 'Show'
        };
    }

    titlesForProp() {
        return {

        };
    }

    decode(props) {
        return (
            <Section padding="5">
                <div style={{"borderBottom": "1px dashed #e3e3e3", paddingBottom: "6px"}}>
                <View width={"100%"} model={props} inspector={(model, updateModel) => {
                    return this.inspector(model, updateModel)
                }}>
                    <Text readonly={true} style={"caption"} align={"center"} content={props.description ?? "Show"}></Text>
                </View>        
                </div>
            </Section>
        );
    }

    inspector(model, updateModel) {
        
        return (
            <InspectorContent key={"appearance-detent"}>
                <InspectorHeader title={"Apperance Detent"} />
                <Stack spacing={20} align={"leading"} width={"infinity"}>
                    <InspectorProperty key={"description"} property={'description'} singleColumn={true} title={"Description"}>
                        <InspectorControlText value={model.description} onChange={v => {
                            model.description = v
                            updateModel(model)
                        }} />
                    </InspectorProperty>
                </Stack>
            </InspectorContent>
        )
    }

    encode(model, props) {
        // From inspector
        props = {...model}
        return props
    }

    validations() {
        return [
            
        ];
    }

    icon() {
        return (
<svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="100" height="70" fill="white"/>
<rect x="5.60626" y="50.6287" width="88.6821" height="13.3743" rx="4" fill="#EDEFF9"/>
<path d="M50.041 46.8808C49.7659 46.8808 49.5437 46.7919 49.3745 46.6142C49.2052 46.4407 49.1205 46.21 49.1205 45.9223V38.8827L49.2158 36.496L49.7426 36.8515L47.9018 38.9779L46.4736 40.3998C46.3889 40.4845 46.2916 40.5543 46.1816 40.6093C46.0758 40.6601 45.9552 40.6855 45.8198 40.6855C45.5701 40.6855 45.3606 40.6029 45.1913 40.4379C45.0263 40.2729 44.9438 40.0592 44.9438 39.7968C44.9438 39.5513 45.039 39.3313 45.2294 39.1366L49.3618 34.998C49.4464 34.9133 49.548 34.8477 49.6664 34.8012C49.7892 34.7504 49.914 34.725 50.041 34.725C50.1637 34.725 50.2864 34.7504 50.4091 34.8012C50.5318 34.8477 50.6355 34.9133 50.7202 34.998L54.8525 39.1366C55.0429 39.3313 55.1381 39.5513 55.1381 39.7968C55.1381 40.0592 55.0535 40.2729 54.8842 40.4379C54.7192 40.6029 54.5118 40.6855 54.2621 40.6855C54.1267 40.6855 54.004 40.6601 53.894 40.6093C53.784 40.5543 53.6887 40.4845 53.6083 40.3998L52.1738 38.9779L50.3329 36.8515L50.8598 36.496L50.955 38.8827V45.9223C50.955 46.21 50.8704 46.4407 50.7011 46.6142C50.5318 46.7919 50.3118 46.8808 50.041 46.8808Z" fill="#B7BEE8"/>
</svg>


        )
    }
}
