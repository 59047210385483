import React from 'react';
import { InspectorProperty } from '../inspector-ui/InspectorProperty.js';
import { InspectorHeader } from '../inspector-ui/InspectorHeader.js';

/**
 * Built in inspector for Spacer
 * @param {} props 
 * @returns 
 */
function InspectorSpacer(props) {
    var model = props.model;

    return <div>
        <section>
            <InspectorHeader title="Spacer" />
            <InspectorProperty title="Height">
                {model.height}
            </InspectorProperty>
        </section>
    </div>;
}

export { InspectorSpacer }