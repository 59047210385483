import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import * as React from 'react';
import { useSection } from '../SectionContext.js';
import useUniqueId from '../useUniqueId.js';
import { processStyle } from '../Page.js';
import { View } from './View.js';

function DateText(props) {
    const { fonts, styles, setSelected, selectedId, updateModel, renderMarkdown, setInspectorModel } = useAppState();
    const { sectionId, sectionStyle, sectionEncode } = useSection();

    const id = useUniqueId(props.id);

    var style = processStyle({ props: props, fonts: fonts, sectionStyle: sectionStyle });

    const viewProps = {
        fonts: fonts,
        style: style,
        styles: styles,
    };

    const model = {
        type: 'date',
        componentTitle: props.componentTitle,
        validationProperty: props.validationProperty,
        title: props.title,
        align: props.align,
        style: props.style,
        fonts: fonts,
        id: id,
        date: props.date,
        sectionId: sectionId,
        sectionEncode: sectionEncode,
        canEditStyle: false
    };

    const onClick = () => {
    };

    var finalModel = props.inspectorModel ? props.inspectorModel : model;
    finalModel.validationProperty = props.validationProperty;

    var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var dateString =  (props.date ?? new Date()).toLocaleDateString("en-US", dateOptions);

    if (props.readonly) {
        return (<span className="view-text" style={style}>{dateString}</span>);
    } else {
        return (
            <View {...viewProps} id={id} model={finalModel} onClick={onClick}>
                <span className="view-text" style={style}>{dateString}</span>
            </View>
        );
    }
}

export { DateText }