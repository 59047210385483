
/* eslint-disable */

import IconHeaderComponent from './Components/IconHeaderComponent.js'
import QuizComponent from './Components/QuizComponent.js'
import TextComponent from './Components/TextComponent.js'
import GalleryComponent from './Components/GalleryComponent.js'
import ImageComponent from './Components/ImageComponent.js'
import VideoComponent from './Components/VideoComponent.js'
import LearnActivityComponent from './Components/LearnActivityComponent.js'

const componentList = [
    new IconHeaderComponent(),
    new TextComponent(),
    new GalleryComponent(),
    new QuizComponent(),
    new ImageComponent(),
    new VideoComponent(),
    new LearnActivityComponent()
]


const componentTemplates = [
    {
        title: 'Text',
        columns: 2,
        components: [
            { template: new TextComponent("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"), title: "Paragraph" },
            { template: new TextComponent("Section Header", 'h2'), title: "Section Header" },
            { template: new IconHeaderComponent(), title: "Large Header" },
        ]
    },

    {
        title: 'Media',
        columns: 2,
        icon: 'gallery',
        components: [
            { template: new ImageComponent(), title: "Image" },
            { template: new GalleryComponent(), title: "Gallery" },
        ]
    },


    {
        title: 'Info',
        icon: 'facts',
        columns: 2,
        components: [
            { template: new LearnActivityComponent(), title: "Learn Activity" },
        ]
    },

    {
        title: 'Interactive',
        icon: 'quiz',
        columns: 2,
        components: [
            { template: new QuizComponent(),  title: "Quiz Question" },
            {}
        ]
    },


]

export { componentList, 
         componentTemplates, 

         GalleryComponent, 
         TextComponent, 
         ImageComponent, 
         VideoComponent,
         IconHeaderComponent,
         LearnActivityComponent,
         QuizComponent }