
/* eslint-disable */
import HeaderComponent from './Components/HeaderComponent.js'
import ActionComponent from './Components/ActionComponent.js'
import TextComponent from './Components/TextComponent.js'
import GalleryComponent from './Components/GalleryComponent.js'
import LearnMoreComponent from './Components/LearnMoreComponent.js'
import ImageComponent from './Components/ImageComponent.js'
import VideoComponent from './Components/VideoComponent.js'
import ModelPageComponent from './Components/ModelPageComponent.js'
import ModelHeaderComponent from './Components/ModelHeaderComponent.js'
import InfoTableComponent from './Components/InfoTableComponent.js'
import BuyButtonComponent from './Components/BuyButtonComponent.js'
import AppearanceDetentComponent from './Components/AppearanceDetentComponent.js'
import SpacerComponent from './Components/SpacerComponent.js'
import LargeHeaderComponent from './Components/LargeHeaderComponent.js'
import NumberComponent from './Components/NumberComponent.js'
import InfoCardComponent from './Components/InfoCardComponent.js'
import ProductGalleryComponent from './Components/ProductGalleryComponent.js'
import LearnContentComponent from './Components/LearnContentComponent.js'
const componentList = [
    new HeaderComponent(),
    new TextComponent(),
    new ActionComponent(),
    new GalleryComponent(),
    new VideoComponent(),
    new ImageComponent(),
    new ModelPageComponent(),
    new ModelHeaderComponent(),
    new InfoTableComponent(),
    new BuyButtonComponent(),
    new AppearanceDetentComponent(),
    new SpacerComponent(),
    new LargeHeaderComponent(),
    new NumberComponent(),
    new InfoCardComponent(),
    new ProductGalleryComponent(),
    new LearnContentComponent()
]

const componentTemplates = [
    {
        title: 'Text',
        columns: 2,
        components: [
            { template: new TextComponent("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"), title: "Paragraph" },
            { template: new TextComponent("Section Header", 'h2'), title: "Section Header" },
            { template: new LargeHeaderComponent(), title: "Large Header" },
            { template: new InfoTableComponent(), title: "Info Table" },
            { template: new NumberComponent(), title: "Number Paragraph" },           
            { template: new InfoCardComponent(), title: "Info Card"} ,
        ]
    },

    {
        title: 'Product',
        columns: 2, 
        components: [
            { template: new ModelPageComponent(), title: "Model Page" },
            { template: new ModelHeaderComponent(), title: "Model Header" },
            { template: new BuyButtonComponent(), title: "Product Button" },
            { template: new ProductGalleryComponent(), title: "Product Gallery" },
            // { template: new LearnContentComponent(), title: "Learn" }
        ]
    },

    {
        title: 'Media',
        columns: 2,
        icon: 'gallery',
        components: [
            { template: new GalleryComponent(), title: "Gallery" },
            { template: new ImageComponent(), title: "Image" },
            { template: new VideoComponent(), title: "Video" },
        ]
    },


    {
        title: 'Layout',
        columns: 2,
        components: [
            { template: new SpacerComponent(), title: "Spacer"},
            { template: new AppearanceDetentComponent(), title: "Show Button" },
        ]
    },


]


export {
    componentList,
    componentTemplates,
    ModelPageComponent,
    ModelHeaderComponent, 
    HeaderComponent, 
    GalleryComponent, 
    TextComponent, 
    LearnMoreComponent, 
    ImageComponent, 
    ActionComponent, 
    InfoTableComponent,
    BuyButtonComponent,
    LargeHeaderComponent,
    NumberComponent,
    VideoComponent,
    SpacerComponent
}