import { componentList as messsageComponentList, componentTemplates as messageComponentTemplates } from "./Instances/Message/Components.js"
import { validations as messageValidations } from "./Instances/Message/Validations.js"
import { templateLibrary as messageTemplateLibrary } from "./Instances/Message/Templates.js"
import { fonts as messageFonts , styles as messageStyles } from "./Instances/Message/StyleData.js"


import { componentList as retailComponentList, componentTemplates as retailComponentTemplates } from "./Instances/Retail/Components.js"
import { templateLibrary as retailTemplateLibrary } from "./Instances/Retail/Templates.js"
import { fonts as retailFonts, styles as retailStyles } from "./Instances/Retail/StyleData.js"

import { componentList as learningComponentList, componentTemplates as learningComponentTemplates } from "./Instances/Learning/Components.js"
import { templateLibrary as learningTemplateLibrary } from "./Instances/Learning/Templates.js"
import { fonts as learningFonts, styles as learningStyles } from "./Instances/Learning/StyleData.js"


import { componentList as athenaComponentList, componentTemplates as athenaComponentTemplates } from "./Instances/Athena/Components.js"
import { templateLibrary as athenaTemplateLibrary } from "./Instances/Athena/Templates.js"
import { fonts as athenaFonts, styles as athenaStyles } from "./Instances/Athena/StyleData.js"
import { AthenaPageRenderer, AthenaPreviewRenderer } from "./Instances/Athena/Render/AthenaPageRenderer.js"
const instanceMap =  {
    
    'message' : { 
        components: messsageComponentList,
        componentTemplates: messageComponentTemplates,
        validations: messageValidations,
        templateLibrary:  messageTemplateLibrary,
        fonts: messageFonts,
        pageRenderer: null,
        styles: messageStyles
    },

    'retail' : { 
        components: retailComponentList,
        componentTemplates: retailComponentTemplates,
        validations: null,
        templateLibrary: retailTemplateLibrary,
        fonts: retailFonts,
        pageRenderer: null,
        styles: retailStyles
    },

    'learning': {
        components: learningComponentList,
        componentTemplates: learningComponentTemplates,
        validations: null,
        templateLibrary: learningTemplateLibrary,
        fonts: learningFonts,
        pageRenderer: null,
        styles: learningStyles        
    },

    'athena' : {
        components: athenaComponentList,
        componentTemplates: athenaComponentTemplates,
        validations: null,
        templateLibrary: athenaTemplateLibrary,
        fonts: athenaFonts,
        styles: athenaStyles,
        pageRenderer: new AthenaPageRenderer(),
        multiplePages: true,

        previewRenderer: new AthenaPreviewRenderer({ preview: 'main', width: 1024 }),

        indexPreviewRenderer: new AthenaPreviewRenderer({ preview: 'index', width: 256 }),
        indexPreviewClassName: "content-index-preview-item-athena",

        pageTitle: (page, index, pages) => {
            return 'Question ' + (index + 1)
        },
        canInsertComponent: (component, props) => {
            if (component == null) {
                return (props.group == 'right') ? false : true
            } else {
                if (props.group == 'right' && component.type == 'answer') {
                    return true
                } else if (props.group == 'left' && component.type == 'content') {
                    return true
                } else {
                    return false
                }
            }
        }     
    }
   
}

const getConfiguration = (instanceId) => {
    console.log(`getConfiguration: instance id ${instanceId}`)
    return instanceMap[instanceId ?? 'message']
}

export { getConfiguration }
