var colors = {
    background: '#ffffff',
    headerBackground : '#35363B',
    primary: '#000000',
    secondary: '#00000055',
    separator: '#00000022',
    accent: '#E7C500'
}

var blueColors = {
    background: '#ffffff',
    headerBackground : '#f3f3f3',
    primary: '#000000',
    secondary: '#00000055',
    separator: '#00000022',
    placeholder: '#ECECEC',
    accent: '#324DC7'
}

var darkColors = {
    background: '#222222',
    primary: '#ffffff',
    secondary: '#ffffff55',
    separator: '#ffffff22',
    accent: '#324DC7'
}

var sharedFonts = { 
    h1   : { userSelectable: true, name: 'Heading 1', size: 28, weight: 'bold', color: "primary" },
    h2   : { userSelectable: true, name: 'Heading 2', size: 18,  weight: 'bold',color: "primary"},
    h3   : { userSelectable: true, name: 'Heading 3', size: 16, weight: 'bold', color: "primary" },
    body : { userSelectable: true, name: 'Body', size: 16, lineHeight: 1.5, color: "primary" },

    largeTitleHeader : { name: 'Large Title Header', size: 34, weight: 'bold', color: "primary", fontStyle: 'regular' },
    titleHeader : { name: 'Title Header', size: 34, weight: 'bold', color: "primary", fontStyle: 'regular' },
    productTitleHeader : { name: 'Heading 2', size: 22,  weight: 'bold',color: "primary"},
    cardTitleHeader : { name: 'Heading 2', size: 24,  weight: '600',color: "primary"},
    summaryText : { name: 'Summary Text', size: 15,  weight: '600',color: "primary"},
    subtitle : { name: 'Subtitle', size: 14, color: "secondary" },
    headline : { name: 'Headline', size: 16, color: "secondary",weight: 'bold' },
    headline2 : { name: 'Headline 2', size: 16, color: "secondary",weight: 'bold' },
    quote : { name: 'Quote', size: 28, weight: 'bold', color: "primary", fontFamily: '-apple-system-ui-serif, ui-serif', fontStyle: 'italic' },
    subhead: { name: 'Subhead', size: 15, weight: 'regular', color: "primary"},
    caption: { name: 'Caption', size: 12, weight: 'regular', color: "secondary"},
    captionUppercased: { name: 'Caption (Uppercase)', size: 12, weight: 'bold', color: "secondary", textTransform: "uppercase"},
    footnote: { name: 'Footnote', size: 13, weight: 'regular', color: "secondary" },
}

var productFonts = {
    productHeaderCaption: { sename: 'Caption (Uppercase)', size: 16, weight: 'bold', align: "left",  color: "accent", textTransform: "uppercase"},
    productInfoTitle: { name: 'Product Info Title', size: 27, weight: 'bold', color: "accent", fontVariation: "\"wdth\" 130"},
    productHeader: { name: 'Product Header', size: 44, weight: 'bold', color: "white", align: "left", fontVariation: "\"wdth\" 130"},
    productHeaderSubtitle: { name: 'Product Header Subtitle', size: 44, weight: 'bold', align: "left",  color: "transparent", outline: "white", fontVariation: "\"wdth\" 130"},
}

var blueProductFonts = {
    productHeaderCaption: { name: 'Caption (Uppercase)', size: 16, weight: 'bold', color: "accent", textTransform: "uppercase",  align: "center" },
    productInfoTitle: { name: 'Product Info Title', size: 27, weight: 'bold', color: "white" },
    productInfoDescription: { name: 'Product Info Description', size: 18, weight: 'regular', color: "#ffffff90" },
    productHeader: { name: 'Product Header', size: 44, weight: 'bold', color: "primary", align: "center" },
    productHeaderSubtitle: { name: 'Product Header Subtitle', size: 44, weight: 'bold', color: "#aaaaaa",  align: "center" },
}

var fonts = {
    ...sharedFonts,
    ...productFonts
}

var styles = {
    'default': { name: 'Yellow Brand', colors: colors },
    'blue': { name: 'Blue Brand', colors: blueColors, fonts: { ...sharedFonts, ...blueProductFonts } },
}


export {  fonts, colors, styles }