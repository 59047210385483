import * as React from 'react'
import { useIcons } from "../content-builder-components/ContentBuilderIconProvider.js"

function ValidationIcon({ issue, errorState }) {
    let icons = useIcons()

    if (issue.valid) {
        return icons.get('validation.checkmark', 'color-validation-default');
    } else {
        switch (issue.type) {
            case 'requirement':
                return errorState ? icons.get('validation.asterisk', 'color-error') : icons.get('validation.asterisk', 'color-validation-default');
            case 'error':
                return icons.get('validation.error', 'color-error');
            case 'warning':
                return icons.get('validation.warning', 'color-warning');
            case 'info':
                return icons.get('validation.info', 'color-validation-default');
        }
    }
}

export default ValidationIcon