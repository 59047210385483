import React from 'react';
import { Section, Box, Stack, Spacer, VStack, ZStack, HStack, Text, ActionButton, Image } from '@yapstudios/yap-content-builder';
import { InspectorModelWell, InspectorHeader , InspectorContent, InspectorControlSlider, InspectorProperty, InspectorControlText, InspectorControlSelect, InspectorControlButton, InspectorControlDate, useInspectorState }  from '@yapstudios/yap-content-builder'
import { View } from '@yapstudios/yap-content-builder';
//import modelViewer from '@google/model-viewer'

export default class ModelPageComponent {
    static type = 'model-page'

    constructor(products) {
        this.type = ModelPageComponent.type;
        this.url = "http://127.0.0.1:3000/drill.glb"
    }

    defaultProps() {
        return {
            url: this.url,
            rotX: 0,
            rotY: 90,
            zoom: 100,
            translateX: 0,
            translateY: 0,
            translateZ: 0,
            animationTime: 0,
            title: "Title",
            style: 'light',
            pageType: 'info-panel',
            animations: { },
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        };
    }

    titlesForProp() {
        return {
            price: 'Price',
        };
    }

    decode(props) {

        const containerStyle = { "overflow": "scroll", width: '-webkit-fill-available', paddingTop: '0px' };
        
        let height = 800

        console.log(props.asset)

        const objectRef = { }

        let model = <ModelObject themeStyle={props.style} 
                                 height={height} 
                                 url={props.asset ? props.asset.src : null} 
                                 rotX={props.rotX} 
                                 rotY={props.rotY} 
                                 translateX={props.translateX} 
                                 translateY={props.translateY} 
                                 translateZ={props.translateZ} 
                                 zoom={props.zoom}
                                 objectRef={objectRef}
                                 animations={props.animations}/>

        const infoPanel = (
            <div className="model-info-panel">
                <Box paddingRight={60} paddingBottom={20}>
                    <Text canEditStyle={false} readonly={false} id="title" style={"productInfoTitle"} content={props.title}></Text>
                </Box>
                <Text canEditStyle={false} readonly={false} id="description"  style={"productInfoDescription"} content={props.description}></Text>
            </div>
        )

        return (
            <Section padding="0" paddingBottom="0" id="section-header"  title="Header">
                <View width={"infinity"}model={props} inspector={(model, updateModel) => {
                    return this.inspector(model, updateModel, objectRef)
                }}>
                    <ZStack width={"infinity"} height={height} align={"trailing"}>
                        {model}
                        {props.pageType != 'model-only' && infoPanel}
                    </ZStack>
                </View>        
            </Section>
            
        );
    }

    inspector(model, updateModel, objectRef) {

        const groupStyle = {"display" : 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '5px'}
        const styleOptions = { 'light' : 'Light ', 'dark' : 'Dark' }

        // Find any animations on the model and present them in the inspector
        var availableAnimations = []
        if (objectRef && objectRef.current) {
            availableAnimations = objectRef.current.availableAnimations
        }
        const modelAnimations = model.animations ?? { }
        const hasAnimations = availableAnimations.length > 0

        const animationOptions = availableAnimations.map( (animationKey) => {
            return (
                <InspectorProperty  title={animationKey}>
                    <InspectorControlSlider width={90} value={modelAnimations[animationKey] ?? 0.0} step={0.01} min={0.0} max={1.0} onChange={v => {
                        modelAnimations[animationKey] = v
                        model.animations = modelAnimations
                        updateModel(model)
                    }}/>

                </InspectorProperty> 
            )
        })

        const infoPanelProps = ([
            <InspectorProperty  title={"Title"}>
                <InspectorControlText value={model.title} onChange={v => {
                        model.title = v
                        updateModel(model)
                    }} /> 
            </InspectorProperty> ,

            <InspectorProperty title={"Description"}>
                <InspectorControlText  value={model.description} onChange={v => {
                        model.description = v
                        updateModel(model)
                    }} /> 
            </InspectorProperty>                     
        ])

        const editingControls = [

                <InspectorProperty title={"Rotate X"}>
                    <div style={groupStyle}>
                        <InspectorControlSlider width={90} value={model.rotX} min={-360} max={360} onChange={v => {
                            model.rotX = v
                            updateModel(model)
                        }}/>
                        <InspectorControlText width={24} value={model.rotX} onChange={v => {
                                model.rotX = v
                                updateModel(model)
                            }} /> 
                    </div>
                </InspectorProperty> ,             

                <InspectorProperty title={"Rotate Y"}>
                    <div style={groupStyle}>
                        <InspectorControlSlider width={90} value={model.rotY} min={0} max={360} onChange={v => {
                            model.rotY = v
                            updateModel(model)
                        }}/>
                        <InspectorControlText width={24} value={model.rotY} onChange={v => {
                                model.rotY = v
                                updateModel(model)
                            }} /> 
                    </div>                    
                </InspectorProperty> ,     

                <InspectorProperty title={"Zoom"}>
                    <div style={groupStyle}>
                        <InspectorControlSlider width={90} value={100 - model.zoom} min={0} max={100} onChange={v => {
                            model.zoom = 100 - v
                            updateModel(model)
                        }}/>
                        <InspectorControlText width={24} value={100 - model.zoom} onChange={v => {
                                model.zoom = 100 - v
                                updateModel(model)
                            }} /> 
                    </div>      
                </InspectorProperty>,      

               <InspectorProperty divider={true} title={"Position X"}>
                    <div style={groupStyle}>
                        <InspectorControlSlider width={90} value={model.translateX} min={-10} max={10} onChange={v => {
                            model.translateX = v
                            updateModel(model)
                        }}/>
                        <InspectorControlText width={24} value={model.translateX} onChange={v => {
                                model.translateX = v
                                updateModel(model)
                            }} /> 
                    </div> 
                </InspectorProperty> ,             

                <InspectorProperty title={"Position Y"}>
                    <div style={groupStyle}>
                        <InspectorControlSlider width={90} value={model.translateY} min={-10} max={10} onChange={v => {
                            model.translateY = v
                            updateModel(model)
                        }}/>
                        <InspectorControlText width={24} value={model.translateY} onChange={v => {
                                model.translateY = v
                                updateModel(model)
                            }} /> 
                    </div> 
                </InspectorProperty> ,     

                <InspectorProperty title={"Position Z"}>
                    <div style={groupStyle}>
                        <InspectorControlSlider width={90} value={model.translateZ} min={-10} max={10} onChange={v => {
                            model.translateZ = v
                            updateModel(model)
                        }}/>
                        <InspectorControlText width={24} value={model.translateZ} onChange={v => {
                                model.translateZ = v
                                updateModel(model)
                            }} /> 
                    </div> 
                </InspectorProperty>      
        ]


        return (
            <InspectorContent key={"header-inspector"}>
                <InspectorHeader title={"Page Info"} />      

                <InspectorProperty property={'pageType'} title={"Type"}>
                    <InspectorControlSelect value={model.pageType} options={['Info', 'Info Alt', 'Model Only']} keys={['info-panel', 'info-panel-alt', 'model-only']} onChange={v => {
                        updateModel({ ...model, 
                                    pageType: v})
                    }} />
                </InspectorProperty>

                {model.pageType != 'model-only' && infoPanelProps}
            
                <InspectorHeader  divider={true}  title={"Model"} />    

                <InspectorProperty singleColumn={true}>
                    <InspectorModelWell  src={model.asset ? model.asset.src : null} title={model.asset ? model.asset.title : null} onFileSelected={asset => {
                            console.log('file selected')
                            console.log(asset)
                            model.asset = asset
                            updateModel(model)
                        }} /> 
                </InspectorProperty>   

                <InspectorHeader  divider={true}  title={"Appearance"} />    

                <InspectorProperty title={"Style"} >
                    <InspectorControlSelect value={model.style} options={Object.values(styleOptions)} keys={Object.keys(styleOptions)} onChange={(v) => {
                        model.style = v
                        updateModel(model)
                    }} />                    
                </InspectorProperty>     

                {model.asset && <InspectorHeader  divider={true} title={"Model"} />}
                {model.asset && editingControls}

                {hasAnimations && <InspectorHeader  divider={true} title={"Animations"} />}
                {animationOptions}

            </InspectorContent>
        )
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="70" fill="white"/>
                <path d="M8.98163 23.7517C8.53046 23.4998 8.19647 23.2126 7.97968 22.8904C7.76288 22.5623 7.65448 22.0935 7.65448 21.4841V14.1365C7.65448 13.6443 7.75116 13.2312 7.94452 12.8972C8.14374 12.5632 8.46014 12.282 8.89374 12.0535L14.9933 8.70483C15.5558 8.39429 16.1389 8.23901 16.7424 8.23901C17.3517 8.23901 17.9377 8.39429 18.5002 8.70483L24.5998 12.0535C25.0334 12.282 25.3469 12.5632 25.5402 12.8972C25.7336 13.2312 25.8303 13.6443 25.8303 14.1365V21.4841C25.8303 22.0935 25.7219 22.5623 25.5051 22.8904C25.2941 23.2126 24.9601 23.4998 24.5031 23.7517L17.8937 27.364C17.1262 27.7742 16.3615 27.7742 15.5998 27.364L8.98163 23.7517ZM10.2121 22.0642L15.7141 25.1228V18.4958L9.62323 15.1384V21.1414C9.62323 21.3523 9.66718 21.5281 9.75507 21.6687C9.84296 21.8093 9.9953 21.9412 10.2121 22.0642ZM23.2726 22.0642C23.4894 21.9412 23.6418 21.8093 23.7297 21.6687C23.8176 21.5281 23.8615 21.3523 23.8615 21.1414V15.1384L17.7707 18.4958V25.1228L23.2726 22.0642ZM16.7424 16.6941L22.7453 13.3982L17.6037 10.5681C17.0353 10.2576 16.4641 10.2576 15.8898 10.5681L10.7482 13.3982L16.7424 16.6941Z" fill="#B7BEE8"/>
                <rect x="5.44611" y="34.3016" width="89.23" height="29.8291" rx="5" fill="#384CC0" fillOpacity="0.09"/>
                <rect opacity="0.3" x="10.4077" y="40.2718" width="46.8051" height="6" rx="3" fill="#384CC0"/>
                <rect opacity="0.3" x="10.4077" y="52.2718" width="32.302" height="6" rx="3" fill="#384CC0"/>
            </svg>
        )
    }

    encode(model, props) {
        switch (model.id) {
            // Description title direct entry
            case "title":
                props['title'] = model.content
                break

            // Description text direct entry
            case "description":
                props['description'] = model.content
                break
            
            // From inspector
            default:
                props = {...props,...model}
                break
        }
        return props;
    }

    validations() {
        return [
            //PolymerValidations.fieldRequired('title')
        ];
    }
}

function ModelObject({ url, rotX, rotY, translateX, translateY, translateZ, zoom, height, themeStyle, objectRef }) {
    let style = {
        backgroundColor: 'white',
        width: '-webkit-fill-available'
    }

    const ref = React.useRef(null)

    let w = 375
    let h = height

    let orbit = `${rotX ?? 0}deg ${rotY ?? 0}deg ${zoom}m`

    let unit = (v) => {
        if (v == null) {
            return "auto"
        } else if (v == 0) {
            return "auto"
        } else if (v != 0) {
            return `${v}m`
        } else {
            return v
        }
    }

    if (objectRef) {
        objectRef.current = ref.current
    }

    React.useEffect( () => {
        if (objectRef) {
            objectRef.current = ref.current
        }    
    }, [ref])

    let target = `${unit(translateX)} ${unit(translateY)} ${unit(translateZ)}`

    var background = null//"#F7F7F7"
    if (themeStyle == "dark") {
        background = "#333333"
    }

    return (
        <VStack align={"leading"} spacing={3} ustify={"leading"} width={w} spaceItems={"between"} height={h} padding={0} backgroundColor={background}>
            <model-viewer src={url}
                  iosSrc={url}
                  alt="A drill"
                  camera-orbit={orbit}
                  camera-target={target}
                  shadow-intensity="2"
                  max-camera-orbit={"Infinity 360deg auto"}
                  min-camera-orbit={"-Infinity 0deg auto"}
                  style={{height: `${h}px`, width: `${w}px`}}
                  ref={ref}>
            </model-viewer>
        </VStack>
    )
}
