import * as React from 'react'

import AssetImage from "./AssetImage.js"
import { TableRow, TableRowDescription, TableRowAction, TableValueValid, TableValueSlider } from '../shared-components/TableRow.js';
import { TableSection } from '../shared-components/TableSection.js';
import { useYapPageState } from '../uikit-components/Page.js';
import PolymerHeaderButton from '../uikit-components/HeaderButton.js';
import Scrollable from '../uikit-components/Scrollable.js'
import AssetCataloguePreviews from "./AssetCataloguePreviews.js"
import PolymerProgressBar from "../uikit-components/ProgressBar.js"
import { PageContent } from "../uikit-components/Page.js"
import { validateAssetSize, recommendedAssetSize, validateAspectRatio, AssetValidationDetail, hasValidations } from './AssetCatalogueValidation.js';
import AssetFileChooser from "./AssetFileChooser.js"
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import LargeButton from '../components/LargeButton.js';
import SegmentedControl from '../uikit-components/SegmentedControl.js';

const AssetEditModeType = {
    Details: 1,
    Crop: 2
}

function formatDate(date) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (date && date.getDay) {
        let day = days[date.getDay()];
        let dateNumber = date.getDate();
        let month = months[date.getMonth()];
        let year = date.getFullYear();

        return `${day} ${dateNumber} ${month} ${year}`;
    } else {
        return date
    }
}

function formatBytes(bytes, decimals = 1) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function findTemplateForAssetId(id, templates) {
    for (var i in templates) {
        let template = templates[i]
        for (var j in template.sizes) {
            let assetTemplate = template.sizes[j]
            if (assetTemplate.id == id) {
                return assetTemplate
            }
        }
    }
    return null
}

function assetDetailRows(asset, dimensions) {
    var details = []

    if (asset && asset.crop) {
        let cropIcon = (
            <svg alt="Cropped Image" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3612 11.0484C14.3612 11.2727 14.2808 11.4631 14.12 11.6197C13.9634 11.772 13.7581 11.8482 13.5042 11.8482H3.48764C3.30144 11.8482 3.15333 11.7932 3.0433 11.6831C2.93328 11.5731 2.87827 11.425 2.87827 11.2388V1.2349C2.87827 0.985227 2.96078 0.782102 3.12582 0.625526C3.29086 0.468951 3.48764 0.390663 3.71616 0.390663C3.94467 0.390663 4.14145 0.468951 4.30649 0.625526C4.47153 0.782102 4.55405 0.985227 4.55405 1.2349V10.0708C4.55405 10.1893 4.61118 10.2486 4.72543 10.2486H13.5042C13.7581 10.2486 13.9634 10.3247 14.12 10.4771C14.2808 10.6294 14.3612 10.8199 14.3612 11.0484ZM0.0535583 4.10404C0.0535583 3.87976 0.131846 3.69144 0.288422 3.5391C0.449229 3.38253 0.656586 3.30424 0.910492 3.30424H10.9334C11.1154 3.30424 11.2614 3.36137 11.3714 3.47562C11.4815 3.58565 11.5365 3.73376 11.5365 3.91996V13.9175C11.5365 14.1714 11.4539 14.3745 11.2889 14.5269C11.1239 14.6835 10.9271 14.7618 10.6986 14.7618C10.4701 14.7618 10.2733 14.6835 10.1082 14.5269C9.94321 14.3745 9.86069 14.1714 9.86069 13.9175V5.08158C9.86069 4.96732 9.80356 4.91019 9.6893 4.91019H0.910492C0.656586 4.91019 0.449229 4.83402 0.288422 4.68168C0.131846 4.5251 0.0535583 4.33256 0.0535583 4.10404Z" fill="#767676"/>
            </svg>
        )
        let str = `${asset.crop.width}x${asset.crop.height}`
        details.push(<TableRow key={"cropped-dimensions"} title="Dimensions"><span alt="Cropped Image" title="Cropped Image" className="center-content space-between-8"><span className="badge">{cropIcon}</span> {str}</span></TableRow>)
    } else {
        let dimension = (asset.dimensions && asset.dimensions.width > 0 && asset.dimensions.height > 0) ? asset.dimensions : dimensions
        if (dimension) {
            let d = (asset.dimensions && asset.dimensions.width > 0 && asset.dimensions.height > 0) ? asset.dimensions : dimensions
            let str = `${d.width}x${d.height}`
            details.push(<TableRow key={"dimensions"} title="Dimensions">{str}</TableRow>)
        }
    }

    if (asset.created != null) {
        var date = asset.created.toDate ? asset.created.toDate() : asset.created
        details.push(<TableRow key={"created"} title="Created">{formatDate(date)}</TableRow>)
    }

    if (asset.size != null) {
        details.push(<TableRow key={"title"} title="Size">{formatBytes(asset.size)}</TableRow>)
    }

    if (asset.id != null) {
        details.push(<TableRow last={true} key={"id"} title="ID">{asset.id}</TableRow>)
    }

    return details
}

function scaleCrop(crop, imageDimensions, displayDimensions, fromCropResult) {

    if (!(displayDimensions && imageDimensions)) {
        return null
    }

    if (crop == null) {
        return null
    }

    let cropX      = crop ? crop.x : 0
    let cropY      = crop ? crop.y : 0
    let cropWidth  = crop ? crop.width : imageDimensions.width
    let cropHeight = crop ? crop.height : imageDimensions.height

    if (crop && crop.unit == '%') {
        cropWidth  = displayDimensions.width * (crop.width / 100)
        cropHeight = displayDimensions.height * (crop.height / 100)
    }

    let scaleX = fromCropResult ? imageDimensions.width / displayDimensions.width   : displayDimensions.width / imageDimensions.width
    let scaleY = fromCropResult ? imageDimensions.height / displayDimensions.height : displayDimensions.height / imageDimensions.height

    return {
        x: cropX * scaleX,
        y: cropY * scaleY,
        width: Math.round(cropWidth * scaleX),
        height: Math.round(cropHeight * scaleY),
        unit: 'px'
    }
}

function AssetDetail(props) {
    var { asset, assets, assetTemplates, assetLibrary, onFileSelected, onAssetChange, onAssetLibraryImport, onAssetLibrarySelected, onAssetLibraryDelete, onAssetLibraryChange, onDelete, modalEdit, canEditName, canEditDescription, canAdjustFilters, canCrop, canMultiUpload, cropAspectRatios } = props

    let pageState = useYapPageState()

    const debug = false

    const [editMode, setEditMode] = React.useState(props.editMode)
    const [editModeType, setEditModeType] = React.useState(props.editModeType ?? AssetEditModeType.Details)
    const editingAsset = React.useRef(null)
    const currentAsset = React.useRef(null)

    const [dimensions, setDimensions] = React.useState(asset ? asset.dimensions : null)
    const [displayDimensions, setDisplayDimensions] = React.useState(null)
    const [assetVersion, setAssetVersion] = React.useState(0)

    const [isUploading, setIsUploading] = React.useState(false)
    const [uploadingProgress, setUploadingProgress] = React.useState(0)

    const [crop, setCrop] = React.useState(asset && asset.crop ? asset.crop : null)
    const [cropAspect, setCropAspect] = React.useState(null)

    // Dont update passed hash
    var asset = { ...asset }

    if (currentAsset.current == null) {
        currentAsset.current = asset
    }

    if (editMode && editingAsset.current == null) {
        editingAsset.current = currentAsset.current
    }

    if (editMode && editingAsset.current) {
        asset = editingAsset.current
    }

    const isCroppping = editMode && editModeType == AssetEditModeType.Crop

    const editModeTypes = canCrop == false ? [AssetEditModeType.Details] : [AssetEditModeType.Crop, AssetEditModeType.Details]

    // Scale crop control bounds to current displayed size of image on load
    React.useEffect(() => {
        if (isCroppping) {
            if (displayDimensions && dimensions) {
                if (debug) {
                    console.log('- onload: scaleCrop')
                    console.log(currentAsset.current.crop)
                }

                if (currentAsset.current && currentAsset.current.crop && currentAsset.current.crop.userSet != true && crop.unit == 'px') {
                    setCrop(scaleCrop(currentAsset.current.crop, dimensions, displayDimensions, false))
                } else if (currentAsset.current && currentAsset.current.crop == null) {
                    setCrop(scaleCrop(currentAsset.current.crop, dimensions, displayDimensions, false))
                }
            } 
        }
    }, [displayDimensions, dimensions, isCroppping])
    

    // Update editing asset crop when user changes crop
    React.useEffect( () => {
        if (isCroppping) {
            if (editingAsset.current && dimensions && displayDimensions && crop && crop.userSet && crop.unit == 'px') {
                var c = scaleCrop(crop, dimensions, displayDimensions, true)
                c.userSet = false
                editingAsset.current.crop = c
                if (debug) { console.log('  -- user: scaling crop') }
            } else if (crop == null) {
                if (debug) { console.log('  -- user: removing crop') }
                editingAsset.current.crop = null
            }
        }
    }, [crop, dimensions, displayDimensions])

    //
    // Edit Actions
    //
    const templateDetails = findTemplateForAssetId(asset.id, props.assetTemplates)

    // Update header actions & title on load
    React.useEffect(() => {

        // Reset back to details.
        if (editMode == false && editModeType != AssetEditModeType.Details) {
            setEditModeType(AssetEditModeType.Details);
        }

        if (pageState && pageState.setHeaderTitle) {
            pageState.setHeaderTitle(templateDetails ? templateDetails.title : asset.title)
        }

        if (pageState && pageState.setHeaderActions) {
            var actions = []
            if (editMode) {

                const cancelButton = <PolymerHeaderButton key={"cancel"} primary={false} onClick={() => {
                    editingAsset.current = null
                    if (props.onCancel) {
                        props.onCancel()
                    }
                    if (props.modalEdit) {
                        pageState.setBackAction(null)
                        pageState.setHeaderActions(null)
                        pageState.onBack()                        
                    } else {
                        pageState.setBackAction(null)
                        pageState.setHeaderActions(null)
                        setEditMode(false);
                        setAssetVersion(assetVersion + 1)
                    }
                }} >Cancel</PolymerHeaderButton>

                const doneButton = <PolymerHeaderButton key={"done"} primary={true} onClick={() => {
                    if (onAssetChange) {
                        onAssetChange(editingAsset.current)
                    }
                    if (modalEdit) {
                        pageState.setBackAction(null)
                        pageState.setHeaderActions(null)
                        pageState.onBack()                        
                    } else {
                        pageState.setBackAction(null)
                        pageState.setHeaderActions(null)
                        editingAsset.current = null
                        setEditMode(false)
                        setAssetVersion(assetVersion + 1)
                    }
                }} >Done</PolymerHeaderButton>

                if (props.modalEdit) {
                    pageState.setBackAction(cancelButton)
                    pageState.setHeaderActions([doneButton])
                } else {
                    pageState.setBackAction(cancelButton)
                    pageState.setHeaderActions([doneButton])
                    //pageState.setHeaderActions([cancelButton, doneButton])
                }
            } else {

                actions = [<PolymerHeaderButton key={"edit"} primary={true} onClick={() => {
                    editingAsset.current = currentAsset.current
                    setEditMode(true)
                }} >Edit</PolymerHeaderButton>]

                pageState.setHeaderActions(actions)
                pageState.setBackAction(null)
            }

            
        }

    }, [editMode])

    //var validations = assetValidations({ dimensions:dimensions, templateDetails: templateDetails, asset: asset})

    if (asset.contrastRatio) {
        <TableRow key={"contrastRatio"} title="Contrast Ratio">{asset.contrastRatio}</TableRow>
    }

    var imageContainerStyle = { }
    var dark = false
    var variableWidth = true
    var maxDisplayWidth = 512
    var maxDisplayHeight = 512
    var assetWidth = null
    var assetHeight = null

    if (templateDetails) {
        //assetWidth      = templateDetails.width * templateDetails.displayScale
        //assetHeight     = templateDetails.height * templateDetails.displayScale
        dark            = templateDetails.dark
        //maxDisplayWidth = templateDetails.width * templateDetails.displayScale

        // if (templateDetails.variableWidth) {
        //     assetWidth = null
        //     assetHeight = null
        // }

        variableWidth = templateDetails.variableWidth

        imageContainerStyle = {
            minHeight: (templateDetails.height * templateDetails.displayScale) + 'px'
        }
    }

    const uploadContent = (
        <div className="asset-detail-uploading" key={uploadingProgress}>
            <h2>Uploading</h2>
            <PolymerProgressBar key={uploadingProgress} progress={uploadingProgress} />
        </div>
    )

    //
    //  Main Image
    //
    if (debug) {
        console.log(`AssetDetail: asset crop`)
        console.log(asset.crop)
        console.log(crop)
    }

    const imageContent = <AssetImage key={asset.src} 
                                     background={true} 
                                     dark={dark} 
                                     assetVersion={asset.src} 
                                     asset={{...asset}} 
                                     maxHeight={maxDisplayHeight} 
                                     maxWidth={maxDisplayWidth} 
                                     width={assetWidth} 
                                     height={assetHeight} 
                                     isCropping={isCroppping}
                                     variableWidth={variableWidth} 
                                     onDimensions={setDimensions}
                                     onDisplayDimensions={setDisplayDimensions} />

    var finalImageContent = imageContent

    //
    // Cropping overlay
    // 
    if (isCroppping) {   

        // Get current crop dimensions to display     
        const cropDimensions = scaleCrop(crop, dimensions, displayDimensions, true) ?? dimensions ?? {};

        // Default crop area is entire image
        const defaultCrop = { unit: '%',  x: 0, y: 0, width: 100, height: 100 }
        
        finalImageContent = (
            <div className={"asset-detail-image-crop"}>
                <ReactCrop crop={crop ?? defaultCrop} aspect={cropAspect} keepSelection={false} onChange={ (c) => { 
                    const newCrop = { ...c, width: Math.round(c.width), height: Math.round(c.height) }

                    if (debug) { console.log(' -- crop changed') }
                    // Set current editing crop
                    if (newCrop.width > 0 && newCrop.height > 0) {
                        setCrop({ ...newCrop, userSet: true})
                    } else {
                        setCropAspect(null)
                        setCrop({ unit: 'px', x: 0, y: 0, width: displayDimensions.width, height: displayDimensions.height, userSet: true  })
                    }
                }}>  
                    {/* Dont pass crop to the image itself sinfo when cropping */}
                    {React.cloneElement(imageContent, { 
                        asset: { ...asset, crop: null }
                    })}
                </ReactCrop>
                <span className={"asset-detail-image-crop-info"}>{cropDimensions.width}x{cropDimensions.height}</span>
            </div>
        )
    }

    const withPreviews = (
        <Scrollable>
            <div className="asset-detail-image asset-detail-image-with-previews" style={imageContainerStyle}>
                {finalImageContent}
            </div>
            {props.previews && <AssetCataloguePreviews containerClassName={"content-builder-container-inset"} fullscreen={false} previews={props.previews} assets={assets} assetVersion={assets} selectedAsset={asset} assetTemplates={assetTemplates} />}
        </Scrollable>
    )

    const withoutPreviews = (
        <div className="asset-detail-image" style={imageContainerStyle}>
            {finalImageContent}
        </div>
    )

    const onAssetLibrarySelectedCallback = (asset, templateDetails) => {
        currentAsset.current = {...asset, id: templateDetails ? templateDetails.id : asset.id}
        if (props.onAssetLibrarySelected) {
            props.onAssetLibrarySelected(asset, templateDetails)
        }
    }

    //
    //  Sidebar
    //  
    //  - If edit mode or view mode show default 'details' inspector
    //  - If edit mode and cropping show croin psector.
    //
    var sidebar = null
    switch (editModeType) {
        case AssetEditModeType.Details:
            sidebar = (
                <AssetDetailSidebarDetails
                    {...props}
                    editingAsset={editingAsset}
                    asset={asset}
                    assetLibrary={assetLibrary}
                    onAssetLibrarySelected={props.onAssetLibrarySelected ? onAssetLibrarySelectedCallback : null}
                    dimensions={dimensions}
                    editMode={editMode}
                    onDelete={props.onDelete}
                    onFileSelected={props.onFileSelected}
                    setAssetVersion={setAssetVersion}
                    assetVersion={assetVersion}
                    templateDetails={templateDetails}
                    setIsUploading={setIsUploading}
                    setUploadingProgress={setUploadingProgress}
                    canCrop={canCrop}
                    canMultiUpload={canMultiUpload}
                />
            );
            break;
        case AssetEditModeType.Crop:
            sidebar = (
                <AssetDetailSidebarCrop
                    {...props}
                    aspect={cropAspect}
                    crop={crop}
                    dimensions={dimensions}
                    displayDimensions={displayDimensions}
                    setAspect={setCropAspect}
                    setCrop={setCrop}
                    cropAspectRatios={cropAspectRatios}
                    asset={asset}
                />
            );
            break;
        default:
            break;
    }

    //
    // Determine valid edit modes
    var segmentedController = null

    if (editMode) {
        var options = []
        
        const validTypes = editModeTypes ?? [AssetEditModeType.Details]

        if (validTypes.includes(AssetEditModeType.Details)) {
            options.push({ icon: 'menu.info', value: AssetEditModeType.Details })
        }
        if (validTypes.includes(AssetEditModeType.Crop)) {
            options.push({ icon: 'menu.crop', value: AssetEditModeType.Crop })
        }
                         
        if (validTypes.length > 1) {
            segmentedController = <SegmentedControl titles={options} selectedIndex={editModeType} setSelectedIndex={setEditModeType} />
        }
    }

    return (
        <div className="asset-detail">
            <div className="layout">
                <section className="layout-center center-content">
                    {isUploading ? uploadContent : (props.previews && !editMode ? withPreviews : withoutPreviews)}
                </section>

                <section className="layout-right polymer-scroll-content">
                    <PageContent>
                        <div className="asset-detail-content">
                            {segmentedController}
                            {sidebar}
                        </div>
                    </PageContent>
                </section>
            </div>
        </div>
    )
}

function AssetDetailSidebarCrop({ setAspect, dimensions, displayDimensions, aspect, setCrop, crop, cropAspectRatios }) {

    const isOriginalCrop = crop == null;
    const [aspectCustom, setAspectCustom] = React.useState(false)

    const updateAspect = (value, isCustom) => {

        let { width, height } = displayDimensions;
        let { x, y } = crop ?? { x: 0, y: 0 };
        
        setAspectCustom(isCustom)

        if (crop == null) {
            width = displayDimensions.width;
            height = displayDimensions.height;
        } else if (crop.unit == 'px') {
            width = crop.width;
            height = crop.height;
        } else if (crop.unit == '%') {
            width = Math.round((crop.width / 100) * displayDimensions.width);
            height = Math.round((crop.height / 100) * displayDimensions.height);
        } 

        // Get current center point
        const currentCenterPoint = { x: x + (width / 2), y: y + (height / 2) } 

        // Derive with from aspect * height
        width = value * height

        // Validate bounds
        if (width >= displayDimensions.width) {
            width = displayDimensions.width
            height = width * (1 / value)
        }

        if (height >= displayDimensions.height) {
            height = displayDimensions.height
            width  = height * (value)
        }

        // Center new square to current crop center
        x = Math.min(Math.max(currentCenterPoint.x - (width / 2), 0), displayDimensions.width - width)
        y = Math.min(Math.max(currentCenterPoint.y - (height / 2), 0), displayDimensions.height - height) 
        
        // Setup new crop
        const finalCrop = { unit: 'px', x: Math.round(x), y: Math.round(y), width: Math.round(width), height: Math.round(height), userSet: true }

        // Update crop
        setAspect(value);
        setCrop(finalCrop);
    }

    const resetCrop = () => {
        setCrop(null)
        setAspect(null)
        setAspectCustom(false)
    }

    const setFreeform = () => {
        setAspect(null)
        setAspectCustom(false)

        if (isOriginalCrop) {
            setCrop({unit: 'px',
                    x: 0,
                    y: 0,
                    width: displayDimensions.width,
                    height: displayDimensions.height,
                    userSet: true})
        }
    }

    const customAspectRatios = (cropAspectRatios ?? []).map( (ratioItem) => {
        return (
            <LargeButton key={ratioItem.title} selected={aspect == ratioItem.aspect && aspectCustom == true}  onClick={() => { updateAspect(ratioItem.aspect, true) }}>{ratioItem.title}</LargeButton> 
        )
    })

    return (
        <div>
            <TableSection title="Crop Aspect" tableHeader={true} actions={null}>
                <div className={"polymer-grid polymer-grid-2-columns"}  style={{"paddingBottom" : "20px"}}>
                    <LargeButton key={"original"} selected={aspect == null && isOriginalCrop}  onClick={() => { resetCrop() }}>{"Original"}</LargeButton> 
                    <LargeButton key={"freeform"} selected={aspect == null && !isOriginalCrop} onClick={() => { setFreeform() }}>{"Freeform"}</LargeButton> 
                    <LargeButton key={"1:1"} selected={aspect == 1    && aspectCustom == false}    onClick={() => { updateAspect(1/1, false) }}>{"Square"}</LargeButton> 
                    <LargeButton key={"portrait"} selected={aspect == 2/3  && aspectCustom == false}  onClick={() => { updateAspect(2/3, false) }}>{"Portrait"}</LargeButton> 
                    <LargeButton key={"16:9"} selected={aspect == 16/9 && aspectCustom == false} onClick={() => { updateAspect(16/9, false) }}>{"16:9"}</LargeButton> 
                    <LargeButton key={"4:3"} selected={aspect == 4/3 && aspectCustom == false} onClick={() => { updateAspect(4/3, false) }}>{"4:3"}</LargeButton> 
                </div>
                {customAspectRatios.length > 0 && <div className="view-seperator"></div>}
                <div className={"polymer-grid polymer-grid-1-columns"}  style={{"paddingTop" : "20px"}}>
                    {customAspectRatios}
                </div>
            </TableSection>
        </div>
    )
}

function AssetDetailSidebarDetails({ canCrop, canMultiUpload, editingAsset, canEditName, setIsUploading, setUploadingProgress, canEditDescription, asset, assetLibrary, onDelete, onFileSelected, onAssetLibrarySelected, onAssetLibraryDelete, onAssetLibraryChange, onAssetLibraryImport, editMode, canAdjustFilters, dimensions, templateDetails, assetVersion, setAssetVersion}) {
 
    var contrast   = (editingAsset.current ? editingAsset.current.contrast   : asset.contrast) ?? 0
    var saturation = (editingAsset.current ? editingAsset.current.saturation : asset.saturation) ?? 0


    const uploadProgressCallback = (progress, uploading) => {
        if (!uploading) {
            setIsUploading(false)
            setUploadingProgress(0)
        } else {
            setIsUploading(true)
            setUploadingProgress(progress)
        }
    }

    const onUploadFile = (file) => {
        setEditMode(false)
        if (onFileSelected) {
            var newAsset = { id: asset.id, created: new Date(), filesize: file.size, type: file.type, title: asset.title }
            onFileSelected(file, newAsset, uploadProgressCallback)
        }
    }

    const onAssetLibrarySelectedCallback = (asset) => {
        if (onAssetLibrarySelected) {
            onAssetLibrarySelected(asset, templateDetails)
        }
    }


    //
    // Uploader
    //
    const changeImage = (
        <AssetFileChooser assetLibrary={assetLibrary} 
                          onAssetLibrarySelected={onAssetLibrarySelectedCallback}
                          onAssetLibraryDelete={onAssetLibraryDelete}
                          onAssetLibraryChange={onAssetLibraryChange}
                          onUploadFile={assetLibrary ? onAssetLibraryImport : onUploadFile} 
                          className={"asset-item-uploader-action"}
                          canCrop={canCrop}
                          canMultiUpload={canMultiUpload}
                          >
                <TableRowAction single={true} title="Change Image" />
        </AssetFileChooser>
    )


    //
    // Details
    //
    var details = assetDetailRows(asset, dimensions)

    //
    // Edit / Change
    //
    const deleteTitle = assetLibrary ? "Clear Image" : "Delete Image"
    const editActions = (
        <TableSection tableHeader={true}>

            {onDelete && asset.canDelete != false && <TableRowAction single={true} destructive={true} title={deleteTitle} onClick={() => {
                onDelete(asset)
            }} />}

            { (onFileSelected || onAssetLibrarySelected) && changeImage}
        </TableSection>
    )

    // 
    // Filters
    // 
    var resethHeaderAction = <PolymerHeaderButton key={"reset"} onClick={ ()  => {
        var newAsset = asset
        newAsset.saturation = 0
        newAsset.contrast = 0
        editingAsset.current = newAsset
        setAssetVersion(assetVersion + 1)
    }}>{"Reset"}</PolymerHeaderButton> 

    const filterActions = (
        <TableSection title="Adjustments" tableHeader={true} actions={resethHeaderAction}>
            <TableRow title="Contrast"><TableValueSlider min={-100} max={100} value={(contrast) * 100} onChange={(value) => {
                var newAsset = asset
                newAsset.contrast = value / 100
                editingAsset.current = newAsset
                setAssetVersion(assetVersion + 1)
            }} />
            </TableRow>

            <TableRow title="Saturation"><TableValueSlider min={-100} max={100} value={(saturation) * 100} onChange={(value) => {
                var newAsset = asset
                newAsset.saturation = value / 100
                editingAsset.current = newAsset
                setAssetVersion(assetVersion + 1)
            }} />
            </TableRow>
        </TableSection> 
    )

    return (
        <div>
            {editMode && filterActions && canAdjustFilters}

            {editMode && canEditName && <TableSection tableHeader={true} title="Name">
                <input type="textfield" value={asset.title} onChange={(event) => {
                    editingAsset.current.title = event.target.value
                    setAssetVersion(assetVersion + 1)
                }} className={"polymer-large-textfield"} />
            </TableSection>}

            {editMode && canEditDescription && <TableSection tableHeader={true} title="Alt Text">
                <textarea value={asset.description} className={"polymer-large-textfield"} onChange={(event) => {
                    editingAsset.current.description = event.target.value
                    setAssetVersion(assetVersion + 1)
                }} />
            </TableSection>}

            <TableSection tableHeader={true} title="Details">
                {details}
            </TableSection>

            {!editMode && asset.description && <TableSection tableHeader={true} title="Alt Text">
                <span className="asset-detail-section-content-description">
                    {asset.description}
                </span>
            </TableSection>}

            {hasValidations({ asset: asset, dimensions: dimensions, template: templateDetails }) && <TableSection tableHeader={true} title="Validation">
                <AssetValidationDetail dimensions={dimensions} template={templateDetails} asset={asset} showValid={true}/>
            </TableSection>}

            {editActions}
        </div>
    )
}

export { AssetDetail , AssetEditModeType, assetDetailRows, findTemplateForAssetId }