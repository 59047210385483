import React from 'react';
import { Section, Stack, VStack, HStack, Text, ActionButton, Image } from '@yapstudios/yap-content-builder';
import { InspectorHeader , InspectorContent, InspectorProperty, InspectorControlText, InspectorControlButton, InspectorControlDate, useInspectorState }  from '@yapstudios/yap-content-builder'
import { View } from '@yapstudios/yap-content-builder';

export default class LargeHeaderComponent {
    static type = 'large-header';

    constructor(title, brandName) {
        this.type = 'large-header';
        this.title = title
    }

    defaultProps() {
        return {
            title: this.title ?? "Title",
        };
    }

    titlesForProp() {
        return {
            title: 'Title',
        };
    }

    decode(props) {

        var title = props.title;
        var titleStyle = props.titleStyle ?? "h2";

        return (
            <Section padding="0" paddingVertical="0" id="section-header"  title="Header">
                <HStack cornerRadius={12} paddingTop={150} paddingLeft={20} paddingRight={50} paddingBottom={20} spacing={5} width="infinity" backgroundColor="white" padding="20">
                    <VStack align={"leading"}>
                        <Text id="title" align={"left"} canEditStyle={false} componentTitle={this.titlesForProp()['title']} readonly={false} content={title} style="largeTitleHeader" color="primary" />
                    </VStack>
                </HStack>
            </Section>            
        );
    }


    encode(model, props) {
        
        // From field
        switch (model['id']) {
            case 'title':
                props['title'] = model.content;
                break;
            default:
                break;
        }

        return props;
    }

    validations() {
        return [
            //PolymerValidations.fieldRequired('title')
        ];
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="70" fill="white"/>
                <rect x="5.44611" y="6.97676" width="89.23" height="57.154" rx="5" fill="#384CC0" fillOpacity="0.09"/>
                <rect opacity="0.3" x="11.8257" y="15.3874" width="74.1245" height="16.77" rx="4" fill="#384CC0"/>
                <rect opacity="0.3" x="11.8257" y="39.9683" width="42.2867" height="16.77" rx="4" fill="#384CC0"/>
            </svg>
        )
    }
}
