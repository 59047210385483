import * as React from 'react'

import { TableSection, TableSectionActionInfo } from '../shared-components/TableSection.js';
import { AssetPreviews, AssetPreview } from './AssetPreviews.js';
import Modal from 'react-modal';
import { Page, PageContent } from '../uikit-components/Page.js';
import PolymerHeader from "../uikit-components/Header.js"
import PolymerToolbar from '../uikit-components/Toolbar.js';
import PolymerPrevNext from '../uikit-components/PrevNext.js'
import { useYapPageState } from '../uikit-components/Page.js';
import ContentPreviewsModal from '../preview-components/ContentPreviewsModal.js';

function AssetCataloguePreviews(props) {

    var { previews } = props

    const [selectedPreviewIndex, setSelectedPreviewIndex] = React.useState(null)
    const [allPreviews, setAllPreviews] = React.useState([])
    const selectedPreview = selectedPreviewIndex != null ? allPreviews[selectedPreviewIndex] : null
    let pageState = useYapPageState()

    const onSelectedPreview = (preview, allPreviews, index) => {
        setAllPreviews(allPreviews)
        setSelectedPreviewIndex(index)
    }

    const findAssetTemplateById = (assetTemplates, id) => {
        for (const template of assetTemplates) {
            for (const assetSizeTemplate of template.sizes) {
                if (assetSizeTemplate.id === id) {
                    return assetSizeTemplate
                }
            }
        }
        return null
    }

    if (props.selectedAsset && props.assetTemplates) {
        let template = findAssetTemplateById(props.assetTemplates, props.selectedAsset.id)
        if (template) {
            previews = template.previews
        }
    }


    var selectedModal = (
        <ContentPreviewsModal show={selectedPreview != null} onClose={() => { setSelectedPreviewIndex(null) }}>
            {selectedPreview}
        </ContentPreviewsModal>
    )


    // var selectedModal = (
    //     <Modal  ariaHideApp={false} className="modal modal-fullscreen" overlayClassName="modal-overlay" isOpen={selectedPreview != null} onRequestClose={  () => {
    //         setSelectedPreviewIndex(null)
    //     }}>
    //         <Page fullscreen={true}>
    //             <PolymerHeader title={selectedPreview ? selectedPreview.title : null} largeTitle={false} onClose={() => {
    //                 setSelectedPreviewIndex(null)
    //             }}></PolymerHeader>
    //             <PageContent className="asset-preview-modal-content">
    //                 <AssetPreview key={selectedPreviewIndex} assets={props.assets} preview={selectedPreview} assetVersion={1} scale={0.8} />
    //             </PageContent>
    //             <PolymerToolbar actionsCenter={<PolymerPrevNext key={"prev-next"} width={100} total={allPreviews.length} index={selectedPreviewIndex} onIndexChange={setSelectedPreviewIndex}/>}></PolymerToolbar>
    //         </Page>
    //     </Modal>
    // )

    return <div className={`polymer-asset-catalogue-previews ${props.className}`}>
                <div className={`polymer-page-content ${props.containerClassName ?? pageState.containerClassName}`}>
                    <TableSection tableHeader={true} title={props.title ?? "Previews"}>
                        <AssetPreviews assets={props.assets} previews={previews} assetVersion={props.assetVersion} onSelectedPreview={onSelectedPreview} />
                    </TableSection>
                </div>
                {selectedModal}
            </div>
}

export default AssetCataloguePreviews