/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useSessionStorage } from 'usehooks-ts'
import { useAuth } from "./useAuth.js";
import { YapPage } from '@yapstudios/yap-content-builder'

import VerificationInput from "react-verification-input";

// Data model methods
import { validateCode , loadConfig } from './AppData.js'

const ContentAccess = () => {
    const [code, setCode ] = useState("")

    const { login } = useAuth()

    const submitCode = (code) => {
        code = code.toLowerCase()
        validateCode(code).then( bucketData => {
            loadConfig(bucketData).then(config => {

                let loginData = {...config, ...bucketData }
                console.log(loginData)

                login(loginData).then( result => {
                    if (!result) {
                        alert('Access Code Invalid')
                        setCode("")
                    }
                })
            })
        })  
    }

    return (
        <YapPage fullscreen={true} >
            <div className="login-content">
                <VerificationInput placeholder={""} length={4} autoFocus={true} onComplete={submitCode} value={code.toUpperCase()} onChange={setCode} classNames={{
                    container: "login-input",
                    character: "character",
                    characterInactive: "character--inactive",
                    characterSelected: "character--selected",
                    characterFilled: "character--filled",
                }} />
                <p>Enter Access Code</p>
            </div>
        </YapPage>
    )
}

export { ContentAccess }