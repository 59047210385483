import * as React from 'react'
import { useState } from "react";
import { useCanvas } from '../../content-builder-components/ContentBuilderCanvasState.js'
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js'
import { SectionContext } from '../SectionContext.js';
import Props from "../PropsHelper.js"
import useUniqueId from '../useUniqueId.js';

function Section(props) {
    const id = useUniqueId(props.id)
    const { setHoveredSectionRef, setHoveredSectionId } = useCanvas()
    const sectionRef = React.useRef(null)
    const { setSelectedId, setInspectorModel, styles, pageStyle, onTemplateSelected, addSection, isReadonly } = useAppState()
    const [isHighlighted, setIsHighlighted] = useState(false)
    const highlightClass = isHighlighted ? 'highlighted ' : ''

    const currentStyle = props.style ?? pageStyle
    const sectionStyle = styles[currentStyle] ?? styles['default']

    const sectionContextValue = {
        sectionId: id,
        sectionEncode: props.encode,
        sectionStyle: sectionStyle
    }

    var style = []
    if (props.padding) {
        style['padding'] = props.padding + 'px'
    }

    if (props.height) {
        style['height'] = Props.height(props)
    }

    if (props.paddingVertical != null) {
        style['paddingTop'] = props.paddingVertical + 'px'
        style['paddingBottom'] = props.paddingVertical + 'px'
    }

    if (props.paddingTop != null) {
        style['paddingTop'] = props.paddingTop + 'px'
    }

    if (props.paddingBottom != null) {
        style['paddingBottom'] = props.paddingBottom + 'px'
    }

    if (props.paddingHorizontal) {
        style['paddingLeft'] = props.paddingHorizontal + 'px'
        style['paddingRight'] = props.paddingHorizontal + 'px'
    }

    if (props.paddingRight) {
        style['paddingRight'] = props.paddingRight + 'px'
    }

    if (props.paddingLeft) {
        style['paddingLeft'] = props.paddingLeft + 'px'
    }

    if (props.style) {
        style['backgroundColor'] = sectionStyle.colors.background
    }

    if (props.anchor) {
        //style['background-color'] = 'red'
        style['justifySelf'] = props.anchor
    }

    if (props.align == 'leading') {
        style['align-items'] = 'flex-start'
    } else if (props.align == 'trailing') {
        style['align-items'] = 'flex-end'
    } else if (props.align == 'center') {
        style['align-items'] = 'center'
    }

    if (props.justify == 'leading') {
        style['justify-content'] = 'flex-start'
    } else if (props.justify == 'trailing') {
        style['justify-content'] = 'flex-end'
    } else if (props.justify == 'center') {
        style['justify-content'] = 'center'
    }

    if (props.align || props.justify) {
        style['display'] = 'flex'
        style['flex-direction'] = 'column'
    }


    if (props.clip) {
        style['overflow'] = 'hidden'
    }

    if (props.clipX) {
        style['overflow-x'] = 'hidden'
    }

    var templateAdded = function (template) {
        addSection(props.id, template)
    }

    var onMouseOver = function () {
        if (isReadonly) { return false }

        setIsHighlighted(true)
        if (setHoveredSectionId) {
            setHoveredSectionId(props.id)
        }
        if (setHoveredSectionRef) {
            setHoveredSectionRef(sectionRef.current)
        }

        //onTemplateSelected.current = templateAdded
    }

    var onMouseOut = function () {
        if (isReadonly) { return false }

        //setHoveredSectionRef(null)
        setIsHighlighted(false)
    }

    var onClick = function (e) {
        if (isReadonly) { return false }

        setSelectedId(id)
        setInspectorModel(null)
        e.stopPropagation()
    }

    const sectionTitle = <div onClick={onClick} className="section-title">
        {props.name}
    </div>

    return (
        <div
            className={`section ${highlightClass}`}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            ref={sectionRef}>
            {sectionTitle}

            <div style={style} className="section-content">
                <SectionContext.Provider value={sectionContextValue}>
                    {props.children}
                </SectionContext.Provider>
            </div>
        </div>)

}

export { Section }