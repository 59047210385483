import * as React from 'react'

import { FileUploader } from "react-drag-drop-files";
import PolymerAssetType from './AssetType';

function AssetFileUploader({ canMultiUpload, children, types, onUploadFile, onError, onWillBeginUploading, className, style, withDimensions = false }) {
    var types = types ?? [PolymerAssetType.Image]

    const fileList = React.useRef(null)
    const fileIndex = React.useRef(-1)

    var isMultiUpload = canMultiUpload == false ? false : true

    const finishUpload = () => {
        fileIndex.current = -1
        fileList.current = null
    }

    const uploadFileComplete = (success) => {
        if (isMultiUpload) {
            if (success) {
                fileIndex.current = fileIndex.current + 1
                uploadNextFile()
            } else {
                finishUpload()
            }
        } else {
            finishUpload()
        }
    }

    const uploadFile = (file) => {
        console.log(file)

        if (onWillBeginUploading) { onWillBeginUploading() }

        var url = URL.createObjectURL(file);

        const progressInfo = {
            total: isMultiUpload ? fileList.current.length : 1,
            index: fileIndex.current
        }

        const fileExtension = file.name.fileExtension()
        const isVideo =  PolymerAssetType.Video.fileTypes.includes(fileExtension)
        const isImage =  PolymerAssetType.Image.fileTypes.includes(fileExtension)
        const supportDimensions = (isVideo || isImage)

        if (withDimensions && supportDimensions) {
            // Load image to get dimensions before uploading
            var imageObject = new Image()

            imageObject.onload = () => {
                onUploadFile(file, { width: imageObject.width, height: imageObject.height }, isVideo, progressInfo, uploadFileComplete)
            }
            imageObject.onerror = () => {
                if (onError) { onError() }
            }
            imageObject.src = url
        } else {
            onUploadFile(file, { width: null, height: null }, isVideo,progressInfo, uploadFileComplete)
        }
    }

    const uploadNextFile = () => {
        if (fileList.current == null) {
            return
        }

        if (fileIndex.current > fileList.current.length - 1) {
            finishUpload()
            return
        }

        let file = fileList.current.item(fileIndex.current)
        uploadFile(file)
    }

    const onFileUploaderSelectedFile = (list) => {
        if (isMultiUpload) {
            fileList.current = list
            fileIndex.current = 0
            uploadNextFile()
        } else {
            fileIndex.current = 0
            uploadFile(list)
        }
    }

    // Uploader
    var fileTypes = []

    if (types.includes(PolymerAssetType.Video)) {
        fileTypes = fileTypes.concat(PolymerAssetType.Video.fileTypes)
    }

    if (types.includes(PolymerAssetType.Image)) {
        fileTypes = fileTypes.concat(PolymerAssetType.Image.fileTypes)
    }
    if (types.includes(PolymerAssetType.Model)) {
        fileTypes = fileTypes.concat(PolymerAssetType.Model.fileTypes)
    }

    return (
        <FileUploader hoverTitle={"drop"} 
                      dropMessageStyle={{ border: "0px", background: "#00000000" }} 
                      onDraggingStateChange={null} label={""} 
                      classes={className ?? "asset-item-uploader-action"} 
                      handleChange={onFileUploaderSelectedFile} 
                      name="file" 
                      style={style}
                      multiple={isMultiUpload}
                      types={fileTypes}>
            {children}
        </FileUploader>
    )
}

export default AssetFileUploader