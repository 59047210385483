import React, { useEffect, createContext, useContext } from 'react';

// Define a type for the style context
export interface RendererContextType {
    viewCallback: any;
}

// Create the StyleContext with an empty default value
export const RendererContext = createContext<RendererContextType>({ viewCallback: null });


// Custom hook to use the StyleContext
export function useRendererContext() {
    return useContext(RendererContext);
}
