import React from 'react';

function SegmentedControl(props) {

    const elements = props.options.map((option, index) => {
        var selected = false;

        if (props.keys) {
            selected = props.keys[index] == props.value;
        } else {
            selected = index == props.value;
        }

        return (
            <SegmentedControlOption key={props.keys[index]} selected={selected} onClick={() => {
                if (props.onChange) {
                    props.onChange(props.keys[index]);
                }
            }}>{option}</SegmentedControlOption>
        );
    });

    return (
        <div className="control-segmented">
            {elements}
        </div>
    );
}

function SegmentedControlOption(props) {
    const className = (props.selected) ? "selected " : ""

    return (
        <div onClick={props.onClick} className={`control-segmented-option ${className}`}>
            {props.children}
        </div>
    )
}

export { SegmentedControl, SegmentedControlOption }