import * as React from 'react'
import { Sidebar, SidebarSectionHeader, SidebarItem, SidebarSection } from "../components/Sidebar.js"
import Masonry from 'react-masonry-css'
import { useAppState } from '../content-builder-components/ContentBuilderStateProvider.js'

var lastSelectedTemplateLibrary = null

function TemplateLibrary() {

    var { onTemplateSelected, setShowTemplateLibrary, templates } = useAppState()
    templates = templates ?? []

    if (lastSelectedTemplateLibrary == null && templates.length > 0) {
        lastSelectedTemplateLibrary = templates[0].name
    }

    const [selectedSectionId, setSelectedSectionId] = React.useState(lastSelectedTemplateLibrary)

    var selectedSection = { templates: [] }
    templates.forEach(section => {
        var id = section.id ?? section.name
        if (id == selectedSectionId) {
            selectedSection = section
        }
    })

    var content = <div className="section-template-items">
        <Masonry
            breakpointCols={selectedSection.cols ?? 3}
            className="section-template-grid"
            columnClassName="section-template-grid-item">
            {selectedSection.templates.map((item, index) => {
                const filename = item.thumbnail
                const id = item.title + "-" + index
                return <TemplateLibraryItem key={id} isIcon={selectedSection.isIcon} title={item.name ?? item.src} id={id} thumbnail={item.thumbnail}  thumbnailSVG={item.template.icon()} onClick={() => {
                    if (onTemplateSelected) {
                        onTemplateSelected.current(item.template)
                        setShowTemplateLibrary(false)
                    }
                }}></TemplateLibraryItem>
            })}
        </Masonry>
    </div>

    return <div className="layout">
        <section className="layout-left">
            <Sidebar selectedId={selectedSectionId} setSelectedId={(id) => {
                setSelectedSectionId(id)
                lastSelectedTemplateLibrary = id
            }}>
                <SidebarSection>
                    <SidebarSectionHeader title="Choose Template"></SidebarSectionHeader>
                    {templates.map((section) => {
                        var id = section.id ?? section.name
                        return <SidebarItem key={id} title={section.name} id={id}></SidebarItem>
                    })}
                </SidebarSection>
            </Sidebar>
        </section>
        <section className="layout-center">
            <div className="modal-content">
                <div className="header-container">
                    <h2>{selectedSection.name}</h2>
                </div>
                {content}
            </div>
        </section>
    </div>
}

function TemplateLibraryItem(props) {
    var icon = props.isIcon ? 'section-template-item-icon' : ''
    return <div className={`section-template-item  ${icon} template-item`} onClick={props.onClick}>
        {props.thumbnailSVG}
        {/* <img src={props.thumb}/> */}
    </div>
}

export { TemplateLibrary }