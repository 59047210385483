import BasicMessageTemplate from "./Templates/BasicMessageTemplate.js"
import VideoMessageTemplate from "./Templates/VideoMessageTemplate.js"
import QuizMessageTemplate from "./Templates/QuizMessageTemplate.js"
import StoryMessageTemplate from "./Templates/StoryMessageTemplate.js"
import DatesMessageTemplate from "./Templates/DatesMessageTemplate.js"
import MapMessageTemplate from "./Templates/MapMessageTemplate.js"

const templateLibrary = [
    {
        name: 'Messages',
        templates: [
            { template: new BasicMessageTemplate(), title: "Basic Message"  },
            { template: new VideoMessageTemplate(), title: "Message with Video" },
            { template: new QuizMessageTemplate(), title: "Quiz" },   
            { template: new StoryMessageTemplate(), title: "Story Message" },            
            { template: new DatesMessageTemplate(), title: "Dates Message" }, 
            { template: new MapMessageTemplate(), title: "Map Message"},
            // { template: new StoryTemplate(), title: "Map Story" },            
        ]
    },
]

export { templateLibrary }