import React from 'react'

/** JSON Formatting */
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { githubGist as syntaxStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
SyntaxHighlighter.registerLanguage('json', json);

export function JSONFormatter({ children, onClose }) {
    const content = children
    const style = {
        overflowY: 'scroll',
        overflowX: 'scroll',
        height: '-webkit-fill-available',
        width: '100%',
        padding: '0px',
        wordWrap: 'break-word',
        fontSize: '14px',
    }

    return (
        <div style={style}>
            <SyntaxHighlighter wrapLines={true} wrapLongLines={true} language="json" style={syntaxStyle}>
                {JSON.stringify(content, null, 2)}
            </SyntaxHighlighter>
        </div>
    )
}
