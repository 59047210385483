import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css'; //Example style, you can use another

export function CodeEditor({ content, setContent }) {
    return (
        <Editor
            value={content}
            onValueChange={code => setContent(code)}
            highlight={code => highlight(code, languages.xml)}
            padding={25}
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 14,
            }}
        />
    );
}