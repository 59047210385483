import React, { createContext, useContext, ReactNode } from 'react';
import { SetVariables, Variable,  Variables, VariablesContext, useVariables } from './Variable.tsx';

export function State(props) {
    const { variables } = useVariables();

    /**
     * Lower case key names first
     */
    let stateProps = {}
    for (const key of Object.keys(props)) {
        if (key == 'children') { continue; }
        var lckey = key.toLowerCase();
        stateProps[lckey] = props[key];   
    }

    // console.log('stateProps: ')
    // console.log(stateProps);

    /**
     * Setup state storage
     */
    const [stateVariables, setStateVariables] = React.useState(stateProps);
    let allVariables = {...variables, ...stateVariables};

    /**
     * Setup callback to update state. Only accepts keys that are in the stateProps
     */
    const setVariables = (newVariables) => {
        // console.log('setVariables: ')
        // console.log(newVariables)
        var updatedVariables = {...stateVariables};
        for (const key in stateProps) {
            if (newVariables.hasOwnProperty(key)) {
                updatedVariables[key] = newVariables[key];
            }
        }
        setStateVariables(updatedVariables);
        // alert('updated');
        // console.log('state: ')
        // console.log(updatedVariables)
        // console.log('') 
    }

    return <VariablesContext.Provider value={{ variables: allVariables, setVariables: setVariables }}>{props.children}</VariablesContext.Provider>;
}


export function Defaults(props) {
    const { variables, setVariables } = useVariables();

    /**
     * Lower case key names first
     */
    let stateProps = {}
    for (const key of Object.keys(props)) {
        if (key == 'children') { continue; }
        var lckey = key.toLowerCase();
        stateProps[lckey] = props[key]
    }

    // console.log('stateProps: ')
    // console.log(stateProps);

    /**
     * Setup state storage
     */
    let allVariables = {...variables, ...stateProps};

    return <VariablesContext.Provider value={{ variables: allVariables, setVariables: setVariables }}>{props.children}</VariablesContext.Provider>;
}
