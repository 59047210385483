import * as React from 'react'
import "../uikit-components/Toolbar.js"

// if you want to use core connector components
import { SConnector, LineConnector, NarrowSConnector } from 'react-svg-connector';
import Connector from '../uikit-components/Connector.tsx'
import { px } from "../Util.js"
import Toolbar from '../uikit-components/Toolbar.js';
import PrevNext from '../uikit-components/PrevNext.js';
import HeaderButton from '../uikit-components/HeaderButton.js';
import ContentValidations from './ContentValidations.js';
import ValidationIcon from './ValidationIcon.js';
import { useAppState } from '../content-builder-components/ContentBuilderStateProvider.js'

const PolymerValidationStateContext = React.createContext()

function useValidationState() {
    const context = React.useContext(PolymerValidationStateContext)
    if (!context) {
          throw new Error(`useValidationState must be used within a PolymerValidationStateProvider`)
    }
    return context
}


/**
 * State environment for validation components
 * 
 * @param validations Global validations
 * @param components Polymer page components
 * @param page Page to validate
 * @param enabled If validations are enabled or not
 * @param children Child content to render
 * @param onEnabled If enabled is toggled
 */
function ContentBuilderValidationStateProvider({ validations, validationMessages, components, page, enabled, children, onEnabled }) {

    const [selectedIssueId, setSelectedIssueId] = React.useState(null)
    const [issueIdIndexes, setIssueIdIndexes] = React.useState([])

    var issues = [] 
    var requiredValidations = []
    if (validationMessages != null) {
        issues = validationMessages
    } else if (validations != null) {
        issues              = checkValidationIssues(validations, components, page, false) 
        requiredValidations = checkValidationIssues(validations, components, page, true) 
    }

    React.useEffect( () => {
        let idx = issueIdIndexes.indexOf(selectedIssueId)
        setSelectedIssueId(issueIdIndexes[0])
    }, [issueIdIndexes.length])

    let state = {
        issues: issues,
        requiredValidations: requiredValidations,

        enabled: enabled,

        selectedIssueId: selectedIssueId,
        setSelectedIssueId: setSelectedIssueId,
        
        issueIdIndexes, 
        setIssueIdIndexes,

        onEnabled: onEnabled
    }
    
    return <PolymerValidationStateContext.Provider value={state}>{children}</PolymerValidationStateContext.Provider>
}

function checkValidationIssues(validations, components, page, showingAsIndicator) {
    if (page == null) { return }
    var issues = []

    // Get components with validations for sections in the page
    let componentsWithValidations = page.sections.map( section => {
        return { component: components.find( obj => obj.type == section.type),
                 section: section }
    }).filter( a => a.component != null && a.component.validations != null )
    
    // Get component validations
    let componentValidations = componentsWithValidations.map( ({ component, section }) => {
        ContentValidations._currentComponent = component
        let validations = component.validations(section.props)
        return { validations: validations, component: component, section: section }
    })

    // Get validations on for the page
    let pageValidations = validations.map( validation => {
        let component = components.find( obj => obj.type == validation.component)
        let section = page.sections.find( obj => obj.type == validation.component)
        
        if (component && section) {
            return { validations: [validation], component: component, section: section }
        } else {
            return null
        }
    })
    .filter( a => a != null)
    
    // Combine
    const allValidations = componentValidations.concat(pageValidations)

    allValidations.forEach( ({ validations, component, section }) => {
        
        let componentIssues = validations.map( validation => {
            if (validation.check == null) {
                console.warn(`Validation requires a check function ${JSON.stringify(validation)} `)
                return null
            }
            
            if (validation.filter != null) {
                let passed = validation.filter(section.props)
                if (!passed) { return null }
            }

            let checkPassed = validation.check(section.props)
            let validationType = validation.type

            // type also can be a function
            if (typeof validationType === 'function') {
                validationType = validationType(section.props, checkPassed)
            }

            let validationDescription = validation.description
            // description also can be a function
            if (typeof validationDescription === 'function') {
                validationDescription = validationDescription(section.props, checkPassed)
            }

            let showAsIndicator = validation.showAsIndicator
            if (typeof showAsIndicator === 'function') {
                showAsIndicator = showAsIndicator(section.props, checkPassed)
            }

            let showAsValidation = validation.showAsValidation
            if (showAsValidation == null) {
                showAsValidation = true
            } else if (typeof showAsValidation === 'function') {
                showAsValidation = showAsValidation(section.props, checkPassed)
            }

            if ((checkPassed == false && showAsValidation && !showingAsIndicator) || (showAsIndicator && showingAsIndicator)) {
                let val = { 
                    title: validation.title ?? component.title ?? component.type, 
                    type: validationType,
                    description: validationDescription,
                    sectionId: section.id,
                    elementId: validation.elementId,
                    property: validation.property,
                    valid: checkPassed
                }

                if (showingAsIndicator) {
                    val.title = validation.elementTitle ?? component.title
                    if (checkPassed) {
                        //val.description = null// `${validation.elementTitle} has no issues`
                    }
                }

                val['id'] = component.title + val.type + val.sectionId + val.elementId + validation.description
                return val
            }
        }).filter( a => a )

        issues = issues.concat(componentIssues)
    });

    return issues
}


function ContentBuilderValidationToolbar(props) {
    let { issues, selectedIssueId, setSelectedIssueId, onEnabled, enabled, issueIdIndexes } = useValidationState()

    if (!enabled) {
        return null
    }

    let ids = issueIdIndexes
    let idx = ids.indexOf(selectedIssueId)

    const onIndexChange = (idx) => {
        let id = ids[idx]
        setSelectedIssueId(id)
    }

    var pagination = <PrevNext key={"pagination"} total={issues.length} index={idx} width={100} onIndexChange={onIndexChange}/>

    if (issues.length == 0) {
        pagination = "No Issues"
    }

    const actionHide = (
        <HeaderButton key={"hide"} onClick={ () => {
            onEnabled(false)
        }}>Hide</HeaderButton>
    )
    
    let titleLeft = <span key={"title"}>{"Validation Issues"}</span>

    return <Toolbar position={"top"} actionsCenter={[pagination]}  actionsRight={[actionHide]} actionsLeft={[titleLeft]}/>
}

function ContentBuilderValidation(props) {
    const layout = React.useRef({})
    const [issuesWithPosition, setIssuesWithPosition] = React.useState([])
    const [requiredValidationsWithPosition, setRequiredValidationsWithPosition] = React.useState([])
    const [canvasHeightWithoutValidations, setCanvasHeightWithoutValidations] = React.useState(0)

    let { issues, requiredValidations, selectedIssueId, setSelectedIssueId, setIssueIdIndexes, enabled } = useValidationState()
    
    // Construct a hash of the page sections to use to determine if layout should be updated
    let sectionsOrderHash = props.page.sections.map(section => {
        return section.id
    }).join('')


    let issuesHash = issues.map(issue => {
        return issue.id
    }).join('')

    const itemWidth = 250
    const itemPadding = 32

    React.useLayoutEffect( () => {
        if (enabled && canvasHeightWithoutValidations == 0) {
            // Get canvas and canvas rect before validations 
            // are shown which may impact bottom padding
            var canvas = document.getElementById('canvas')
            if (canvas == null) { return null }
            var canvasRect = canvas.getBoundingClientRect();
            setCanvasHeightWithoutValidations(canvasRect.height)
        }
    }, [enabled])

    /**
     * Update all elements when content changes
     */
    const layoutDidChange = (layout) => {

        // Get canvas and canvas rect
        var canvas = document.getElementById('canvas')
        if (canvas == null) { return null }
        var canvasRect = canvas.getBoundingClientRect();

        const requiredValidationsWithPosition =  requiredValidations.map( validation => {
            let layoutAttrs = layout[validation.id]
            var issue = validation
            issue.siblingIssues = []

            if (layoutAttrs && layoutAttrs['section']) {
                issue.sectionRef = layoutAttrs['sectionRef']
                issue.top = layoutAttrs['section']['top'] - (canvasRect.top + window.scrollY)
                issue.sectionCenter = issue.top + layoutAttrs['section']['height'] / 2
                if (layoutAttrs['item']) {
                    issue.left = layoutAttrs['section']['left'] - canvasRect.left - (layoutAttrs['item']['width'] + 32)
                    issue.hasLayout = true
                    issue.height = layoutAttrs['item']['height']
                }
            }
            
            return issue            
        })

        var finalRequireValidations = []

        requiredValidationsWithPosition.forEach( validation => {
            let other = finalRequireValidations.find( existing => Math.round(existing.top) == Math.round(validation.top) )
            if (other) {
                other.siblingIssues.push(validation)
            } else {
                finalRequireValidations.push(validation)
            }
        })

        // Calcaulte positions
        const issuesWithPosition = (enabled ? issues : requiredValidations).map( currentIssue => {
            let layoutAttrs = layout[currentIssue.id]
            var issue = currentIssue            

            if (layoutAttrs && layoutAttrs['section']) {
                issue.sectionRef = layoutAttrs['sectionRef']
                issue.top = layoutAttrs['section']['top'] - (canvasRect.top + window.scrollY)
                issue.sectionCenter = issue.top + layoutAttrs['section']['height'] / 2
                if (layoutAttrs['item']) {
                    issue.left = layoutAttrs['section']['left'] - canvasRect.left - (layoutAttrs['item']['width'] + 32)
                    issue.hasLayout = true
                    issue.height = layoutAttrs['item']['height']
                }
            }
            
            return issue
        })

        // Order by top
        const orderedByTop = issuesWithPosition.sort( (a, b) => {
            return a.sectionCenter > b.sectionCenter
        })

        // Flow final positions
        var lastBottomY = 0
        const finalPositions = orderedByTop.map( (issue) => {
            issue.top = Math.max(issue.top, lastBottomY + 16)
            lastBottomY = issue.top + issue.height
            return issue
        })

        if (props.onBottomPadding && canvasHeightWithoutValidations > 0) {
            if (lastBottomY > canvasHeightWithoutValidations) {
                props.onBottomPadding((lastBottomY - canvasHeightWithoutValidations) + 50)
            } else {
                props.onBottomPadding(0)
            }
            //console.log(`lastBottomY ${lastBottomY} canvasHeight ${canvasHeightWithoutValidations}`)
        }
        let ids = finalPositions.map( issue => issue.id )

        setIssueIdIndexes(ids)
        setRequiredValidationsWithPosition(finalRequireValidations)
        setIssuesWithPosition(finalPositions)
        
    }

    var currentIssues = (enabled ? issues : requiredValidations)

    /**
     * Calc dimensions for each section or element issues are pointing to
     */
    React.useLayoutEffect( () => {
        var currentLayout = {...layout.current}
        var scrollTop = window.scrollY;

        // Get ref and dimensions of each section or element an issue is pointing to
        (enabled ? issues : requiredValidations).forEach( issue => {
            var ref = document.getElementById('polymer-page-section-' + issue.sectionId)
            if (ref) {

                // If element id is specified, find the element within the section
                if (issue.elementId) {
                    for (let child of ref.getElementsByTagName('div')) {
                        const id = child.id.split('.rootId')[0]

                        // Replace section ref with element ref if found
                        if (id == issue.elementId) { ref = child }
                    }
                }

                const boundingRect = ref.getBoundingClientRect();
                const rect = {
                    left: boundingRect.left,
                    top: boundingRect.top + scrollTop,
                    width: boundingRect.width,
                    height: boundingRect.height
                }

                var layoutAttrs = currentLayout[issue.id] ?? { }
                layoutAttrs['section'] = rect 
                layoutAttrs['sectionRef'] = ref
                currentLayout[issue.id] = layoutAttrs
            }
        })
        
        layout.current = {...currentLayout}
        layoutDidChange(layout.current)

    }, [sectionsOrderHash, issuesHash, enabled, selectedIssueId])

    /**
     * Callback when layout is updated for a validation item
     */
    const onItemLayout = (item, rect) => {
        var currentLayout = layout.current
        var layoutAttrs = currentLayout[item.id] ?? { }
        layoutAttrs['item'] = rect 
        currentLayout[item.id] = layoutAttrs
        layout.current = {...currentLayout}
        layoutDidChange({...currentLayout})
    }

    const onItemSelect = (item) => {
        setSelectedIssueId(item.id == selectedIssueId ? null : item.id)
    }

    /**
     * Create Items
     */
    let issueItems = issuesWithPosition.map( issue => {
        return <PolymerValidationItem key={issue.id} 
                                      selected={issue.id == selectedIssueId} 
                                      width={itemWidth} 
                                      containerWidth={itemWidth + itemPadding} 
                                      issue={issue} 
                                      sectionRef={issue.sectionRef} 
                                      onSelect={onItemSelect} 
                                      onLayout={onItemLayout}
                                      minimised={!enabled}/>
    })

    let indicatorItems = requiredValidationsWithPosition.map( issue => {
        return <PolymerValidationIndicator  key={issue.id} 
                                            selected={issue.id == selectedIssueId} 
                                            width={itemWidth} 
                                            containerWidth={itemWidth + itemPadding} 
                                            issue={issue} 
                                            sectionRef={issue.sectionRef} 
                                            onSelect={onItemSelect} 
                                            onLayout={onItemLayout}/>
    })

    
    var containerStyle = {
        position: 'relative',
        left: -(itemWidth + itemPadding) + 'px'
    }

    return (
            <div className="polymer-validation-items" style={containerStyle}>
                <div className="polymer-validation-items-content">
                {enabled ? issueItems : indicatorItems}
                </div>
            </div>
    )
}


const chevronDown = (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.86719 7.13867C5.58594 7.13867 5.32812 7.02344 5.09375 6.79297L0.757812 2.35742C0.671875 2.26758 0.605469 2.16992 0.558594 2.06445C0.515625 1.95508 0.494141 1.83789 0.494141 1.71289C0.494141 1.53711 0.535156 1.37891 0.617188 1.23828C0.703125 1.09375 0.816406 0.980469 0.957031 0.898438C1.09766 0.8125 1.25391 0.769531 1.42578 0.769531C1.6875 0.769531 1.91797 0.869141 2.11719 1.06836L6.10742 5.17578H5.63281L9.61133 1.06836C9.81055 0.869141 10.0391 0.769531 10.2969 0.769531C10.4688 0.769531 10.625 0.8125 10.7656 0.898438C10.9062 0.980469 11.0176 1.09375 11.0996 1.23828C11.1855 1.37891 11.2285 1.53711 11.2285 1.71289C11.2285 1.9668 11.1406 2.18164 10.9648 2.35742L6.63477 6.79297C6.51758 6.91016 6.39648 6.99609 6.27148 7.05078C6.14648 7.10547 6.01172 7.13477 5.86719 7.13867Z" fill="#868686"/>
    </svg>
)

const chevronUp = (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.757812 5.20508L5.09375 0.769531C5.32422 0.535156 5.58203 0.417969 5.86719 0.417969C6.01172 0.421875 6.14648 0.453125 6.27148 0.511719C6.39648 0.566406 6.51758 0.652344 6.63477 0.769531L10.9648 5.20508C11.1406 5.37695 11.2285 5.5918 11.2285 5.84961C11.2285 6.02539 11.1855 6.18359 11.0996 6.32422C11.0176 6.46484 10.9062 6.57812 10.7656 6.66406C10.625 6.75 10.4688 6.79297 10.2969 6.79297C10.0312 6.79297 9.80273 6.69336 9.61133 6.49414L5.63281 2.38672H6.10742L2.11719 6.49414C1.92188 6.69336 1.69141 6.79297 1.42578 6.79297C1.25391 6.79297 1.09766 6.75 0.957031 6.66406C0.816406 6.57812 0.703125 6.46484 0.617188 6.32422C0.535156 6.18359 0.494141 6.02539 0.494141 5.84961C0.494141 5.72461 0.515625 5.60742 0.558594 5.49805C0.605469 5.38867 0.671875 5.29102 0.757812 5.20508Z" fill="#868686"/>
    </svg>
)


function PolymerValidationIndicator({ issue, onLayout, sectionRef, width, selected, onSelect, containerWidth }) {
    
    const ref = React.useRef(null)
    const infoTextRef = React.useRef(null)
    const infoDotRef = React.useRef(null)
    const [hovering, setHovering] = React.useState(false)
    const [indicatorRect, setIndicatorRect] = React.useState({})
    const [infoRect, setInfoRect] = React.useState({})
    const updateLayout = () => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            onLayout(issue, rect)
        }
    }

    let {  setShowSidebar } = useAppState()

    React.useLayoutEffect( () => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            const indicatorRect = { top: rect.top + window.scrollY, height: rect.height }
            setIndicatorRect(indicatorRect)
        }

        if (infoTextRef.current) {
            const infoRect = infoTextRef.current.getBoundingClientRect()
            setInfoRect(infoRect)
        }
    }, [issue, issue.siblingIssues])

    // When loaded and layout has happened tell the parent
    React.useLayoutEffect( () => {
        updateLayout()
    }, [ref, selected])

    // Validate required 
    if (sectionRef == null) {
        return 
    }

    // Calculate offsets based on where page-container is and how much the canvas has scrolled.
    var canvas    = document.getElementById('canvas')
    var pageRect  = document.getElementById('page-container').getBoundingClientRect()
    var cavasRect = canvas.getBoundingClientRect()

    let offsetY   = canvas.scrollTop - cavasRect.top
    let offsetX   = -(pageRect.left - containerWidth)
    let connectorOffsetY = -(cavasRect.top + window.scrollY)

    // Calculate box to highlight the canvas section or element
    let sectionRect = sectionRef.getBoundingClientRect()
    let sectionHighlightStyle = {
        position: 'absolute',
        boxSizing: 'border-box',
        left: `${(sectionRect.left + offsetX)}px`,
        top: `${(sectionRect.top + offsetY)}px`,
        width: px(sectionRect.width),
        height: px(sectionRect.height),
    }

    // Item style
    const style = {
        position: 'absolute',
        left: '220px',
        zIndex: 2,
        top: `${( (sectionRect.top + (sectionRect.height / 2)) + offsetY - 16)}px`,
    }

    const onMouseOver = () => {
        setHovering(true)
        //setShowSidebar(false)
    }

    const onMouseOut = () => {
        setHovering(false)
    }

    const typeClass = ""
    const hoverClass = ""

    // Layout info dialog, snap to bottom.
    let infoTop = 10
    let infoBottom = infoTop + indicatorRect.top + infoRect.height 
    if (infoBottom > cavasRect.height) {
        infoTop -= infoBottom - cavasRect.height
    }

    let infoStyle = {
        left: '-260px',
        top: infoTop + 'px',
        opacity: hovering ? '1' : '0.0'
    }

    let order = [
        'error',
        'warning',
        'requirement',
        'info'
    ]

    let infoItems = [issue].concat(issue.siblingIssues).sort( (a, b) => {
        let typeA = order.indexOf(a.type);
        let typeB = order.indexOf(b.type);
        return typeA - typeB;
    })
    
    let infoItemContent = infoItems.map(issue => {
        return <div key={"info-content-" + issue.id} className="polymer-validation-item-container">
                    <span className="polymer-validation-item-icon">{<ValidationIcon issue={issue}/>}</span>
                    <span className="polymer-validation-item-content">
                        {<h1>{issue.title}</h1>}
                        {issue.description}
                    </span>
            </div>
    })

    let infoText = (
        <div ref={infoTextRef} style={infoStyle} onMouseOut={onMouseOut} className={`polymer-validation-item ${typeClass} ${hoverClass}`}>
            {infoItemContent}
        </div>
    )
    
    const className = 'polymer-validation-indicator'

     // Determine line connector color
     let connectorStroke = "rgba(0,0,0,0.1)"

    // Wrapper style for connector
    const connectorWrapperStyle = {
        position: 'absolute',
        top: px(3),
        left: px(-1),
        width: '100vw',
        height: pageRect.height,
        pointerEvents: 'none',
        zIndex: 1
    }

    // Determine most relevant icon to show
    var priorityIssue = issue
    issue.siblingIssues.forEach( siblingIssue => {
        if (siblingIssue.type == 'error') {
            priorityIssue = siblingIssue
        } else if (siblingIssue.type == 'warning' && priorityIssue.type == 'requirement') {
            priorityIssue = siblingIssue
       }
    })

     let connector = <div style={connectorWrapperStyle}>
                         <Connector
                             key={issue.top + issue.id + issue.hasLayout}
                             el1={infoTextRef.current}
                             el2={sectionRef}
                             shape="narrow-s"
                             stem={8}
                             left={offsetX}
                             top={connectorOffsetY}
                             stroke={connectorStroke}
                             direction="r2l" // "l2l", "r2r", "l2l"
                             roundCorner={true}
                             endArrow={false}
                         />
                         {/* {(hovering || selected) && <div className={`polymer-validation-section-highlight polymer-validation-section-highlight-${issueType}`} style={sectionHighlightStyle}></div>} */}
                     </div>

    return <div>        
                {hovering ? connector : null}
                <div onMouseOut={onMouseOut} ref={ref} style={style} className={className}>
                    <div className="polymer-validation-item-container">
                        <div onMouseOver={onMouseOver} ref={infoDotRef} className="polymer-validation-indicator-content">
                            <ValidationIcon key={priorityIssue.id} issue={priorityIssue}/>
                        </div>
                        {infoText}
                    </div>
                </div>
                
            </div>
}

function PolymerValidationItem({ issue, onLayout, sectionRef, width, selected, onSelect, containerWidth }) {
    
    // State
    const ref = React.useRef(null)
    const [hovering, setHovering] = React.useState(false)
    const [draw, redraw] = React.useState(0);

    // Redraw for the connector
    React.useEffect(() => {
        redraw(Math.random());
    }, [ref.current, sectionRef, selected, issue.top]);


    const updateLayout = () => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            onLayout(issue, rect)
        }
    }

    // When loaded and layout has happened tell the parent
    React.useLayoutEffect( () => {
        updateLayout()
    }, [ref, ref.current, selected])
    
    // Validaite required 
    if (sectionRef == null) {
        return 
    }

    // Calculate offsets based on where page-container is and how much the canvas has scrolled.
    var canvas    = document.getElementById('canvas')
    var pageRect  = document.getElementById('page-container').getBoundingClientRect()
    var cavasRect = canvas.getBoundingClientRect()

    let offsetY   = canvas.scrollTop - cavasRect.top//  - window.scrollY
    let offsetX   = -(pageRect.left - containerWidth)
    let connectorOffsetY = -(cavasRect.top + window.scrollY)
    
    // Calculate box to highlight the canvas section or element
    let sectionRect = sectionRef.getBoundingClientRect()
    let sectionHighlightStyle = {
        position: 'absolute',
        boxSizing: 'border-box',
        left: `${(sectionRect.left + offsetX)}px`,
        top: `${(sectionRect.top + offsetY)}px`,
        width: px(sectionRect.width),
        height: px(sectionRect.height),
    }


    // Wrapper style for connector
    const connectorWrapperStyle = {
        position: 'absolute',
        top: px(0),
        left: px(0),
        width: '100vw',
        height: pageRect.height,
        pointerEvents: 'none',
        zIndex: 10 + (hovering ? 1 : 0)
    }

    var issueType = 'error'
    var tintColor = 'red'
    if (issue.type == 'warning') {
        issueType = 'warning'
        tintColor = 'orange'
    }

    // Determine line connector color
    let connectorStroke = (hovering || selected) ? tintColor : "#B6B6B6"

    let connector = <div style={connectorWrapperStyle}>
                        <Connector
                            key={issue.top + issue.id + issue.hasLayout}
                            el1={ref.current}
                            el2={sectionRef}
                            shape="narrow-s"
                            stem={8}
                            left={offsetX}
                            top={connectorOffsetY}
                            stroke={connectorStroke}
                            direction="r2l" // "l2l", "r2r", "l2l"
                            roundCorner={true}
                            endArrow={false}
                        />
                        {/* {(hovering || selected) && <div className={`polymer-validation-section-highlight polymer-validation-section-highlight-${issueType}`} style={sectionHighlightStyle}></div>} */}
                    </div>

    var typeClass = 'polymer-validation-item-' + issueType

    const onMouseOver = () => {
        setHovering(true)
    }

    const onMouseOut = () => {
        setHovering(false)
    }

    // Item style
    const style = {
        left: '0px',
        top: px(issue.top),
        width: px(width),
        visibility: issue.hasLayout ? 'visible' : 'hidden'
    }

    const hoverClass = (hovering || selected) ? "selected" : ""
    
    const onClick = () => {
        onSelect(issue)
    }

    return (
        <div onClick={onClick}>
            <div ref={ref} onMouseOver={onMouseOver} onMouseOut={onMouseOut} className={`polymer-validation-item ${typeClass} ${hoverClass}`} style={style}>
                <div className="polymer-validation-item-container">
                    <span className="polymer-validation-item-icon"><ValidationIcon issue={issue} errorState={true}/></span>
                    <span className="polymer-validation-item-content">
                        {/* {issue.type && <h2>{issue.type}</h2>} */}
                        {<h1>{issue.title}</h1>}
                        {selected && <span>{issue.description}</span>}
                    </span>
                    <span className="polymer-validation-item-chevron">
                        {selected ? chevronUp : chevronDown}
                    </span>
                </div>
            </div>
            {issue.hasLayout && connector}            
        </div>
    )
}

export { ContentBuilderValidation, ContentBuilderValidationToolbar, ContentBuilderValidationStateProvider, checkValidationIssues, useValidationState }