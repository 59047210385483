import React, { useState, useEffect } from 'react';
import { useStyle } from '../Style.tsx';
import { useRendererContext } from '../../RendererContext.tsx';    
import { replaceChildrenForNodeType } from '../../Utils.tsx';
import { ConfigurationLabel } from '../ButtonStyle/ConfigurationLabel.tsx';

export function Button({ buttonstyle, children }) {
    const style = useStyle()
    const rendererContext = useRendererContext();

    if (buttonstyle && rendererContext.viewCallback ) {
        let buttonStyleView = rendererContext.viewCallback(buttonstyle);
        let configuration = replaceChildrenForNodeType(buttonStyleView, 'ConfigurationLabel', children);
        if (configuration) {    
            return configuration;
        }
    } 
    return (
        <button style={style}>{children}</button>
    );
}
