
/* eslint-disable */

import HeaderComponent from './Components/HeaderComponent.js'
import CalendarComponent from './Components/CalendarComponent.js'
import ActionComponent from './Components/ActionComponent.js'
import QuizComponent from './Components/QuizComponent.js'
import TextComponent from './Components/TextComponent.js'
import UniversityComponent from './Components/UniversityComponent.js'
import GalleryComponent from './Components/GalleryComponent.js'
import LearnMoreComponent from './Components/LearnMoreComponent.js'
import ImageComponent from './Components/ImageComponent.js'
import VideoComponent from './Components/VideoComponent.js'
import MapComponent from './Components/MapComponent.js'
import LargeHeaderComponent from './Components/LargeHeaderComponent.js'
import VideoHeaderComponent from "./Components/VideoHeaderComponent.js"
import NumberComponent from "./Components/NumberComponent.js"

const componentList = [
    new HeaderComponent(),
    new TextComponent(),
    new ActionComponent(),
    new GalleryComponent(),
    new UniversityComponent(),
    new CalendarComponent(),
    new QuizComponent(),
    new VideoComponent(),
    new ImageComponent(),
    new MapComponent(),
    new LargeHeaderComponent(),
    new VideoHeaderComponent(),
    new NumberComponent()
]


const componentTemplates = [
    {
        title: 'Text',
        columns: 2,
        components: [
            { template: new TextComponent("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"), title: "Paragraph" },
            { template: new TextComponent("Section Header", 'h2'), title: "Section Header" },
            { template: new LargeHeaderComponent(), title: "Large Header" },
            { template: new VideoHeaderComponent(), title: "Video Header" },
            { template: new NumberComponent(), title: "Number" },
        ]
    },

    {
        title: 'Media',
        columns: 2,
        icon: 'gallery',
        components: [
            { template: new ImageComponent(), title: "Image" },
            { template: new GalleryComponent(), title: "Gallery" },
            { template: new VideoComponent(), title: "Video" },
            { template: new MapComponent(), title: "Map" },
        ]
    },


    {
        title: 'Info',
        icon: 'facts',
        columns: 2,
        components: [
            { template: new CalendarComponent(), title: "Calendar Event" },
            {}
        ]
    },

    {
        title: 'Interactive',
        icon: 'quiz',
        columns: 2,
        components: [
            { template: new QuizComponent(),  title: "Quiz Question" },
            {}
        ]
    },


]

export { componentList, 
         componentTemplates, 
         MapComponent, 
         HeaderComponent, 
         CalendarComponent, 
         GalleryComponent, 
         TextComponent, 
         LearnMoreComponent, 
         ImageComponent, 
         ActionComponent, 
         VideoComponent,
         QuizComponent,
         UniversityComponent }