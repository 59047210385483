import * as React from 'react'
import AssetImage from './AssetImage'

function AssetLibraryAlbums({ albums, onAlbumSelected }) {
    const albumCells = albums.map((album) => {
        var id = album.id ?? album.name
        return <AssetLibraryAlbum key={id} title={album.name} assets={album.assets} id={id} onClick={onAlbumSelected}></AssetLibraryAlbum>
    })

    return (
        <div className="polymer-grid polymer-grid-albums-columns">
            {albumCells}
        </div>
    )
}

function AssetLibraryAlbum({ assets, title, id, onClick }) {
    let firstAsset = (assets && assets.length) ? assets[0] : null

    return (
        <div className={"asset-album-item"} onClick={() => {
            onClick(id)
        }}>
            {firstAsset && <AssetImage asset={firstAsset} width={"100%"} height={"100%"}/>}
            <div className="asset-album-item-details">
                <span className="asset-album-title">{title ?? "Untitled"}</span>
                <span className="asset-album-count">{assets.length} Image{assets.length > 1 ? "s" : ""}</span>
            </div>
        </div>
    )
}

export default AssetLibraryAlbums