import * as React from 'react'
import { useYapPageState } from '../uikit-components/Page'
import useUniqueId from '../page-components/useUniqueId'

function PolymerHeaderButton(props) {

    var className = "polymer-header-button "
    if (props.primary == true) {
        className += " primary"
    }
    if (props.disabled == true) {
        className += " disabled"
    }
    if (props.highlight == true) {
        className += " highlight"
    }

    if (props.content) {
        const state = useYapPageState()
        const id = useUniqueId()
        const [mouseOverDetail, setMouseOverDetail] = React.useState(false)

        let onMouseOver = () => {
            state.setDropdownId(id)
        }
    
        let onMouseOut = () => {
            state.clearDropdownId()
        }
    
        let button = (
            <button onMouseOver={onMouseOver} onMouseOut={onMouseOut} className={className}>
                    {props.children}
            </button>
        )
    
        const dropdown = (
            <PolymerHeaderButtonDropdown setMouseOver={setMouseOverDetail} show={true}>
                {props.content}
            </PolymerHeaderButtonDropdown>
        )

        return (
            <div className="polymer-header-button-with-content">
                {button}
                { (state.dropdownId == id || mouseOverDetail) && dropdown}
            </div>
        )
    } else {
        return (
            <button onClick={props.onClick} className={className}>
                    {props.children}
            </button>
        )
    }
}

function PolymerHeaderButtonDropdown({ children, setMouseOver }) {
    
    const mouseOutRef = React.useRef(null)

    let onMouseOver = () => {
        clearTimeout(mouseOutRef.current)
        setMouseOver(true)
    }

    let onMouseOut = () => {
        clearTimeout(mouseOutRef.current)
        mouseOutRef.current = setTimeout( () => {
            setMouseOver(false)
        }, 10)
    }

    return (
        <div className="polymer-header-button-dropdown">
            <div className="polymer-header-button-dropdown-content"  onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
                {children}
            </div>
        </div>
    )
}

export default PolymerHeaderButton 