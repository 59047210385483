
// Example Templates
import MultipleChoiceTemplate from "./Templates/MultipleChoiceTemplate.js"
import EmptySplitTemplate from "./Templates/EmptySplitTemplate.js"
import ImageQuestionTemplate from './Templates/ImageQuestionTemplate.js'
import DrawingResponseTemplate from './Templates/DrawingResponseTemplate.js'


const templateLibrary = [
    {
        name: 'Quiz',
        templates: [
            { template: new EmptySplitTemplate(), title: "Empty Split View"},
            { template: new MultipleChoiceTemplate(), title: "Multiple Choice" },
            { template: new ImageQuestionTemplate(), title: "Image Question" },
            { template: new DrawingResponseTemplate(), title: "Drawing Response"}
        ]
    },
]

export {  templateLibrary  }