import React, { createContext, useContext, ReactNode } from 'react';

import styled from 'styled-components'

export function ViewStyle({children}) {
    const style = {...useStyle()}
    return <div style={style}><ClearStyle>{children}</ClearStyle></div>
}

export function ClearStyle({ children }) {
    return <StyleContext.Provider value={{style: {}}}>{children}</StyleContext.Provider>
}

// Define a type for the style context
export interface StyleContextType {
    style: React.CSSProperties;
}

// Create the StyleContext with an empty default value
export const StyleContext = createContext<StyleContextType>({ style: {} });

// Custom hook to use the StyleContext
export function useStyleContext() {
    return useContext(StyleContext);
}

export function useStyle() : React.CSSProperties {
    return useContext(StyleContext).style ?? { };
}

// Provider component to pass down styles
export function StyleProvider({ children, style }: { children: ReactNode; style: React.CSSProperties }) {
    return <StyleContext.Provider value={{ style }}>{children}</StyleContext.Provider>;
}
