import * as React from 'react'

/**
 * React interface
 */
function Component(props) {
    return <>{props.children}</>
}

function Modifier(props) {
    return <>{props.children}</>
}
export { Component, Modifier }