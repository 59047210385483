import * as React from 'react'
import { v4 as uuid } from 'uuid';

function useUniqueId(existingId) {
    const id = React.useRef(existingId)
    const unique_id = id.current

    React.useEffect( () => {
        if (existingId == null) {
            id.current = uuid();
        }
    }, [])

    return id.current
}

export default useUniqueId