import React from 'react';
import { validationContent } from '../InspectorValidation.js'

function InspectorProperty(props) {
    let { property } = props;

    let propTitle = props.title && (
        <h2>
            {props.title}
        </h2>
    );

    var propertyValidationContent = property ? validationContent(property) : null;

    let propertyContent = (
        <div className="inspector-property">
            {propTitle}
            <div className="inspector-property-value">
                {props.children}
            </div>
        </div>
    );

    let className = (validationContent) ? "inspector-property-with-validation" : null;

    if (props.singleColumn) {
        className += ' inspector-property-single-column';
    }

    if (props.divider) {
        className += ' inspector-property-with-divider';
    }

    return (
        <div className={className}>
            {propertyContent}
            {propertyValidationContent}
        </div>
    );

}

export { InspectorProperty }