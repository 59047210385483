import React from 'react';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import AssetImage from "../../asset-components/AssetImage.js";
import PolymerAssetType from '../../asset-components/AssetType.js';


function InspectorImageWell(props) {
    if (props.children) {
        return <InspectorImageWellSelector {...props}/>
    } else if (props.condensed == true) {
        return <InspectorImageWellCondensed {...props}/>
    } else {
        return <InspectorImageWellLarge {...props}/>
    }
}

function InspectorImageWellSelector(props) {
    const { setShowAssetLibrary, onAssetSelected, setAssetLibraryTypes } = useAppState();

    const onImageSelectedCallback = (asset) => {
        if (props.onImageSelected) {
            props.onImageSelected(asset);
        }
    };

    return (<div
            onClick={() => {
                setAssetLibraryTypes([PolymerAssetType.Image]);
                setShowAssetLibrary(true);
                onAssetSelected.current = onImageSelectedCallback;
            }}
            >
            {props.children}
        </div>
    )

}

function InspectorImageWellLarge(props) {
    const { setShowAssetLibrary, onAssetSelected, setAssetLibraryTypes } = useAppState();

    const [dimensions, setDimensions] = React.useState(null);
    var content = "Choose Image";
    var style = {};

    if (dimensions) {
        style = {
            backgroundSize: 'cover',
            aspectRatio: dimensions.width / dimensions.height,
            width: '100%',
            height: 'unset'
        };
    }

    const onImageSelectedCallback = (asset) => {
        if (props.onImageSelected) {
            props.onImageSelected(asset);
        }
    };

    const chooseTitle = (
        <div key={"title"} className="inspector-image-well-title">
            <span>{content}</span>
        </div>
    );

    const replaceTitle = (
        <div key={"replaceTitle"} className="inspector-image-well-action">
            <span>{"Replace"}</span>
        </div>
    );
    
    const hasImage = props.src != null
    const imageWellClassName = hasImage ? "image-filled" : ""

    return (
        <div className="inspector-property" style={{ paddingBottom: "0px" }}
            onClick={() => {
                setAssetLibraryTypes([PolymerAssetType.Image]);
                setShowAssetLibrary(true);
                onAssetSelected.current = onImageSelectedCallback;
            }}>

            <div className={`inspector-image-well ${imageWellClassName}`} style={style}>
                {props.src != null && <AssetImage key={"asset"} asset={props.model} onDimensions={setDimensions} width={"100%"} maxHeight={300}/>}
                {props.src == null && chooseTitle}
                {props.src != null && replaceTitle}
            </div>
        </div>
    );
}

function InspectorImageWellCondensed(props) {
    const { setShowAssetLibrary, onAssetSelected, setAssetLibraryTypes } = useAppState();

    const [dimensions, setDimensions] = React.useState(null);
    var content = "Choose Image";
    var style = {};

    const onImageSelectedCallback = (asset) => {
        if (props.onImageSelected) {
            props.onImageSelected(asset);
        }
    };

    const chooseTitle = (
        <div key={"chooseTitle"} className="inspector-image-well-title">
            <span>{content}</span>
        </div>
    );

    const fileTitle = (
        <div key={"fileTitle"} className="inspector-image-well-title">
            <span>{props.model.title}</span>
        </div>
    );

    return (
        <div className="inspector-property" style={{ paddingBottom: "0px", paddingTop: "0px" }}
            onClick={() => {
                setAssetLibraryTypes([PolymerAssetType.Image]);
                setShowAssetLibrary(true);
                onAssetSelected.current = onImageSelectedCallback;
            }}>

            <div className="inspector-image-well-condensed image-filled" style={style}>
                <div key={"thumbnail"} className="thumbnail">
                    {props.src != null && <AssetImage asset={props.model} onDimensions={setDimensions} width={"100%"} height={"100%"} />}
                </div>
                <span>
                    {props.src == null && chooseTitle}
                    {props.model.title != null && fileTitle}
                </span>
            </div>
        </div>
    );
}

export { InspectorImageWell, InspectorImageWellCondensed }