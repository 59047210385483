import * as React from 'react';
import { useSection } from '../SectionContext.js';

function Seperator(props) {
    let { sectionStyle } = useSection();
    var style = {
        borderColor: sectionStyle.colors.separator,
    };

    return <div className="view-seperator" style={style}>
    </div>;
}

export { Seperator }