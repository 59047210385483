/* eslint-disable */
import React, { useEffect , Suspense } from 'react'

import {  YapHeaderButton, YapPage, YapHeader, VStack, YapContextMenuActionMore , useIcons } from '@yapstudios/yap-content-builder'
import { componentList } from './ContentConfiguration.js';
import { useNavigate, useLoaderData, Link , useRevalidator, redirect, Await } from "react-router-dom";
import { v4 as uuid } from 'uuid';

import { loadDocuments, deleteDocument, duplicateDocument, initBucketData, loadConfig, currentInstanceId } from './AppData.js'

// Auth / loading
import { AuthProvider } from "./useAuth.js"
import { ProtectedRoute } from "./ProtectedRoute.js"

// Configuration
import { getConfiguration } from './ContentConfiguration.js'

// Import YapContentBuilder components
import { YapContentPreview, YapContextMenu, YapContextMenuOption } from '@yapstudios/yap-content-builder'

/**
* Loader
*/
export const ContentIndexAuthLoader = (props) => {
    const { userPromise } = useLoaderData();

    return (
        <AuthProvider>
            <ProtectedRoute>
                <Suspense fallback={<ContentIndexLoadingState/>}>
                    <Await
                        resolve={userPromise()}
                        errorElement={<div>Something went wrong!</div>}
                        children={(data) => (
                            <ContentIndex documents={data.documents} config={data.config}/>
                        )}
                    />
                </Suspense>
            </ProtectedRoute>
        </AuthProvider>
    );
};

const ContentIndexLoadingState = () => {
    const content = (
            <YapPage fullscreen={true} >
                <YapHeader fixed={true} largeTitle={false} title={""} headerActions={[]}></YapHeader>
                <div className={`layout layout-app with-top-toolbar`}>
                    <section className="layout-center">
                        <div className="loading-content"/>
                    </section>
                </div>
            </YapPage>
    )
    return content
}

/*
 * Asset Demo Index Page
 */
const ContentIndex = ( { documents, config }) => {
    
    const navigate  = useNavigate();
    const revalidator = useRevalidator();

    if (documents == null) {
        return <div></div>
    }

    const  { components, componentTemplates, templateLibrary, validations, fonts, styles, pageRenderer, previewRenderer, indexPreviewWidth, indexPreviewClassName, indexPreviewRenderer } = getConfiguration(currentInstanceId())

    const actions = [
        <YapHeaderButton key="new-page" onClick={() => {
            navigate("/" + uuid());
        }}>{"New"}</YapHeaderButton>
    ]

    const leftActions = [
        <YapHeaderButton key="logout" onClick={() => {
            navigate("/login");
        }}>{"Logout"}</YapHeaderButton>
    ]

    const confirmedDelete = async (documentId) => {
        await deleteDocument(documentId)
        revalidator.revalidate() // refresh
    }
     
    const confirmedDuplicate = async (documentId) => {
        await duplicateDocument(documentId)
        revalidator.revalidate() // refresh
    }

    const sortedDocuments = documents.sort( (a, b) => {
        return a.created > b.created 
    })

    const gridStyle = {
        "grid-template-columns" : `repeat(auto-fill, ${indexPreviewRenderer && indexPreviewRenderer.width ? indexPreviewRenderer.width + 40 : 180}px)`
    }

    const documentPreviews = sortedDocuments.map( (doc => {
        let page = doc.pages[0]
        return (
            <VStack spacing={20}>
                <Link to={"/" + doc.id}>
                    <div className={indexPreviewClassName ?? "content-index-preview-item"}>
                        <YapContentPreview fonts={fonts} styles={styles} components={components} page={page} width={150} renderer={indexPreviewRenderer} pageRenderer={pageRenderer}/>
                    </div>
                </Link>
                <ItemMenu onDelete={confirmedDelete} onDuplicate={confirmedDuplicate} pageTitle={page.title} docId={doc.id}/>
            </VStack>                
        )
    }))

    const content = (
        <YapPage fullscreen={true} >
            <YapHeader fixed={true} largeTitle={false} title={config.indexTitle ?? "Content Builder"} headerActionsLeft={leftActions} headerActions={actions}></YapHeader>
            <div className={`layout layout-app with-top-toolbar`}>
                <section className="layout-center">
                    <div className="preview-grid polymer-grid polymer-grid-4-columns" style={gridStyle}>
                        {documentPreviews}
                    </div>
                </section>
            </div>
        </YapPage>
    )

    return content
}

function ItemMenu(props) {
    const icons = useIcons()

    return (
        <VStack spacing={10} justify={"center"} align={"center"}>
            <YapContextMenu action={<YapContextMenuActionMore />}>
                
                <YapContextMenuOption title={"Duplicate"} icon={icons.get('menu.duplicate')} destructive={false} onClick={ () => {
                    props.onDuplicate(props.docId)
                }}/>

                <YapContextMenuOption title={"Delete"} icon={icons.get('menu.trash', 'color-destructive')} destructive={true} onClick={ () => {
                    var confirmDelete = confirm(`Are you sure you want to delete ${props.pageTitle}`);
                    if (confirmDelete) {
                        props.onDelete(props.docId)
                    }
                }}/>
            </YapContextMenu>
        </VStack>
    )
}

async function indexLoader() {
    // Initialise info from user 
    initBucketData()

    console.log('load documents')    
    const documents = await loadDocuments();
    const config = await loadConfig()
    return { documents: documents, config: config} 
}

export { ContentIndex, indexLoader }