import * as React from 'react'

const prevIcon = (enabled, onClick) => {
    return (
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { enabled && onClick() }}>
            <path d="M0.962891 8.00684C0.968424 7.79655 1.00993 7.60563 1.0874 7.43408C1.16488 7.257 1.28662 7.08545 1.45264 6.91943L7.72803 0.776855C7.98258 0.527832 8.28971 0.40332 8.64941 0.40332C8.8929 0.40332 9.11426 0.464193 9.31348 0.585938C9.51823 0.702148 9.68148 0.859863 9.80322 1.05908C9.92497 1.2583 9.98584 1.47965 9.98584 1.72314C9.98584 2.09391 9.84196 2.41764 9.5542 2.69434L4.08398 7.99854L9.5542 13.311C9.84196 13.5988 9.98584 13.9253 9.98584 14.2905C9.98584 14.534 9.92497 14.7554 9.80322 14.9546C9.68148 15.1538 9.51823 15.3115 9.31348 15.4277C9.11426 15.5495 8.8929 15.6104 8.64941 15.6104C8.28971 15.6104 7.98258 15.4831 7.72803 15.2285L1.45264 9.08594C1.28109 8.91992 1.15658 8.75114 1.0791 8.57959C1.00163 8.40251 0.962891 8.21159 0.962891 8.00684Z"
            fill={enabled ? "#324DC7" : "#e3e3e3"}/>
        </svg>
    )
}

const nextIcon = (enabled, onClick) => {
    return (
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { enabled && onClick() }}>
            <path d="M9.03711 8.00684C9.03158 7.79655 8.99007 7.60563 8.9126 7.43408C8.83512 7.257 8.71338 7.08545 8.54736 6.91943L2.27197 0.776855C2.01742 0.527832 1.71029 0.40332 1.35059 0.40332C1.1071 0.40332 0.885742 0.464193 0.686523 0.585938C0.481771 0.702148 0.318522 0.859863 0.196777 1.05908C0.0750326 1.2583 0.0141602 1.47965 0.0141602 1.72314C0.0141602 2.09391 0.15804 2.41764 0.445801 2.69434L5.91602 7.99854L0.445801 13.311C0.15804 13.5988 0.0141602 13.9253 0.0141602 14.2905C0.0141602 14.534 0.0750326 14.7554 0.196777 14.9546C0.318522 15.1538 0.481771 15.3115 0.686523 15.4277C0.885742 15.5495 1.1071 15.6104 1.35059 15.6104C1.71029 15.6104 2.01742 15.4831 2.27197 15.2285L8.54736 9.08594C8.71891 8.91992 8.84342 8.75114 8.9209 8.57959C8.99837 8.40251 9.03711 8.21159 9.03711 8.00684Z" 
                fill={enabled ? "#324DC7" : "#e3e3e3"}/>
        </svg>
    )
}

function PolymerPrevNext({ total, index, onIndexChange, width }) {

    const onPrev = () => {
        if (index <= 0) { return }
        onIndexChange(index - 1)
    }
    
    const onNext = () => {
        if (index >= total - 1) { return }
        onIndexChange(index + 1)
    }

    React.useEffect(() => {

        const handlePress = (e) => {
            switch (e.key) {
                case "ArrowRight":
                    onNext()
                    break
                case "ArrowLeft":
                    onPrev()
                    break
                default:
                    break
            }
        }

        window.addEventListener('keyup', handlePress);
        
        return () => {
             window.removeEventListener('keyup', handlePress)
        }

    }, [index]);

    return <div className="polymer-prev-next" style={{minWidth: width ? width + 'px' : 'auto'}}>{prevIcon(index > 0, onPrev)} <span>{index + 1} of {total}</span>  {nextIcon(index < total - 1, onNext)}</div>
}

export default PolymerPrevNext