function px(value) {
    return `${value}px`
}

String.prototype.capitalized = function() {
    // Method 1: Using toUpperCase()
    // return this.toUpperCase();
  
    // Method 2: Using string manipulation
    // const firstChar = this.charAt(0).toUpperCase();
    // const restOfString = this.slice(1);
    // return firstChar + restOfString;
  
    // Method 3: Using regular expressions
    return this.replace(/^(.)(.*)$/, (_, firstChar, restOfString) => firstChar.toUpperCase() + restOfString);
};

String.prototype.fileExtension = function() {
    const file = this.split('?')[0];
    const comps = file.split('.')
    return (comps.length > 1) ? comps[comps.length - 1].toLowerCase() : null
}


export { px }