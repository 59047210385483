import React from 'react';
import Props from '../../page-components/PropsHelper';

function InspectorControlText(props) {
    const onChange = (e) => {
        var text = e.target.value;
        if (props.onChange) {
            props.onChange(text);
        }
    };

    const style = {
        width: Props.width(props),
        height: Props.height(props),
        minHeight: Props.height(props)
    };

    if (props.multiline) {
        return <textarea style={style} placeholder={props.placeholder} value={props.value} key={props.key} className="control-input" onChange={onChange}></textarea>;
    } else {
        return <input style={style} type="textfield" value={props.value} key={props.key} className="control-input" onChange={onChange}></input>;
    }
}

export {InspectorControlText}