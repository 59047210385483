import React, { useState, useEffect } from 'react';
import { useStyle } from './Style.tsx';
import { useVariables,  Variables } from './Variable.tsx';
import JSON5 from 'json5'
import { executeExpression } from './Variable.tsx';

export function ForEach({ variable, value, children }) {
    const { variables } = useVariables();

    let reactChildren = React.Children.toArray(children).filter((child) => { return React.isValidElement(child) });

    const forEachValue = value ?? variables[variable]
    if (forEachValue) {
        var parsed = forEachValue
        if (parsed == null || parsed.length == 0 || parsed.length == null || parsed.map == null) {    
            return null
        }
        // console.log('forEachValue: ', forEachValue);
        // let parsed = executeExpression(forEachValue, variables) ?? [];
        // if (parsed == null || parsed.length == 0 || parsed.length == null) {
        //     return null;
        // }
        // let parsed = []
        // try {
        //     parsed = JSON5.parse(forEachValue) ?? [];
        // } catch (e) {
        //     console.log(e)
        // }
        
        return parsed.map((item, index) => {

            const args = {  index: index,  item: item as any, element: item as any } as any;

            return React.Children.map(reactChildren, (child) => {
                if (React.isValidElement(child)) {
                    return <>
                        <Variables key={index} {...args}>{React.cloneElement(child, {...child.props, ...args}, child.props.children)}</Variables>
                        </>
                    //return <Variables key={index} {...args}>{child}</Variables>;
                } else {
                    return null;
                    return child;
                }
            })
        });
    } else {
        return <b>[${variable}]</b>;
    }
}

export function Iterator({ property, children, item, key, index })  {
    
    let args = {}
    args[property] = item
    args['index'] = index
    let reactChildren = React.Children.toArray(children).filter((child) => { return React.isValidElement(child) });

    let firstChild = children ? reactChildren[0] as React.ReactElement :  null; 
    if (firstChild) {
        return <Variables {...args}>{React.cloneElement(firstChild, args)}</Variables>
    } else {
        return null
    }
}