import React from 'react';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import { InspectorProperty } from '../inspector-ui/InspectorProperty.js';
import { InspectorHeader } from '../inspector-ui/InspectorHeader.js';
import { InspectorControlText } from '../inspector-ui/InspectorControlText.js';

export function InspectorActionButton(props) {
    let { updateModel } = useAppState();
    var model = props.model;

    const canEditURL = model.canEditURL;
    const canEditTitle = model.canEditText;

    let urlProperty = (
        <InspectorControlText key={"url"} value={model.url} onChange={v => {
            model.url = v;
            updateModel(model);
        }} />
    );

    let urlText = (
        <span>{model.url}</span>
    );

    let title = (
        <InspectorProperty title="Title" property={'title'}>
            <InspectorControlText key={"title"} value={model.title} onChange={v => {
                model.title = v;
                updateModel(model);
            }} />
        </InspectorProperty>
    );

    return (
        <div>
            <section>
                <InspectorHeader title="Action Button" />

                <InspectorProperty title="URL" property={'url'}>
                    {canEditURL && urlProperty}
                    {!canEditURL && urlText}
                </InspectorProperty>
                {canEditTitle && title}
            </section>
        </div>
    );
}
