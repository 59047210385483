
import React, { createContext, useContext, ReactNode } from 'react';
import { useFunctions } from './Function.tsx';
import { nodeString } from '../Utils.tsx';
import { v4 as uuid } from 'uuid';

export function Script({ name, args, children }) {
    const { scripts } = useFunctions();
    const [ id , setID] = React.useState(uuid());

    let content = nodeString(<>{children}</>)

    if (scripts && id && content) {
        scripts[id] = content;
    }
    return <></>
}