import React from 'react'
import { useInspectorState, InspectorStateProvider } from './InspectorState.js'
import { useValidationState } from '../content-builder-components/ContentBuilderValidation.js'
import ValidationIcon from '../content-builder-components/ValidationIcon.js'

function validationIssues(property, short = false) {
    let { model } = useInspectorState()
    let id = model.id
    let { requiredValidations } = useValidationState()

    let idElements = id.split('.rootId-')
    let elementId = idElements[0]
    let sectionId = model.sectionId

    let modelIssues = requiredValidations.filter((issue) => {
        if (property) {
            let match = sectionId == issue.sectionId
            return match && property == issue.property
        } else {
            return sectionId == issue.sectionId && elementId == issue.elementId
        }
    })

    let order = [
        'error',
        'warning',
        'requirement'
    ]

    return modelIssues.sort((a, b) => {
        let typeA = order.indexOf(a.type);
        let typeB = order.indexOf(b.type);
        return typeA - typeB;
    })

}

function validationContent(property) {

    var issue = validationIssues(property)

    if (issue) {
        return issue.map(issue => {
            return (
                <div key={"validation-issue-" + issue.id} className="inspector-property-validations">
                    <div className="inspector-property-requirement">
                        <div className="inspector-property-requirement-icon"><ValidationIcon issue={issue} /></div>
                        {issue.description}
                    </div>
                </div>
            )
        })

    }

    return null
}

export { validationContent }