/* eslint-disable */
import React, { useEffect, async } from 'react'

/**
 * Cache that will retain in memory images that have been cropped.
 * For a particular src, the image will only 
 */
class ImageCropCache {
    constructor() {
        this.cache = {};
        this.promiseCache = {};
    }

    // Method to cache an image based on properties
    cacheImage(src, image, properties) {
        if (!this.cache[src]) {
            this.cache[src] = {
                key: JSON.stringify(properties),
                image: image
            }
        }
        //console.log(`ImageCropCache keys [${Object.keys(this.cache).length}]`)
    }

    // Method to get the cached image based on properties
    getCachedImage(src, properties) {
        const key = JSON.stringify(properties);
        let object = this.cache[src] 
        if (object && object.key == key) {
            return object.image
        } else {
            return null
        }
    }

    cachePromise(src, promise, props) {
        const key = JSON.stringify(props);
        this.promiseCache[src] = { promise: promise, key }
    }

    getCachedPromise(src, props) {
        const key = JSON.stringify(props);
        const obj = this.promiseCache[src]
        if (obj && obj.key == key) {
            return this.promiseCache[src].promise
        } else {
            return null
        }
    }
    clearPromise(src) {
        delete this.promiseCache[src]
    }

    clearImage(src) {
        delete this.cache[src]
    }    
}

// Global image cache
const _assetImageCropCache = new ImageCropCache()

export default function ImageCropped({ width, height, src, crop, style, contentMode }) {
    const previewCanvasRef = React.useRef();
    const imgRef = React.useRef(new Image());

    const scale = 1.0
    const rotate = 0.0
    const imageCrop = crop ?? {}
    const dimensions = { 
        width: width ?? crop.width,
        height: height ?? crop.height
    }

            
    // Properties to key the image cache off.
    const cacheProps = {
        cropX: imageCrop.x,
        cropY: imageCrop.y,
        cropWidth: imageCrop.width,
        cropHeight: imageCrop.height
    }

    const createImageBitmapCached = (imageRef, cropX, cropY, cropWidth, cropHeight) => {
        //console.log(`createImageBitmapCached [${imageRef.src}] [${cropX} ${cropY} ${cropWidth} ${cropHeight}]`)

        const props = cacheProps

        const existingPromise = _assetImageCropCache.getCachedPromise(imageRef.src, props)
        if (existingPromise) {
            //console.log('   |_ existing promise')
            return existingPromise
        }

        const promise = new Promise((resolve, reject) => {
           
            //console.log('fetching cached image ' + src)
            const cachedImage = _assetImageCropCache.getCachedImage(imageRef.src, props)
            if (cachedImage) {
                resolve(cachedImage);
            } else {
                createImageBitmap(imageRef, cropX, cropY, cropWidth, cropHeight)
                    .then(image => {
                        _assetImageCropCache.cacheImage(imageRef.src, image, props);
                        _assetImageCropCache.clearPromise(imageRef.src);
                        resolve(image);                        
                    })
                    .catch(error => {
                        //console.log(`   |_ error createImageBitmap ${imageRef.src}`)
                        //console.log(error)

                        _assetImageCropCache.clearPromise(imageRef.src);
                        _assetImageCropCache.clearImage(src);
                        reject(error);                        
                    });
            }
        });

        _assetImageCropCache.cachePromise(imageRef.src, promise, props)
        return promise
    }

    const cropImageLoaded = (image) => {
        if (contentMode == "fit") {
            fitImage(image, previewCanvasRef.current, dimensions);
        } else {
            fillImage(image, previewCanvasRef.current, dimensions)
        }
    }

    const cropImage = async () => {
        if (
            imageCrop.width &&
            imageCrop.height &&
            imgRef.current &&
            imgRef.current.src &&
            previewCanvasRef.current
        ) {
            //console.log(`createImageBitmap ${crop.x} ${crop.y} ${crop.width} ${crop.height}`);

            Promise.any([
                // Create the crop of the image
                createImageBitmapCached(imgRef.current, crop.x, crop.y, crop.width, crop.height)
            ]).then((image) => {
                cropImageLoaded(image)
                _assetImageCropCache.clearPromise(imgRef.current.src);
            }).catch((e) => {
                //console.log(`Error cropping ${imgRef.current.src}`)
                //console.error(e)
                _assetImageCropCache.clearPromise(imgRef.current.src);
                _assetImageCropCache.clearImage(src);                
            })
            
        }
    }
 
    React.useEffect( () => {
        // Setup canvas
        let canvas = previewCanvasRef.current
        const pixelRatio = 1.0;
        canvas.width = Math.floor(dimensions.width  * pixelRatio)
        canvas.height = Math.floor(dimensions.height  * pixelRatio)            

        // ---
        // Check cache
        const cachedImage = _assetImageCropCache.getCachedImage(src, cacheProps)
        if (cachedImage) {
            cropImageLoaded(cachedImage)
            return // Early exit
        }

        // ---
        // No cache..
        
        // Create crop        
        imgRef.current.onload = () => {
            cropImage()
        }

        // Assign Src
        imgRef.current.src = src

        // Trigger crop immediately if cached
        cropImage()

    }, [src, imageCrop.x, imageCrop.y, imageCrop.width, imageCrop.height, scale, rotate, dimensions, width, height])

    const targetWidth = dimensions.width ?? imageCrop.width
    const targetHeight = dimensions.height ?? imageCrop.height

    return (<canvas
        ref={previewCanvasRef}
        style={{
            border: "0px solid black",
            objectFit: "contain",
            width: targetWidth,
            height: targetHeight,
            ...style
        }}
    />
    )
}

const TO_RADIANS = Math.PI / 180

// function to calculate crop values from source image, its visible size and a crop strategy
function fillImage(image, canvas, size) {

    // Validate canvas
    if (canvas == null) { return }

    // Get the canvas context
    const ctx = canvas.getContext('2d');
    
    // Calculate the aspect ratios
    const canvasAspectRatio = size.width / size.height;
    const imageAspectRatio = image.width / image.height;
    
    // Calculate the scaling factors
    let scaleX, scaleY;
    if (imageAspectRatio < canvasAspectRatio) {
      // Scale image to fit width
      scaleX = size.width / image.width
      scaleY = scaleX;
    } else {
      // Scale image to fit height
      scaleY = size.height / image.height;
      scaleX = scaleY;
    }
    
    // Calculate the position to center the image
    const xOffset = (canvas.width - (image.width * scaleX)) / 2;
    const yOffset = (canvas.height - (image.height * scaleY)) / 2;
    
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    // Draw the image with aspect fill
    ctx.drawImage(image, xOffset, yOffset, image.width * scaleX, image.height * scaleY);
 }


function fitImage(image, canvas, dimensions) {
    // Validate canvas
    if (canvas == null) { return }

    var context = canvas.getContext('2d');

    const pixelRatio = 1.0;// window.devicePixelRatio
    //canvas.width = Math.floor(dimensions.width  * pixelRatio)
    //canvas.height = Math.floor(dimensions.height  * pixelRatio)

	var imageAspectRatio = image.width / image.height;
	var canvasAspectRatio = canvas.width / canvas.height;
	var renderableHeight, renderableWidth, xStart, yStart;

	// If image's aspect ratio is less than canvas's we fit on height
	// and place the image centrally along width
	if(imageAspectRatio < canvasAspectRatio) {
		renderableHeight = canvas.height;
		renderableWidth = image.width * (renderableHeight / image.height);
		xStart = (canvas.width - renderableWidth) / 2;
		yStart = 0;
	}

	// If image's aspect ratio is greater than canvas's we fit on width
	// and place the image centrally along height
	else if(imageAspectRatio > canvasAspectRatio) {
		renderableWidth = canvas.width
		renderableHeight = image.height * (renderableWidth / image.width);
		xStart = 0;
		yStart = (canvas.height - renderableHeight) / 2;
	}

	// Happy path - keep aspect ratio
	else {
		renderableHeight = canvas.height;
		renderableWidth = canvas.width;
		xStart = 0;
		yStart = 0;
	}

    // Clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Draw canvas
	context.drawImage(image, xStart, yStart, renderableWidth, renderableHeight);
};
