import * as React from 'react'
import AssetImage from './AssetImage'
import { useAppAssetState } from './AssetCatalogueState.js';

function AssetPreviews({ assets, previews, assetsUsePreviewPath, limit, onSelectedPreview, highlightId, scale, border, assetVersion, dark, className, src, asset }) {

    if (assets == null) {
        throw new Error('AssetPreviews requires assets property');
    }

    if (previews == null) {
        return <div className="asset-previews">No Previews</div>;
    }

    let allPreviews = previews.flatMap((previewFunction) => previewFunction(assets)).filter(Boolean);

    if (limit > 0) {
        allPreviews = [allPreviews[0]];
    }

    const onPreviewClick = (preview) => {
        const idx = allPreviews.findIndex((existingPreview) => existingPreview === preview);

        var allContentPreviews = allPreviews.map( item => {
            if (item.contentPreview) {
                return item.contentPreview({ scale: 1.0 })
            } else {
                return null
            }
        }).filter(a => a)

        if (onSelectedPreview) {
            onSelectedPreview(preview, allContentPreviews, idx);
        }
    };

    const allPreviewsElements = allPreviews.map((preview, idx) => (
        <AssetPreviewDevice
            key={idx}
            highlightId={highlightId}
            highlightLayer={preview.highlightLayer}
            scale={scale}
            border={border}
            assets={assets}
            assetVersion={assetVersion}
            layers={preview.layers}
            dark={dark}
            contentPreview={preview.contentPreview}
            background={preview.background}
            defaultSrc={src}
            defaultAsset={asset}
            onClick={() => onPreviewClick(preview)}
            assetsUsePreviewPath={assetsUsePreviewPath}
        />
    ));

    return <div className={`asset-previews ${className}`}>{allPreviewsElements}</div>;
}

function AssetPreview(props) {
    const preview = props.preview

    return preview && <AssetPreviewDevice highlightId={props.highlightId}
        scale={props.scale}
        border={props.border}
        assets={props.assets}
        assetVersion={props.assetVersion}
        layers={preview.layers}
        contentPreview={preview.contentPreview}
        dark={props.dark}
        background={preview.background}
        defaultSrc={props.src}
        defaultAsset={props.asset}
        onClick={props.onClick} />
}

function AssetPreviewDevice(props) {

    const { previewAssetPath } = useAppAssetState()

    const screenSize = {
        width: 393,
        height: 852
    }
    const scale = props.scale ?? 0.5

    const style = {
        width: (screenSize.width * scale) + 'px',
        height: (screenSize.height * scale) + 'px',
        borderRadius: (32 * scale) + 'px',
        position: 'relative'
    }

    const backgroundStyle = {
        width: (screenSize.width * scale) + 'px',
        height: (screenSize.height * scale) + 'px',
        bborder: '0px',
        borderRadius: (12 * scale) + 'px',
        backgroundImage: 'url(' + (previewAssetPath ?? "") + "/" + props.background + ')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }

    var highlightLayerTop = null

    if (props.highlightLayer && props.highlightId != null) {
        let item = props.highlightLayer

        const highlightStyle = {
            borderRadius: (((item.cornerRadius ?? 0) + 4) * scale),
            position: 'absolute',
            left: (((item.x) * scale) - 2) + 'px',
            top: (((item.y) * scale) - 2) + 'px',
            width: ((item.placeholderWidth ?? item.width) * scale) + 'px',
            height: ((item.placeholderHeight ?? item.height) * scale) + 'px',
            border: '2px dashed #536BFF',
        }

        // const highlightBottomStyle = {
        //     ...highlightStyle,
        //     border: '2px solid white',
        //     boxShadow: '0px 0px 20px #0000ff50'
        // }

        // highlightLayerBottom = <div style={highlightBottomStyle} className='asset-preview-layer-highlight-bottom'></div>

        // Highlight Top
        const highlightStyleUnder = {
            ...highlightStyle,
            border: '2px solid white'
        }

        highlightLayerTop =
            <div>
                <div style={highlightStyleUnder} className='asset-preview-layer-highlight-under-border'></div>
                <div style={highlightStyle} className='asset-preview-layer-highlight'></div>
            </div>
    }

    let layers = (props.layers ?? []).map((item, idx) => {

        var borderStyle = null
        if (item.border) {
            borderStyle = "1px solid " + (item.borderColor ?? "#333333")
        }
        let layerStyle = {
            position: 'absolute',
            left: (item.x * scale) + 'px',
            top: (item.y * scale) + 'px',
            borderRadius: ((item.cornerRadius ?? 0) * scale),
            border: borderStyle
        }

        const highlightStyle = {
            borderRadius: (((item.cornerRadius ?? 0) + 4) * scale),
            position: 'absolute',
            left: (((item.x) * scale) - 2) + 'px',
            top: (((item.y) * scale) - 2) + 'px',
            width: ((item.placeholderWidth ?? item.width) * scale) + 'px',
            height: ((item.placeholderHeight ?? item.height) * scale) + 'px',
            border: '2px dashed #536BFF',
        }

        var highlightLayerBottom = null
        if (props.highlightId != null && props.highlightId == item.id) {
            const highlightBottomStyle = {
                ...highlightStyle,
                border: '2px solid white',
                boxShadow: '0px 0px 20px #0000ff50'
            }

            highlightLayerBottom = <div style={highlightBottomStyle} className='asset-preview-layer-highlight-bottom'></div>

            // Highlight Top
            const highlightStyleUnder = {
                ...highlightStyle,
                border: '2px solid white'
            }

            highlightLayerTop =
                <div>
                    <div style={highlightStyleUnder} className='asset-preview-layer-highlight-under-border'></div>
                    <div style={highlightStyle} className='asset-preview-layer-highlight'></div>
                </div>

        }

        var layerContent = null

        const className = "asset-preview-layer"

        if (item.asset) {
            var layerAsset = item.asset
            if (props.assetsUsePreviewPath) {
                layerAsset = {...layerAsset, src: (previewAssetPath ?? "") + "/" + layerAsset.src}
            }

            layerContent = (
                <div style={layerStyle} className={className}>
                    <AssetImage asset={layerAsset ?? props.defaultAsset} 
                                assetVersion={props.assetVersion} key={"layer" + idx + props.assetVersion} 
                                dark={props.dark} width={item.width} 
                                height={item.height} 
                                maxHeight={item.maxHeight} 
                                maxWidth={item.maxWidth} 
                                scale={scale} 
                                borderRadius={item.cornerRadius * scale} />
                </div>
            )
        } else if (item.src) {
            layerStyle['backgroundImage'] = "url('" + item.src + "')";
            layerStyle['backgroundPosition'] = 'center'
            layerStyle['backgroundSize'] = '16px 16px'
            layerStyle['width'] = ((item.placeholderWidth ?? item.width) * scale) + 'px'
            layerStyle['height'] = ((item.placeholderHeight ?? item.height) * scale) + 'px'
            layerContent = <div className={className} style={layerStyle}></div>
        } else {
            layerContent = null
        }

        return <div key={idx}>
            {highlightLayerBottom}
            {layerContent}
        </div>
    })

    const frameStyle = {
        borderRadius: (32 * scale)
    }

    const frameOuterStyle = {
        borderRadius: (24 * scale)
    }

    if (props.contentPreview) {
        // Border offset
        let previewStyle = {
            top: '-' + (20 * scale) + 'px',
            left: '-' + (20 * scale) + 'px',
            position: 'absolute'
        }

        let contentPreview = props.contentPreview()
        let previewElement = null
        if (contentPreview) {
            previewElement = React.cloneElement(contentPreview, {
                width: 390 * scale,
                interactionEnabled: false
            })
        }

        return (
            <div className="asset-preview-device" style={style} onClick={props.onClick}>
                <div style={previewStyle}>
                    {previewElement}
                </div>
                {layers}
                {highlightLayerTop}
            </div>
        )
    } else {
        return (
            <div className="asset-preview-device" style={style} onClick={props.onClick}>
                <div style={backgroundStyle} className="asset-preview-device-background" key={props.background} />
                {layers}
                <div className="asset-preview-frame" style={frameStyle}></div>
                <div className="asset-preview-frame-outer" style={frameOuterStyle}></div>
                {highlightLayerTop}
            </div>
        )
    }
}

export { AssetPreviews, AssetPreview, AssetPreviewDevice }