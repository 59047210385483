import React from 'react';

import { AssetDetail, AssetEditModeType } from '../../asset-components/AssetDetail.js'
import { Page } from '../../uikit-components/Page.js'
import Header from '../../uikit-components/Header.js'
import LargeButton from '../LargeButton.js';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import { InspectorProperty } from '../inspector-ui/InspectorProperty.js';

function InspectorImageActions(props) {
    const { asset, assetDidChange, cropAspectRatios } = props
    const { setShowAssetEditModal, canCrop } = useAppState();

    const cropModal = (
        <Page fullscreen={true} onBack={() => { }}>
            <Header largeTitle={false} title={"Crop Image"} />
            <AssetDetail modalEdit={true} 
                        editMode={true} 
                        editModeTypes={[AssetEditModeType.Crop]}
                        editModeType={AssetEditModeType.Crop}
                        cropAspectRatios={cropAspectRatios}
                        asset={asset} 
                        onCancel={ () => {
                            setShowAssetEditModal(null) 
                        }}
                        onAssetChange={(asset) => {
                            assetDidChange(asset)
                            setShowAssetEditModal(null)
                        }} 
                        onDelete={null}/>
        </Page>
    )

    const cropIcon = (
        <svg alt="Crop" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2616 14.7652C19.2616 15.0585 19.1564 15.3075 18.9461 15.5122C18.7414 15.7115 18.473 15.8111 18.141 15.8111H5.04233C4.79884 15.8111 4.60515 15.7391 4.46127 15.5953C4.31739 15.4514 4.24545 15.2577 4.24545 15.0142V1.93217C4.24545 1.60567 4.35336 1.34004 4.56918 1.13529C4.785 0.930539 5.04233 0.828163 5.34116 0.828163C5.63998 0.828163 5.89731 0.930539 6.11313 1.13529C6.32895 1.34004 6.43686 1.60567 6.43686 1.93217V13.4869C6.43686 13.6418 6.51157 13.7193 6.66098 13.7193H18.141C18.473 13.7193 18.7414 13.8189 18.9461 14.0181C19.1564 14.2173 19.2616 14.4663 19.2616 14.7652ZM0.551605 5.68412C0.551605 5.39083 0.653982 5.14457 0.858734 4.94535C1.06902 4.7406 1.34018 4.63822 1.67221 4.63822H14.7791C15.0171 4.63822 15.208 4.71293 15.3519 4.86234C15.4958 5.00622 15.5677 5.19991 15.5677 5.4434V18.5171C15.5677 18.8492 15.4598 19.1148 15.244 19.314C15.0282 19.5188 14.7708 19.6211 14.472 19.6211C14.1732 19.6211 13.9159 19.5188 13.7 19.314C13.4842 19.1148 13.3763 18.8492 13.3763 18.5171V6.96244C13.3763 6.81303 13.3016 6.73832 13.1522 6.73832H1.67221C1.34018 6.73832 1.06902 6.63871 0.858734 6.43949C0.653982 6.23474 0.551605 5.98295 0.551605 5.68412Z" fill="#324DC7"/>
        </svg>
    )

    const cropProperty = (
        <InspectorProperty singleColumn={true} divider={false}>
            <LargeButton alt="Crop" onClick={ () => {
                setShowAssetEditModal(cropModal)
            }}>{cropIcon}</LargeButton>
        </InspectorProperty>
    )

    return (
        <div>
            {canCrop != false && asset && asset.src && cropProperty}
        </div>
    )
}

export { InspectorImageActions }