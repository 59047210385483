import React from 'react';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import { InspectorProperty } from '../inspector-ui/InspectorProperty.js';
import { InspectorHeader } from '../inspector-ui/InspectorHeader.js';
import { InspectorControlDate } from '../inspector-ui/InspectorControlDate.js';

export function InspectorDate(props) {
    let { fonts, updateModel } = useAppState();

    var model = props.model;

    if (!model) { return <div></div>; }

    let valueContent = (
        <InspectorProperty singleColumn={true}>
            <InspectorControlDate value={model.date} onChange={v => {
                model.date = v;
                updateModel(model);
            }} />
        </InspectorProperty>
    );

    return <div>
        <section>
            <InspectorHeader title={"Date"} />
            {valueContent}
        </section>

    </div>;
}
