
import { useAppState } from '../content-builder-components/ContentBuilderStateProvider.js'
import { useCanvas } from '../content-builder-components/ContentBuilderCanvasState.js'
import * as React from 'react'

function SectionAdd({ index, group }) {
    const { addSection, dragData, setShowComponentLibrary, setInsertSectionIndex, setInsertSectionGroup } = useAppState()
    const [ isDraggingOver, setIsDraggingOver] = React.useState(false)
    
    const { setHoveredSectionRef, setHoveredSectionId } = useCanvas()

    const onDragOver = function(e) {
        let event = e;
        event.stopPropagation();
        event.preventDefault();
        setIsDraggingOver(true)
    }

    const onDragOut = function() {
        setIsDraggingOver(false)
    }

    const onDrop = function(e) {
        e.stopPropagation()
        setIsDraggingOver(false)
        
        var sectionId = e.dataTransfer.getData("text");
        
        if (Array.isArray(dragData.current)) {
            dragData.current.forEach( child => {
                addSection('', child, index)    
            })
        } else {
            addSection('', dragData.current, index)  
        }
    }

    const onMouseOver = () => {
        setHoveredSectionRef(null)
    }
    
    //const text =  "Add Component"
    
    var className = ''
    if (isDraggingOver) {
        className = 'section-add-dragging-over'
    }

    var onClick = () => {
        setInsertSectionIndex(index)
        setInsertSectionGroup(group)
        setShowComponentLibrary(true)
    }

    var addIcon = (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.02488 17.7326C7.8517 17.7326 6.7477 17.5084 5.71287 17.0602C4.67804 16.6175 3.76495 16.0005 2.97361 15.2091C2.1878 14.4178 1.57078 13.5047 1.12254 12.4699C0.674294 11.435 0.450172 10.331 0.450172 9.15784C0.450172 7.98467 0.674294 6.88066 1.12254 5.84583C1.57078 4.811 2.1878 3.90068 2.97361 3.11488C3.76495 2.32353 4.67527 1.70374 5.70457 1.2555C6.7394 0.807259 7.8434 0.583138 9.01658 0.583138C10.1953 0.583138 11.3021 0.807259 12.3369 1.2555C13.3717 1.70374 14.2848 2.32353 15.0761 3.11488C15.8675 3.90068 16.4873 4.811 16.9355 5.84583C17.3838 6.88066 17.6079 7.98467 17.6079 9.15784C17.6079 10.331 17.3838 11.435 16.9355 12.4699C16.4873 13.5047 15.8675 14.4178 15.0761 15.2091C14.2848 16.0005 13.3717 16.6175 12.3369 17.0602C11.3021 17.5084 10.1981 17.7326 9.02488 17.7326ZM4.9658 9.16615C4.9658 9.4041 5.04327 9.59779 5.19822 9.7472C5.35317 9.89661 5.54962 9.97132 5.78757 9.97132H8.2114V12.4035C8.2114 12.6414 8.28611 12.8379 8.43552 12.9928C8.58494 13.1422 8.77862 13.2169 9.01658 13.2169C9.26007 13.2169 9.45652 13.1422 9.60593 12.9928C9.76088 12.8379 9.83836 12.6414 9.83836 12.4035V9.97132H12.2705C12.5029 9.97132 12.6966 9.89661 12.8515 9.7472C13.0065 9.59779 13.084 9.4041 13.084 9.16615C13.084 8.92266 13.0065 8.7262 12.8515 8.57679C12.7021 8.42184 12.5084 8.34437 12.2705 8.34437H9.83836V5.92054C9.83836 5.68258 9.76088 5.48613 9.60593 5.33118C9.45652 5.1707 9.26007 5.09046 9.01658 5.09046C8.77862 5.09046 8.58494 5.1707 8.43552 5.33118C8.28611 5.48613 8.2114 5.68258 8.2114 5.92054V8.34437H5.78757C5.54962 8.34437 5.35317 8.42184 5.19822 8.57679C5.04327 8.7262 4.9658 8.92266 4.9658 9.16615Z" fill="#324DC7"/>
        </svg>
    )

    var addIcon = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_1_13)">
                    <circle cx="13" cy="11" r="9" fill="white"/>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.7996 7.59134C13.7996 7.10809 13.4079 6.71634 12.9246 6.71634C12.4414 6.71634 12.0496 7.10809 12.0496 7.59134V10.1517H9.59133C9.10808 10.1517 8.71633 10.5435 8.71633 11.0267C8.71633 11.51 9.10808 11.9017 9.59133 11.9017H12.0496V14.4087C12.0496 14.8919 12.4414 15.2837 12.9246 15.2837C13.4079 15.2837 13.7996 14.8919 13.7996 14.4087V11.9017H16.4087C16.8919 11.9017 17.2837 11.51 17.2837 11.0267C17.2837 10.5435 16.8919 10.1517 16.4087 10.1517H13.7996V7.59134Z" fill="black"/>
                    <defs>
                    <filter id="filter0_d_1_13" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_13"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_13" result="shape"/>
                    </filter>
                    </defs>
                 </svg>
    
    
    const text =  "Add Component"

    const addIconSection = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        gap: '6px',
        fontSize: '14px',
        fontWeight: 'semibold',
        color: '#7F7F7F',
        borderRadius: 8,
        cursor: 'pointer'
    }

    return <div onClick={onClick} onMouseOver={onMouseOver} className={`section section-empty section-add ${className}`} onDragOver={onDragOver} onDragLeave={onDragOut}  onDrop={onDrop}>
        <div className={"section-content"} >
            <span style={addIconSection}>
                {addIcon}
                {text}
            </span>
        </div>
    </div>
}

export { SectionAdd }