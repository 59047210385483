import * as React from 'react';
import { Stack } from './Stack.js';
import { Image } from './Image.js';
import { useViewState } from './View.js'
import { InspectorHeader , InspectorImageWell, InspectorControlButton, InspectorContent, InspectorProperty, InspectorControlText, InspectorImageActions, InspectorListItems }  from '../../components/Inspector.js'
import { Gallery } from './Gallery.js';

function ImageGallery(props) {

    const content = (props.images ?? []).map( (asset) => { 
         return <ImageGalleryComponent asset={asset}/>
    });

    return (
        <Gallery {...props} inspector={ () => {
            return <ImageGalleryInspector/>
        }} itemSize={props.itemSize ?? { width : 330, height: 300 }}>
            {content}
        </Gallery>
    )
}


function ImageGalleryInspector() {
    const { model, updateModel } = useViewState()

    // Add or replace an image
    const addImage = (asset, index) => {
        let newAsset = {
            url: asset.src,
            contrast: asset.contrast,
            saturation: asset.saturation,
            dimensions: asset.dimensions,
            title: asset.title,
            description : asset.description,
            crop: asset.crop
        }

        if (index != null) {
            model.images[index] = newAsset
        } else {
            model.images.push(newAsset)
        }

        updateModel(model)
    }

    // Remove an image
    const deleteImage = (index) => {
        model.images.splice(index, 1);
        updateModel(model)
    }

    // Update image
    const updateImage = (index, props) => {
        let image = model.images[index]
        model.images[index] = {
            ...image,
            ...props
        }
        console.log(image)
        updateModel(model)
    }

    // Swap image places
    const moveImage = (oldIndex, newIndex) => {
        const moveItem = model.images[oldIndex];
        model.images[oldIndex] = model.images[newIndex];
        model.images[newIndex] = moveItem;
        updateModel(model)
    }

    const addButton = (
        <InspectorImageWell key={"addImage"} onImageSelected={(asset) => {
            addImage(asset, null)
         }}>
            <InspectorControlButton key={"addImage"} title="Add Image"/>
        </InspectorImageWell>
    )

    const editImageContent = (editingImageIdx) => {
        let editingImage = model.images[editingImageIdx] ?? {}

        const imageProperty = (
            <InspectorImageWell condensed={false} model={{...editingImage, src: editingImage.url ?? editingImage.src }} src={editingImage.url ?? editingImage.src} onImageSelected={(asset) => {
                updateImage(editingImageIdx, {...asset, url: asset.src})
            }}></InspectorImageWell>
        )

        const actionsProperty = (
            <InspectorImageActions asset={{...editingImage, src: editingImage.url }} assetDidChange={(asset) => {
                updateImage(editingImageIdx, {...asset, url: asset.src})
            }}/>
        )
        const footnoteProperty = (                        
            <InspectorProperty key={"footnote"} property={'footnote'} singleColumn={true} title={"Footnote"}>
                <InspectorControlText height={50} multiline={true}  value={editingImage.footnote ?? ""} onChange={v => {
                    updateImage(editingImageIdx, {footnote: v})
                }} />
            </InspectorProperty>
        )

        const altTextProperty = (                        
            <InspectorProperty key={"description"} property={'description'} singleColumn={true} title={"Alt Text"}>
                <InspectorControlText height={50} multiline={true} value={editingImage.description ?? ""} onChange={v => {
                    updateImage(editingImageIdx, {description: v})
                }} />
            </InspectorProperty>
        )
        
        return [
            imageProperty,     
            actionsProperty,       
            altTextProperty,
            footnoteProperty,
        ]
    }

    const imageItems = model.images.map ((image, idx) => {
        return (
            <div key={idx}>
                <InspectorImageWell condensed={true} model={{...image, src: image.url ?? image.src }} src={image.url ?? image.src} onImageSelected={(asset) => {
                    addImage(asset, idx)
                }}></InspectorImageWell>
            </div>
        )
    })

    const mainContent = [
        <InspectorHeader key={"header"} title={"Gallery"} />,

        <InspectorProperty key={"images"} property={'images'} singleColumn={true} title={`Images`}>     
            <Stack spacing={10}>
                <InspectorListItems key={"listItems"} onDelete={deleteImage} onMove={moveImage} editContent={editImageContent} >
                    {imageItems}
                </InspectorListItems>
                {addButton}
            </Stack>                         
        </InspectorProperty>     
    ]

    return (
        <InspectorContent>
            {mainContent}
        </InspectorContent>
    )
}


function ImageGalleryComponent(props) {
    const { model, updateModel, viewId } = useViewState()
    const { asset , index } = props 
    const imageOnChange = function (asset) {
        model.images[index] = {
            ...model.images[index],
            ...asset,
            url: asset.src
        };
        updateModel(model);
    };

    const id = "gallery"

    return (
        <Image borderRadius={4} readonly={true} key={id + "-image" + index} id={id + "-image" + index} src={asset.url ?? asset.src} height={props.height ?? 200} width={props.width} placeholder={true} onChange={imageOnChange} {...asset} />
    );
}

export { ImageGallery }