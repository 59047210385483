
/* eslint-disable */
import TextComponent from './Components/TextComponent.js'
import GalleryComponent from './Components/GalleryComponent.js'
import ImageComponent from './Components/ImageComponent.js'
import VideoComponent from './Components/VideoComponent.js'

import AthenaQuizComponent from "./Components/AthenaQuizComponent.js"
import AthenaImageQuestionComponent from "./Components/AthenaImageQuestionComponent.js" 
import AthenaDrawingResponseComponent from "./Components/AthenaDrawingResponseComponent.js"
import InfoTableComponent  from './Components/InfoTableComponent.js'

const componentList = [
    new TextComponent(),
    new GalleryComponent(),
    new VideoComponent(),
    new ImageComponent(),
    new InfoTableComponent(),
    new AthenaQuizComponent(),
    new AthenaImageQuestionComponent(),
    new AthenaDrawingResponseComponent()
]


const componentTemplates = [
    {
        title: 'Text',
        columns: 2,
        components: [
            { template: new TextComponent("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"), title: "Paragraph", type: 'content' },
            { template: new TextComponent("Section Header", 'h2'), title: "Section Header", type: 'content' },
        ]
    },

    {
        title: 'Media',
        columns: 2,
        icon: 'gallery',
        components: [
            { template: new ImageComponent(), title: "Image", type: 'content' },
            { template: new GalleryComponent(), title: "Gallery", type: 'content' },
            { template: new VideoComponent(), title: "Video", type: 'content' },
            { template: new InfoTableComponent(), title: "Info Table", type: 'content' },
        ]
    },

    {
        title: 'Answers',
        icon: 'quiz',
        columns: 2,
        components: [
            { template: new AthenaQuizComponent(),  title: "Multiple Choice", type: 'answer' },
            { template: new AthenaImageQuestionComponent(),  title: "Image Response", type: 'answer' },
            { template: new AthenaDrawingResponseComponent(), title: "Drawing Response", type: 'answer'}
        ]
    },


]



export { componentList, componentTemplates, GalleryComponent, TextComponent, ImageComponent }