import React from 'react';

function InspectorControlSelect(props) {
    var elements = (props.options ?? []).map((option, idx) => {
        return <option key={idx} value={idx}>{option}</option>;
    });

    var selectedIndex = (props.keys ?? []).indexOf(props.value);
    return <select className="control-select" value={selectedIndex} onChange={(e) => {
        if (props.onChange) {
            props.onChange(props.keys[e.target.value]);
        }
    }}>{elements}</select>;
}

export { InspectorControlSelect }