import React from 'react';
import { Section, Stack, Text, Image } from '@yapstudios/yap-content-builder';
import { InspectorHeader , InspectorContent, InspectorProperty, InspectorControlText, InspectorControlButton, InspectorControlDate, useInspectorState }  from '@yapstudios/yap-content-builder'
import { View } from '@yapstudios/yap-content-builder';
export default class HeaderComponent {
    static type = 'message-header';

    constructor(title, brandName) {
        this.type = 'message-header';
        this.title = title ?? "20-Volt Max 1/2-in Brushless Cordless Drill (Charger and 2-Batteries Included)";
        this.brandName = brandName ?? "BRAND"
    }

    defaultProps() {
        return {
            title: this.title,
            brandName: this.brandName,
        };
    }

    titlesForProp() {
        return {
            title: 'Title',
            brandName: 'Brand Name',
        };
    }

    decode(props) {

        var title = props.title;
        var titleStyle = props.titleStyle ?? "h2";
        var brandName = props.brandName;

        return (
            <Section padding="0" paddingVertical="0" id="section-header"  title="Header">
                    <View width={"100%"} model={props} inspector={(model, updateModel) => {
                return this.inspector(model, updateModel)
            }}>
                    <Stack paddingTop={100} paddingLeft={20} paddingRight={40} paddingBottom={15} spacing={5}>
                        <Text id="caption" canEditStyle={false} componentTitle={this.titlesForProp()['brandName']} readonly={false} content={brandName} style="captionUppercased" align="left" color="secondary" />
                        <Text id="title" canEditStyle={false} componentTitle={this.titlesForProp()['title']} content={title} readonly={true} style={titleStyle} align="left" color="primary" />
                    </Stack>
                </View>        
            </Section>
            
        );
    }

    inspector(model, updateModel) {
        
        return (
            <InspectorContent key={"header-inspector"}>
                    <InspectorHeader title={"Header"} />
                    <Stack spacing={20} align={"leading"} width={"infinity"}>
                        <InspectorProperty key={"title"} property={'title'} singleColumn={true} title={"Title"}>
                            <InspectorControlText multiline={true} value={model.title} onChange={v => {
                                model.inspector = true
                                model.title = v
                                updateModel(model)
                            }} />
                        </InspectorProperty>
                        
                        <InspectorProperty key={"brandName"} property={"brandName"} singleColumn={true} title={"Brand Name"}>
                            <InspectorControlText value={model.brandName} onChange={v => {
                                model.inspector = true
                                model.brandName = v
                                updateModel(model)
                            }} />
                        </InspectorProperty>
                    </Stack>
                </InspectorContent>
        )
    }

    encode(model, props) {

        // From inspector
        if (model.inspector) {
            model.inspector = null
            props = {...model}
            return props
        }

        // From field
        switch (model['id']) {
            case 'title':
                props['title'] = model.content;
                props['titleStyle'] = model.style;
                break;
            case 'brandName':
                props['brandName'] = model.content;
                break;
            default:
                break;
        }

        return props;
    }

    validations() {
        return [
            //PolymerValidations.fieldRequired('title')
        ];
    }
}
