import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { ContentIndexAuthLoader, indexLoader } from "./ContentIndex";
import { ContentBuilderAuthLoader, contentLoader } from "./ContentBuilder";
import { ContentAccess } from "./ContentAccess";
import { ProtectedRoute } from "./ProtectedRoute.js";
import { AuthProvider } from "./useAuth.js"
import { ContentEditorAuthLoader, contentEditorLoader } from "./Editor/ContentEditor.js"
import {
    createBrowserRouter,
    RouterProvider,
    defer
} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <ContentIndexAuthLoader/>,
        loader: () => defer({ userPromise: indexLoader })
    },
    {
        path: "/login",
        element: <AuthProvider><ContentAccess /></AuthProvider>
    },    
    {
        path: "/:documentId",
        element: <ContentBuilderAuthLoader/>,
        loader: () => defer({ userPromise: contentLoader })        
    },
    {
        path: "/editor",
        element: <ContentEditorAuthLoader/>,
        loader: () => defer({ userPromise: contentEditorLoader })                
    },    
]);

const App = () => {
    return (
        <div>
            <RouterProvider router={router} />
        </div>
    )
}

export default App