import React from 'react';
import { useInspectorState } from '../InspectorState.js';

function InspectorHeader(props) {
    const { model } = useInspectorState();
    var title = model.componentTitle ?? props.title;

    var className = "inspector-header"
    if (props.divider) {
        className += ' inspector-header-with-divider';
    }


    const headerContent = <h1 className={className}>{title}</h1>;
    return headerContent;
}

export { InspectorHeader }