import * as React from 'react'

const CanvasContext = React.createContext()

function useCanvas() {
    const context = React.useContext(CanvasContext)
    if (!context) {
        return null
        //throw new Error(`useCanvas must be used within a CanvasContext`)
    }
    return context
}

export { useCanvas, CanvasContext }