import React from 'react'

const InspectorStateContext = React.createContext()

function useInspectorState() {
    const context = React.useContext(InspectorStateContext)
    if (!context) {
        throw new Error(`useInspectorState must be used within a InspectorStateContext`)
    }
    return context
}

function InspectorStateProvider(props) {
    var value = {
        ...props
    }
    return <InspectorStateContext.Provider value={value}>{props.children}</InspectorStateContext.Provider>
}

export { useInspectorState, InspectorStateProvider }