import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from 'usehooks-ts'
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [bucketData, setBucketData] = useSessionStorage("bucketData", null);
    const navigate = useNavigate();

    // call this function when you want to authenticate the user
    const login = async (data) => {
        if (data.institutionId) {
            setBucketData(data);
            navigate("/");
            return true;
        } else {
            setBucketData(null);
            return false;
        }
    };

    // call this function to sign out logged in user
    const logout = () => {
        setBucketData(null);
        navigate("/logout", { replace: true });
    };

    const value = useMemo(
        () => ({
            bucketData,
            login,
            logout
        }),
        [bucketData]
    );
    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};