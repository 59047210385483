import React, { createContext, useContext, ReactNode } from 'react';
import { useVariables } from './Variable.tsx';
import { isFunction, nodeName } from '../Utils.tsx';

// interface FunctionType {
//     : string;
//     lastName: string;
//  }

interface Dictionary<T> {
    [Key: string]: T;
}

// Define a type for the style context
export interface FunctionContextType {
    functions: Dictionary<string>;
    functionArgs: Dictionary<string[]>;
    scripts:  Dictionary<string>;
}


// Create the StyleContext with an empty default value
export const FunctionContext = createContext<FunctionContextType>({ functions: {}, functionArgs: {}, scripts: {} });

// Custom hook to use the StyleContext
export function useFunctions() {
    return useContext(FunctionContext);
}

export function UIFunction({ name, args, children }) {
    const { functions, functionArgs } = useFunctions();

    functions[name] = React.Children.toArray(children).join("\n");
    if (args != null) {
        functionArgs[name] = String(args).split(",");
    }
    
    return <></>
}

export function Call({ name, children }) {
    const { functions, functionArgs } = useFunctions();
    const { variables } = useVariables();
    const [ value, setValue] = React.useState(null);

    React.useEffect( () => {

        const args = React.Children.toArray(children).filter( (item => { return React.isValidElement(item) })).map((child) => {
            var value = null
            var variableName = child.props.variable
            React.Children.forEach(child.props.children, (child) => {
                if (React.isValidElement(child) && nodeName(child) == 'Variable') {
                    let props = child.props as any
                    variableName = props.name 
                } else {
                    value = child
                }
            })

            return { name: child.props.name, variable: variableName, default: child.props.default, value: value }
        });
        //console.log(args)

        if (functions && functions[name]) {

            var dynamicJS = functions[name]
            var result = null 
            
            try {
                let fargs = functionArgs[name] ?? [];
                //if (args.length == 1) {
                    const f = new Function(...fargs, dynamicJS);
                    //result = f(variables[args[0].variable] ?? args[0].value ?? args[0].default);
                    result = f(...args.map((arg) => { return variables[arg.variable] ?? arg.value ?? arg.default }));
                //}
            } catch (e) {
                console.error(e);
            }
            setValue(result);
        }
        //setValue(functions[name]);
    })
    return <>{value}</>
}

export function Argument() {
    return <></>
}