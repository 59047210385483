import React from 'react';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import PolymerAssetType from '../../asset-components/AssetType.js';

function InspectorModelWell(props) {
    const { setShowAssetLibrary, onAssetSelected, setAssetLibraryTypes } = useAppState();
    var content = "Choose Model";
    const style = {};

    var className = ''
    var accessory = ''

    if (props.src && props.src.split) {
        let components = props.src.split('/');
        content = components[components.length - 1];
        
        if (props.title) {
            content = props.title;
        } else {
            content = "Replace"
        }

        accessory = 'Replace'
        className += ' file-filled'
    } else {
        //content = props.src
    }

    const onFileSelectedCallback = (image) => {
        if (props.onFileSelected) {
            props.onFileSelected(image);
        }
    };

    
    return (
        <div className="inspector-file-well" style={{ paddingBottom: "0px" }}
            onClick={() => {
                setAssetLibraryTypes([PolymerAssetType.Model]);
                setShowAssetLibrary(true);
                onAssetSelected.current = onFileSelectedCallback;
            }}>
            <div className={`inspector-file-well-title ${className}`} style={style}>
                <span className='title'>{content}</span>
                <span className='accessory'>{accessory}</span>
            </div>
        </div>
    );
}

export { InspectorModelWell }
