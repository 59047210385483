import * as React from 'react'

function PolymerProgressBar(props) {
    const progressStyle = {
        width: ((props.progress ?? 0) * 100) + '%'
    }

    return (
        <div className="polymer-progress-bar">
            <div className="polymer-progress-bar-inner" style={progressStyle}>
            </div>
        </div>
    )

}

export default PolymerProgressBar