import * as React from 'react';
import Props from "../PropsHelper.js";
import { View } from './View.js';
import { Stack } from './Stack.js';
import { EmptyPlaceholder } from './EmptyPlaceholder.js';

function Gallery(props) {
    const size = props.itemSize ?? { width: 330, height: 300}
    const spacing     = 8
    const paddingLeft = 20

    var model = {
        type: 'gallery',
        ...props,
    };

    const galleryContent = React.Children.map(props.children, (child, index) => {
        return React.cloneElement(child, {
            width: size.width,
            height: size.height,
            index: index,
            key: index
        })
    })

    const emptyContent = <EmptyPlaceholder title="Gallery" height={size.height}/>

    const content = (
        <Stack direction={"horizontal"} align={"leading"} justify={"leading"} spacing={spacing} width="100%" paddingLeft={paddingLeft} paddingBottom="20">
            {galleryContent}
        </Stack>
    )

    const hasContent = !(galleryContent == null || React.Children.count(galleryContent) == 0)
    const containerStyle = { "overflow": "scroll", width: '100%', paddingTop: '20px' };

    return (
        <View id={props.id} model={model} inspector={props.inspector}>
            <div className={"gallery-content"}  style={containerStyle}>
                {hasContent ? content : emptyContent}
            </div>
        </View>
    )
}


export { Gallery }