import * as React from 'react'
import { useIcons } from "../content-builder-components/ContentBuilderIconProvider.js"
import { TableRowDescription } from '../shared-components/TableRow.js';

function validateAssetSize(asset, dimensions, template) {
    if (dimensions.height < template.height) {
        return false
    }
    if (!template.variableWidth && dimensions.width < template.width) {
        return false
    }
    return true
}

function validateAspectRatio(dimensions, template) {
    if (template.aspectRatio == null) {
        return true
    }

    let ratio = dimensions.width / dimensions.height
    let targetAspectRatio = template.aspectRatio.numerator / template.aspectRatio.denominator
    return (ratio == targetAspectRatio)
}

function validateAsset(asset, dimensions, template) {
    var warnings = 0
    let validSize = validateAssetSize(null, dimensions, template)
    let validAspectRatio = validateAspectRatio(dimensions, template)
    if (!validSize) { warnings += 1 }
    if (!validAspectRatio) { warnings += 1 }
    for (var validation of (template.validations ?? [])) {
        let check = validation.check({...asset, ...dimensions})
        if (check == false) {
            warnings += 1
        }
    }

    return warnings
}

function recommendedAssetSize(template) {
    var recommendedWidth = "Any Width"
    var recommendedHeight = "Any Height"

    if (!template.variableWidth && template.width) {
        recommendedWidth = template.width
    }
    if (template.height) {
        recommendedHeight = template.height
    }
    return `${recommendedWidth} x ${recommendedHeight}`
}

function hasValidations({ asset, dimensions, template }) {
    if (dimensions == null || template == null) {
        return false
    }

    if (template.width || template.height) {
        return true
    }

    if (template.aspectRatio) {
        return true
    }
    
    if (template.validations) {
        return true
    }

    return false
}

function AssetValidationDetail({ asset, dimensions, template, showValid }) {
    const icons   = useIcons()

    if (dimensions == null || template == null) {
        return []
    }

    var validations = []
    // Size Validation Info
    
    let validIcon   = icons.get('validation.checkmark', 'color-correct')
    let warningIcon = icons.get('validation.warning', 'color-warning')

    if (template.width || template.height) {
        const sizeValid = validateAssetSize(asset, dimensions, template)
        const sizeInfo  = recommendedAssetSize(template)

        const assetSize = `${dimensions.width}x${dimensions.height}`

        const validMessage   = <span>Asset size of {assetSize} is greater than recommended minimum size.</span>
        const invalidMessage = <span>Asset size of {assetSize} is less than recommended minimum size.</span>

        if (showValid == true || sizeValid == false) {
            validations.push(<TableRowDescription key={"minsize"} icon={sizeValid ? validIcon : warningIcon} title="Minimum Size" value={sizeInfo}>
                                {sizeValid ? validMessage : invalidMessage}
                            </TableRowDescription>)
        }
    }

    if (template.aspectRatio) {
        const validAspectRatio = validateAspectRatio(dimensions, template)
        const targetAspect = <span>{template.aspectRatio.numerator}:{template.aspectRatio.denominator}</span>

        const validMessage   = "Image dimensions are the correct aspect ratio of 1:1."
        const invalidMessage = "Image aspect ratio is not 1:1 as the width is different than the height."

        if (showValid == true || validAspectRatio == false) {
            validations.push(<TableRowDescription key={"aspect-ratio"}  icon={validAspectRatio ? validIcon : warningIcon} title="Aspect Ratio" value={targetAspect}>
                                <span>{validAspectRatio ? validMessage : invalidMessage}</span>
                             </TableRowDescription>)
        }
    }

    if (template.validations) {
        for (var validation of template.validations) {

            let check = validation.check({ ...asset, ...dimensions})
            if (check == false || (validation.validDescription && showValid == true)) {
                let description = validation.description instanceof Function ? validation.description({ ...asset, ...dimensions}) : validation.description
                let validDescription = description
                if (validation.validDescription) {
                    validDescription = validation.validDescription instanceof Function ? validation.validDescription({ ...asset, ...dimensions}) : validation.validDescription
                }
                validations.push(<TableRowDescription key={"validationIssue"}  icon={check ? validIcon : warningIcon} title={validation.title} value={validation.value ?? ""}>
                    <span>{check == true ? validDescription : description}</span>
                </TableRowDescription>)
            }
        }
    }
  
    validations = validations.map( (child, idx) => {
        let element = React.cloneElement(child, {
            first: idx == 0,
            last: idx == validations.length - 1
        })
        return element
    })

    return validations.length > 0 ? validations : null
}

export { validateAssetSize, validateAspectRatio, validateAsset, recommendedAssetSize, AssetValidationDetail , hasValidations}