import * as React from 'react'

const AppAssetStateContext = React.createContext()

function useAppAssetState() {
    const context = React.useContext(AppAssetStateContext)
    if (!context) {
        throw new Error(`useAppAssetState must be used within a PolymerAssetStateProvider`)
    }
    return context
}


function PolymerAssetStateProvider(props) {

    var { assets, assetTemplates, assetLibrary,canCrop, canMultiUpload, onAssetsUpdated, onAssetLibrarySelected, onAssetLibraryChange, onAssetLibraryDelete,onAssetLibraryImport, onFileSelected, onAssetSelected, previewAssetPath } = props

    var emptyDocument = assets == null || assets.length == 0

    /**
     * Setup Default Page (If needed)
     */
    if (emptyDocument) {
        assets = []
    }
    assetTemplates = assetTemplates ? assetTemplates : []

    var currentGroup = assetTemplates ? assetTemplates[0] : {}

    const currentPageRef = React.createRef()

    const [selectedAssetTemplateGroupId, setSelectedAssetTemplateGroupId] = React.useState(currentGroup.id)
    const [sidebarState, setSidebarState] = React.useState("default")

    const onAssetGroupSelected = React.useRef(() => { })
    const dragData = React.useRef(null)

    /**
    * Find current page
    */

    assetTemplates.forEach((assetTemplateGroup, idx) => {
        if (assetTemplateGroup.id == selectedAssetTemplateGroupId) {
            currentGroup = assetTemplateGroup
        }
    })


    let s = {
        assetTemplates: assetTemplates,
        assets: assets,
        assetLibrary: assetLibrary,
        
        onAssetsUpdated: onAssetsUpdated,
        onAssetSelected: onAssetSelected,
        
        onAssetLibraryDelete: onAssetLibraryDelete,
        onAssetLibrarySelected: onAssetLibrarySelected,
        onAssetLibraryChange: onAssetLibraryChange,
        onAssetLibraryImport: onAssetLibraryImport,
        
        onFileSelected: onFileSelected,

        previewAssetPath: previewAssetPath,
        
        selectedAssetTemplateGroupId: selectedAssetTemplateGroupId,
        setSelectedAssetTemplateGroupId: setSelectedAssetTemplateGroupId,

        canCrop: canCrop,
        canMultiUpload: canMultiUpload
    }

    const value = s
    return <AppAssetStateContext.Provider value={value} {...props} />
}

export { PolymerAssetStateProvider, useAppAssetState }