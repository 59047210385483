import React, { useState, useEffect, createContext, useContext } from 'react';
import { useStyle } from './Style.tsx';

// Define a type for the style context
export interface ChildrenContextType {
    name?: string
    content: React.ReactElement[]
    attributes: React.Attributes
}

// Create the StyleContext with an empty default value
export const ChildrenContext = createContext<ChildrenContextType>({ name: "", content: [], attributes: {} });

export function Children() {
  const style = useStyle()     
  let context = useContext(ChildrenContext)
  let content : React.ReactElement[] | null = context.content
  
  if (context && (content.length > 0)) {
        content = context.content.filter( (item) => {
            if (React.isValidElement(item)) {
                return true
            } else {
                return false
            }
        })
  }
  return <div {...context.attributes} style={style}>{content}</div>;
}
