import React from 'react';
import { useInspectorState } from '../InspectorState.js';

function InspectorControlButton(props) {
    const { title } = props
    const { model } = useInspectorState();

    return <button className="inspector-button" onClick={props.onClick}>{title}</button>;
}

export { InspectorControlButton }