import React, { createContext, useContext, ReactNode } from 'react';
import { useStyle, StyleProvider } from './Style.tsx';
import styled from 'styled-components'
import JSON5 from 'json5'

function px(value: string) {
    if (String(value).endsWith('px')) {
        return value;
    } else {
        return `${value}px`;
    }
}

export function Padding({ value, leading, trailing, top, bottom, children } : { value?: string, leading?: string, trailing?: string, top?: string, bottom?: string, children : React.ReactNode }) {
    // Initialize the style object with the current style values
    var style = { ...useStyle() };

    // Apply the padding value to all sides if provided
    if (value) {
        style.padding = px(value);
    }

    // Override specific sides if individual values are provided
    if (leading) {
        style.paddingLeft = px(leading);
    }
    if (trailing) {
        style.paddingRight = px(trailing);
    }
    if (top) {
        style.paddingTop = px(top);
    }
    if (bottom) {
        style.paddingBottom = px(bottom);
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Border
 */
export function Border({ value, children } : { value?: string, children: React.ReactNode[] }) {
    var style = {...useStyle()};

    if (value) {
        style.borderColor = 'black';
        style.borderStyle = 'solid';
        style.borderWidth = px(value);
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * CornerRadius
 */
export function CornerRadius({ value, children } : { value?: string, children: React.ReactNode[] }) {
    const style = {...useStyle()};

    style.borderRadius = value ? px(value) : style.borderRadius;

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Blur
 */
export function Blur({ value, children }: { value?: string, children: React.ReactNode[] }) {
    const style = { ...useStyle() };

    if (value && parseInt(value) > 0) {
        style.filter = `blur(${px(value)})`;
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Background
 */
export function Background({ value, children } : { value?: string, children: React.ReactNode[] }) {
    const style = {...useStyle()};
    style.backgroundColor = value;
    return <StyleProvider style={style}>{children}</StyleProvider>;
}


/**
 * ForegroundStyle
 */
export function ForegroundStyle({ value, children } : { value?: string, children: React.ReactNode[] }) {
    const style = {...useStyle()};
    style.color = value;
    return <StyleProvider style={style}>{children}</StyleProvider>;
}


/**
 * Shadow
 */
export function Shadow({ value, radius, x, y, color, children } : { value?: string, radius: string, x: string, y: string, color: string, children: React.ReactNode[] }) {
    const style = {...useStyle()};
    var shadowRadius = radius ?? "30"
    var shadowX = x ?? "0"
    var shadowY = y ??  String(parseInt(shadowRadius) / 3)
    var shadowColor = color ?? 'rgba(0, 0, 0, 0.1)'
    if (value) {
        shadowRadius = value;
    }

    style.boxShadow = `${px(shadowX)} ${px(shadowY)} ${px(shadowRadius)} ${shadowColor}`;

    return <StyleProvider style={style}>{children}</StyleProvider>;
}


/**
 * Frame
 */
export function Frame({ maxwidth, width, height, minwidth, maxheight, minheight, children}) {
    const style = {...useStyle()};

    const minWidth = minwidth;
    const minHeight = minheight;
    const maxWidth = maxwidth;
    const maxHeight = maxheight;

    if (maxWidth == 'infinity') {
        style.width = '-webkit-fill-available';
    } else if (maxWidth) {
        style['maxWidth'] = px(maxWidth);
    }

    if (minWidth) {
        style['minWidth'] = px(minWidth);
    }

    if (width) {
        style.width = px(width);
    }

    if (maxHeight == 'infinity') {
        style.height = '-webkit-fill-available';
    }  else if (maxHeight) {
        style['maxHeight'] = px(maxHeight);
    } 

    if (minHeight) {
        style['minHeight'] = px(minHeight);
    }

    if (height) {
        style.height = px(height);
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function FlexFrame(props) {  
    return Frame(props);    
}

export function ContainerRelativeFrame({ value, children }) {
    return children
}

export function ID({ value, children }) {
    return children
}

/**
 * Font
 */
export function Font({ value, children }) {
    const style = {...useStyle()};

    const fontStyle = {
        title: [28, '700'],          // Title, typically bold and larger
        largeTitle: [34, '900'],     // Large title, used for prominent headings
        title1: [28, '700'],         // Title 1, a bit larger
        title2: [22, '600'],         // Title 2, smaller than Title 1
        title3: [20, '600'],         // Title 3, smaller than Title 2
        headline: [17, '600'],       // Headline, typically bold text
        subheadline: [15, '400'],    // Subheadline, smaller than headline
        body: [17, '400'],           // Body, regular text size
        callout: [16, '400'],        // Callout, slightly smaller than body
        footnote: [13, '400'],       // Footnote, smaller text
        caption: [12, '400'],        // Caption, small, regular text
        caption2: [11, '400'],       // Caption 2, even smaller
    };

    var fontDefinition = fontStyle[value];   
    if (fontDefinition) {
        style.fontSize = px(fontDefinition[0]);
        style.fontWeight = style.fontWeight ?? fontDefinition[1];
    }
    
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function FontWeight({ value, children }) {
    const style = {...useStyle()};
    const fontWeightMap = {
        bold: '700',
        semibold: '600',
        medium: '500',
        regular: '400',
        light: '300',
        thin: '200',
        ultralight: '100',
        black: '900',
        heavy: '800'
    };

    var weightDefinition = fontWeightMap[value];   
    if (weightDefinition) {
        style.fontWeight = weightDefinition
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}   