import React from 'react';
import Props from '../../page-components/PropsHelper';

function InspectorControlSlider(props) {

    const style = {
        width: Props.width(props)
    }
    
    return (
        <div class="slidecontainer">
            <input  style={style} type="range"  {...props} min={props.min ?? 0} max={props.max ?? 100} value={props.value} onChange={ (event) => {
                if (props.onChange) {
                    props.onChange(event.target.value)
                }
            }} className="control-slider"/>
        </div>
    );
}

export { InspectorControlSlider }