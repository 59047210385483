import React, { useEffect } from 'react';
import JSON5 from 'json5'
import sanitizeHtml from 'sanitize-html';

import { nodeName , nodeString} from './Renderer/Utils.tsx';

// Modifiers
import { Padding, Border, CornerRadius, Frame, FlexFrame, Background, ContainerRelativeFrame, ID, Shadow, ForegroundStyle, Font, FontWeight, Blur } from './Renderer/ui/Modifiers.tsx';

// Views
import { HStack, VStack, ZStack, Spacer } from './Renderer/ui/Layout.tsx';
import { Children } from './Renderer/ui/Children.tsx';
import { Button } from './Renderer/ui/Views/Button.tsx';
import { ForEach, Iterator } from './Renderer/ui/ForEach.tsx';
import { Circle } from './Renderer/ui/Views/Circle.tsx';
import { Group } from './Renderer/ui/Views/Group.tsx';
import { Rectangle } from './Renderer/ui/Views/Rectangle.tsx';
import Text from "./Renderer/ui/Views/Text.tsx"
import { ConfigurationLabel } from './Renderer/ui/ButtonStyle/ConfigurationLabel.tsx';
import { isVariable, isVariableOrExpression, SetVariables, Variable,  Variables } from './Renderer/ui/Variable.tsx';
import parse, { domToReact } from 'html-react-parser';
import { ChildrenContext } from './Renderer/ui/Children.tsx';
import { UIFunction, Call, Argument, FunctionContext } from './Renderer/ui/Function.tsx';    
import { Script } from './Renderer/ui/Script.tsx';
import { State , Defaults } from './Renderer/ui/State.tsx';

// Actions
import { OnTap } from './Renderer/ui/Actions.tsx';

// Tag / component map
const componentsMap = {
    hstack: HStack,
    vstack: VStack,
    zstack: ZStack,
    spacer: Spacer,
    text: Text,
    foreach: ForEach,
    iterator: Iterator,
    function: UIFunction,
    script: Script,
    argument: Argument,
    call: Call,    
    bbutton: Button,
    button: Button,
    circle: Circle,
    state: State,
    defaults: Defaults,
    rectangle: Rectangle,
    group: Group,
    variable: Variable,
    configurationlabel: ConfigurationLabel
};

// Modifier map
const modifiersMap = {
    padding: Padding,
    foregroundstyle: ForegroundStyle,
    background: Background,
    font: Font,
    fontweight: FontWeight,
    border: Border,
    shadow: Shadow,
    blur: Blur,
    cornerradius: CornerRadius,
    containerrelativeframe: ContainerRelativeFrame,
    frame: Frame,
    ontap: OnTap,
    id: ID,
};

String.prototype.capitalize = function() {
    return this.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
};

export function swiftuiToReact(swiftUIString) {
    try {
        return _swiftuiToReact(swiftUIString);
    } catch {
        return <></>
    }
}
export function _swiftuiToReact(swiftUIString) {
        // Regular expressions to match components, modifiers, and braces
        const componentRegex = /([A-Za-z]+)\((.*?)\)/g;
        const modifierRegex = /\.(\w+)\((.*?)\)/g;
        const blockRegex = /(\w+)\s*\{([^}]*)\}/g;
    
        // Function to parse modifiers into props
        function parseModifiers(componentString) {
            let props = {};
            let match;
            while ((match = modifierRegex.exec(componentString)) !== null) {
                const [_, modifier, value] = match;
                // Convert the modifier into a React prop
                const propName = modifier.replace(/([A-Z])/g, (match) => `-${match.toLowerCase()}`);
                props[propName] = value;
            }
            return props;
        }
    
        // Function to parse components and nested blocks
        function parseComponent(block) {
            const components = [];
            let match;
            
            // Handle each block component
            while ((match = blockRegex.exec(block)) !== null) {
                const [_, componentName, innerBlock] = match;
                let attributes = {};
                
                // Find inline attributes (e.g., spacing: 20)
                const inlineAttrs = componentName.match(/\((.*?)\)/);
                if (inlineAttrs) {
                    attributes = JSON.parse(`{${inlineAttrs[1].replace(/(\w+):/g, '"$1":')}}`);
                }
    
                // Find any modifiers
                const modifiers = parseModifiers(innerBlock);
    
                // Combine attributes and modifiers into props
                const props = { ...attributes, ...modifiers };
    
                // Recursively parse the inner block content
                const children = parseComponent(innerBlock);
    
                components.push({ type: componentName.replace(/\(.*?\)/, ''), props, children });
            }
    
            // Handle leaf components (e.g., Text("Hello"))
            swiftUIString.replace(componentRegex, (match, component, props) => {
                components.push({
                    type: component,
                    props: JSON.parse(`{${props.replace(/(\w+):/g, '"$1":')}}`),
                    children: []
                });
            });
    
            return components;
        }
    
        // Parse the top-level SwiftUI block
        const parsedTree = parseComponent(swiftUIString);
    
        return parsedTree;
}

/**
 * jsxStringToReact
 * 
 * Converts a JSX string to a React tree.
 * 
 * Any inbuilt components will be converted to their respective React components.
 * Any custom components will be wrapped in a component with the same name and flagged as unresolved. 
 * 
 * These can then be resolved later using resolveUnresolvedNodes, or left as is to encode to javascript. 
 * 
 * @param {} jsx 
 * @returns 
 */
export function jsxStringToReact(jsx) {

    const parseView = (name, content, attributes)  => {
        const BuiltInComponent = componentsMap[name.toLowerCase()];
        if (BuiltInComponent) {
            return <BuiltInComponent {...attributes}>{content}</BuiltInComponent>;
        } else { 
            let ComponentName = name
            return <ComponentName _unresolved={true} {...attributes}>{content}</ComponentName>;
        }
    };
 
    const options = {
        replace(node) {
            const { type, name, attribs, children } = node;

            if (!attribs) { return; }            

            const c = () => {
                return domToReact(children, options)
            }

            return parseView(name, c(), attribs)
        },
    }

    try {
        const sjsx = sanitizeHtml(jsx + "\n", {
            allowedTags: false,
            allowedAttributes: false,
            allowVulnerableTags: true
        })
        const result = parse(sjsx, options).filter( (item) => { return React.isValidElement(item) } )
        if (result.length > 1) {
            return <Group>{result}</Group>
        } else if (result.length) {
            return result[0]
        } else {
            return result
        }
    } catch (e) {
        console.log('error')
        console.log(e)
    }
}

/**
 * 
 * rewriteVariables
 *
 * Find ndoes with properties that have expressions or variables in them and wrap them in a SetVariables component that will execute the expression at runtime.
 * A node ends up with a single SetVariables component that wraps all the properties that have expressions or variables in them.
 * @param {} node 
 * @returns 
 */
export function rewriteVariables(node) {

    const ignoredProps = [
        'ontap',
        'onclick'
    ]

    const ignoredNodeNames = [
        'ontap',
        'onclick',
        'variable',
    ]

    const ignoredNodeContent = [
        'variable'
    ]

    // Early exit for ignore nodes
    const name = nodeName(node);
    const lcname = String(name).toLowerCase();
    
    if (ignoredNodeContent.includes(lcname)) {
        return node;
    }

    if (ignoredNodeNames.includes(lcname)) {
        const wrappedChildren = React.Children.map(node.props.children, rewriteVariables);
        return React.cloneElement(node, node.props, wrappedChildren);
    }

    if (!React.isValidElement(node)) {
        if (typeof node === 'string' && nodeName(node) != 'Variable') {
            let stringContent = nodeString(node);
            if (isVariableOrExpression(stringContent)) {
                return <Variable>{stringContent}</Variable>;
            }
        }
        return node;
    }


    let newProps = {};
    let shouldWrap = false;

    var variables = {}

    const propFilter = (propName) => { return ignoredProps.includes(String(propName).toLowerCase()) == false }

    Object.keys(node.props).filter(propFilter).forEach(propName => {
        const propValue = node.props[propName];

        if (isVariableOrExpression(propValue)) {
            shouldWrap = true
            variables[propName] = propValue;   
        } else {
            newProps[propName] = propValue;
        }
    });

    const wrappedChildren = React.Children.map(node.props.children, rewriteVariables);

    if (shouldWrap) {
        return (
            <SetVariables variables={variables}>
                {React.cloneElement(node, newProps, wrappedChildren)}
            </SetVariables>
        );
    } else {
        return React.cloneElement(node, newProps, wrappedChildren);
    }
}

/**
 * rewriteReactModifiers
 * 
 * Find nodes with properties that are in-built modifiers and wrap them in a component that will apply the modifier to the node.
 * 
 * It will also translate any properties that are prefixed with the modifier name to be a prop on the modifier component.
 * So for example:
 * frameMaxWidth={100} will become <Frame maxWidth={100}>...</Frame>
 * 
 * @param {} node 
 * @returns 
 */
export function rewriteReactModifiers(node) {


    /**
     * Shortcuts
     */
    let propertyRemap = {
        'maxwidth' : 'framemaxwidth',
        'maxheight' : 'framemaxheight',
        'width' : 'framewidth',
        'height' : 'frameheight',
        'minwidth' : 'frameminwidth',
        'minheight' : 'frameminheight',
    }

    const modifiers = Object.keys(modifiersMap);


    const parseModifier = (name, content, attributeValue) => {   
        let Modifier = modifiersMap[name];
        if (Modifier) {  
            return <Modifier {...attributeValue}>{content}</Modifier>;
        } else {
            return null
        }
    }

    // Traverse the react node and if it has any property that's in the modifiers list, then wrap it in a modifier component as returned by parseModifier and remove that prop from the node 
    const traverseNode = (node) => {
        if (!React.isValidElement(node)) {
            return node;
        }

        let element = node;
        const props = { ...node.props };

        // Traverse children if they exist
        if (props.children) {
            props.children = React.Children.map(props.children, traverseNode);
        }

        var nodeProps = {...props}
        modifiers.forEach((modifier) => {
            const name = modifier.toLowerCase();

            // Get prefixed props
            for (var propName of Object.keys(props)) {  
                if (propName.startsWith(name) && propName !== name) {   
                    delete nodeProps[propName]; 
                }
            }

            delete nodeProps[name];
        });

        // Clone the element and set new props that don't include the modifiers
        let filteredElement = { ...element, props: nodeProps };
        element = React.cloneElement(filteredElement, { }, props.children);

        // Apply modifiers and make them the parent of the current node
        modifiers.forEach((modifier) => {
            var elementProps = {};

            const name = modifier.toLowerCase();


            // Get prefixed props.
            // So any props that have modifiernamePropertyname will become a prop on the modifier like 'propertyname' = 'value' 
            for (var propName of Object.keys(props)) {  
                const elementPropName = propertyRemap[propName] ?? propName  
                if (elementPropName.startsWith(name) && elementPropName !== name) {   
                    const keyName = elementPropName.replace(name, '')

                    // Add new props to the elementProps object
                    elementProps[keyName] = props[propName]
                }
            }   

            // Otherwise use the propertyName=value as the value
            if (props.hasOwnProperty(name)) {
                var value = props[name];
                elementProps['value'] = value;
                delete props[name];
            }

            // Wrap in the modifier if it has any props found for it.
            if (Object.keys(elementProps).length > 0) { 
                element = parseModifier(name, element, elementProps );
            }

        });

        return element;
    };

    return traverseNode(node);    
}   



export const resolveUnresolvedNodes = (node, resolveCallback, lastChildren) => {
    // If the node is a string or number, return it as is
    if (typeof node === 'string' || typeof node === 'number') {
        return node;
    }

    // If the node is an array, map over its children
    if (Array.isArray(node)) {
        return node.map(child => resolveUnresolvedNodes(child, resolveCallback, lastChildren));
    }

    // If the node is an object (React element)
    if (React.isValidElement(node)) {
        
        // If the node has _unresolved={true}, resolve it
        if (node.props && node.props._unresolved === true) {

            var resolvedProps = {...node.props};


            const resolvedNode = resolveCallback(node.type, resolvedProps, node.children);
            var returnNode = null


            // If the resolved node contains a <Children/> component, replace it with the original children
            if (resolvedNode && resolvedNode.props && resolvedNode.props.children) {
                returnNode = resolveUnresolvedNodes(resolvedNode, resolveCallback, node.props.children);
            } else {
                returnNode = resolvedNode;
            }

            var variableProps = {...resolvedProps};

            return (
                <Variables {...variableProps}>
                    {returnNode}
                </Variables>
            )
        }

        // If it's a regular node, clone it and recursively resolve its children
        const newProps = { ...node.props };
        if (newProps.children) {
            newProps.children = React.Children.map(newProps.children, child => {
                if (child && (child.type === Children  || String(child.type).toLowerCase() === 'children')) {
                    return resolveUnresolvedNodes(lastChildren, resolveCallback, null);
                } else {
                    return resolveUnresolvedNodes(child, resolveCallback, lastChildren);
                }
            });
        }

        return React.createElement(node.type, newProps);
    }

    // For any other type of node, return it unchanged
    return node;
}

