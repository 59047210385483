import React from 'react';
import { Section, Stack, Text, YapUI } from '@yapstudios/yap-content-builder';

export default class NumberComponent {
    constructor(text, style) {
        this.type = 'number';
        this.defaultNumber = 1
    }

    titlesForProp() {
        return {
            number: 'Number'
        };
    }

    defaultProps() {
        return {
            number: 1,
            style: this.defaultStyle,
            align: "left"
        };
    }

    yapUI(props) {
        return YapUI.Directive('NumberHeader', { number: props.number }, [ ])
    }

    decode(props) {

        let containerStyle = {
            width: '32px',
            height: '32px',
            background: 'var(--content-builder-ui-tint-color)',
            borderRadius: '32px',
            display: 'flex',
            alignItems: 'center'
        }

        return (
            <Section padding="0" paddingVertical="0" id="text" title="Header">
                <Stack paddingTop={40} paddingHorizontal={20} spacing={20}>
                    <div style={containerStyle}>
                        <Text id="title" componentTitle={"Number"} content={String(props.number) ?? "1"} canEditStyle={false} style={"headline"} align={"center"} color="white" />
                    </div>
                </Stack>
            </Section>
        );
    }

    encode(model, props) {
        props['number'] = model.content;
        return props;
    }

    icon() {
        return (
<svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="100" height="70" fill="white"/>
<path d="M49.8696 47.251C48.245 47.251 46.7176 46.9403 45.2876 46.3188C43.8576 45.7049 42.596 44.8514 41.5029 43.7583C40.4098 42.6727 39.5526 41.4149 38.9312 39.9849C38.3172 38.5549 38.0103 37.0238 38.0103 35.3916C38.0103 33.7669 38.3172 32.2396 38.9312 30.8096C39.5526 29.3796 40.4061 28.118 41.4917 27.0249C42.5848 25.9318 43.8464 25.0783 45.2764 24.4644C46.7139 23.8429 48.245 23.5322 49.8696 23.5322C51.4943 23.5322 53.0216 23.8429 54.4517 24.4644C55.8892 25.0783 57.1507 25.9318 58.2363 27.0249C59.3294 28.118 60.1867 29.3796 60.8081 30.8096C61.4295 32.2396 61.7402 33.7669 61.7402 35.3916C61.7402 37.0238 61.4295 38.5549 60.8081 39.9849C60.1867 41.4149 59.3294 42.6727 58.2363 43.7583C57.1507 44.8514 55.8929 45.7049 54.4629 46.3188C53.0329 46.9403 51.5018 47.251 49.8696 47.251ZM50.6558 40.9058C51.105 40.9058 51.4644 40.7785 51.7339 40.5239C52.0034 40.2694 52.1382 39.8763 52.1382 39.3447V31.4272C52.1382 30.9556 51.9847 30.5775 51.6777 30.293C51.3783 30.0085 50.9777 29.8662 50.4761 29.8662C50.1541 29.8662 49.8547 29.9149 49.5776 30.0122C49.3006 30.1021 48.9937 30.2593 48.6567 30.4839L46.7476 31.7529C46.5379 31.8877 46.3807 32.0374 46.2759 32.2021C46.1785 32.3594 46.1299 32.554 46.1299 32.7861C46.1299 33.0706 46.2235 33.314 46.4106 33.5161C46.5978 33.7108 46.8337 33.8081 47.1182 33.8081C47.2454 33.8081 47.3577 33.7931 47.4551 33.7632C47.5599 33.7332 47.6984 33.6584 47.8706 33.5386L49.0835 32.7412H49.1958V39.3447C49.1958 39.8763 49.3268 40.2694 49.5889 40.5239C49.8584 40.7785 50.214 40.9058 50.6558 40.9058Z" fill="#384CC0"/>
</svg>

        )
    }

}
