import React from 'react';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import { InspectorProperty } from '../inspector-ui/InspectorProperty.js';
import { InspectorHeader } from '../inspector-ui/InspectorHeader.js';
import { InspectorControlSelect } from '../inspector-ui/InspectorControlSelect.js';
import { InspectorControlText } from '../inspector-ui/InspectorControlText.js'
import { InspectorControlButton } from '../inspector-ui/InspectorControlButton.js'
import { InspectorVideoWell } from '../inspector-ui/InspectorVideoWell.js';
import { Stack } from '../../page-components/ui/Stack.js';
import { HStack } from '../../page-components/ui/HStack.js';
import { VStack } from '../../page-components/ui/VStack.js';
import { Spacer } from '../../page-components/ui/Spacer.js';
import { useIcons } from "../../content-builder-components/ContentBuilderIconProvider.js"

export function InspectorVideo(props) {
    let { updateModel } = useAppState();
    var model = props.model;
    var videoRef = model.videoRef
        
    const titleProperty = (                        
        <InspectorProperty key={"title"} property={'title'} singleColumn={true} title={"Title"}>
            <InspectorControlText value={model.title} onChange={v => {
                updateModel({...model, 
                            title: v })
            }} />
        </InspectorProperty>
    )

    const videoWellProperty = (
        <InspectorProperty key={"video"} property={"video"} singleColumn={true} title={"Video"}>
            <InspectorVideoWell title={model.assetTitle} videoRef={videoRef} src={model.src} onImageSelected={ (asset) => {
                updateModel({...model,
                            src: asset.src,
                            assetTitle: asset.title,
                            dimensions: asset.dimensions   
                        })
            }} ></InspectorVideoWell> 
        </InspectorProperty>
    )

    const playbackStyleProperty = (
        <InspectorProperty title="Playback Style" singleColumn={false} divider={true}>
            <InspectorControlSelect value={model.playbackStyle == null ? 'fullscreen' : model.playbackStyle} options={['Fullscreen', 'Inline Autoplay']} keys={['fullscreen', 'inline']} onChange={v => {
                updateModel({...model, 
                            playbackStyle: v})
            }} />
        </InspectorProperty>
    )    


    const fullbleedProperty = (
        <InspectorProperty title="Inset" singleColumn={false} divider={false}>
            <InspectorControlSelect value={model.fullbleed == true ? 'fullbleed' : 'default'} options={['Inset', 'Full Bleed']} keys={['default', 'fullbleed']} onChange={v => {
                updateModel({...model, 
                            fullbleed: (v == 'fullbleed')})
            }} />
        </InspectorProperty>
    )    

    const posterProperty = (
        <InspectorProperty divider={true} key={"thumbnailTime"} singleColumn={true} title={"Thumbnail"}>
            <VideoThumbnailControls videoRef={videoRef} value={model.thumbnailTime} onChange={v => {
                updateModel({...model, 
                    thumbnailTime: v})
            }}/>
        </InspectorProperty>
    )

    const previewControls = (
        <InspectorProperty divider={true} key={"preview"} singleColumn={true} title={"Preview"}>
            <VideoInspectorControls videoRef={videoRef}/>
        </InspectorProperty>
    )

    const editOptions = model.editOptions ?? [ 'playbackStyle', 'fullbleed', 'thumbnailTime']

    return (
        <div>
                <InspectorHeader title={"Video"} />
                <Stack spacing={0} align={"leading"} width={"infinity"}>
                    <Stack spacing={10} align={"leading"} width={"infinity"}>
                        {titleProperty}
                        {videoWellProperty}
                    </Stack>
                    <Stack spacing={10} align={"leading"} width={"infinity"}>
                        {editOptions.includes('playbackStyle') && playbackStyleProperty}
                        {editOptions.includes('fullbleed') && fullbleedProperty}
                    </Stack>
                    {model.src && editOptions.includes('thumbnailTime') && posterProperty}
                    {model.src && previewControls}
                </Stack>
        </div>
    )
}

export function VideoThumbnailControls(props) {
    const [duration, setDuration] = React.useState(0)
    const [progress, setProgress] = React.useState(0)
    const [isDragging, setIsDragging] = React.useState(false)

    const video = props.videoRef.current
    const value = props.value ?? 0

    
    const onDurationChanged = (event) => {
        if (video == null) { return }
        if (video.duration) {
            console.log('onDurationChanged')
            let duration = video.duration ?? 0
            setDuration(video.duration ?? 0) 
            setProgress(value / duration)
        }
    }

    React.useEffect( () => {
        const video = props.videoRef.current
        if (video == null) { return }

        video.addEventListener("durationchanged", onDurationChanged)
        
        onDurationChanged()

        return () => {
            video.removeEventListener("durationchanged", onDurationChanged)
        }

    }, [props.videoRef.current, props.videoRef, isDragging])

    if (video == null) {
        return <div></div>
    }

    const onProgressDragStart = () => {
        console.log('drag start')
        video.pause()
        setIsDragging(true)
    }

    const onProgressDragEnd = () => {
        console.log('drag end')
        setIsDragging(false)
        if (props.onChange) {
            props.onChange(duration * progress)
        }
    }

    const onProgressDragChange = (event) => {
        const progress = event.target.value / 100
        setProgress(progress)
        video.currentTime = progress * duration
    }
    
    const slider = (
        <HStack spaceItems={"between"}>
            <span>{mmss(duration > 0 ? progress * duration : 0)}</span>
            <Spacer/>
                <input onMouseDown={onProgressDragStart} onChange={onProgressDragChange} onMouseUp={onProgressDragEnd} style={{"width": "200px"}} type="range" min="0" max="100" value={progress * 100}></input>
            <Spacer/>
            <span>{mmss(duration > 0 ? duration : 0)}</span>
        </HStack>
    )

    return (
        slider
    )
}

export function VideoInspectorControls(props) {
    const [playing, setPlaying] = React.useState(false)
    const [duration, setDuration] = React.useState(0)
    const [playbackTime, setPlaybackTime] = React.useState(0)
    const [progress, setProgress] = React.useState(0)
    const [isDragging, setIsDragging] = React.useState(false)

    const video = props.videoRef.current

    const icons = useIcons()

    const onPlaybackChange = (event) => {
        if (video == null) { return }
        console.log('onPlaybackChange')  
        setPlaying(video.paused == false)
    }

    const onDurationChanged = (event) => {
        if (video == null) { return }
        console.log('onDurationChanged')
        setDuration(video.duration ?? 0) 
    }

    const onProgressChanged = (event) => {
        if (video == null) { return }
        if (isDragging == false && video.paused == false) {
            console.log(video.playbackRate)
            setPlaybackTime(video.currentTime)
            if (video.duration > 0) {
                setProgress(video.currentTime / video.duration)
            } else {
                setProgress(0)
            }            
        }
    }

    React.useEffect( () => {
        const video = props.videoRef.current
        if (video == null) { return }

        video.addEventListener("ratechange", onPlaybackChange)
        video.addEventListener("pause", onPlaybackChange)
        video.addEventListener("play", onPlaybackChange)
        video.addEventListener("durationchanged", onDurationChanged)
        video.addEventListener("loadedmetadata", onDurationChanged)
        video.addEventListener("timeupdate", onProgressChanged)
        
        onPlaybackChange()
        onDurationChanged()
        onProgressChanged()

        return () => {
            video.removeEventListener("ratechange", onPlaybackChange)
            video.removeEventListener("pause", onPlaybackChange)
            video.removeEventListener("play", onPlaybackChange)
            video.removeEventListener("durationchanged", onDurationChanged)
            video.removeEventListener("timeupdate", onProgressChanged)
        }

    }, [props.videoRef.current, props.videoRef, isDragging])

    if (video == null) {
        return <div>{props.videoRef.current}</div>
    }

    const playButton = (
        <InspectorControlButton title={playing ? icons.get('button.pause') : icons.get('button.play')} onClick={ () => {
            if (playing) {
                video.pause()
            } else {
                video.play()
            }
        }}>
            
        </InspectorControlButton>
    )

    const onProgressDragStart = () => {
        console.log('drag start')
        setIsDragging(true)
    }

    const onProgressDragEnd = () => {
        console.log('drag end')
        setIsDragging(false)
    }

    const onProgressDragChange = (event) => {
        const progress = event.target.value / 100
        setProgress(progress)
        video.currentTime = progress * duration
    }

    const slider = (
        <HStack spaceItems={"between"}>
            <span>{mmss(duration > 0 ? progress * duration : 0)}</span>
            <Spacer/>
                <input onMouseDown={onProgressDragStart} onChange={onProgressDragChange} onMouseUp={onProgressDragEnd} style={{"width": "200px"}} type="range" min="0" max="100" value={progress * 100}></input>
            <Spacer/>
            <span>{mmss(duration > 0 ? duration : 0)}</span>
        </HStack>
    )

    return (
        <VStack paddingTop={10} spacing={20}>
            {slider}
            {playButton}
        </VStack>
    )
}

export function mmss(seconds) {
    if (seconds == null) {
        return `00:00`
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    
    const minutesString = String(minutes).padStart(2, '0');
    const secondsString = String(remainingSeconds).padStart(2, '0');
    
    return `${minutesString}:${secondsString}`;
 }